import { faMapMarker, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const AddressUI = ({ title, address, phoneNumber, notes }) => {
  return (
    <div className='border-2 border-gray-200 rounded-md p-8'>
      <h1 className='text-base font-semibold text-yellow-600 mb-3'>{title}</h1>
      <section className='flex items-start mb-3'>
        <FontAwesomeIcon icon={faMapMarker} className='w-4 h-4 mr-2 mt-1' />
        <span className='text-sm font-light'>{address}</span>
      </section>
      <section className='flex items-center'>
        <FontAwesomeIcon icon={faPhone} className='w-4 h-4 mr-2' />
        <span className='text-sm font-light'>{phoneNumber}</span>
      </section>
      {
        notes && notes.length ?
          <ul className='px-4 mr-8 font-medium text-xs text-gray-500 grid gap-y-2 mt-4'>
            {notes.map(note => <li>{note}</li>)}
          </ul> : null
      }
    </div>
  )
}

export default AddressUI;
