import React, {useState} from 'react'
import {NavLink, useParams} from "react-router-dom";
import Select from 'react-select'
import MyProductsSelectStyle from "../../../../constants/custom_styles/myProductsSelectStyle";

export const MobileCategoriesHeader = ({selectClass, category}) => {
    let {market_slug, integration_slug} = useParams()
    let query = new URLSearchParams(location.search)
    let is_market_query = query.get('market_slug')
    const [selected, setSelected] = useState(null)
    const options = category.children_with_products.map((subCategory) => {
        return {label: subCategory.name, value: subCategory.permalink}
    })
    options.push({label: "All Products", value: category.permalink})

    const handleChange = (selected) => {
        setSelected(selected)
        let location = ''

        let setLocation = (slug) => {
            location = slug && `/markets/${slug}/${selected.value}` || `/${selected.value}`
        }

        setLocation(market_slug || integration_slug || is_market_query)

        window.location.href = `${location}`
    }
    return <Select className={selectClass || "w-10"} styles={MyProductsSelectStyle} components={{IndicatorSeparator: () => null}}
                   isClearable={false} options={options} value={selected} onChange={handleChange}/>
}

export const ParentSubCategories = ({category}) => {
    return (
        <div className='flex items-start'>
            <NavLink activeClassName='text-gray-600 text-sm hover:text-gray-700'
                     to={`/${category.permalink}`}>All Products</NavLink>
            {category.children_with_products.map(child => (<SubCategoryHeader key={child.id} category={child}/>))}
        </div>
    )
}

export const SubCategories = ({category}) => {
    return (
        <div className='flex items-center text-sm text-gray-600 hover:text-gray-700'>
            <NavLink className=''
                     style={{'textDecorationColor': 'rgba(245, 158, 11)', 'textDecorationThickness': '3px'}}
                     activeClassName='underline' to={`/${category.permalink}`}>All
                Products</NavLink>
            {category.children_with_products.length > 0 ? (
                    category.children_with_products.map(child => (<SubCategoryHeader key={child.id} category={child}/>))
                ) :
                <SubCategoryHeader key={category.id} category={category}/>
            }
        </div>
    )
}

export const SubCategoryHeader = ({category}) => {
    return (
        <CategoryLinkSubHeader category={category}>
            <div
                className='truncate text-gray-600 text-sm px-4 hover:text-gray-700'>
                <div className='text-center'>{category.name}</div>
            </div>
        </CategoryLinkSubHeader>
    )
}

export const CategoryLinkSubHeader = ({category, className, children}) => {
    let query = new URLSearchParams(location.search)
    let is_market_query = query.get('market_slug')

    function categoryUrl({category}) {
        let {market_slug, integration_slug} = useParams()

        if (market_slug) {
            return `/markets/${market_slug}/${category.permalink}`
        } else if (integration_slug) {
            return `/shops/${integration_slug}/${category.permalink}`
        } else if (is_market_query) {
            return `/markets/${is_market_query}/${category.permalink}`
        } else {
            return `/${category.permalink}`
        }
    }

    return (
        <NavLink style={{'textDecorationColor': 'rgba(245, 158, 11)', 'textDecorationThickness': '3px'}}
                 activeClassName='underline' to={categoryUrl({category: category})} className={className}>
            {children}
        </NavLink>
    )
}
