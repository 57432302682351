import React from 'react';

const NoData = ({ className }) => {
  return (
    <div className={className}>
      <h3 className='text-lg text-center px-8 py-4 font-light'>No recent orders</h3>
      <a href='/products' className='text-sm text-center px-8 py-3 text-white bg-yellow-600 font-light rounded-full'>View All Products</a>
    </div>
  )
}

export default NoData;
