import React from 'react'
import { formatPrice } from "../../../../utilities/formatPrice";

const OrderSection = ({ item }) => {
  return (
    <>
      <div className='flex items-center justify-between w-full py-2'>
        <div className='flex flex-col items-start w-full'>
          <span>{item.product_title}</span>
          <span className='text-gray-700 text-md'>{item.variant_title}</span>
        </div>
        <div
          className='flex items-center justify-center w-full'>{formatPrice(item.price)} x {item.quantity}</div>
        <div className='w-full flex items-end justify-end'>{formatPrice(item.price * item.quantity)}</div>
      </div>
      <hr className='w-full' />
    </>
  )
}

export default OrderSection
