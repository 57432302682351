import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import ShippingRow from './shippingRow';
import CountrySelect from '../shared/countrySelect';
import ShippingFormContext from '../shippingFormContext';

const InternationalShippings = () => {
  const { values } = useFormikContext();
  const {
    countries,
    currencyPrefix,
    hasCountries,
    hasInternationalShippings,
    getErrorsInInternationalShippingAt,
    updateInternationalShippingAt,
    deleteInternationalShippingAt
  } = useContext(ShippingFormContext);

  return (
    <>
      {
        hasCountries && hasInternationalShippings &&
        values.international_shippings.map((shipping, index) =>
          !shipping._destroy &&
          <ShippingRow
            name='flat_rate'
            labelClassName='flex flex-row gap-2'
            rowClassName='gap-2 pt-8 bg-white flex items-start justify-between'
            currencyPrefix={currencyPrefix}
            values={shipping}
            errors={getErrorsInInternationalShippingAt(index)}
            enableDeletion={true}
            enableActions={true}
            getFieldName={partialFieldName => partialFieldName}
            updateFormikValue={(fieldName, fieldValue) => {
              updateInternationalShippingAt(index, fieldName, fieldValue)
            }}
            label={
              <CountrySelect
                label='International'
                countries={countries}
                countryId={shipping.country_id}
                onChange={updatedId => {
                  updateInternationalShippingAt(index, 'country_id', updatedId);
                }}
              />
            }
            onDelete={() => deleteInternationalShippingAt(index)}
          />
        )
      }
    </>
  )
}

export default InternationalShippings;
