import React from "react"

function LinkedInIconUnfilled() {
    return(
        <svg 
        className="h-5 w-6 text-white hover:fill-current hover:text-mustard-400"
        viewBox="0 0 20 20" 
        stroke="currentColor"
        fill="none" 
        strokeWidth="1.5"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path d="M4.93414 19.0011C5.21029 19.0011 5.43414 
        18.7772 5.43414 18.5011V6.81748C5.43414 6.54134 
        5.21029 6.31748 4.93414 6.31748H1.29031C1.01417 
        6.31748 0.790314 6.54134 0.790314 6.81748V18.5011C0.790314 
        18.7772 1.01417 19.0011 1.29031 19.0011H4.93414ZM14.4358 
        18.5011C14.4358 18.7772 14.6597 19.0011 14.9358 
        19.0011H18.5718H18.5758C18.8519 19.0011 19.0758 
        18.7772 19.0758 18.5011V12.0831C19.0758 10.5018 18.9107 
        8.9853 18.1984 7.85886C17.4529 6.67976 16.1764 6.02451 
        14.2101 6.02451C12.9393 6.02451 11.9242 6.47498 11.2114 
        7.08372V6.81748C11.2114 6.54134 10.9875 6.31748 10.7114 
        6.31748H7.21664C6.9405 6.31748 6.71664 6.54134 6.71664 
        6.81748V18.5011C6.71664 18.7772 6.9405 19.0011 7.21664 
        19.0011H10.8565C11.1327 19.0011 11.3565 18.7772 11.3565 
        18.5011V12.7159C11.3565 11.9635 11.433 11.3274 11.6759 
        10.8888C11.7909 10.6813 11.9409 10.5225 12.1421 10.4116C12.3463 
        10.2991 12.6324 10.2198 13.0414 10.2198C13.4288 10.2198 
        13.6752 10.309 13.8398 10.4233C14.0053 10.5382 14.1307 
        10.7083 14.2241 10.9432C14.4225 11.442 14.4358 12.1139 
        14.4358 12.8136V18.5011ZM0.5 3.10264C0.5 4.53483 1.66486 
        5.72373 3.11027 5.72373C4.55522 5.72373 5.72054 4.53489 
        5.72054 3.10264C5.72054 2.41206 5.445 1.75021 4.95524 
        1.26257C4.46556 0.774994 3.80186 0.501465 3.11027 
        0.501465C2.41868 0.501465 1.75498 0.774994 1.26529 
        1.26257C0.775537 1.75021 0.5 2.41206 0.5 3.10264Z" 
        strokeLinejoin="round"/>
        </svg>
    );
}

export default LinkedInIconUnfilled;
