import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import IMGButton from '../../shared/IMGButton';

import IcEdit from '../../../../../../assets/images/pencil-edit.png';
import IcDelete from '../../../../../../assets/images/ic-delete.png';

const SavedShippingAddressUI = ({
  className,
  address,
  onEdit,
  onDelete,
  onChoose,
  disableInteraction
}) => {

  if (!address)
    return null;

  const getAddressUIProperties = () => {
    if (address.is_default) {
      return {
        defaultAddressHolderColor: 'bg-orange-100',
        defaultAddressLabelSpan: <span className='text-sm font-light text-red-400'>This is your default shipping address</span>,
        defaultAddressChooseButton: null
      }
    } else {
      return {
        defaultAddressHolderColor: '',
        defaultAddressLabelSpan: null,
        defaultAddressChooseButton: <button onClick={onChoose} disabled={disableInteraction}
          className='rounded-l-full rounded-r-full px-6 py-2 relative bg-gray-200 text-sm'>Choose</button>
      }
    }
  }

  const addressUIProperties = getAddressUIProperties();

  return (
    <section className={`border border-beige-200 rounded-md p-4 ${addressUIProperties.defaultAddressHolderColor} ${className}`}>
      {addressUIProperties.defaultAddressLabelSpan}
      <h3 className='text-base font-bold'>{address.name}, {formatPhoneNumber(address.phone_number)}</h3>
      <p className='text-base font-light'>{address.street_address}, {address.city}
        , {address.province}, {address.country}, {address.postal_code}</p>
      <section className='flex justify-between mt-4'>
        <section>
          {addressUIProperties.defaultAddressChooseButton}
        </section>
        <section className='mr-0 ml-auto grid grid-cols-2 gap-x-2 content-center'>
          <IMGButton onClick={onEdit} src={IcEdit} label='Edit' disabled={disableInteraction}
            imgClassName='w-4 h-auto mr-1' labelClassName='text-sm font-light text-teal-800 sm:inline hidden' />
          <IMGButton onClick={onDelete} src={IcDelete} label='Delete' disabled={disableInteraction}
            imgClassName='mr-1' labelClassName='text-sm font-light text-red-800 sm:inline hidden' />
        </section>
      </section>
    </section>
  )
}

export default SavedShippingAddressUI;
