import React from 'react';
import RateTypeSelect from '../shared/rateTypeSelect';
import ValueInput from '../shared/valueInput';
import DeleteButton from '../shared/deleteButton';
import ToggleButton from '../shared/toggleButton';

const ShippingRow = ({
  name,
  label,
  currencyPrefix,
  rowClassName,
  labelClassName,
  values,
  errors,
  getFieldName,
  updateFormikValue,
  enableActions,
  enableDeletion,
  onDelete
}) => {

  const hasErrorInRates = !!(errors && errors[name])

  return (
    <tr className={rowClassName}>
      <td className={`text-left pl-5 font-bold`}>{label}</td>
      <td className={labelClassName}>
          <h2 className={`text-sm xl:text-base pt-3 px-3`}>Flat Rate</h2>
        <RateTypeSelect getFieldName={getFieldName} updateFormikValue={updateFormikValue} values={values} className={"w-40"} />
        <div className='flex flex-col items-center'>
          <ValueInput currencyPrefix={currencyPrefix} value={values[name]}
            onValueChange={value => updateFormikValue(name, value)}
          />
          <span className={`${hasErrorInRates && "visible" || "invisible"} text-red-600 text-xs font-light`}>should be a valid rate</span>
        </div>


      <td className={`text-right`}>
        <ToggleButton
          isChecked={values[getFieldName('enabled')]}
          onChange={checked => updateFormikValue(getFieldName('enabled'), checked)}
          iconChecked={<h2 className='text-sm absolute pt-2 left-4 text-white'>Enabled</h2>}
          iconUnchecked={<h2 className='text-sm absolute pt-2 right-4 text-gray-600 '>Disabled</h2>}
          width={105}
          height={33}
          diameter={24}
        />
      </td>
          <td>
              <DeleteButton onDelete={onDelete} className={`${enableDeletion && "block"|| "hidden"} pt-2 pr-2 w-full`} />
          </td>

      </td>
    </tr>
  )
}

export default ShippingRow;
