import PopularCategories from '../main/page_body/mainPage/popularCategories'
import DiscoverProducts from '../main/page_body/mainPage/discoverProducts'
import React, { useContext, useState, useEffect } from 'react'
import ComingSoon from '../main/page_body/shared_components/comingSoon'
import { MarketContext } from './market'
import FeaturedBrands from '../main/page_body/mainPage/featuredBrands'
import LoadingSpinner from '../main/page_body/shared_components/loadingSpinner'
import ReactGA from 'react-ga'
import FooterBanner from './footerBanner'
import SplashBanner from './marketSplashBanner'
import MadeLocallyProducts from "../main/page_body/mainPage/madeLocallyProducts";
import CommunityMarkets from "../main/page_body/mainPage/communityMarkets";

const MarketLandingPage = () => {
  let { market } = useContext(MarketContext)
  let query = new URLSearchParams(location.search)
  let is_preview = query.get('preview')
  
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize(window.innerWidth);
    });
  }, []);
  const carousel_arrows = {
    right: "-0.7rem",
    top: (768 < screenSize && "57rem") || ((640 < screenSize < 769) && "53rem")
  }



  if (market) {
    if (is_preview || market.is_live) {
      ReactGA.initialize(
        market.google_analytics_tracker
          ? `${market.google_analytics_tracker}`
          : ''
      )
      ReactGA.pageview(window.location.pathname)
      return (
        <>
          <SplashBanner className='text-left px-4'
                        market={market} link={`/markets/${market.slug}/products`}
                        title={market.name}
                        tagline={market.tag_line}
          />
          <div>
            <div className='mx-auto md:p-8'>
              <DiscoverProducts arrow_positon={carousel_arrows} show_more_link={`/markets/${market.slug}/products`}/>
              <FeaturedBrands market={market} />
            </div>              
              <MadeLocallyProducts  />
            </div>
            <div className='mx-auto md:p-8'>
              <CommunityMarkets />
              <div className='pb-7 lg:p-8'><PopularCategories market={market} /></div>
            </div>
            {market.footer_banner && (
              <FooterBanner footer_banner={market.footer_banner} />
            )}

        </>
      )
    } else {
      return <ComingSoon />
    }
  } else {
    return <LoadingSpinner />
  }
}

export default MarketLandingPage
