import React, { useContext } from 'react'
import Products, { ProductsContext } from "../main/page_body/products/products";
import ProductsGrid from "../main/page_body/products/productsGrid";
import Shops from "../main/page_body/brands/shops";
import SubHeader from "../main/page_body/shared_components/subHeader";
import { MarketContext } from "./market";

const MarketSearchResultsPage = () => {
  const SearchResults = () => {
    const { searchParams } = useContext(ProductsContext)
    let { market } = useContext(MarketContext)

    return (
      <>
        <div className='mb-8'>
          <SubHeader location={market?.name} breadcrumbs={searchParams} />
        </div>
        <div className='p-2 md:p-8'>
          <div className='container mx-auto'>
            <ProductsGrid />
          </div>
        </div>
      </>
    )
  }

  return (
    <Products>
      <Shops>
        <SearchResults />
      </Shops>
    </Products>
  )
}

export default MarketSearchResultsPage
