import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const PreviousButtonLabel = () => {
  return (
    <div className='flex flex-row items-center'>
      <FontAwesomeIcon className='w-5 h-full mb-px' icon={faLongArrowLeft} />
    </div>
  )
}

export default PreviousButtonLabel;
