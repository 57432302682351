import React from 'react';
import SubHeaderImage from '../../main/page_body/shared_components/subHeaderImage';
import UserTabs from './userTabs';

const NavigationBar = ({ activeKey }) => {
  return (
    <div className='md:block hidden'>
      <SubHeaderImage className='px-8 lg:py-4 py-0'>
        <UserTabs activeKey={activeKey} />
      </SubHeaderImage>
    </div>
  )
}

export default NavigationBar;
