import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faHandPointer,
  faTachometer,
  faSpinner,
  faCheck,
  faStore,
  faSirenOn,
  faChevronCircleLeft
} from '@fortawesome/pro-regular-svg-icons'
import {
  faShopify,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'
import Logo from '../../../../../assets/images/logo.png'
import Button from './steps_button'
import Step from './step'

const HelpMenu = ({ setIsOpened }) => {
  const [isClicked, setIsClicked] = useState(false)
  const [isShowing, setIsShowing] = useState(true)
  const [integrationName, setIntegrationName] = useState('')

  const toggleHelpMenu = (integrationName) => {
    setIsClicked(!isClicked)
    setIntegrationName(integrationName)
    setIsShowing(!isShowing)
  }

  return (
    <div className='fixed w-full h-full top-0 left-0 flex items-center justify-center z-40'>
      <div className='w-screen h-screen bg-gray-200 max-h-full z-50 overflow-scroll'>
        <div className='text-gray-300 bg-black flex px-4 py-3 items-center justify-between items-center'>
          <div className='flex px-4 py-3 sm:p-0 items-center'>
            <a href='/'>
              <img
                className='h-auto w-32 '
                src={Logo}
                alt='Local Shops'
              />
            </a>
            <h4 className='text-lg font-semibold'>
              Importing Products to Local Shops
            </h4>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => setIsOpened(false)}
            className='text-xl cursor-pointer z-10'
          />
        </div>
        {
          !isShowing ? (
        <div
          onClick={() => toggleHelpMenu()}
          className='flex justify-end p-4 cursor-pointer'
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className='text-xl cursor-pointer z-10 text-gray-600'
          />
          <p className=' text-gray-600 pl-1 items-center'>
            Back
          </p>
        </div>

          ) : null
        }
        <div className='p-4 flex justify-around  text-xl'>
          {isShowing ? (
            <div className=''>
              <h1 className='text-gray-600 text-2xl text-center p-4 '>
                Click a button to see step by step instructions!
              </h1>
              <div className='w-full items-center flex lg:flex-row flex-col'>
                <div
                  onClick={() => toggleHelpMenu('shopify')}
                  className='w-full px-4 py-4 rounded-lg'
                >
                  <Step
                    instructions='Shopify'
                    color='text-green-600'
                    icon={faShopify}
                  />
                </div>
                <div
                  onClick={() => toggleHelpMenu('instagram')}
                  className='w-full px-4 py-4 rounded-lg'
                >
                  <Step
                    instructions='Instagram'
                    color='text-pink-600'
                    icon={faInstagramSquare}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='flex'>
              <p className='text-gray-600 text-2xl text-center p-4 mt-8 capitalize'>
                How to import your products to {integrationName}
              </p>
            </div>
          )}
        </div>
        <div>
          {integrationName === 'shopify' && (
            <div className='flex flex-col lg:flex-row justify-start mt-12 p-6 gap-2 lg:grid lg:grid-cols-4'>
              <Step
                instructions='Click on "Connect" in the Shopify box'
                color='text-green-900'
                icon={faHandPointer}
              />
              <Step
                instructions='In your Shopify Dashboard, install the Local Shops App'
                color='text-green-800'
                icon={faTachometer}
              />
              <Step
                instructions=' Go back to your Local Shops dashboard & import your products by clicking on the sync button'
                color='text-green-700 '
                icon={faSpinner}
              />
              <Step
                instructions='Congratulations! Your products are now listed'
                color='text-green-600'
                icon={faCheck}
              />
            </div>
          )}
        </div>
        <div>
          {integrationName === 'instagram' && (
            <div className='flex flex-col lg:flex-row mt-12 p-6 gap-2 lg:grid lg:grid-cols-4'>
              <Step
                instructions='Click on the "Dashboard" tab & click on "Connect" in the Instagram box'
                color='text-pink-900'
                icon={faStore}
              />

              <Step
                instructions='Grant Local Shops Permission to access your account &
                  Click on import products'
                color='text-pink-800'
                icon={faSirenOn}
              />
              <Step
                instructions=' Select the images you want to import'
                color='text-pink-700'
                icon={faSpinner}
              />
              <Step
                instructions="Congratulations! Your products are now listed! Don't forget to edit the name, description, and price of your product, in the Product tab! "
                color='text-pink-600'
                icon={faCheck}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HelpMenu
