import React, { useContext } from 'react';
import NavigationBar from '../navigationBar';
import { useHistory } from 'react-router-dom';
import NoData from '../../noData';
import { ShopInfoCardSkeleton } from '../../../main/page_body/shared_components/skeletons/skeletonHelpers';
import ShopInfoCard from '../../../main/page_body/brands/shopInfoCard';
import SavedSellersContext from './savedSellersContext';
import SavedSellersFilters from './savedSellersFilters';
import Pagination from "../../../main/page_body/shared_components/pagination";

const SavedSellersUI = () => {
  const history = useHistory();
  const { savedSellers, fetchingSellers, currentPage, onPageChange, setCurrentPage, totalPages } = useContext(SavedSellersContext);

  return (
    <div className='w-full mx-auto'>
      <NavigationBar activeKey={location.pathname} />
      <section className='lg:w-4/5 xl:w-2/3 w-full px-4 mx-auto md:my-12 my-6'>
        <h1 className='text-3xl font-medium text-black'>Saved Sellers</h1>
        <section className='flex md:flex-row flex-col justify-end gap-x-3 gap-y-2 mt-8 mb-4'>
          <SavedSellersFilters />
        </section>
        <section className='mt-8 mb-4'>
          {
            fetchingSellers ? <ShopInfoCardSkeleton /> :
              <section className='flex flex-col antialiased justify-items-auto gap-x-1 gap-y-1 md:gap-x-4 md:gap-y-4'>
                {savedSellers.map(shop => <ShopInfoCard shop={shop} />)}
              </section>
          }
          {
            !fetchingSellers && !savedSellers.length &&
            <NoData className='flex flex-col items-center justify-center mb-12 pb-12 h-64'
              buttonText='View Brands' onClick={() => { history.push('/shops') }}>
              You haven't saved any sellers yet.
            </NoData>
          }
        </section>
        <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
      </section>
    </div>
  )
}

export default SavedSellersUI;
