import React from 'react';

import IcShippingHeaderBG from '../../../../assets/images/shipping-header-bg.png';
import IcVisitedNode from '../../../../assets/images/visited-node.png';
import IcNonVisitedNode from '../../../../assets/images/non-visited-node.png';
import IcArrowRight from '../../../../assets/images/ic-arrow-right.png';

const NavigationNode = ({ title, hasBeenVisited, hasNextStep }) => {
  const nodeImage = hasBeenVisited ? IcVisitedNode : IcNonVisitedNode;
  const nodeTextColor = hasBeenVisited ? '' : 'text-gray-600';
  return (
    <div className='min-w-full'>
      <img src={nodeImage} className='float-left transform md:scale-100 scale-75' />
      <span className={`float-left sm:ml-2 ml-1 mt-1 ${nodeTextColor}`}>{title}</span>
      {hasNextStep && <img src={IcArrowRight} className='float-left md:ml-4 ml-2 mt-2' />}
    </div>
  )
}

const CheckoutNavigationUI = ({
  hasShippingBeenVisited,
  hasPaymentBeenVisited
}) => {
  return (
    <div>
      <section className='w-full mx-auto relative'>
        <img src={IcShippingHeaderBG} className='md:h-24 h-16 w-full object-cover' />
        <section className='absolute xl:ml-20 xl:mr-20 lg:ml-18 lg:mr-18 md:ml-8 md:mr-8 md:top-8 top-4
            ml-4 mr-4 grid grid-flow-col auto-cols-auto gap-x-2'>
          <NavigationNode title='Shipping Information' hasBeenVisited={hasShippingBeenVisited} hasNextStep={true} />
          <NavigationNode title='Payment' hasBeenVisited={hasPaymentBeenVisited} />
        </section>
      </section>
    </div>
  )
}

export default CheckoutNavigationUI;
