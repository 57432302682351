import React from 'react';

const Question = ({ question, className, children }) => {
  return (
    <div className={className}>
      <h2 className='font-semibold text-lg my-4'>{question}</h2>
      <p className='font-medium text-sm mx-auto my-4'>{children}</p>
    </div>
  )
}

export default Question;
