import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef, useState } from 'react';
import BuyerAccountMenu from './buyerAccountMenu';
import MerchantAccountMenu from './merchantAccountMenu';

const MyAccountButton = ({ current_merchant, current_user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const ref = useRef(null);

  return (
    <>
      <button
        ref={ref}
        onClick={() => { setIsMenuOpen(true) }}
        onMouseEnter={() => { setIsMenuOpen(true) }}
        className={'text-gray-700 px-4 lg:mr-5 py-1'}
      >
        <span className='flex items-center'><FontAwesomeIcon className='text-lg'
          icon={faUser} />
          <p
            className='text-lg px-1 sm:block hidden'>My Account</p>
        </span>
      </button>
      {
        current_merchant ?
          <MerchantAccountMenu isOpen={isMenuOpen} anchorRef={ref} setIsOpen={setIsMenuOpen} /> :
          <BuyerAccountMenu isOpen={isMenuOpen} anchorRef={ref} setIsOpen={setIsMenuOpen} />
      }
    </>
  )
}

export default MyAccountButton;
