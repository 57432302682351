import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons'

const CircleIcon = ({ rejected } ) => {
  return (
    <div>
      <FontAwesomeIcon icon={ rejected ? faExclamationCircle : faCheckCircle} size={"6x"} color={"rgba(138, 169, 183, 1)"} />
    </div>
  )
}

export default CircleIcon;
