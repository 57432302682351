import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const LocalProductCard = ({ product }) => {
  let [productImage, setProductImage] = useState(product.thumbnail_image_url)
  const transitionGrowClass =
    'transition duration-500 ease-in-out hover:shadow-xl'
  return (
    product && (
      <Link
        className={`rounded-t-xl px-1 lg:px-0 text-tomato-700`}
        to={`${product.path}${location.search}`}
        title={`${product.title}`}
      >
        <div style={{ backgroundImage: `url('${productImage}')` }}
             className={`w-full h-96 bg-cover bg-center rounded-xl ${transitionGrowClass}`}>
        </div>
      </Link>
    )
  )
}

export default LocalProductCard
