import React from 'react';
import IcVisitedNode from '../../../../assets/images/visited-node.png';

const OrderPlacedUI = () => {
  return (
    <div style={{ height: 'fit-content' }} className='border-2 border-gray-200 rounded-md'>
      <section className='flex w-full rounded-md p-10' style={{ height: '50vh' }}>
        <div className='m-auto'>
          <img className='ml-auto mr-auto mb-4' src={IcVisitedNode} />
          <h2 className='text-2xl text-center font-bold'>Order Placed!</h2>
          <p className='w-4/5 ml-auto mr-auto text-base text-center font-light'>
            Your order has been placed and details regarding it have been emailed to your registered email address.
          </p>
        </div>
      </section>
    </div>
  )
}

export default OrderPlacedUI;
