import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Candle from "../../../../../assets/images/candle.svg";
import Rabbit from "../../../../../assets/images/rabbit_doll.svg";
import EarringRight from "../../../../../assets/images/earring-right.svg";
import EarringLeft from "../../../../../assets/images/earring-left.svg";
import SpiralLine from "../../../../../assets/images/spiral_line.svg";
import SMSpiralLine from "../../../../../assets/images/spiral_line_sm.svg";
import MDSpiralLine from "../../../../../assets/images/spiral_line_md.svg";



const HomeSplashBanner = ({ link, market }) => {
  const transition =
    "transition duration-500 ease-in-out transform hover:scale-105 lg:text-xl";
  const border = "rounded-lg border-white border-4";

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setScreenSize( window.innerWidth );
    });
  }, []);
  
  const accessory_position = {
    left: (screenSize > 768 && "-23%")  || (screenSize < 768 && "0") || (640 < screenSize < 768 && "19rem"),
    top:  (screenSize > 768 && "7.5rem")  || (screenSize < 768 && "3rem") || (640 < screenSize < 768 && "-15rem")
  };
  const toys_and_hobbies_position = {
    top: (screenSize > 768 && "17rem")  || (screenSize < 768 && "10.3rem") || (640 < screenSize < 768 && "-7.3rem"),
    right: (screenSize > 768 && "29%")  || (screenSize < 768 && "3%") || (640 < screenSize < 768 && "11%")
  };
  const home_and_garden_position = {
    left: (screenSize > 768 && "35%")  || (screenSize < 768 && "57%") || (640 < screenSize < 768 && "68%"),
    top: (screenSize > 768 && ' ')  || (screenSize < 768 && 'none') || (640 < screenSize < 768 && "-18rem")
  };

  const earring_right_position = {
    right: (screenSize > 1870 && "-3.7rem") ||(screenSize > 1750 && "-2.6rem") || (screenSize > 1650 && "-2rem") || (screenSize > 768 && "0")  || (screenSize < 768 && "-5.66rem") || (640 < screenSize < 768 && "-1.5rem"),
    bottom: (screenSize > 768 && "0")  || (screenSize < 768 && "-9.3rem") || (640 < screenSize < 768 && "-2.36rem"),
    backgroundSize: "fill",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${EarringRight})`,
    transform: (screenSize > 768 && "scale(1)")  || (screenSize < 768 && "scale(0.6") || (640 < screenSize < 768 && "scale(0.7)")
  };

  const earring_left_position = {
    left: (screenSize > 768 && "-0.6rem") || (screenSize < 768 && "-3.2rem")  || (640 < screenSize < 768 && "-2.8rem"),
    bottom: (screenSize > 768 && "0") || (screenSize < 768 && "-6.8rem")  || (640 < screenSize < 768 && "-6.5rem"),
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${EarringLeft})`,
    transform: (screenSize > 768 && "scale(0.9)") || (screenSize < 768 && "scale(0.6)")  || (640 < screenSize < 768 && "scale(0.65)"),
  };

  const rabbit = {
    backgroundImage: `url(${Rabbit})` ,
    bottom: (screenSize > 768 && "0" ) || "-0.5rem",
    right: (screenSize > 768 && " " ) || "-0.4rem"
  }




  const main_accent = {
    color: "rgba(56,56,56,0.7)",
  };

  const section_height = {
    height: (screenSize > 768 && "36rem") || (screenSize < 768 && "43rem") || (640 < screenSize < 768 && "28rem")
  }

  const spiral_line = {
    backgroundImage: (screenSize > 768 &&`url(${SpiralLine})`) || (screenSize < 768 && `url(${SMSpiralLine})`) || (640 < screenSize < 768 && `url(${MDSpiralLine})`),
    width: "56rem",
    height: "26.5rem",
    right: (screenSize > 768 && ' ')  || (screenSize < 768 && "-32rem") || (640 < screenSize < 768 && "-25rem"),
    top: (screenSize > 768 && ' ')  || (screenSize < 768 && "-5.5rem") || (640 < screenSize < 768 && "-20.3rem")
  }

  const mobileStyles = "flex lf:flex-none mx-auto my-auto";
  const categoryUrlHelper = (category) =>
    market ? `/markets/${market.slug}/${category}` : `/${category}`;

  return (
      <div className="w-full relative h-full">
        <div
          style={section_height}
          className="w-full lg:w-2/3 inline-block text-center h-full bg-light-orange md:text-left overflow-hidden"
        >
          <div className="mt-10 lg:mt-28 md:mt-20 md:ml-7 lg:ml-40">
            <h3 className="text-4xl font-semibold text-gray-800 lg:text-4xl xl:text-6xl">
              Discover
              <span className="md:hidden sm:inline-block"> Local</span>
            </h3>
            <h3 className="text-4xl font-semibold text-gray-800 lg:text-4xl xl:text-6xl">
              <span className="hidden md:inline-block">Local</span> Brands
            </h3>
            <div>
              <div
                className="text-base font-base mt-3 lg:text-base text-gray-700 xl:text-4xl"
                style={main_accent}
              >
                Shop and buy locally from <b>1000 +</b>
              </div>
              <div
                className="text-base font-base lg:text-base text-gray-700 xl:text-4xl"
                style={main_accent}
              >
                independent brands
              </div>
            </div>
            <div>
              <Link
                to={link}
                className={`inline-block bg-mustard-400 text-white rounded-full mt-3 md:px-10 px-6 py-3 text-sm lg:text-base lg:px-10 lg:mt-8 hover:shadow-2xl ${transition} `}
              >
                Go to Catalog
              </Link>
            </div>
          </div>
          <div className="w-full h-25 relative text-white lg:w-2/5 lg:text-2xl lg:absolute top-0 right-0 mt-8 lg:mt-0">
            <div
              className="inline-block absolute right-4/5 -ml-1/6 -mt-4 lg:mt-0 lg:left-auto bg-no-repeat"
              style={spiral_line}
            />
            <Link
              to={categoryUrlHelper("home-garden")}
              style={home_and_garden_position}
              className={`bg-moody-blue md:w-56 lg:w-2/3 lg:h-64 w-1/2 h-40 absolute rounded-lg text-left ${transition} ${border} ${mobileStyles}`}
            >
              <span
                className=" lg:inline-block absolute bottom-0 right-0 lg:left-0 w-full h-32 md:h-40 lg:w-full lg:h-full bg-cover lg:bg-none"
                style={{ backgroundImage: `url(${Candle})`, right: "1.6rem" }}
              />
              <div className="p-2 lg:p-6">
                <span className="text-md lg:text-2xl ">
                  Home & <br className=" lg:inline-block" /> Garden{" "}
                </span>
              </div>
            </Link>
            <div className="py-1">
              <Link
                to={categoryUrlHelper("accessories")}
                style={accessory_position}
                className={`xl:w-1/2 lg:h-96 bg-mustard-300 absolute top-5 ml-3 rounded-lg text-left w-48 h-56 ${mobileStyles} ${transition} ${border}`}
              >
                <span
                  className="lg:inline-block absolute lg:w-3/4 xl:w-8/12 w-64 h-96 transform lg:scale-90"
                  style={earring_left_position}
                />
                <span
                  className="lg:inline-block absolute lg:w-2/3 lg:h-64 md:w-40 md:h-64 w-64 h-96 transform lg:scale-100 "
                  style={earring_right_position}
                />
                <span className="inline-block lg:m-3 py-2 px-2 lg:px-4 xl:text-2xl">
                  Accessories &rarr;
                </span>
              </Link>
            </div>
            <Link
              to={categoryUrlHelper("toys-hobbies")}
              style={toys_and_hobbies_position}
              className={`xl:w-2/5 lg:h-64 md:w-40 md:h-48 w-2/5 h-40 bg-saucy-red absolute rounded-lg text-left ${transition} ${border} ${mobileStyles}`}
            >
              <span
                className="lg:inline-block absolute w-32 h-full md:w-full right-0 lg:w-48 lg:h-56 bg-cover z-40 transform scale-90 lg:scale-100"
                style={rabbit}
              />
              <div className="p-2 lg:p-6 z-50">
                <span className="text-md lg:text-lg xl:text-2xl ">
                  Toys & <br className="lg:inline-block z-50" /> Hobbies
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
  )
}

export default HomeSplashBanner;
