import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from '../../products_dashboard/forms/shared_components/button'
import BetaBadge from "./beta_badge"

const ButtonWithIcon = ({ onClick, icon, name, color, children }) => {
  return (
    <div className='w-full flex items-center justify-between cursor-pointer text-base w-full btn btn-default btn-rounded bg-white border border-gray-400'>
      <div className='flex items-center p-3'>
        <FontAwesomeIcon icon={icon} className={`${color} text-5xl`} />{' '}
        <p className='ml-2'>{name}</p>
      </div>
      <div className='flex items-center'>
        <BetaBadge name={name} />
        <Button
          onClick={onClick}
          hover='gray-200'
          color='white'
          textC='gray-700'
          className='border border-gray-700'
          children={children}
        />
      </div>
    </div>
  )
}

export default ButtonWithIcon
