import React from "react";
import Map from "../../../../../assets/images/map.svg"

const NoProductsPopup = ({ handleModal }) => {
  return (
    <div
      className="flex items-center justify-center h-screen fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-10">
      <div className="w-5/6 h-1/3 md:w-1/2 md:h-auto xl:h-2/5 xl:w-1/3 shadow-lg rounded-md bg-white">
        <div className="flex items-end items-end justify-end px-3 pt-3 md:py-3" onClick={handleModal}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.3335 1.3335L14.6668 14.6668"
              stroke="#383838"
              strokeWidth="1.5"
            />
            <path
              d="M14.6665 1.3335L1.33317 14.6668"
              stroke="#383838"
              strokeWidth="1.5"
            />
          </svg>
        </div>

        <div className="md:mt-3 text-center">
          <div className="mx-auto flex items-center justify-center">
            <div
              className="bg-no-repeat bg-contain h-16 w-16"
              style={{ backgroundImage: `url(${Map})` }}
            />
          </div>
          <div className="pt-5 px-4 md:px-16 leading-6 font-sm text-gray-900">
            Currently there are no independent brands or shops listed in your
            location - please explore these results nearby
          </div>
          <div className="items-center px-4 py-6">
            <button className="px-4 py-2 md:py-4 bg-mustard-400 text-white font-sm rounded-full w-40"
                    onClick={handleModal}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>)
}

export default NoProductsPopup
