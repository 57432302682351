import React from 'react';
import Select from 'react-select';
import MyProductsSelectStyle from "../../../../constants/custom_styles/myProductsSelectStyle";

const CountrySelect = ({ label, countries, countryId, onChange, className }) => {
  return (
    <div className={`flex flex-row gap-x-4 items-center ${className}`}>
      {label && <span>{label}</span>}
      <Select
        value={countries.find(option => option.value === countryId) || countries[0]}
        className='w-full lg:w-32 xl:w-full'
        styles={MyProductsSelectStyle}
        components={{IndicatorSeparator: () => null}}
        isClearable={false}
        options={countries}
        onChange={option => onChange(option.value)}
      />
    </div>
  )
}

export default CountrySelect;
