import React from 'react';

import IcLoaderWhite from '../../../../assets/images/ic-loader-white.png';

const FormButton = ({
  className,
  type,
  formId,
  isLoading,
  onClick,
  loaderClassName,
  loaderContainerClassName,
  loaderImageSrc,
  children
}) => {
  return (
    <button type={type} form={formId} className={className} disabled={isLoading} onClick={onClick}>
      {
        isLoading &&
        <span className={loaderContainerClassName}>
          <img src={loaderImageSrc ?? IcLoaderWhite}
            className={`animate-spin ${loaderClassName}`}
          />
        </span>
      }
      {children}
    </button>
  )
}

export default FormButton;
