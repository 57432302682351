import React from 'react';
import { ErrorMessage } from 'formik';

const FormTextArea = ({
  name,
  placeholder,
  onChange,
  value,
  isDisabled,
  inputClassName,
  errorClassName
}) => {
  return (
    <section>
      <textarea
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        disabled={isDisabled}
        className={`border border-beige-200 placeholder-black font-light px-4 py-3 rounded-md ${inputClassName}`}
      />
      <ErrorMessage
        name={name}
        render={errorMessage => (
          <span className={`text-red-400 text-sm ${errorClassName}`}>{errorMessage}</span>
        )}
      />
    </section>
  )
}

export default FormTextArea;
