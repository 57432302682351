import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { appendCurrentCheckoutMode } from '../../../../utilities/checkoutUtility';
import { CheckoutShippingContext } from './checkoutShippingContext';
import CheckoutUI from '../checkoutUI';
import ShippingAddressForm from './forms/shippingAddressForm';

const CheckoutShippingAddFormUI = () => {

  const history = useHistory();
  const { addAddress, isAddingAddress } = useContext(CheckoutShippingContext);

  useEffect(() => {
    resetScrollPosition()
  }, [])

  const resetScrollPosition = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  const handleSubmit = values => {
    addAddress(values).then(() => {
      history.replace(appendCurrentCheckoutMode('/checkout/addresses'));
    })
  }

  return (
    <CheckoutUI
      pageTitle='Shipping Information'
      primaryFormId='shipping-address-form'
      isSubmittingForm={isAddingAddress}
      hasShippingBeenVisited={true}
      hasPaymentBeenVisited={false}>
      <ShippingAddressForm
        formId='shipping-address-form'
        formTitle='Shipping Address'
        onSubmit={handleSubmit}
      />
    </CheckoutUI>
  )
}

export default CheckoutShippingAddFormUI;
