import React, { useContext } from 'react';

import IcPaypalLogo from '../../../../../assets/images/ic-paypal-logo.png'
import PaymentsContext from '../paymentsContext';
import { PaypalConnectContext } from './paypalConnectContext';

const PaypalBlock = ({ className }) => {

  const { integration } = useContext(PaymentsContext);
  const { getLoginWithPaypalLink, setCurrentIntegrationId } = useContext(PaypalConnectContext);

  const getAccountIdDependentParameters = () => {
    if (integration.paypal_email) {
      return {
        holderClassName: 'px-3 py-3 bg-blue-200',
        className: 'center',
        linkOrChange: 'Change Account'
      }
    }

    return {
      holderClassName: '',
      className: 'left',
      linkOrChange: 'Link Paypal'
    }
  }

  const accountIdDependentParameters = getAccountIdDependentParameters();

  return (
    <div className={className}>
      <div className='m-auto flex md:flex-row flex-col items-center'>
        <div className='flex lg:flex-row flex-col'>
          <img src={IcPaypalLogo} className='w-auto sm:h-12 h-16 lg:m-0 m-auto transform scale-75' />
          <div className={`flex max-w-sm mx-4 lg:my-0 my-2 ${accountIdDependentParameters.holderClassName} rounded-lg`}>
            <p className={`m-auto text-${accountIdDependentParameters.className}`}>
              {
                integration.paypal_email ||
                'Connect your PayPal account to receive payouts for sales. ' +
                'If you do not have a PayPal account yet, you can sign up here.'
              }
            </p>
          </div>
        </div>
        <a onClick={() => { setCurrentIntegrationId(integration.id) }} href={getLoginWithPaypalLink()} target="_blank"
          className='px-12 py-4 md:w-auto w-full bg-blue-500 md:mx-4 mx-auto md:ml-auto max-w-sm rounded-full flex items-center'>
          <span className='w-full inline-block text-white text-center'>
            {accountIdDependentParameters.linkOrChange}
          </span>
        </a>
      </div>
    </div >
  )
}

export default PaypalBlock;
