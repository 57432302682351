import algoliasearch from "algoliasearch";
import { isFinite, last } from "lodash"

const initialSearchRadius = 100000     // distance in meters

export function loadAlgoliaProducts(searchOptions, retrySequence) {

  const searchClient = algoliasearch('85FKHL2CCS', process.env.ALGOLIA_SEARCH_KEY); //read only api key
  const {
    city = null,
    category_slug = null,
    integration_slug = null,
    market = null,
    currentPage = 0,
    searchParams = "",
    isMadeLocally = null,
    hits = 50,
    currentSort = 'asc'
  } = searchOptions

  const indexName = currentSort === 'desc' ? `${process.env.ALGOLIA_INDEX}_title_desc` : process.env.ALGOLIA_INDEX;
  const index = searchClient.initIndex(indexName)

  let requestOptions = {
    "getRankingInfo": true,
    "attributesToRetrieve": "*",
    "attributesToSnippet": "*:20",
    "maxValuesPerFacet": 100,
    "snippetEllipsisText": "…",
    "responseFields": "*",
    "explain": "*",
    "page": currentPage,
    "hitsPerPage": hits,
    "filters": "NOT thumbnail_image_url:false",
    "facets": [
      "*"
    ],
    "facetFilters": []
  };

  if (city) {
    requestOptions.aroundLatLng = `${city.latitude},${city.longitude}`
    requestOptions.aroundRadius = retrySequence ? recalculateSearchRadius(initialSearchRadius, retrySequence) : initialSearchRadius
  }

  if (category_slug) {
    requestOptions.facets.push("category.slug")
    requestOptions.facetFilters.push([`category.slug:${category_slug}`])
  }

  if (integration_slug) {
    requestOptions.facets.push("integration.slug")
    requestOptions.facetFilters.push([`integration.slug:${integration_slug}`])
  }

  if (market) {
    const filters = market.integration_slugs.map(integration_slug => `integration.slug:${integration_slug}`).join(" OR ")
    const formatFiltersQuery = `market.slug:${market.slug}` + (filters.length > 0 ? ` OR ${filters}` : '')
    requestOptions["filters"] = `${formatFiltersQuery}`
  }

  if (isMadeLocally) {
    requestOptions.facets.push("is_made_locally")
    requestOptions.facetFilters.push([`is_made_locally:${isMadeLocally}`])

  }

  return index.search(`${searchParams}`, requestOptions)
}

export const recalculateSearchRadius = (initRadius, sequence) => {
  /* TypeError handling */
  if (!isFinite(initRadius)) throw new TypeError(`Invalid input radius type.`)
  if (!isFinite((sequence))) throw new TypeError(`Invalid sequence type.`)

  /* Breakout before loop for known values */
  if (sequence === 0) return 0
  if (sequence === 1) return initRadius

  const fibArr = [0, initRadius]

  for (let i = 2; i <= sequence; i++) {
    fibArr[i] = fibArr[i - 1] + fibArr[i - 2]
  }

  /* Return last value of calculated fibonacci sequence to be used as new search radius */
  return last(fibArr)
}

