import { useEffect, useState } from "react"

const useVisibility = (defaultVisibility) => {
  const [visible, setVisible] = useState(false)
  
  useEffect(() => {
    setVisible(defaultVisibility)
  }, [defaultVisibility])
  
  const toggle = () => {
    setVisible(!visible)
  }
  return [visible, toggle]
}

export default useVisibility
