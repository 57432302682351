import React, { useState, useEffect } from "react";
import MadeLocallyProducts from "../mainPage/madeLocallyProducts";
import HomeSplashBanner from "../mainPage/homeSplashBanner";
import PopularCategories from "../mainPage/popularCategories";
import DiscoverProducts from "../mainPage/discoverProducts";
import FeaturedBrands from '../mainPage/featuredBrands';
import CommunityMarkets from '../mainPage/communityMarkets';

const ProductsLandingPage = () => {
  const query = new URLSearchParams(location.search)
  const isMarketQuery = query.get('market_slug');

  return (
    <div className='overflow-hidden'>
      <HomeSplashBanner link='/products' />
      <div className='bg-white'>
        <div className='mx-auto md:p-8'>
          <DiscoverProducts show_more_link="/products" />
          <FeaturedBrands />
        </div>
        <MadeLocallyProducts />
        <div className='mx-auto md:p-8'>
          <CommunityMarkets />
          <div className='lg:p-8'><PopularCategories /></div>
        </div>
      </div>
    </div>
  )
}

export default ProductsLandingPage
