import React from 'react'

const DeleteConfirm = ({ doDelete, cancel }) => {
  return <div
    className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
    style={{ background: "rgba(0,0,0,.2)" }}>
    <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-lg shadow-lg  bg-white ">
      <div className="">
        <div className="text-center p-5 flex-auto justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" className="w-12 h-12 flex items-center text-red-500 mx-auto"
               fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <h3 className="font-bold py-4 ">Are you sure?</h3>
          <p className="text-sm text-gray-500">This process cannot be undone.</p>
        </div>
        <div className="p-3 mt-2 flex flex-row justify-center gap-x-2">
          <div
            className='cursor-pointer mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100'
            onClick={() => cancel()}>Cancel
          </div>
          <div className='cursor-pointer mb-2 md:mb-0 bg-red-500 px-5
                            py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg
                            hover:bg-red-600' onClick={() => doDelete()}>Delete
          </div>
        </div>
      </div>
    </div>
  </div>
}
export default DeleteConfirm
