import React, { useContext } from 'react'
import Products, { ProductsContext } from "./products";
import Shops from "../brands/shops";
import ProductsGrid from "./productsGrid";
import SubHeader from "../shared_components/subHeader";

const ProductsSearchResults = () => {
  const SearchResults = () => {
    const { searchParams } = useContext(ProductsContext)
    return (
      <>
        <div className='mb-8'>
          <SubHeader breadcrumbs={searchParams} />
        </div>
        <div className='p-2 md:p-8'>
          <div className='container mx-auto'>
            <ProductsGrid />
          </div>
        </div>
      </>
    )
  }

  return (
    <Products>
      <Shops>
        <SearchResults />
      </Shops>
    </Products>
  )
}

export default ProductsSearchResults
