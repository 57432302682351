import axios from "axios";
import React, { useEffect, useState } from "react";
import FormImageInput from "../../../../shared/rails_form_fields/FormImageInput";

const StoreImageUpload = () => {
  const [logoURL, setLogoURL] = useState(null);
  const [bannerURL, setBannerURL] = useState(null);

  useEffect(() => {
    axios.get("/merchants/integrations.json").then((res) => {
      if (res.data.length > 0) {
        setLogoURL(res.data[0].shop_logo_path);
        setBannerURL(res.data[0].shop_banner_path);
      }
    });
  }, []);

  const ImageUpload = ({
    name,
    headerLabel,
    footerLabel,
    fileURL,
    className,
  }) => {
    return (
      <section className={className}>
        <h1 className="font-semibold text-base">{headerLabel}</h1>
        <div class="flex flex-row items-center">
          <FormImageInput name={name} fileURL={fileURL} className="my-2" label={footerLabel}/>
        </div>
      </section>
    );
  };

  return (
    <section className="flex flex-col lg:flex-row justify-between">
      <ImageUpload
        name="integration[logo]"
        headerLabel="Shop Avatar"
        fileURL={logoURL}
      />
      <ImageUpload
        name="integration[banner]"
        headerLabel="Shop Head Image"
        fileURL={bannerURL}
        footerLabel="Recommended size 970x646"
      />
    </section>
  );
};

export default StoreImageUpload;
