import React, { useState } from 'react'
import ReactTooltip from "react-tooltip";
import ToggleMadeLocally from "../shared_components/toggleMadeLocal";

const MadeLocallyToggle = ({ integration, product, iconClassName }) => {
  let [madeLocally, setMadeLocally] = useState(product.is_made_locally)

  return <>
    <ReactTooltip id='made-locally'>
      <span>Made Locally</span>
    </ReactTooltip>
    <div data-tip data-for='made-locally' className={`${(madeLocally ? '' : 'opacity-50')}`}>
      <ToggleMadeLocally
        iconClassName={iconClassName}
        integration={integration}
        product={product}
        madeLocally={madeLocally}
        setMadeLocally={setMadeLocally}
      />
    </div>
  </>
}

export default MadeLocallyToggle
