import React from "react";
import NavArrow from "./arrow-nav"

export const UserIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="6" r="4.25" stroke="#383838" strokeWidth="1.5" />
        <path
          d="M20.9998 19.5C20.9998 13.9 18.3331 12.1667 16.9998 12C16.9998 12 14.5 13.5 11.9998 13.5C9.49951 13.5 7 12 7 12C5.66667 12.1667 3 13.9 3 19.5C3 21.1569 4.34315 22.5 6 22.5H11.9998H17.9998C19.6566 22.5 20.9998 21.1569 20.9998 19.5Z"
          stroke="#383838"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};

export const ShopIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.81616 10.6348V19.8364C2.81616 20.3887 3.26388 20.8364 3.81616 20.8364H8.83685C9.38913 20.8364 9.83685 20.3887 9.83685 19.8364V16.571C9.83685 16.0187 10.2846 15.571 10.8368 15.571H13.2246C13.7769 15.571 14.2246 16.0187 14.2246 16.571V19.8364C14.2246 20.3887 14.6724 20.8364 15.2246 20.8364H20.2449C20.7972 20.8364 21.2449 20.3887 21.2449 19.8364V10.6348"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3129 2.4082H4.74847C3.86274 2.4082 3.0825 2.99079 2.83087 3.84003L1.5 8.33172C1.5 9.78571 2.67869 10.9644 4.13268 10.9644C5.58666 10.9644 6.76535 9.78571 6.76535 8.33172C6.76535 9.78571 7.94404 10.9644 9.39803 10.9644C10.852 10.9644 12.0307 9.78571 12.0307 8.33172C12.0307 9.78571 13.2094 10.9644 14.6634 10.9644C16.1174 10.9644 17.2961 9.78571 17.2961 8.33172C17.2961 9.78571 18.4747 10.9644 19.9287 10.9644C21.3827 10.9644 22.5614 9.78571 22.5614 8.33172L21.2305 3.84003C20.9789 2.99079 20.1987 2.4082 19.3129 2.4082Z"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const SalesIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4 1L1 5V19C1 19.5304 1.21071 20.0391 1.58579 20.4142C1.96086 20.7893 2.46957 21 3 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V5L16 1H4Z"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 9C14 10.0609 13.5786 11.0783 12.8284 11.8284C12.0783 12.5786 11.0609 13 10 13C8.93913 13 7.92172 12.5786 7.17157 11.8284C6.42143 11.0783 6 10.0609 6 9"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 5H19"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const LogoutIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.2143 8V6C14.2143 4.89543 13.3188 4 12.2143 4H4.78571C3.68114 4 2.78571 4.89543 2.78571 6V18C2.78571 19.1046 3.68114 20 4.78571 20H12.2143C13.3188 20 14.2143 19.1046 14.2143 18V16"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M9.61859 12H21.3146"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M18.6155 8.40137L22.2143 12.0002L18.6155 15.5989"
          stroke="#383838"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const ArrowNav = () => {
  return (
    <div className="w-3 h-12 bg-contain bg-no-repeat" style={{backgroundImage: `url(${NavArrow})`}} />
  );
};
