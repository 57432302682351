import { MenuItem } from '@szhsin/react-menu';
import React from 'react';
import { getCategoryURL } from './utility';

const Option = props => {
  const { category } = props;

  return (
    <MenuItem {...props} href={getCategoryURL(category.permalink)} className='flex flex-col'>
      <div className='flex items-center'>
        <span className='whitespace-no-wrap text-sm text-medium-grey cursor-pointer md:mr-4 mr-2 capitalize'>{category.name}</span>
      </div>
    </MenuItem>
  )
}

export default Option;
