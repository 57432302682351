import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

const ShopCity = ({ shop }) => {
  return shop.city ?
    <div className='flex-1 inline-flex items-center'>
      <span className='text-sm text-gray-600 font-thin'>{shop.city.name}</span>
    </div> : <a href={`/merchants/integrations/${shop.id}/edit`}
      className='flex flex-row items-center uppercase hover:underline'>
      <FontAwesomeIcon icon={faExclamationTriangle} className='text-yellow-400 mr-1' />
      <span className='text-sm text-gray-600 font-thin'>City is missing</span>
    </a>
}

export default ShopCity;
