import * as Yup from 'yup';
import ErrorMessages from '../errorMessages';

const rateValidation = Yup.number().min(0, ErrorMessages.IsValidRate).typeError(ErrorMessages.IsValidRate)

const ShippingRatesValidation = Yup.object().shape({
  local: rateValidation,
  domestic: rateValidation,
  international_shippings: Yup.array(Yup.object().shape({
    flat_rate: rateValidation
  }))
});

export default ShippingRatesValidation;
