import React from 'react'

const Button = ({ color, hover, children, path, onClick, target = '_self'}) => {
  let bgColor = color === undefined ? 'blue-500' : color
  let bgHover = hover === undefined ? 'blue-600' : hover
  console.log(target)
  return (
    <a
      className={`btn btn-default rounded-full hover:bg-${bgHover} text-white bg-${bgColor} whitespace-no-wrap`}
      href={path}
      onClick={onClick}
      target={target}
    >
      {children}
    </a>
  )
}

export default Button
