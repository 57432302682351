import React, { useContext, useState, useEffect } from 'react'
import axios from "axios";
import FavoritesContext from "../../../../contexts/favoritesContext"
import { toast } from 'react-toastify'
import HeartUnfilled from '../../footer/shared/HeartUnfilled';
import WishlistContext from '../../../users/dashboard/wishlist/wishlistContext';
import HeartFilled from '../../footer/shared/HeartFilled';

const ProductFavorite = ({ product}) => {
  const { token, userFavorites } = useContext(FavoritesContext)
  const [isFavorited, setIsFavorited] = useState(product.is_favorited)
  const { setWishlist, fetchWishlist } = useContext(WishlistContext);

  useEffect(() => {
    setIsFavorited(userFavorites?.products.find(fav => fav.slug === product.slug))
  }, [userFavorites])

  const toggleFavorite = (e) => {
    e.preventDefault()
    if (isFavorited) {
      unfavorite(product)
      return
    }
    axios({
      method: 'post',
      url: `/users/favorites`,
      data: { authenticity_token: token, product_id: product.slug }
    }).then((resp) => {
      if (resp.request.responseURL.includes('favorites')) {
        toast.success(`Successfully saved`);
        setIsFavorited(true)
      } else {
        toast.error('Please log in if you want to add this item to your favorites')
      }
    }).catch((err) => {
      toast.error('There was an error')
    })
  }

  const unfavorite = () => {
    axios.delete(`/users/favorites/${product.slug}`, { data: { authenticity_token: token } }).then((res) => {
      fetchWishlist && fetchWishlist()
      toast.success('Product was removed from your favorites');
      setIsFavorited(false)
    })
  }

  return <div
    className={`transition duration-500 ease-in-out mt-3 mr-3 pt-2 md:pt-0 transform hover:-translate-y-1 float-right`}
    onClick={toggleFavorite}>
    {isFavorited ? <HeartFilled /> :
      <HeartUnfilled />}
  </div>
}

export default ProductFavorite
