import React from 'react';
import { ErrorMessage } from 'formik';
import Select from 'react-select';

const FormSelect = ({
  name,
  isLoading,
  placeholder,
  options,
  onChange,
  isDisabled,
  value,
  containerClassName,
  errorClassName,
  errorsDisabled,
  styles
}) => {
  return (
    <section className={containerClassName}>
      <Select name={name} placeholder={placeholder} options={options}
        value={value}
        isLoading={isLoading}
        styles={styles}
        isDisabled={isDisabled}
        onChange={onChange} />
      {
        !errorsDisabled &&
        <ErrorMessage
          name={name}
          render={errorMessage => (
            <span className={`text-red-400 text-sm ${errorClassName}`}>{errorMessage}</span>
          )}
        />
      }
    </section>
  )
}

export default FormSelect;
