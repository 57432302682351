import React, { useContext, useEffect, useState } from 'react';
import  FavoritesContext  from '../../../../contexts/favoritesContext';
import queryString from 'query-string';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import WishlistContext from './wishlistContext';
import WishlistUI from './wishlistUI';

const Wishlist = () => {

  const history = useHistory();
  const parsedQuery = queryString.parse(window.location.search);

  const [wishlist, setWishlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(parsedQuery.page || 1);
  const [currentSort, setCurrentSort] = useState(parsedQuery.order);
  const [searchTerm, setSearchTerm] = useState(parsedQuery.search);
  const [categorySelected, setCategorySelected] = useState(parsedQuery.category_id ? parseInt(parsedQuery.category_id) : null);
  const [totalPages, setTotalPages] = useState(1);
  const [fetchingWishlist, setIsFetchingWishlist] = useState(false)

  const { token } = useContext(FavoritesContext);

  useEffect(() => {
    fetchWishlist();
  }, [currentPage, currentSort, categorySelected, searchTerm])

  const fetchWishlist = () => {
    setIsFetchingWishlist(true);
    axios({
      method: 'get',
      url: `/users/favorites.json`,
      data: { authenticity_token: token },
      params: {
        page: currentPage,
        order: currentSort,
        category_id: categorySelected,
        search: searchTerm,
        per_page: 8
      }
    }).then(response => {
      setWishlist(response.data.products);
      setTotalPages(response.data.pages);
    }).finally(() => {
      setIsFetchingWishlist(false);
    })
  }

  const resetScroll = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const updateURL = () => history.push(`${location.pathname}?${queryString.stringify(parsedQuery)}`)

  const onPageChange = page => {
    resetScroll();
    setCurrentPage(page.selected + 1)
    parsedQuery.page = page.selected + 1;
    updateURL();
  }

  const onSortChange = sort => {
    resetScroll();
    setCurrentSort(sort);
    setCurrentPage(1);
    parsedQuery.page = 1;
    parsedQuery.order = sort;
    updateURL();
  }

  const onCategoryChange = categoryId => {
    resetScroll();
    setCategorySelected(categoryId);
    setCurrentPage(1);
    parsedQuery.page = 1;
    parsedQuery.category_id = categoryId;
    updateURL();
  }

  const onSearchTermChange = search => {
    resetScroll();
    setSearchTerm(search);
    setCurrentPage(1);
    parsedQuery.page = 1;
    parsedQuery.search = search;
    updateURL();
  }

  return (
    <WishlistContext.Provider value={{
      wishlist,
      currentPage,
      totalPages,
      fetchingWishlist,
      fetchWishlist,
      onPageChange,
      currentSort,
      onSortChange,
      categorySelected,
      onCategoryChange,
      searchTerm,
      onSearchTermChange,
      setWishlist
    }}>
      <WishlistUI />
    </WishlistContext.Provider>
  )
}

export default Wishlist;
