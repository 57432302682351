import React, { useState } from 'react';
import Select from 'react-select';

import RailsFormSelectStyle from '../../../constants/custom_styles/railsFormSelectStyle';

const FormSelect = ({
  name,
  isLoading,
  placeholder,
  options,
  isDisabled,
  value,
  containerClassName,
}) => {

  const [selectedValue, setSelectedValue] = useState(options.find(option => option.value === value) || null)

  return (
    <section className={containerClassName}>
      <Select name={name} placeholder={placeholder} options={options}
        value={selectedValue}
        isLoading={isLoading}
        components={{IndicatorSeparator: () => null}}
        styles={RailsFormSelectStyle}
        isDisabled={isDisabled}
        onChange={option => setSelectedValue(option)} />
    </section>
  )
}

export default FormSelect;
