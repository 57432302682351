import React from 'react'

export default function ToggleNavigationMenu({visible, toggle, isDashboard}) {
  return (
    <div className='lg:flex'>
      <div className='flex px-3 justify-between items-center'>
        <div>
          <button
            type='button'
            className='lg:hidden block text-gray-600  focus:outline-none'
            onClick={toggle}
          >
            <svg viewBox='0 0 24 24' className='h-6 w-6 fill-current'>
              <path
                className={visible ? 'block' : 'hidden'}
                fillRule='evenodd'
                d='M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z'
              />
              <path
                className={!visible ? 'block' : 'hidden'}
                fillRule='evenodd'
                d='M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z'
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}
