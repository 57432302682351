import { applyHOC, applyStatics, ControlledMenu, MenuItem, SubMenu, useMenuState } from '@szhsin/react-menu';
import React, { useRef } from 'react';
import Option from './option';
import { getCategoryURL } from './utility';
import '@szhsin/react-menu/dist/transitions/slide.css';
import MenuImage from './menuImage'

export default function CategoryMenu({ category }) {

  const ref = useRef(null)
  const { toggleMenu, ...menuProps } = useMenuState({ transition: true });

  const OptionMenu = applyHOC(subMenuEnhancement)(SubMenu);
  applyStatics(MenuItem)(Option);
  applyStatics(MenuItem)(MenuImage);

  const categoryHasChildren = () => {
    return !!(category.children && category.children.length);
  }

  return (
    <a
      ref={ref}
      className='py-4 mx-2 '
      onMouseOver={() => { toggleMenu(true) }}
      onMouseLeave={() => { toggleMenu(false) }}
      href={getCategoryURL(category.permalink)}
    >
      <span className='whitespace-no-wrap text-medium-grey hover:text-mustard-400 cursor-pointer md:mr-4 mr-2 sm:block hidden capitalize'>{category.name}</span>
      {
        categoryHasChildren() &&
        <ControlledMenu
          {...menuProps}
          arrow
          anchorRef={ref}
          onMouseLeave={() => { toggleMenu(false) }}
          onClose={() => { toggleMenu(false) }}
          offsetY={-14}
        >
          {
            category.children.map(subCategory => {
              return subCategory.children && subCategory.children.length ?
                <OptionMenu category={subCategory} /> :
                <Option category={subCategory} />
            })
          }
          <MenuImage category={category} />
        </ControlledMenu>
      }
    </a>
  )
}
const subMenuEnhancement = (WrappedComponent, value) => {
  const Enhance = (props) => (
    <a href={getCategoryURL(props.category.permalink)} >
      <WrappedComponent {...props} value={value}
                        label={props.category.name} className='whitespace-no-wrap text-sm text-medium-grey cursor-pointer md:mr-4 mr-2 capitalize' >
        {props.category.children.map(subCategory => <Option category={subCategory} />)}
      </WrappedComponent>
    </a>
  );

  Enhance.displayName = `Enhance${value}`
  return Enhance;
};

