import { MenuItem } from '@szhsin/react-menu';
import React, { useEffect, useState } from 'react';
import { getCategoryURL } from './utility';
import IcPlaceholder from '../../../../../../assets/images/image-icon-productPage.png';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';

const MenuImage = props => {
  const { category } = props;

  const [categoryImage, setCategoryImage] = useState(IcPlaceholder);
  const [isFetchingImage, setIsFetchingImage] = useState(false);

  useEffect(() => {
    fetchCategoryImage();
  }, [category])

  const fetchCategoryImage = () => {
    setIsFetchingImage(true);
    axios.get(`/categories/${category.slug}/product_image.json`).then(response => {
      setCategoryImage(response.data);
    }).catch(error => {
      setCategoryImage(IcPlaceholder);
    }).finally(() => {
      setIsFetchingImage(false);
    })
  }

  return (
    <MenuItem {...props} href={getCategoryURL(category.permalink)} className='flex flex-col'>
      <div className='flex items-center'>
        {isFetchingImage ?
          <Skeleton width={160} height={128} className='rounded-md' /> :
          <img src={categoryImage} className='w-40 h-32 object-cover rounded-md' />}
      </div>
    </MenuItem>
  )
}

export default MenuImage;
