import axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import PaymentsContext from './paymentsContext';
import PaymentsUI from './paymentsUI';
import {spliceBaseUrl, includesMarketAdmin} from '../../../utilities/paymentUtility'

const Payments = ({ integration }) => {
  const [payouts, setPayouts] = useState([]);
  const [sortOrder, setSortOrder] = useState(null);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [fetchingPayouts, setIsFetchingPayouts] = useState(false);

  const baseUrl = spliceBaseUrl()
  const isMarketAdmin = includesMarketAdmin(baseUrl)

  useEffect(() => {
    fetchPayments();
  }, [dateRange, sortOrder])

  const fetchPayments = () => {
    setIsFetchingPayouts(true);
    axios({
      method: 'get',
      url: `${baseUrl}/${integration.id}/payments.json`,
      params: {
        from_date: dateToParam(dateRange.from),
        to_date: dateToParam(dateRange.to),
        order: sortOrder
      }
    }).then(response => {
      setPayouts(response.data);
    }).finally(() => {
      setIsFetchingPayouts(false);
    })
  }

  const dateToParam = date => {
    return date && format(date, 'yyyy-MM-dd');
  }

  return (
    <PaymentsContext.Provider value={{
      integration,
      fetchingPayouts,
      payouts,
      dateRange,
      setDateRange,
      sortOrder,
      setSortOrder,
      baseUrl,
      isMarketAdmin
    }}>
      <PaymentsUI />
    </PaymentsContext.Provider>
  )
}

export default Payments;
