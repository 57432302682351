import React from 'react';

const CartQuantityDisplay = cartShops => {
  let cartQuantity = 0;
  if (cartShops) {
    cartShops.map((cart) => {
      cartQuantity += cart.cart_items.length
    })
  }

  return cartQuantity
}

export default CartQuantityDisplay;
