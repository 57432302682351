import React, { useContext } from "react";
import CategorySearch from "../main/header/category_search/category_search";
import Search from "../main/header/search";
import { MarketContext } from "./market";
import MarketLogo from "./marketLogo";
import ToggleNavigationMenu from "../main/header/toggleNavigationMenu";
import useVisibility from "../../hooks/useVisibility";
import { CartContext } from "../../contexts/cartContext";
import CartQuantityDisplay from "../main/header/shared_components/cartQuantityDisplay";
import SignInOptions from "../main/header/shared_components/signInOptions";
import CartDrawer from "../main/header/shared_components/cartDrawer"
import { HeaderLinks } from "../main/header/headerLinks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/pro-light-svg-icons";


const Header = ({ current_merchant, current_user }) => {
  const [visible, toggle] = useVisibility(false);
  const { cart, setIsOpen } = useContext(CartContext);
  let { market } = useContext(MarketContext);


  const MobileMenuOptions = () => {
    return (
      <>
        <div className="flex items-start py-1">
          <HeaderLinks market={market} />
        </div>
      </>
    );
  };
  if (market) {
    return (
      <>
        <header className="bg-white w-full relative flex items-center flex-col">
          <div className="hidden xl:block">
            <HeaderLinks market={market} />
          </div>
          <div className="flex flex-row items-center md:justify-between w-full">
            <div>
              <div className="flex items-center xl:ml-10 xl:mr-20 ">
                <ToggleNavigationMenu visible={visible} toggle={toggle} />
                <div className="flex flex-col md:flex-row-reverse">
                  <div className="flex flex-col items-center md:flex-row md:pl-16 lg:pl-1">
                    {visible && <MobileMenuOptions />}
                  </div>
                  <div className="flex flex-row-reverse justify-between">
                    <div className={(visible && "hidden md:block") || ""}>
                      <MarketLogo market={market} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between flex-col-reverse  w-full">
              <div className="flex items-center text-sm w-full">
                {!visible && (
                  <div className={"flex items-center py-1 w-full"}>
                    <div className=" md:block w-full">
                      <Search />
                    </div>
                    <div className="flex items-center">
                      <button
                        className={
                          "text-gray-700 lg:ml-10 lg:pr-6 md:pl-4 md:pr-0 pr-4 py-1 w-full"
                        }
                        onClick={() => setIsOpen(true)}
                      >
                      <span className="flex items-center space-x-1">
                        <FontAwesomeIcon icon={faShoppingCart} className='text-xl' />
                        <p className="text-base px-1">
                          Cart({CartQuantityDisplay(cart && cart.cart_shops)})
                        </p>
                      </span>
                      </button>
                      <SignInOptions
                        current_merchant={current_merchant}
                        current_user={current_user}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full bg-white border-t">
            <div className="flex flex-row items-center justify-between px-5">
              {visible && (
                <div className="md:hidden">
                  <MarketLogo market={market} />
                </div>
              )}
            </div>

            <CategorySearch
              visible={visible}
              setVisible={() => toggle(!visible)}
            />
          </div>
        </header>
        <CartDrawer />
      </>
    );
  } else {
    return null;
  }
};

export default Header;
