import React, { useContext, useState, useMemo } from "react";
import { NoProductsFound } from "./noProductsFound";
import { ProductsContext } from "./products";
import LargeProductCard from "./largeProductCard";
import MyProductsSelectStyle from "../../../../constants/custom_styles/myProductsSelectStyle";
import ProductTabs from "../shared_components/ProductTabs";
import { MobileCategoriesHeader } from "../shared_components/subCategoryHeader";
import Pagination from "../shared_components/pagination";
import ProductsGridSkeleton from "../shared_components/skeletons/productsGridSkeleton";

const ProductsGrid = ({ category, subCategory }) => {
  const {
    products,
    totalPages,
    currentPage,
    setCurrentPage,
    searchParams,
    isMadeLocally,
    city
  } = useContext(ProductsContext);

  const [currentCategory, setCurrentCategory] = useState("all-products");

  const onChange = (updatedKey) => {
    setCurrentCategory(updatedKey);
  }

  const title = useMemo(() => whichTitle(subCategory, category, isMadeLocally, city), [subCategory, category, isMadeLocally, city])

  function productsGrid() {
    if (products === undefined) {
      return null;
    } else {
      return (
        <>
          {products.length > 0 ? (
            <>
              <div className="items-center md:mb-2">
                {(location.pathname.includes("/shops") && (
                  <div className="justify-self-start">
                    <ProductTabs
                      products={products}
                      onChange={onChange}
                      activeKey={currentCategory}
                      containerClassName="w-80 mb-4"
                      styles={MyProductsSelectStyle}
                      value={currentCategory}
                    />
                  </div>
                )) || (
                  <div className="flex flex-col md:flex-row w-full justify-between items-start md:items-center">
                    <h1 className={`mb-6 md:mb-4 text-3xl ${subCategory && "hidden md:block"}`}>
                      {title}
                    </h1>
                    <div className={"flex flex-col md:flex-row gap-2 items-center w-full md:w-auto"}>
                      {category && category.children && category.children.length > 1 && (
                        <span className="block lg:hidden w-full md:w-auto">
                          <MobileCategoriesHeader category={category} selectClass={"md:w-56 w-full"} />
                        </span>
                      )}
                    </div>
                    <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                  </div>
                )}
              </div>
              <div
                className="w-full text-gray-900 antialiased justify-items-auto grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-x-4 gap-y-1 md:gap-x-5 md:gap-y-4">
                {products.map(
                  (product) =>
                    (product.category.name === currentCategory ||
                      currentCategory === "all-products") && (
                      <LargeProductCard
                        product={product}
                        key={product.objectID}
                      />
                    )
                )}
              </div>
              <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </>
          ) : (
            <NoProductsFound products={products} searchParams={searchParams} />
          )}
        </>
      );
    }
  }

  return (
    <div className="w-full">
      {products ? productsGrid() : <ProductsGridSkeleton />}
    </div>
  )
}


const whichTitle = (subCategory, category, isMadeLocally, city) => {
  if (subCategory) {
    return subCategory.name
  }
  if (category) {
    return category.name
  }
  if (isMadeLocally) {
    return `Locally Made Products in ${city?.name}`
  } else {

    return "All Products"
  }

}

export default ProductsGrid;
