import React from 'react';
import MobileCardRow from '../../../../merchants/shared_components/mobileCardRow';
import IcPlaceholder from '../../../../../../assets/images/image-icon-productPage.png'

const OrderCard = ({ data, onDetailsClicked }) => {
  return (
    <div className='border rounded-md px-2 py-3 border-beige-200'>
      <section className='flex flex-row p-4'>
        <img src={data.primaryItem.product_image || IcPlaceholder}
          className='w-10 h-10 mr-4 inline max-w-none rounded-lg align-top object-cover' />
        <span className='text-sm max-w-xs'>{data.primaryItem.product.title} - {data.primaryItem.product_variant.title}</span>
      </section>
      <section>
        <MobileCardRow label='Order Number'>{data.orderNumber}</MobileCardRow>
        <MobileCardRow label='Seller'>{data.seller}</MobileCardRow>
        <MobileCardRow label='Date'>{data.date}</MobileCardRow>
        <MobileCardRow label='Items'>{data.totalItems}</MobileCardRow>
        <MobileCardRow label='Sub Total'>{data.subTotal}</MobileCardRow>
        <MobileCardRow label='Taxes'>{data.taxes}</MobileCardRow>
        <MobileCardRow label='Shipping'>{data.shipping}</MobileCardRow>
        <MobileCardRow label='Total Price'>{data.grandTotal}</MobileCardRow>
      </section>
      <section className='px-4 my-2'>
        <button className='w-full rounded-full px-6 py-3 text-white bg-blue-grey'
          type='button' onClick={() => onDetailsClicked(data.orderNumber)}>Details
        </button>
      </section>
    </div>
  )
}

export default OrderCard;
