import { Form, useFormikContext } from 'formik';
import React from 'react';
import MyProductsSelectStyle from '../../../../../../constants/custom_styles/myProductsSelectStyle';
import PlusMinus from '../../../../../main/header/shared_components/plusMinus';
import FormButton from '../../../../../shared/form_fields/FormButton';
import FormSelect from '../../../../../shared/form_fields/FormSelect';
import FormTextArea from '../../../../../shared/form_fields/FormTextArea';

const RefundForm = ({ items }) => {

  const { values, isSubmitting, setFieldValue, handleChange } = useFormikContext();

  const dropdownItems = items.map(item => {
    return {
      label: `${item.product.title} - ${item.product_variant.title}`,
      value: item
    }
  })

  const selectedItem = dropdownItems.find(option => option.value.id === values.order_item_id)

  return (
    <Form className='flex flex-col gap-y-3 text-left my-4'>
      <section className='flex justify-between gap-x-6'>
        <FormSelect
          name='order_item_id'
          containerClassName='w-full'
          placeholder='Select Item...'
          options={dropdownItems}
          value={selectedItem}
          styles={{
            ...MyProductsSelectStyle,
            menuList: (provided) => ({
              ...provided,
              maxHeight: '10rem'
            })
          }}
          onChange={option => {
            setFieldValue('order_item_id', option.value.id)
            setFieldValue('quantity', option.value.quantity)
          }}
        />
        <PlusMinus className='h-8' quantity={values.quantity}
          plusDisabled={isSubmitting || !selectedItem || values.quantity >= selectedItem.value.quantity}
          minusDisabled={isSubmitting || values.quantity <= 1}
          onPlusClicked={() => setFieldValue('quantity', values.quantity + 1)}
          onMinusClicked={() => setFieldValue('quantity', values.quantity - 1)}
        />
      </section>
      <FormTextArea
        inputClassName='w-full'
        name='reason'
        placeholder='Reason...'
        value={values.reason}
        onChange={handleChange}
      />
      <section className='flex justify-end'>
        <FormButton
          type='submit'
          className='px-8 py-3 bg-yellow-600 text-white text-sm font-light rounded-full flex justify-center'
          loaderContainerClassName='w-5 self-center mr-1'
          isLoading={isSubmitting}>
          <span className='text-sm text-white self-center'>Request</span>
        </FormButton>
      </section>
    </Form>
  )
}

export default RefundForm;
