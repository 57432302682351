import React from 'react';

const NoData = ({ children, className, buttonText, onClick }) => {
  return (
    <div className={className}>
      <h3 className='text-lg text-center px-8 py-4 font-light'>{children}</h3>
      <button type='button' onClick={onClick}
        className='text-sm text-center px-8 py-3 text-white bg-yellow-600 font-light rounded-full'>
        {buttonText}
      </button>
    </div>
  )
}

export default NoData;
