import { applyStatics, ControlledMenu, MenuDivider, MenuItem } from '@szhsin/react-menu';
import React from 'react';
import { faBox, faDoorOpen, faHeart, faStar, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { faAbacus } from '@fortawesome/pro-solid-svg-icons';
import AccountOption from './accountOption';

const BuyerAccountMenu = ({ anchorRef, isOpen, setIsOpen }) => {

  applyStatics(MenuItem)(AccountOption);

  return (
    <ControlledMenu
      state={isOpen ? 'open' : 'closed'}
      arrow
      anchorRef={anchorRef}
      onMouseLeave={() => { setIsOpen(false) }}
      onClose={() => { setIsOpen(false) }}
    >
      <AccountOption icon={faHeart} label="My Wishlist" href='/users' />
      <MenuDivider />
      <AccountOption icon={faStar} label="Saved Sellers" href='/users/saved_sellers' />
      <MenuDivider />
      <AccountOption icon={faBox} label="Purchase History" href='/users/orders' />
      <MenuDivider />
      <AccountOption icon={faAbacus} label="Account Settings" href='/user/edit' />
      <MenuDivider />
      <AccountOption
        icon={faTruck} label="Sign up as a Merchant" href='/merchants/sign_up'
        iconClassName='text-yellow-600' spanClassName='text-yellow-600'
      />
      <MenuDivider />
      <AccountOption icon={faDoorOpen} label="Log Out" href='/users/sign_out' dataMethod="delete" />
    </ControlledMenu >
  )
}

export default BuyerAccountMenu;
