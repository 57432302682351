import React from 'react'
import Logo from "../../../../assets/images/logo_black.png";
import UserCircleIcon from "../../main/header/shared_components/UserCircleIcon";

const Header = ({ current_market_admin }) => {
  return (
    <header className="flex justify-between items-center px-2 lg:px-20 border">
      <div className="h-16 md:h-20 py-1 lg:py-2">
        <div className="flex items-center xl:ml-10 xl:mr-20 ">
          <div className="flex flex-col md:flex-row-reverse">
            <div className="flex flex-row-reverse justify-between">

              <a href="/">
                <img className="h-12 md:h-16 ml-4" src={Logo} alt="Local Shops" />
              </a>

            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row px-2 pt-2 pb-4 gap-x-4 items-center">

        {current_market_admin.email && (
          <div className="flex flex-row items-center">
            <a
              className="hidden md:block text-cerulean-800 text-sm font-medium hover:text-black px-2 cursor-pointer"
              href="/market_admins/edit"
            >
              {current_market_admin.email}
            </a>
            <UserCircleIcon />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
