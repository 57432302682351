import React from 'react'
import { Link } from 'react-router-dom'
import JewellaryBanner from '../../../../../assets/images/jewellary_banner.svg'
import GirlBanner from '../../../../../assets/images/girl_banner.svg'
import DecorBanner from '../../../../../assets/images/decor_banner.svg'
import ArtBanner from '../../../../../assets/images/art_banner.svg'
import GiftsBanner from '../../../../../assets/images/gift-ideas-bg.png'

const PopularCategories = ({ market }) => {
  const popularCategoriesStyles = {
    height: '32rem'
  }

  const categoryUrlHelper = (categoryPath) => market ? `/markets/${market.slug}/${categoryPath}` : `/${categoryPath}`


  return (
    <section id='popular_categories'>
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-1'>
        <div style={popularCategoriesStyles}
             className='grid grid-cols-1 grid-rows-2 col-span-2 lg:grid-cols-2 px-5 lg:px-1 lg:grid-rows-1 gap-4'>
          <div className='grid grid-rows-2 grid-cols-1 gap-4'>
            <Link to={categoryUrlHelper('arts-crafts/paintings')}
                  className='w-full h-full lg:h-full'>
              <div
                className='rounded-lg py-4 px-12 w-full h-full bg-cover relative bg-left transform transition ease-in-out hover:scale-105 duration-500 flex flex-col justify-center'
                style={{ backgroundImage: `url(${GiftsBanner})` }}>
                <div className='flex flex-col justify-center h-auto'>
                  <span className='text-sm md:text-2xl font-medium'>Gift Ideas</span>
                  <span className='text-base font-normal hidden lg:block'>
                    Localshops is your go-to spot for unusual gifts they’ve never seen but will definitely flip over.{' '}
                  </span>
                </div>
              </div>
            </Link>
            <Link
              to={categoryUrlHelper('accessories/jewellery')}
              className='w-full h-full rounded-lg relative lg:h-full bg-mustard-350 transform hover:scale-105 duration-500'>
              <div className='rounded-lg w-full h-full bg-cover bg-right'
                   style={{ backgroundImage: `url(${JewellaryBanner})` }}>
                <div className='pt-8 pl-8 text-sm md:text-2xl text-white'>Jewellery</div>
              </div>
            </Link>
          </div>
          <div className='grid h-24 md:h-40 lg:h-full grid-cols-2 gap-4'>
            <Link to={categoryUrlHelper('apparel/girl-s')} className='w-full h-full'>
              <div
                className='rounded-lg w-full h-full bg-no-repeat bg-cover transform hover:scale-105 duration-500 text-sm md:text-2xl pl-8 pt-8 text-white'
                style={{ backgroundImage: `url(${GirlBanner})` }}>
              </div>
            </Link>
            <div className='grid grid-rows-2 gap-4'>
              <Link to={categoryUrlHelper('home-garden/decor')}
                    className='w-full '>
                <div
                  className='rounded-lg w-full h-32 lg:h-full bg-mustard-300 text-sm md:text-2xl pl-8 pt-8 text-white bg-cover bg-center transform hover:scale-105 duration-500'
                  style={{ backgroundImage: `url(${DecorBanner})` }}>
                  Decor
                </div>
              </Link>
              <Link to={categoryUrlHelper('arts-crafts')} className='w-full '>
                <div
                  className='rounded-lg w-full h-full bg-cover transform hover:scale-105 duration-500'
                  style={{ backgroundImage: `url(${ArtBanner})` }}>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopularCategories
