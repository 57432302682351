import React from "react"
import { useState } from "react"

const ImageCard = ({ item, image, path }) => {
  const transitionGrowClass = 'transition duration-500 ease-in-out transform'
  let [hover, setHover] = useState(false)

  return (
    <div
      className={`cursor-pointer ${hover && "bg-light-orange"} ${transitionGrowClass} relative flex items-center space-x-3 rounded-lg border bg-white px-6 py-6 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400 `}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <div className="flex-shrink-0">
        <img className="h-12 w-12 rounded-full" src={image} alt="" />
      </div>
      <div className="min-w-0 flex-1">
        <a href={`${path}`}
           target="_blank"
           rel="noopener noreferrer" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{item.name}</p>
          <p className="truncate text-sm text-gray-500">{item.tag_line}</p>
        </a>
      </div>
    </div>
  )
}

export default ImageCard;
