import axios from "axios";
import SortDropdown from "../../main/payments_dashboard/sortDropdown";
import React, { useEffect, useState } from "react";

const ShopSelection = ({ currentShop }) => {
  let [shops, setShops] = useState([])

  useEffect(() => {
    axios.get('/merchants/integrations.json')
      .then(res => setShops(res.data))
      .catch(e => console.log(e))
  }, [])

  const onChange = (e) => {
    window.location.href = `/merchants/integrations/${e}/products`
  }

  const options = shops.map((shop) => {
    return { label: shop.shop_name, value: shop.id }
  })

  return shops.length > 0 && 
  
  
  
  <SortDropdown
    setValue={onChange}
    value={currentShop?.shop_name}
    className='w-64 m-2'
    options={options}
    placeholder={currentShop?.shop_name}
  />
}

export default ShopSelection
