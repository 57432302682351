import React, { useState } from "react";
import CrossableModal from "../../../../shared_components/crossableModal";

const SearchSelectModal = ({ onClose, children }) => {
  return (
    <CrossableModal
      title={<h5 className="text-left pl-5 pb-5">Filter</h5>}
      onClose={onClose}
    >
      <div className="flex flex-col items-center">
        <div className="text-center items-left">{children}</div>

        <button
          className="rounded-full my-4 px-10 py-3 bg-yellow-600 text-white"
          onClick={onClose}
        >
          Back
        </button>
      </div>
    </CrossableModal>
  );
};

export default SearchSelectModal;
