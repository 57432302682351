import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Calendar = ({
  className,
  wrapperClassName,
  selected,
  selectsRange,
  isClearable,
  startDate,
  placeholder,
  endDate,
  onChange,
  minDate,
  maxDate
}) => {
  return <DatePicker
    placeholderText={placeholder}
    wrapperClassName={wrapperClassName}
    startDate={startDate}
    endDate={endDate}
    selected={selected}
    isClearable={isClearable}
    onChange={onChange}
    minDate={minDate}
    showYearDropdown
    showMonthDropdown
    dropdownMode="select"
    maxDate={maxDate}
    selectsRange={selectsRange}
    className={`border border-beige-200 rounded-md p-2 ${className}`}
  />
}

export default Calendar;
