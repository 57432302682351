import React from "react";
import QuestionDropdown from "./shared/questionDropdown"
import PageTitle from './shared/pageTitle'
import SubHeader from "../page_body/shared_components/subHeader";
import ScrollToTop from "../../../utilities/scrollToTop";

const FAQ = () => {
  return (
    <ScrollToTop>
      <SubHeader breadcrumbs='FAQ' link='/faq' />
      <div className='w-full bg-white border-b border-gray-300 flex flex-col justify-center items-center py-6 mb-4'>
        <PageTitle title={"FAQ"} paragraph={"Frequently Asked Questions"} />
      </div>
      <div className='flex flex-col p-4 items-center w-full'>
        <div className='flex flex-col w-full items-center md:w-2/3'>
          <QuestionDropdown question='What is Local Shops?'>
            Local Shops is an online marketplace for local businesses to post
            their products. The marketplace connects shoppers to the products in
            their area! We built this platform to showcase a variety of products
            made and sold by local businesses in a convenient search directory.
            At the point of purchase, you will be taken to the merchant's
            e-commerce site for an easy checkout.
          </QuestionDropdown>

          <QuestionDropdown question='I have a local business! How do I become a merchant on Local Shops?'>
            Easy, we've partnered with Shopify so you can import your product
            library into our database with just a few simple clicks. If you do
            not have a shopify store or a pre-existing e-commerce solution, you
            can still post and sell your products on Local Shops. Once your
            products are in our database, they will be available for shoppers in
            your area!
          </QuestionDropdown>

          <QuestionDropdown question="Can I still be a Local Shops merchant if I don't have an e-commerce platform for my business?">
            Yes! Businesses who don't have a pre-existing e-commerce solution
            can post and sell their products on Local Shops. When a customer
            decides they want to purchase your item, they will simply have to
            contact you (the product creator) directly to arrange
            purchase/payment. In July, merchants will be able to accept payments
            through Local Shops. We are working on payment gateways, such as
            PayPal, Square and Stripe to accept sales through our platform.
            Expect these updates soon!
          </QuestionDropdown>

          <QuestionDropdown question='What if I want to delete my data?'>
            <p className='mb-2'>
              Local Shops users are entitled to an array of data protection
              rights, including the erasure of their data from online platforms.
              Below we have outlined the GDPR compliant data erasures/requests
              that pertain to Local Shops. A more detailed list of these rights
              can be accessed via our{' '}
              <a className='text-blue-700 hover:underline' href='/privacy'>
                Privacy Policy
              </a>
              .
            </p>

            <h3 className='text-lg font-normal ml-2'>
              1. Merchant Data Request
            </h3>
            <p className='mb-2 ml-2'>
              When a Merchant requests their data from Local Shops, Local Shops
              will provide the data that has been collected from the Merchant.
            </p>

            <h3 className='text-lg font-normal ml-2'>2. Shop Data Erasure</h3>
            <p className='mb-2 ml-2'>
              Local Shops Merchants have the right to obtain the erasure of
              their information following the removal of their store from Local
              Shops. Local Shops has the obligation to erase this data within a
              reasonable amount of time following the 48 hour uninstallment
              period enacted by Shopify. Your Merchant information will then be
              erased from the Local Shops database within a set amount of time.
            </p>

            <h3 className='text-lg font-normal ml-2'>
              3. Customer Data Erasure
            </h3>
            <p className='ml-2'>
              Local Shops does not process the customer data of Merchants in any
              way. If a customer of a Local Shops Merchant wishes to have their
              data erased, the customer will have to request the deletion of
              their data via the Merchant directly.
            </p>
          </QuestionDropdown>
          <QuestionDropdown question="I can't find my city in the dropdown filters">
            Please click on the blue support bubble on the lower right side of
            the screen & ask the customer service rep to make a ticket to
            include the city that you want
          </QuestionDropdown>
          <QuestionDropdown question="I can't find my Category">
            Please click on the blue support bubble on the lower right side of
            the screen & ask the customer service rep to make a ticket to
            include the category that you want
          </QuestionDropdown>
        </div>
      </div>
    </ScrollToTop>
  )
};

export default FAQ;
