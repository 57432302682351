import React, { useState, useEffect } from 'react'

export default function InstagramImageItem({ image_data, selectImage, deSelectImage }) {
  const [imageSelected, setImageSelected] = useState(false)

  const toggleSelected = () => {
    setImageSelected(!imageSelected)
  }

  useEffect(() => {
    imageSelected ? selectImage(image_data) : deSelectImage(image_data)
  }, [imageSelected])

  let outline = (imageSelected ? "border-4 border-opacity-50 border-blue-500" : "border border-gray-400")

  return (
    <div>
      <div
        className={`cursor-pointer bg-white shadow-lg hover:shadow-xl ${outline} rounded-sm flex flex-col h-full mr-2 mb-2`}
        onClick={toggleSelected}
      >
        <img
          className="w-full object-scale-down h-64"
          src={image_data.media_url}
          alt="image"
        />
        <div className="flex flex-col p-4 flex-grow">
          <div className='text-center text-xs'>
            {image_data.caption}
          </div>
        </div>
      </div>
    </div>
  )
}
