import React from "react";
import Tabs, { TabPane } from "rc-tabs";
import Select from "react-select";

const ProductTabs = ({
  activeKey,
  products = "",
  onChange,
  containerClassName,
  styles,
  value,
}) => {
  const allCategories = new Set(
    products.map((product) => product.category.name)
  );

  const options = [{ label: "All Products", value: "all-products" }];
  Array.from(allCategories).map((category) => {
    options.push({ label: category, value: category });
  });

  const CategoryDropdown = () => {
    return (
      <section className={containerClassName}>
        <Select
          placeholder="All Products"
          options={options}
          styles={styles}
          value={options.find((option) => option.value === value) || null}
          isSearchable={false}
          isClearable
          onChange={(option) => {
            onChange(option ? option.value : null);
          }}
        />
      </section>
    );
  };

  const CategoryTabs = () => {
    return (
      <Tabs
        defaultActiveKey={activeKey}
        className="md:mx-12 mx-auto my-auto"
        onChange={onChange}
      >
        <TabPane tab="All Products" key="all-products" />
        {Array.from(allCategories).map((category) => (
          <TabPane tab={category} key={category} />
        ))}
      </Tabs>
    );
  };

  return (window.innerWidth < 640 && <CategoryDropdown />) || <CategoryTabs />;
};

export default ProductTabs;
