import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { appendCurrentCheckoutMode } from '../../../../utilities/checkoutUtility';
import CheckoutUI from '../checkoutUI';
import { CheckoutShippingContext } from './checkoutShippingContext';
import EditAddressForm from './forms/editAddressForm';

const CheckoutShippingEditFormUI = () => {

  const history = useHistory();
  const { id } = useParams();
  const { isUpdatingAddress } = useContext(CheckoutShippingContext);

  useEffect(() => {
    resetScrollPosition()
  }, [])

  const resetScrollPosition = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  return (
    <CheckoutUI
      pageTitle='Shipping Information'
      primaryFormId='shipping-address-form'
      isSubmittingForm={isUpdatingAddress}
      hasShippingBeenVisited={true}
      hasPaymentBeenVisited={false}>
      <EditAddressForm
        id={id}
        onAddressUpdated={() => history.replace(appendCurrentCheckoutMode('/checkout/addresses'))}
        onInvalidAddressRecieved={() => history.replace(appendCurrentCheckoutMode('/checkout/addresses'))}
      />
    </CheckoutUI>
  )
}

export default CheckoutShippingEditFormUI;
