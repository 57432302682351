import React, { useEffect, useState } from 'react'
import axios from "axios";
import Header from "../components/main/header/header";
import Footer from "../components/main/footer/footer";
import Cart from "./cartContext";
import Checkout from '../components/users/checkout/checkoutContext';

export const CitiesContext = React.createContext({
  city: null,
  setCity: () => {
  }
})

const CityContext = ({ current_merchant, current_user, children }) => {
  let [city, setCity] = useState(null)

  const findNearestCity = (position) => {
    axios.put('/cities/set_city.json', {
      position: position
    }).then((res) => setCity(res.data))
  }

  function geolocateCity() {
    navigator.geolocation.getCurrentPosition((position) => {
      findNearestCity([position.coords.latitude, position.coords.longitude])
    }, () => findNearestCity(null), { maximumAge: 60000, timeout: 2000, enableHighAccuracy: false })
  }

  useEffect(() => {
    axios.get('/cities/current_city.json').then((res) => {
      if (res.data.slug) {
        setCity(res.data)
      } else {
        geolocateCity()
      }
    })

  }, [])

  return (
    <CitiesContext.Provider value={{ city: city, setCity: setCity }}>
      <Cart>
        <Checkout>
          <Header current_merchant={current_merchant} current_user={current_user} />
          {children}
        </Checkout>
      </Cart>
      <Footer />
    </CitiesContext.Provider>
  )
}

export default CityContext
