import axios from 'axios';
import React, { useState, useContext, createContext } from 'react';
import { toast } from 'react-toastify';
import  FavoritesContext  from '../../../../contexts/favoritesContext';
import { sortShippingAddresses } from '../../../../utilities/shippingUtility';

export const CheckoutShippingContext = createContext({
  shippingAddresses: [],
  isFetchingAddresses: false,
  isAddingAddress: false,
  isDeletingAddress: false,
  isUpdatingAddress: false,
  hasAddresses: null,
  findDefaultAddress: null,
  fetchAddresses: null,
  deleteAddress: null,
  addAddress: null,
  updateAddress: null,
  chooseAddress: null,
  fetchAddress: null,
})

const CheckoutShipping = ({ children }) => {

  const { token } = useContext(FavoritesContext);

  const [shippingAddresses, setShippingAddresses] = useState([]);

  const [isFetchingAddresses, setIsFetchingAddresses] = useState(false);
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isDeletingAddress, setIsDeletingAddress] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);

  const hasAddresses = () => {
    return !!(shippingAddresses && shippingAddresses.length);
  }

  const findDefaultAddress = () => {
    const defaultAddress = hasAddresses() && shippingAddresses.map((address) => {
      if (address.is_default) return address
    })

    return defaultAddress.length && defaultAddress[0]
  }


  const fetchAddresses = () => {
    setIsFetchingAddresses(true);
    return axios.get('/checkout/addresses.json').then(response => {
      setIsFetchingAddresses(false);
      setShippingAddresses(sortShippingAddresses(response.data));
      return response;
    }).catch(error => {
      setIsFetchingAddresses(false);
      throw error;
    })
  }

  const deleteAddress = addressId => {
    setIsDeletingAddress(true);
    return axios.delete(`/checkout/addresses/${addressId}`, {
      data: { authenticity_token: token }
    }).then(response => {
      toast.success('Shipping address deleted.');
      fetchAddresses();
      return response;
    }).catch(error => {
      toast.error('Error in deleting shipping address.');
      throw error;
    }).finally(() => {
      setIsDeletingAddress(false);
    })
  }

  const chooseAddress = address => {
    setIsUpdatingAddress(true);
    return updateAddressPromise(address.id, {
      name: address.name,
      street_address: address.line1,
      city_id: address.city.id,
      postal_code: address.postal_code,
      phone_number: address.phone_number,
      is_default: true,
    }).then(response => {
      toast.success('Shipping address chosen.');
      fetchAddresses();
      return response;
    }).catch(error => {
      toast.error('Error in choosing this address.');
      throw error;
    }).finally(() => {
      setIsUpdatingAddress(false);
    })
  }

  const addAddress = values => {
    setIsAddingAddress(true);
    return addAddressPromise(values)
      .then(response => {
        setIsAddingAddress(false);
        fetchAddresses();
        toast.success('Shipping address added.');
        return response;
      }).catch(error => {
        setIsAddingAddress(false);
        toast.error('Error in adding shipping address.');
        throw error;
      })
  }

  const updateAddress = (id, values) => {
    setIsUpdatingAddress(true);
    return updateAddressPromise(id, values)
      .then(response => {
        toast.success('Shipping address updated.');
        fetchAddresses();
        return response;
      }).catch(error => {
        toast.error('Error in updating shipping address.');
        throw error;
      }).finally(() => {
        setIsUpdatingAddress(false);
      })
  }

  const fetchAddress = addressId => {
    return axios.get(`/checkout/addresses/${addressId}.json`)
  }

  const addAddressPromise = ({
                               street_address,
                               city_id,
                               postal_code,
                               name,
                               phone_number,
                               is_default
                             }) => {
    return axios.post('/checkout/addresses', {
      authenticity_token: token,
      address: {
        line1: street_address,
        line2: "",
        city_id: city_id,
        postal_code: postal_code,
        name: name,
        phone_number: phone_number,
        is_default: is_default
      }
    })
  }

  const updateAddressPromise = (addressId, {
    street_address,
    city_id,
    postal_code,
    name,
    phone_number,
    is_default
  }) => {
    return axios.put(`/checkout/addresses/${addressId}`, {
      authenticity_token: token,
      address: {
        line1: street_address,
        line2: "",
        city_id: city_id,
        postal_code: postal_code,
        name: name,
        phone_number: phone_number,
        is_default: is_default
      }
    })
  }

  return (
    <CheckoutShippingContext.Provider value={{
      addAddress,
      deleteAddress,
      fetchAddresses,
      findDefaultAddress,
      hasAddresses,
      isAddingAddress,
      isUpdatingAddress,
      isDeletingAddress,
      isFetchingAddresses,
      shippingAddresses,
      updateAddress,
      chooseAddress,
      fetchAddress
    }}>
      {children}
    </CheckoutShippingContext.Provider>
  )
}

export default CheckoutShipping;
