import React from "react";
import { NavLink } from "react-router-dom";

export const HeaderLinks = ({ market }) => {
  return (
    <div
      className=
        "flex-row justify-start xl:justify-around w-full ml-1 xl:ml-3 xl:px-8 xl:pt-2 lg:flex whitespace-no-wrap"
    >
      <div className=" text-lg text-medium-grey flex-row">
        <NavLink
          activeClassName={ActiveLink}
          className={HeaderLinkClass}
          to={(market && `/markets/${market.slug}/categories`) || "/categories"}
        >
          Shop by Category
        </NavLink>
        <NavLink
          activeClassName={ActiveLink}
          className={HeaderLinkClass}
          to={(market && `/markets/${market.slug}/shops`) || "/shops"}
        >
          All Brands
        </NavLink>
        <NavLink
          activeClassName={ActiveLink}
          className={HeaderLinkClass}
          to={(market && `/markets/${market.slug}/products`) || "/products"}
        >
          All Products
        </NavLink>

        {market && <MarketLink label={market.cta_button_label} link={market.cta_button_link} />}
        {market && <MarketLink label={`Return to ${market.name}`} link={market.website} />}

      </div>
    </div>
  )
}

const HeaderLinkClass = "whitespace-no-wrap text-medium-grey hover:text-mustard-400 cursor-pointer md:mr-4 mr-2"
const ActiveLink = "text-mustard-400 font-medium"

const MarketLink = ({ label, link }) => {
  return (
    <>
      {label && link && <a
        href={link}
        target='_blank'
        rel="noopener noreferrer"
        className={HeaderLinkClass}
      >
        {label}
      </a>
      }

    </>
  )
}
