import React from "react"
import ReconnectToShopify from "./reconnectToShopify"
import SynchronizeProducts from "../../../../integrations_dashboard/integration_items/synchronizeProducts"
import ShopCity from "./shopCity"
import ShopImage from "./shopImage"

const ShopHeader = ({ integration }) => {
  return (
    <div className="flex flex-row justify-between pb-8 lg:px-0 lg:pt-0 px-2 pt-4">
      <div className='flex flex-row justify-start items-center'>
        <div className='h-16 w-16 md:h-18 md:w-18'>
          <ShopImage shop={integration} />
        </div>
        <div className='flex-1 justify-start mx-4'>
          <h1 className='text-lg md:text-3xl font-base tracking-tighter leading-tight'>{integration.shop_name}</h1>
          <ShopCity shop={integration} /><br />
        </div>
      </div>
      <div className='flex w-40 md:w-auto items-center justify-end gap-x-2'>
        <ReconnectToShopify integration={integration} />
        {integration.provider === "native" ? (
          <a
            className='px-4 md:px-10 py-3 rounded-full text-base text-white font-light text-sm bg-mustard-400'
            href={`/merchants/integrations/${integration.id}/products/new`}
          >
            Add Product
          </a>
        ) : (
          <SynchronizeProducts integration={integration} />
        )}
      </div>
    </div>
  )
}

export default ShopHeader
