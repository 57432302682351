import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactPaginate from "react-paginate";
import IntegrationProductRow from './integration_product_row'
import Onboarding from '../../../shared_components/onboarding'
import OnboardingIntegrations from '../../../shared_components/onboardingIntegrations'
import NoSearchResult from '../../../shared_components/no_search_results'
import '../../../../../css/pagination.css'
import LoadingSpinner from "../../../../main/page_body/shared_components/loadingSpinner";
import PreviousButtonLabel from '../../../../main/page_body/shared_components/pagination/previousButtonLabel';
import NextButtonLabel from '../../../../main/page_body/shared_components/pagination/nextButtonLabel';
import ShopHeader from './headerComponents/shopHeader';
import TableHeader from './tableComponents/tableHeader';
import BulkSelectRow from './subComponents/bulkSelectRow';
import SearchAndSort from './subComponents/searchAndSort';
import Pagination from "../../../../main/page_body/shared_components/pagination";


const IntegrationProductListing = ({ categories, integration }) => {
  const [productsState, setProductsState] = useState(undefined)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("")
  const [canada, setCanada] = useState(false)
  const [sortbyOrder, setSortbyOrder] = useState("")
  const [filterByFeature, setFilterByFeature] = useState(null)
  const [tableCategory, setTableCategory] = useState(null)
  const [searchEmpty, setSearchEmpty] = useState(true)
  const [isMobileIpad, setIsMobileIpad] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsMobileIpad((window.innerWidth < 1024 && true) || false);
    });
  }, []);

  const areAllProductsSelected = () => !!productsState && productsState.length === selectedProducts.length;
  const isProductSelected = product => !!selectedProducts.find(currentProduct => currentProduct.id === product.id)

  const selectProduct = (product) => {
    if (!isProductSelected(product))
      setSelectedProducts([...selectedProducts, product])
  }

  const deSelectProduct = (product) => {
    setSelectedProducts(
      selectedProducts.filter(
        (currentProduct) => currentProduct.id !== product.id
      )
    )
  }

  const reqProduct = (params) => {
    axios.get(`/merchants/integrations/${integration.id}/products.json`, { params: params }).then((res) => {
      setProductsState(res.data.products)
      setTotalPages(res.data.pages)
      setCurrentPage(res.data.current_page)
    })
  }

  const getCurrentParams = () => {
    return {
      page: currentPage,
      per_page: 50,
      search: searchQuery,
      order: sortbyOrder ? sortbyOrder.value : '',
      category_id: tableCategory ? tableCategory.value : "",
      canada_products: canada,
      feature_tag: filterByFeature ? filterByFeature.value : ''
    }
  }

  useEffect(() => {
    reqProduct(getCurrentParams())
  }, [currentPage])

  const toggleSelectAll = (products) => {
    if (!areAllProductsSelected()) {
      setSelectedProducts(products)
    } else {
      setSelectedProducts([])
    }
  }

  const ProductsTable = function () {
    const handlePageChange = (page) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      setCurrentPage(page.selected + 1)
      setSelectedProducts([]);
    }



    const MobileProductListings = () => {
      return (<div className='bg-white flex flex-col gap-6 lg:flex-none'>
        {productsState.map((product) => (
                  <IntegrationProductRow
                    isMobile={isMobileIpad}
                    key={product.id}
                    product={product}
                    categories={categories}
                    productsState={productsState}
                    setProductsState={setProductsState}
                    integration={integration}
                    selectProduct={selectProduct}
                    deSelectProduct={deSelectProduct}
                    isSelected={isProductSelected(product)}
                    rowClassName='odd:bg-white even:bg-beige-100 opacity-100 hover:opacity-90'
                    labelClassName='px-2 py-4'
                  />
                ))}
      </div>)
    }

    if (productsState && productsState.length > 0) {
      return (
        isMobileIpad &&
        <MobileProductListings />
        ||
        <>
          <div>
            <table className='w-full'>
              <TableHeader
                labelClassName='uppercase text-xs text-gray-700 px-2 py-4 opacity-75 text-left'
                areAllSelected={areAllProductsSelected()}
                onSelectAll={() => { toggleSelectAll(productsState) }}
              />
              <tbody>
                {productsState.map((product) => (
                  <IntegrationProductRow
                    key={product.id}
                    product={product}
                    categories={categories}
                    productsState={productsState}
                    setProductsState={setProductsState}
                    integration={integration}
                    selectProduct={selectProduct}
                    deSelectProduct={deSelectProduct}
                    isSelected={isProductSelected(product)}
                    rowClassName='odd:bg-white even:bg-beige-100 opacity-100 hover:opacity-90'
                    labelClassName='px-2 py-4'
                  />
                ))}
              </tbody>
            </table>
          </div>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
        </>
      )
    } else if (productsState && productsState.length === 0) {
      if (integration.provider === "native") {
        if (searchQuery === '' && searchEmpty) {
          return <Onboarding
            title='Products'
            url={`/merchants/integrations/${integration.id}/products/new`}
          />
        } else {
          return <NoSearchResult className='bg-white flex flex-col items-center px-8 py-48' />
        }
      } else {
        return <OnboardingIntegrations title='Products' integration={integration} />
      }
    } else {
      return <LoadingSpinner />
    }
  }

  return (
    <>
      <ShopHeader integration={integration} />
      {
        !selectedProducts.length &&
        <SearchAndSort
          isMobile={isMobileIpad}
          categories={categories}
          currentParams={getCurrentParams()}
          filterByFeature={filterByFeature}
          reqProduct={reqProduct}
          searchQuery={searchQuery}
          setCanada={setCanada}
          setFilterByFeature={setFilterByFeature}
          setSearchEmpty={setSearchEmpty}
          setSearchQuery={setSearchQuery}
          setSortbyOrder={setSortbyOrder}
          setTableCategory={setTableCategory}
          sortbyOrder={sortbyOrder}
          tableCategory={tableCategory}
        />
      }
      {
        !!selectedProducts.length &&
        <BulkSelectRow categories={categories} integration={integration} selectedProducts={selectedProducts} />
      }
      {ProductsTable()}
    </>
  )
}

export default IntegrationProductListing
