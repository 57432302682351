export const getProductVariantOptions = (product) => {
  return !product.product_variants.length ? [] :
    product.product_variants.map(variant => {
      if (variant.inventory === 0) {
        return {
          label: variant.title + " - Out of Stock",
          value: variant,
          outOfStock: true
        }
      }

      return {
        label: variant.title,
        value: variant,
        outOfStock: false
      }
    })
}
