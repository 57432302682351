import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

export const ShopDescription = ({ shop }) => {
  const hasDescription = (shop) => {
    const description = JSON.parse(shop.description);
    return description && description["blocks"][0]["text"].length > 0
  }

  return !hasDescription(shop) && <a href={`/merchants/integrations/${shop.id}/edit`}
                                     className='flex flex-row items-center uppercase hover:underline'>
    <FontAwesomeIcon icon={faExclamationTriangle} className='text-yellow-400 mr-1' />
    <div>Description is missing</div>
  </a>
}
