import * as Yup from 'yup';
import ErrorMessages from '../errorMessages';

const ShippingAddressValidation = Yup.object().shape({
  name: Yup.string().required(ErrorMessages.FieldRequired),
  country_id: Yup.number().typeError(ErrorMessages.FieldRequired),
  street_address: Yup.string().required(ErrorMessages.FieldRequired),
  city_id: Yup.number().typeError(ErrorMessages.FieldRequired),
  province: Yup.string().required(ErrorMessages.FieldRequired),
  postal_code: Yup.string().required(ErrorMessages.FieldRequired),
  phone_number: Yup.string().required(ErrorMessages.FieldRequired),
  is_default: Yup.bool()
});

export default ShippingAddressValidation;
