import React, { useState } from 'react'
import axios from 'axios';
import { getAllCartItems } from '../../../utilities/cartUtility';
import CheckoutShipping from './shipping/checkoutShippingContext';

export const CheckoutContext = React.createContext({
  order: null,
  createCheckoutOrder: null,
  createBuyNowOrder: null,
  fetchOrder: null,
  updateOrderAddress: null,
  hasOrderBeenPaidFor: null,
  payForOrder: null,
  clearOrder: null,
  isCreatingOrder: false,
  isFetchingOrder: false,
  isUpdatingOrder: false,
  isPayingForOrder: false,
})

const Checkout = ({ children }) => {
  const [order, setOrder] = useState(null);

  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isFetchingOrder, setIsFetchingOrder] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [isPayingForOrder, setIsPayingForOrder] = useState(false);

  const hasOrderBeenPaidFor = () => {
    return order && order.status === 'paid';
  }

  const clearOrder = () => {
    setOrder(null);
  }

  const createOrder = (orderItemAttributes) => {
    setIsCreatingOrder(true);
    return axios.post('/orders', {
      order: {
        order_items: orderItemAttributes
      }
    }, {
      headers: {
        Accept: 'application/json'
      }
    }).then(response => {
      setIsCreatingOrder(false);
      setOrder(response.data);
      return response;
    }).catch(error => {
      setIsCreatingOrder(false);
      console.error(error);
      throw error;
    })
  }

  const createCheckoutOrder = (cart) => {
    return createOrder(getAllCartItems(cart).map(cartItem => {
      return {
        product_variant_id: cartItem.product_variant.id,
        quantity: cartItem.quantity,
        market_id: cartItem.market_id
      }
    }))
  }

  const createBuyNowOrder = (productVariantId) => {
    return createOrder([{
      product_variant_id: productVariantId,
      quantity: 1
    }]);
  }

  const fetchOrder = (orderId) => {
    setIsFetchingOrder(true);
    return axios.get(`/orders/${orderId}`).then(response => {
      setIsFetchingOrder(false);
      setOrder(response.data);
      return response;
    }).catch(error => {
      setIsFetchingOrder(false);
      console.error(error);
      throw error;
    })
  }

  const updateOrderAddress = (orderId, addressId) => {
    setIsUpdatingOrder(true);
    return axios.put(`/orders/${orderId}`, {
      order: {
        address_id: addressId
      }
    }).then(response => {
      setIsUpdatingOrder(false);
      return fetchOrder(orderId);
    }).catch(error => {
      setIsUpdatingOrder(false);
      console.error(error);
      throw error;
    })
  }

  const payForOrder = (orderId, paymentId, amount, currencyCode, status) => {
    setIsPayingForOrder(true);
    return axios.post(`/orders/${orderId}/transactions`, {
      transaction: {
        amount: amount,
        status: status,
        code: paymentId,
        currency: currencyCode
      }
    }, {
      headers: {
        Accept: 'application/json'
      }
    }).then(response => {
      setIsPayingForOrder(false);
      return fetchOrder(orderId);
    }).catch(error => {
      setIsPayingForOrder(false);
      console.error(error);
      throw error;
    })
  }

  return (
    <CheckoutContext.Provider
      value={{
        order: order,
        createCheckoutOrder: createCheckoutOrder,
        createBuyNowOrder: createBuyNowOrder,
        fetchOrder: fetchOrder,
        updateOrderAddress: updateOrderAddress,
        hasOrderBeenPaidFor: hasOrderBeenPaidFor,
        payForOrder: payForOrder,
        isCreatingOrder: isCreatingOrder,
        isFetchingOrder: isFetchingOrder,
        isUpdatingOrder: isUpdatingOrder,
        isPayingForOrder: isPayingForOrder,
        clearOrder: clearOrder
      }}>
      <CheckoutShipping>
        {children}
      </CheckoutShipping>
    </CheckoutContext.Provider>
  )
}

export default Checkout;
