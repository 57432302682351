import React, { useContext, useEffect, useState } from 'react'
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import { ProductsContext } from "./products";
import LargeProductCard from "./largeProductCard";
import CustomCarousel from '../shared_components/customCarousel';
import ProductsGridSkeleton from "../shared_components/skeletons/productsGridSkeleton";

const ProductsGridCarousel = ({show_more_link}) => {
  const { products } = useContext(ProductsContext);
  const [isMobile, setIsMobile] = useState(false);

  const MobileProductsGrid = () => {
    return (
      <div className='grid grid-cols-2'>
        {
          products.map(
            (product, index) =>
              index < 4 && (
                <div className='px-3'>
                  <LargeProductCard product={product} key={product.objectID} />
                </div>
              )
          )
        }
        <div className="flex justify-tems-center col-span-2 py-10">
          <a
            href={show_more_link}
            className="border border-mustard-100 w-full px-32 py-3 rounded-full text-mustard-200"
          >
            Show More
          </a>
        </div>
      </div>
    )
  }


  const ProductsGrid = () => {
    return (
      <section id="/productsGrid">
        <div className='w-full text-gray-900 antialiased'>
          {(isMobile && <MobileProductsGrid />) || (
            <CustomCarousel
              className="hover:bg-grey-900 rounded-full"
              length={"w-full"}
              breakpoints={carouselBreakpoints(3.5, 3.5, 3, 1)}>
              {products.map((product) => (
                <div className='px-3'>
                  <LargeProductCard product={product} key={product.objectID} />
                </div>
              ))}
            </CustomCarousel>
          )}
        </div>
      </section>
    )
  }

  return (
    <div className='w-full'>
      {products ? <ProductsGrid /> : <ProductsGridSkeleton/>}
    </div>
  )
}

export default ProductsGridCarousel
