import React from "react";
import SubHeader from '../page_body/shared_components/subHeader'
import AddressUI from "./shared/addressUI";
import '../../../css/footer-page.css';
import ScrollToTop from "../../../utilities/scrollToTop";

const ShippingInfo = () => {
  return (
    <ScrollToTop>
      <div className='footer-page'>
        <SubHeader breadcrumbs='Shipping Info' link='/shipping-info' />
        <div className="flex flex-col p-8 sm:px-32 lg:px-64 xl:px-80">
          <h1 className="font-bold text-3xl mx-auto my-4">Shipping & Pick-Up Info</h1>
          <p className="font-medium text-lg mx-auto my-4">
            Fulfilment, shipping and local pick-ups are executed by each individual brand on Local Shops. If
            your cart has products from multiple brands, your order will be fulfilled and shipped by those
            individual brands and you will receive separate packages for each item(s).
            <br /><br />
            Similarly, if you have multiple products in your cart from more than one brand and selected
            local pick-up options, you will be picking up from each of those brands local locations.
            <br /><br />
            If you have any questions about your shipment, tracking, or local pick-up, please contact the
            brand directly for details. If the brand is unresponsive after several attempts you can escalate
            your concerns with the team at Local Shops.
          </p>
          <ul className="footer-page-list px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 mb-4">
            <li>You should expect deliveries from Monday to Friday, excluding certain holidays.</li>
            <li>We encourage local brands to work with our same-day delivery partners, but not all do.
              If you have urgency in your order, please contact the brand directly for arrangements.
            </li>
            <li>Brands on Local Shops typically ship domestically via Canada Post in Canada and USPS in
              the United States.
            </li>
            <li>Brands on Local Shops typically ship internationally via DHL, FedEx or UPS.</li>
            <li>Shipping timelines and prices will vary based on each individual brand as they dictate
              their own rates and shipping speeds.
            </li>
            <li>If you have multiple products from multiple brands in your cart, the shipping rates will
              be bundled from each of those individual merchants for a grand total.
            </li>
          </ul>
          <h2 className="font-medium text-lg my-4">Questions?</h2>
          <section className="flex justify-start">
            <AddressUI
              title='Local Shops HQ'
              address={
                <p>
                  1212 34 Ave Se,
                  <br />
                  Calgary, Canada
                </p>
              }
              phoneNumber='587-225-5681'
              notes={[
                "Monday to Thursday 10am – 3pm",
                "Saturday CLOSED",
                "Sunday CLOSED"
              ]}
            />
          </section>
        </div>
      </div>
    </ScrollToTop>
  )
};

export default ShippingInfo;
