import React, { useEffect, useState } from 'react'
import MobileCategoryItem from "./mobileCategoryItem"

export default function MobileCategories({ categories, setVisible }) {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [isCategoriesButtonSelected, setIsCategoriesButtonSelected] = useState(false)

  useEffect(() => {
    window.addEventListener("click", () => {
      setIsCategoriesButtonSelected(false)
      setSelectedCategory(prevState => prevState ? null : prevState)
    })
  }, [])

  return (
    <div className="flex flex-col">
      {categories.map((category) => {
        return <MobileCategoryItem
          key={category.id}
          category={category}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          setVisible={setVisible}
        />;
      })}
    </div>
  )
}
