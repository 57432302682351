import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faImage } from '@fortawesome/pro-regular-svg-icons'

const ShopImage = ({ shop, className }) => {
  return (
    <>
      {shop.shop_logo_path ? (
        <img
          className={`w-full h-full object-cover rounded-full ${className}`}
          src={shop.shop_logo_path}
          alt={shop.shop_name}
        />
      ) : (
        <div
          className={`w-full h-full flex flex-col items-center justify-center` +
            `object-cover rounded-full bg-gray-200 ${className} `}
        >
          <div
            className={'flex flex-col items-center hover:text-cerulean-800 text-sm text-gray-500 ' +
              'transition ease-in duration-300'}>
            <FontAwesomeIcon icon={faImage} className='text-xl' />
            <p className='leading-tight text-xs text-center'>MISSING LOGO</p>
          </div>
        </div>
      )}
    </>
  )
}

export default ShopImage;
