import React, { useState } from 'react'
import ToggleSwitch from '../../../../merchants/products_dashboard/product_listings/shared_components/toggleSwitch'
import Button from '../../../../main/header/shared_components/button'

const MarketMembership = ({market}) => {
  const [isLive, setIsLive] = useState(market.is_live)
  return (
    <div>
      <div class='w-full h-full flex flex-row justify-between items-baseline pb-3 px-10'>
        <h1 class='text-2xl font-semibold'>Shops in {market.name}</h1>
        <div className='flex items-center'>
          <div>
            <label className='font-semibold px-1'>Live</label>
            <ToggleSwitch
              toggled={isLive}
              setToggled={() => setIsLive(!isLive)}
              attribute='is_live'
              url={`/market_admins/markets/${market.id}`}
            />
          </div>
          <div className='px-1'>
            <Button color='mustard-400' hover='gray-200' path={`/markets/${market.slug}?preview=true`} target={'_blank'}>Preview</Button>
          </div>
          <div className='px-1'>
            <Button color='mustard-400' hover='gray-200' path={`/market_admins/markets/${market.id}/export.csv`} target={'_blank'}>Export</Button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default MarketMembership
