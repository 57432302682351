import React from 'react'
import { formatPrice } from "../../../../utilities/formatPrice";
import { statusLabel } from "../shared_components/statusLabel"

const OrderRow = ({ orderIntegration, rowClassName, labelClassName }) => {
  return (
    <tr
      className={`cursor-pointer text-sm  items-center w-full ${rowClassName}`}
      key={orderIntegration.id}
    >
      <td colSpan={2} className={labelClassName}>
        <div className="flex md:flex-row flex-col md:items-center md:mr-12">
          <div className='flex flex-col'>
            <span className="md:inline hidden ml-4 text-base font-medium text-gray-800 max-w-xs">
              {orderIntegration.format_date}
            </span>
          </div>
        </div>
      </td>
      <td className={`table-cell w-full md:w-64 ${labelClassName}`}>
        {orderIntegration.customer.first_name}
      </td>
      <td className={labelClassName}>
        <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
          <div className='flex items-center gap-x-2'>
            {formatPrice(orderIntegration.order_items_total)}
          </div>
        </div>
      </td>
      <td className={labelClassName}>
        <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
          <div className='flex items-center gap-x-2'>
            {orderIntegration.order.status}
          </div>
        </div>
      </td>
      <td className={labelClassName}>
        <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
          <div className='flex items-center gap-x-2'>
            {statusLabel(orderIntegration.order.fulfillment_status)
            }
          </div>
        </div>
      </td>
      <td className={labelClassName}>
        <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
          <div className='flex items-center gap-x-2'>
            {orderIntegration.order_items.length}
          </div>
        </div>
      </td>
      <td>
        <a
          href={`/merchants/integrations/${orderIntegration.integration_id}/order_integrations/${orderIntegration.id}`}
          className='p-2 text-gray-700 font-bold flex items-center rounded'>Go to order</a>
      </td>
    </tr>
  )
}
export default OrderRow
