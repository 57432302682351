import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExternalLink,
  faUserCog,
  faHeart,
  faShoppingBasket,
  faShoppingBag
} from '@fortawesome/pro-regular-svg-icons'

const Sidebar = ({ current_path }) => {
  const Link = ({ id, url, icon, text, className, dataMethod }) => {
    const boldCurrentPath = () => {
      return id === current_path
        ? 'text-white rounded-full bg-cerulean-600 hover:bg-cerulean-500 shadow-item'
        : 'text-gray-600 rounded-full hover:text-white hover:bg-cerulean-600 hover:shadow-item'
    }
    return (
      <li
        className={`mx-4 py-4 p-6 my-4 list-none whitespace-no-wrap ${className} ${boldCurrentPath()}`}
      >
        <a href={url} data-method={dataMethod}>
          <span className='flex items-center'>
            <FontAwesomeIcon icon={icon} />
            {icon ? (
              <p className='ml-2'>{text}</p>
            ) : (
              <p className='w-full text-center'>{text}</p>
            )}
          </span>
        </a>
      </li>
    )
  }
  return (
    <ul className='w-60 bg-white h-auto flex-col lg:flex'>
      <div>
        <Link id='favorite' url='/users' text='Favorites' icon={faHeart} />
        <Link
          id='saved-sellers'
          url='/users/saved_sellers'
          text='Saved Sellers'
          icon={faShoppingBag}
        />
        <Link
          id='checkout'
          url='/users/my-cart'
          text='My Cart'
          icon={faShoppingBasket}
        />
      </div>
      <div>
        <Link
          id='edit'
          url='/user/edit'
          text='User Settings'
          icon={faUserCog}
        />
        <Link
          dataMethod='delete'
          url='/users/sign_out'
          text='Logout'
          icon={faExternalLink}
        />
      </div>
    </ul>
  )
}

export default Sidebar
