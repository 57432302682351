import React, { useEffect, useState } from "react"
import { formatPrice } from "../../../../utilities/formatPrice"
import axios from "axios"
import Tags from "../shared_components/tags"
import PhotoCarousel from "./photoCarousel";
import ShopLink from "../shared_components/shopLink";
import ProductDescription from "../shared_components/productDescription"
import ProductFavorite from '../shared_components/productFavorite'
import {
  ContactInformationDisplay,
  MadeLocalIndicator,
  GoToStoreButton,
  AddToCartButton,
  BuyNowButton
} from "../shared_components/productPageHelpers";
import { ShopImage } from "../shared_components/shopLogo";
import Carousel from "react-multi-carousel";
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import SmallProductCard from "./smallProductCard";
import { loadAlgoliaProducts } from "../shared_components/algoliaSearch";
import FormSelect from "../../../shared/form_fields/FormSelect";
import { getProductVariantOptions } from "../../../../constants/dropdown_options/productVariantOptions";
import MyProductsSelectStyle from "../../../../constants/custom_styles/myProductsSelectStyle";

const Product = ({ product }) => {
  const productVariantOptions = getProductVariantOptions(product);

  let [products, setProducts] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState(productVariantOptions.length ? productVariantOptions[0] : null);

  useEffect(() => {
    const searchOptions = { integration_slug: product.shop_slug, hits: 8 }
    loadAlgoliaProducts(searchOptions).then(({ hits }) => {
      setProducts(hits)
    })
  }, [product])

  useEffect(() => {
    axios({
      method: 'POST',
      url: `/products/${product.id}/impressions`,
    })
  }, [])

  const hasProductVariantOptions = () => {
    return productVariantOptions.length > 0;
  }

  const hasSimilarProducts = () => {
    return !!(products && products.length);
  }

  const getVariantSelectCSS = () => {
    return !hasProductVariantOptions() || productVariantOptions.find(option => option.label !== 'Default Title') ? '' : 'hidden';
  }

  return (
    <div className='w-full px-6'>
      <div className='flex flex-col lg:flex-row gap-y-6 gap-x-6 lg:justify-between lg:grid lg:grid-cols-6'>
        <div className='relative lg:col-span-3'>
          <PhotoCarousel photoUrls={product.product_images} title={product.title}
            itemChild={<MadeLocalIndicator product={product} className='w-24 h-24 absolute z-10 bottom-3 right-3' />}
          />
        </div>
        <div className='lg:mx-4 flex lg:col-span-3 w-full flex-col items-start'>
          <div className="flex items-center justify-between">
            <h1 className='text-xl lg:text-3xl capitalize mb-4'>{product.title}</h1>
          </div>
          {hasProductVariantOptions() &&
            <div className="mb-4 w-full w-full sm:max-w-xs">
              <FormSelect containerClassName={getVariantSelectCSS()}
                placeholder='Select Variant' options={productVariantOptions} errorsDisabled
                value={selectedVariant} onChange={setSelectedVariant} styles={MyProductsSelectStyle} />
            </div>
          }
          <div className='flex items-center justify-around h-8'>
            <div className='text-lg'>
              {selectedVariant && formatPrice(selectedVariant.value.price_cents)} {product.currency}
            </div>
          </div>
          <div className="flex flex-col mt-6 items-start md:w-auto w-full">
            <div className="flex flex-row items-center mb-4 gap-x-2">
              {selectedVariant && !selectedVariant.outOfStock && <AddToCartButton selectedVariant={selectedVariant.value} />}
              {selectedVariant && !!product.integration.paypal_email && <BuyNowButton selectedVariant={selectedVariant.value} />}
              <div className="ml-2 pt-px">
                <ProductFavorite product={product} />
              </div>
            </div>
          </div>
          <ProductDescription product={product} />
          <ShopLink shop_slug={product.shop_slug}
            className='text-sm hover:text-gray-800 flex items-center gap-x-2 gap-y-4 py-4'>
            <ShopImage shop={product.integration} className='w-12 h-12 rounded-full' disableLabel={true} />
            <div className='flex flex-col capitalize text-lg'>{product.shop_name}
              <p className='text-sm font-light text-gray-600'>in {product.city.name}</p>
            </div>
          </ShopLink>
          {product.tag_names.length > 0 && <label className='mt-4 text-sm uppercase font-semibold'>Tags</label>}
          <Tags product={product} />
        </div>
      </div>
      {
        hasSimilarProducts() &&
        <div className='py-8 lg:py-18'>
          <label className='text-xl font-semibold text-gray-800 px-3'>Similar Products</label>
          <div
            className='mt-3 w-full text-gray-900 antialiased justify-items-auto'>
            <Carousel responsive={carouselBreakpoints(4, 4, 3, 2)}>
              {products?.map((product) => (
                <div className='px-2'>
                  <SmallProductCard product={product} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      }
    </div >
  )
}

export default Product
