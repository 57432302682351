import React, { useEffect, useState } from 'react'
import Onboarding from '../../merchants/shared_components/onboarding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlusCircle, faSearch } from "@fortawesome/pro-solid-svg-icons";
import ReactPaginate from "react-paginate";
import LoadingSpinner from "../../main/page_body/shared_components/loadingSpinner";
import { formatPrice } from "../../../utilities/formatPrice";
import 'css/pagination.css'
import axios from 'axios'
import { toast } from 'react-toastify'
import { ProductImage } from 'components/merchants/products_dashboard/product_listings/shared_components/product_image'
import PreviousButtonLabel from '../../main/page_body/shared_components/pagination/previousButtonLabel';
import NextButtonLabel from '../../main/page_body/shared_components/pagination/nextButtonLabel';
import Pagination from "../../main/page_body/shared_components/pagination";

const Favorites = ({ authenticity_token }) => {
  const [productsState, setProductsState] = useState(undefined)
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const reqProduct = (params) => {
    axios.get(`/users/favorites.json`, { params: params }).then((res) => {
      setProductsState(res.data.products)
      setTotalPages(res.data.pages)
      setCurrentPage(res.data.current_page)
    })
  }

  const unfavorite = (product) => {
    axios.delete(`/users/favorites/${product.slug}`, { data: { authenticity_token: authenticity_token } }).then((res) => {

      toast.success('Product was removed from your favorites');
      let params = {
        page: currentPage,
        per_page: 50,
      }

      reqProduct(params)
    })
  }

  useEffect(() => {
    let params = {
      page: currentPage,
      per_page: 50,
    }

    reqProduct(params)
  }, [currentPage])

  const FavoriteProductRow = ({ product }) => {
    return (
      <tr
        className={`bg-white hover:bg-gray-100 cursor-pointer text-sm mb-2 items-center`}
        key={product.id}
      >
        <td>
          <ProductImage product={product} className='rounded-full w-16 h-16' />
        </td>
        <td className='max-w-xs'>
          <a href={`/products/${product.slug}`}>
            <span className='font-semibold text-gray-800'>{product.title}</span>
          </a>
        </td>
        <td>
          <a href={`/shops/${product.shop_slug}`}>
            <span className='font-semibold text-gray-800'>{product.shop_name}</span>
          </a>
        </td>
        <td>{product?.product_variant && formatPrice(product)}</td>
        <td><a className="btn" href="#" onClick={() => unfavorite(product)}>Unfavorite</a></td>
      </tr>
    )
  }

  const ProductsTable = () => {
    const handlePageChange = (page) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setCurrentPage(page.selected + 1)
    }

    return (
      <>
        <div>
          <table className='table table-fixed w-full border-collapse'>
            <thead className='bg-gray-100'>
              <tr>
                <th colSpan='2' className='py-4'>Product Name</th>
                <th>Shop</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {productsState.map((product) => (
                <FavoriteProductRow
                  key={product.id}
                  product={product}
                  productsState={productsState}
                  setProductsState={setProductsState}
                />
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
      </>
    )
  }

  if (productsState && productsState.length > 0) {
    return <ProductsTable />
  } else if (productsState && productsState.length === 0) {
    return <Onboarding url='/products' title='Products' action='Browse for' />
  } else {
    return <LoadingSpinner />
  }
}

export default Favorites
