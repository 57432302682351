import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { Form, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { InternationalRatesInitialValues } from '../../../constants/form_initial_values/shippingRatesInitialValues';
import useViewport from '../../../hooks/useViewport';
import FormButton from '../../shared/form_fields/FormButton';
import LocalPickup from './localPickup';
import ShippingCards from './mobile/shippingCards';
import ShippingFormContext from './shippingFormContext';
import ShippingTable from './table/shippingTable';

const ShippingsForm = ({ integration }) => {

  const { values, errors, setFieldValue, dirty: isFormDirty, resetForm, isSubmitting, initialValues } = useFormikContext();
  const { isMobile } = useViewport();
  const [countries, setCountries] = useState([]);

  const currencyPrefix = integration ? `${integration.city.country.currency} ` : '';

  useEffect(() => {
    fetchCountries();
  }, [])

  const getPrefixedFieldName = (prefix, partialFieldName) => `${prefix}_${partialFieldName}`;

  const fetchCountries = () => {
    axios.get('/countries.json').then(response => {
      setCountries([...response.data.map(country => {
        return {
          label: country.name,
          value: country.id
        }
      }), {
        label: 'Everywhere Else',
        value: null
      }]);
    })
  }

  const addNewInternationalShipping = () => {
    const internationalShippings = values.international_shippings && values.international_shippings.length ?
      [...values.international_shippings] : [];

    internationalShippings.push({ ...InternationalRatesInitialValues })
    setFieldValue('international_shippings', internationalShippings);
  }

  const getErrorsInInternationalShippingAt = (index) => {
    return errors.international_shippings && index < errors.international_shippings.length ?
      errors.international_shippings[index] : null;
  }

  const updateInternationalShippingAt = (index, fieldName, fieldValue) => {
    setFieldValue(`international_shippings[${index}][${fieldName}]`, fieldValue);
  }

  const deleteInternationalShippingAt = index => {
    if (hasInternationalShippings()) {
      const internationalShippings = [...values.international_shippings];
      if (!internationalShippings[index].id) {
        internationalShippings.splice(index, 1);
        setFieldValue('international_shippings', internationalShippings);
      } else {
        updateInternationalShippingAt(index, '_destroy', true);
      }
    }
  }

  const hasInternationalShippings = () => {
    return !!(values.international_shippings && values.international_shippings.length &&
      values.international_shippings.find(internationalShipping => !internationalShipping._destroy))
  }

  return (
    <ShippingFormContext.Provider value={{
      countries,
      currencyPrefix,
      getPrefixedFieldName,
      hasCountries: !!countries.length,
      hasInternationalShippings: hasInternationalShippings(),
      getErrorsInInternationalShippingAt,
      updateInternationalShippingAt,
      deleteInternationalShippingAt
    }}>
      <Form>
        <LocalPickup className='mb-4' />
        {
          isMobile() ?
            <ShippingCards currencyPrefix={currencyPrefix} countries={countries} /> :
            <ShippingTable currencyPrefix={currencyPrefix} countries={countries} />
        }
        <div className='w-full bg-white rounded-b-md px-5 py-3'>
          <button type='button' onClick={addNewInternationalShipping}
            className='flex flex-row gap-x-3 items-center'>
            <FontAwesomeIcon icon={faPlus} className='text-yellow-600 text-base' />
            <span className='text-sm font-light text-yellow-600'>Add Shipping Method</span>
          </button>
        </div>
        <div className='my-4 flex justify-end gap-x-3'>
          {
            isFormDirty &&
            <button onClick={resetForm} type='button'
              className='px-8 py-3 bg-white rounded-full text-black text-sm font-light'>
              Cancel
            </button>
          }
          <FormButton isLoading={isSubmitting} type='submit'
            loaderContainerClassName='w-5 self-center mr-1'
            className='flex justify-center w-24 px-4 py-3 bg-yellow-600 rounded-full text-white text-sm font-light'>Save
          </FormButton>
        </div>
      </Form>
    </ShippingFormContext.Provider>
  )
}

export default ShippingsForm;
