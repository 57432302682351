import React from "react"
import '../../../css/footer-page.css'
import ScrollToTop from "../../../utilities/scrollToTop"
import LeftSpiral from "../../../../assets/images/left_spiral.png"
import RightSpiral from "../../../../assets/images/right_top_spiral.png"
import { InformationBlock } from "./shared/CommunityMarketHelpers";

const CommunityMarkets = () => {
  const leftSpiral = {
    backgroundImage: (`url(${LeftSpiral})`),
    width: "56rem",
    height: "26.5rem",
  }
  const rightSpiral = {
    backgroundImage: (`url(${RightSpiral})`),
    width: "56rem",
    height: "26.5rem",
  }

  return (
    <ScrollToTop>
      <div className='bg-light-orange h-screen'>
        <div
          className="inline-block absolute lg:mt-0 lg:left-auto bg-no-repeat"
          style={leftSpiral}
        />
        <div
          className="inline-block absolute lg:mt-0 right-0 bg-no-repeat"
          style={rightSpiral}
        />
        <div className="flex flex-col md:p-6 xl:p-24">
          <h1 className="font-bold text-5xl mx-auto my-4 text-saucy-red">Community Markets</h1>
          <h3 className='text-center text-3xl py-4'>Pricing Plans</h3>
          <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 md:gap-x-4 md:gap-y-4'>
            <InformationBlock title='Small Market' price='$49' bgColor='bg-moody-blue'>
              <div className='py-2'>
                <p>Create your own niche marketplace for people to shop and merchants to join.</p>
                <p className={paragraphClass}>Ideal for festivals, markets,
                  and non-profits</p>
                <p className={paragraphClass}>Supports up to 100 businesses</p>
              </div>
            </InformationBlock>
            <InformationBlock title='Mid Market' price='$99' bgColor='bg-saucy-red'>
              <div className='py-2'>
                <p>Make it easy for your customers to support vendors and the local community to shop online.</p>
                <p className={paragraphClass}>Ideal for community associations</p>
                <p className={paragraphClass}>Supports up to 250 businesses</p>
              </div>
            </InformationBlock>
            <InformationBlock title='Large Market' price={false} bgColor='bg-mustard-300'>
              <div className='py-2'>
                <p>Support your entire city or region with a dedicated marketplace local to the area</p>
                <p className={paragraphClass}>Ideal for towns and cities</p>
                <p className={paragraphClass}>Unlimited businesses</p>
              </div>
            </InformationBlock>
          </div>
        </div>
      </div>
    </ScrollToTop>
  )
}

export const paragraphClass = 'font-semibold py-4 text-lg lg:block hidden'

export default CommunityMarkets
