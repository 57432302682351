import React from 'react'
import DashboardLinks from './dashboardLinks'

const Sidebar = ({ current_path, market }) => {
  return (
    <ul className="hidden xl:block py-10 w-64 bg-white h-auto flex-col xl:flex">
      <DashboardLinks current_path={current_path} market={market} />
    </ul>
  )
}

export default Sidebar
