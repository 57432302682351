import React from 'react'
import SubHeader from "../shared_components/subHeader";
import PaginatedCategories from "../shared_components/paginatedCategories";

const AllCategories = () => {
  return (
    <>
      <SubHeader breadcrumbs='All Categories' link='/categories'/>
      <div className='p-2 md:p-8'>
        <PaginatedCategories/>
      </div>
    </>
  )
}

export default AllCategories
