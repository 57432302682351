import React from "react"

function TwitterIconUnfilled() {
    return(
        <svg 
        className="h-5 w-6 text-white hover:fill-current hover:text-mustard-400" 
        viewBox="0 0 20 17" 
        stroke="currentColor"
        fill="none" 
        strokeWidth="1.5"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M18.8079 2.21487C18.7201 2.25139 18.6314 
        2.28642 18.5418 2.31988C18.7211 2.04185 18.8664 
        1.74089 18.9721 1.422C19.0374 1.22507 18.9742 1.00829 
        18.8134 0.877188C18.6526 0.746088 18.4276 0.727905 
        18.2478 0.83149C17.6591 1.17073 17.0013 1.42872 16.3133 
        1.58261C15.5628 0.907127 14.558 0.5 13.4607 0.5C11.1557 
        0.5 9.27155 2.30778 9.27155 4.5625C9.27155 4.65252 
        9.27473 4.74212 9.28073 4.83101C6.60712 4.54021 4.24529 
        3.24104 2.63916 1.32911C2.53538 1.20558 2.37838 1.13971 
        2.21754 1.15222C2.05669 1.16474 1.91176 1.25409 1.82834 
        1.39218C1.46354 1.99606 1.25379 2.69157 1.25379 
        3.4485C1.25379 4.18466 1.45554 4.87448 1.80744 5.46913C1.69402 
        5.45158 1.57614 5.47333 1.47463 5.53393C1.3235 5.62416 
        1.23094 5.78722 1.23094 5.96325V6.00735C1.23094 7.27975 
        1.82681 8.40385 2.75616 9.1461C2.71229 9.17356 2.67239 
        9.20804 2.63822 9.24884C2.52694 9.38172 2.49242 9.56276 
        2.54699 9.72727C2.94541 10.9284 3.90178 11.876 5.11603 
        12.2997C4.15189 12.7885 3.05584 13.0625 1.89086 13.0625C1.59476 
        13.0625 1.32865 13.0519 1.05533 13.0215C0.823136 12.9956 
        0.603989 13.134 0.527532 13.3548C0.451075 13.5755 0.537694 
        13.8198 0.736147 13.9431C2.44766 15.0064 4.4805 15.6176 
        6.66499 15.6176C13.7673 15.6176 17.6612 9.92347 17.6612 
        4.98162V4.97403C17.6612 4.90776 17.6612 4.83746 17.6601 
        4.76467C18.3336 4.25505 18.9248 3.64436 19.4073 2.96644C19.5367 
        2.78466 19.53 2.53914 19.3909 2.3647C19.2518 2.19026 19.0139
        2.12913 18.8079 2.21487Z" 
        strokeLinejoin="round"/>
        </svg>
    );
}

export default TwitterIconUnfilled;
