import React, { useContext, useState } from "react";
import CategorySearch from "./category_search/category_search";
import Search from "./search";
import MadeLocalLogo from "./madeLocalLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleNavigationMenu from "./toggleNavigationMenu";
import CartDrawer from "./shared_components/cartDrawer";
import { CartContext } from "../../../contexts/cartContext";
import { faAngleDown, faShoppingCart } from "@fortawesome/pro-light-svg-icons";
import CitySelectModal from "./location_select/citySelectModal";
import { CitiesContext } from "../../../contexts/citiesContext";
import useVisibility from "../../../hooks/useVisibility";
import CartQuantityDisplay from "./shared_components/cartQuantityDisplay";
import SignInOptions from "./shared_components/signInOptions";
import { HeaderLinks } from "./headerLinks";
import Banner from './banner'

const Header = ({ current_merchant, current_user }) => {
  const [visible, toggle] = useVisibility(false);

  return (
    <div className='w-full'>
      <div className="bg-white w-full relative flex items-center flex-col">
        <Banner />
        <div className="hidden xl:block">
          <HeaderLinks />
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between w-full">
          <div className="flex items-center xl:ml-10 xl:mr-20 ">
            <ToggleNavigationMenu visible={visible} toggle={toggle} />
            <MadeLocalLogo />
          </div>
          <div className="flex items-center justify-between flex-col-reverse px-1 w-full">
            <div className="flex items-center text-sm w-full">
              <div>
                <LocationSelect />
              </div>
              <div className="flex items-center py-1 w-full">
                <div className="w-full">
                  <Search />
                </div>
                <div className="flex items-center">
                  <Cart />
                  <SignInOptions
                    current_merchant={current_merchant}
                    current_user={current_user}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="w-full bg-white lg:border-t">
          <CategorySearch
            visible={visible}
            setVisible={(prev) => toggle(!prev)}
          />
        </div>
      </div>
    </div>
  )
}

const LocationSelect = () => {
  const { city } = useContext(CitiesContext);
  const [isOpened, setIsOpened] = useState(false)

  return (
    <div
      className={
        "p-1 md:p-3 flex flex-col w-full items-start md:text-lg text-md"
      }
    >
      <span>Location</span>
      <div
        className="flex-row text-mustard-400 font-semibold cursor-pointer whitespace-pre"
        onClick={setIsOpened}
      >
        <span>{city && city.name}</span>
        <FontAwesomeIcon className="text-base px-1" icon={faAngleDown} />
      </div>
      {isOpened && <CitySelectModal onClose={(prev) => setIsOpened(!prev)} />}
    </div>
  )
}

const Cart = () => {
  const { cart, setIsOpen } = useContext(CartContext);

  return (
    <>
      <button
        className={
          "text-gray-700 lg:ml-10 lg:pr-6 md:pl-4 md:pr-0 pr-4 py-1 w-full"
        }
        onClick={() => setIsOpen(true)}
      >
                      <span className="flex items-center space-x-1">
                        <FontAwesomeIcon icon={faShoppingCart} className='text-xl'/>
                        <p className="text-lg px-1">
                          Cart({CartQuantityDisplay(cart && cart.cart_shops)})
                        </p>
                      </span>
      </button>
      <CartDrawer />
    </>
  )
}


export default Header
