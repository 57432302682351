import React, { useContext } from 'react'
import { doPayoutsExist, makePayoutReadable } from '../../../../utilities/payoutUtility'
import { getRowColor, getRowRoundedModifier } from '../../../../utilities/tableUtility'
import PaymentsContext from '../paymentsContext'
import TableCell from './tableCell'
import TableHeader from './tableHeader'

const PaymentsTable = () => {
  const { payouts } = useContext(PaymentsContext);
  return (
    <table className='w-full rounded-md bg-beige-150 p-2'>
      <TableHeader
        labelClassName='uppercase text-xs font-medium p-5 rounded-md bg-beige-150 text-gray-700 opacity-75'
      />
      <tbody>
        {doPayoutsExist(payouts) && payouts.map((payout, index) =>
          <TableCell
            labelClassName={`text-sm font-medium p-5 ${getRowRoundedModifier(index, payout.length)} bg-${getRowColor(index)}`}
            data={makePayoutReadable(payout)}
          />
        )}
      </tbody>
    </table>
  )
}

export default PaymentsTable
