import React, { useContext, useState, useEffect } from 'react';
import NavigationBar from '../navigationBar';
import LargeProductCard from '../../../main/page_body/products/largeProductCard';
import { useHistory } from 'react-router-dom';
import Loader from './loader';
import NoData from '../../noData';
import WishlistContext from './wishlistContext';
import WishlistFilters from './wishlistFilters';
import Pagination from "../../../main/page_body/shared_components/pagination";

const WishlistUI = () => {
  const history = useHistory();
  const { fetchingWishlist, wishlist, currentPage, totalPages, setCurrentPage, onPageChange } = useContext(WishlistContext);

  return (
    <div className='w-full mx-auto'>
      <NavigationBar activeKey={location.pathname} />
      <section className='lg:w-4/5 xl:w-2/3 w-full px-4 mx-auto my-12 md:my-12 my-6'>
        <h1 className='text-3xl font-medium text-black'>My Wishlist</h1>
        <section className='flex md:flex-row flex-col justify-end gap-x-3 gap-y-2 mt-8 mb-4'>
          <WishlistFilters />
        </section>
        <section className='mt-8 mb-4'>
          <section className='grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-4 mb-4 mt-8'>
            {
              fetchingWishlist  ? <Loader count={8} /> :
                wishlist?.map(product => <LargeProductCard product={product} />)
            }
          </section>
          {
            !fetchingWishlist && !wishlist.length &&
            <NoData className='flex flex-col items-center justify-center mb-12 pb-12 h-64'
              buttonText='View All Products' onClick={() => { history.push('/products') }}>
              Your Wishlist is currently empty
            </NoData>
          }
        </section>
        <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
      </section>
    </div>
  )
}

export default WishlistUI;
