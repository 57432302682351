import React from 'react'
import ScrollToTop from "../../../utilities/scrollToTop"
import SubHeader from "../page_body/shared_components/subHeader"
import { CalendarButton } from "./shared/CommunityMarketHelpers"
import RetailBody from '../../../../assets/images/retailBody.png'

const RetailCollective = () => {
  return (
    <ScrollToTop>
      <div className='bg-white'>
        <SubHeader breadcrumbs='Retail Collective' link='/retail-collective' />
        <div className='text-center text-xl'>
          <h1 className="font-light text-6xl mx-auto my-4 text-gray-900">Retail Collective</h1>
          <p className={paragraphClass}>This is our version of the general store, but full of local independent brands!
            It's a great place for
            customers to discover online brands, see product in person and buy in store</p>
          <p className={paragraphClass}>The Retail Collective can also be flipped and booked for Pop-up Shops. All the
            infrastructure is in place,
            just fill it with your product and go. Takeovers can be booked daily or weekly.</p>
          <p className={paragraphClass}>If you are a shopper, you can visit us at:</p>
          <p>1212 34 Ave SE</p>
          <p>Calgary, Canada</p>
          <p className={paragraphClass}>Store Hours:</p>
          <p>Monday – Closed </p>
          <p>Tuesday – 10am – 3:00pm</p>
          <p>Wednesday – 10am – 3:00pm</p>
          <p>Thursday – 10am – 3:00pm</p>
          <p>Friday – 10am – 3:00pm</p>
          <p>Saturday – Closed </p>
          <p>Sunday – Closed </p>
          <p className={paragraphClass}>If you are a local brand, we'd love to hear from you:</p>
          <CalendarButton text='Contact' className='bg-saucy-red text-white px-4' /></div>
      </div>
      <div className='w-full flex items-center justify-center'>
        <img alt='retail collective images' className='w-full' src={RetailBody}/>
      </div>
    </ScrollToTop>

  )
}

const paragraphClass = 'py-4'
export default RetailCollective
