import React, { useContext, useEffect, useState } from 'react';
import { PayPalButtons, usePayPalScriptReducer, } from "@paypal/react-paypal-js";
import { CheckoutContext } from '../checkoutContext';
import PaypalPaymentBlockUISkeleton from '../skeletons/paypalPaymentBlockUISkeleton';

const PaypalPaymentBlockUI = ({
  handlePayment
}) => {

  const { order, isFetchingOrder, isUpdatingOrder, isPayingForOrder } = useContext(CheckoutContext);
  const [{ isPending, options }] = usePayPalScriptReducer();

  const [purchaseUnits, setPurchaseUnits] = useState([]);

  useEffect(() => {
    if (!isFetchingOrder && !isUpdatingOrder) {
      updatePurchaseUnits();
    }
  }, [order])

  const updatePurchaseUnits = () => {
    if (!options.currency)
      return;

    const newUnits = !order ? [] : [
      {
        amount: {
          currency_code: options.currency,
          value: order.grand_total.toFixed(2),
          breakdown: {
            item_total: {
              currency_code: options.currency,
              value: order.sub_total.toFixed(2)
            },
            tax_total: {
              currency_code: options.currency,
              value: order.taxes.toFixed(2)
            },
            shipping: {
              currency_code: options.currency,
              value: order.shipping.toFixed(2)
            }
          }
        },
        items: order.order_items.map(item => {
          return {
            name: item.product.title,
            quantity: item.quantity,
            unit_amount: {
              currency_code: options.currency,
              value: (item.product_variant.price_cents / 100).toFixed(2)
            }
          }
        })
      }
    ]

    setPurchaseUnits(newUnits);
  }

  const createPaypalOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: purchaseUnits,
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      })
  }

  const onApprovePaypalOrder = (data, actions) => {
    return actions.order.capture().then((capture) => {
      try {
        const paymentCapture = capture.purchase_units[0].payments.captures[0];
        const amount = paymentCapture.amount;
        handlePayment(paymentCapture.id, parseFloat(amount.value), amount.currency_code, paymentCapture.status)
      } catch (error) {
        console.error(error);
      }
    })
  }

  return (
    <div style={{ height: 'fit-content' }} className='border border-beige-200 rounded-md'>
      <section className='w-full h-auto rounded-md p-10'>
        {
          isPending || !purchaseUnits.length ?
            <PaypalPaymentBlockUISkeleton /> :
            <PayPalButtons style={{ color: 'gold' }} disabled={isPayingForOrder} className="flex justify-center"
              createOrder={createPaypalOrder} onApprove={onApprovePaypalOrder}
              onShippingChange={(e) => {/* Weird hack to force paypal to open popup for credit/debit cards */ }}
            />
        }
      </section>
    </div>
  )
}

export default PaypalPaymentBlockUI;
