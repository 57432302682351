import React from "react";
import GroupSellerBanner from '../../../../../assets/images/groupSeller.svg'


const JoinTheCollective = () => {
  const merchantSignUpStyles = {
    height: '30rem'
  }
  return (<section
    id='merchant_sign_up'
    style={{ merchantSignUpStyles, minHeight: '400px', borderRadius: '15px' }}
    className='w-full h-full bg-mustard-350 flex items-center justify-center relative'
  >
    <div
      className='main-content bg-auto w-full h-full flex bg-cover absolute border-white border-4'
      style={{ backgroundImage: `url(${GroupSellerBanner})`, borderRadius: '10px' }}
    ></div>
    <div className='flex items-center justify-center text-white flex-col absolute max-w-xl  p-2 lg:p-0'>
      <span className='font-medium text-2xl lg:text-5xl'>Join the Collective</span>
      <span className='text-lg lg:text-lg font-thin text-center mb-4'>We support local brands through a niche marketplace and offer co-warehousing,
3PL (third party logistics), and retail space to help you scale.</span>
      <div className='block'>
        <a
          href='https://calendly.com/localshops'
          className='inline-block text-lg font-medium px-3 py-2 lg:px-5 lg:py-3 rounded-full text-mustard-300 bg-white transform hover:scale-110 hover:shadow-2xl focus:shadow-md duration-500'>
          Join
        </a>
      </div>
    </div>
  </section>)
}

export default JoinTheCollective
