import React from "react"
import { Link } from "react-router-dom"
import { getCategoryURL } from "../../header/category_search/categoryMenu/utility"

const CategoryLink = ({ category, className, onMouseEnter, children }) => {
  return (
    <Link to={getCategoryURL(category.permalink)} onMouseEnter={onMouseEnter} className={className}>
      {children}
    </Link>
  )
}

export default CategoryLink
