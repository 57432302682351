import React, { useEffect, useState } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons"

const ReconnectToShopify = ({ integration }) => {
  const [requiresReconnection, setRequiresReconnection] = useState(true)

  useEffect(() => {
    if (integration.provider === 'shopify') {
      axios.get(`/merchants/shopify_integrations/${integration.id}/is_eligible_for_orders`).then(res => setRequiresReconnection(!res.data.is_eligible_for_orders))
    }
  }, [])

  return (
    <>
      {integration.provider === 'shopify' && requiresReconnection && (
        <a
          className='rounded-full py-3 px-8 text-sm font-light undefined text-white hover:bg-yellow-500 text-white bg-yellow-600'
          href={process.env.SHOPIFY_URL}>
          <FontAwesomeIcon className='px-1' icon={faExclamationTriangle} />
          <span>Reconnect</span>
        </a>
      )}
    </>
  )
}

export default ReconnectToShopify
