import React, { useEffect, useState } from "react"
import ShopLink from "../shared_components/shopLink"
import { ShopImage } from "../shared_components/shopLogo";
import axios from "axios";
import { IntegrationDescription } from "../shared_components/shopPageHelper";

const ShopCard = ({ shop }) => {
  let [hover, setHover] = useState(false)
  let [image, setImage] = useState('')
  let [integration, setIntegration] = useState({ name: '', city: { name: '' } })

  useEffect(() => {
    axios({
      method: 'GET',
      url: `/shops/${shop.slug}.json`
    }).then((response) => {
      setIntegration(response.data)
    })
  }, [])


  useEffect(() => {
    const img = new Image()
    img.src = shop.thumbnail_image
    img.onload = () => setImage(shop.thumbnail_image)
  }, [])

  return (
    shop && (
      <ShopLink
        shop_slug={shop.slug}
        className={`p-4 cursor-pointer hover:shadow-xl rounded-xl w-full`}
      >
        <div
          style={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundImage: hover && image && `url(${image})` }}
          className={`lg:w-11/12 lg:h-64 w-40 md:w-56 h-56 flex flex-col justify-center items-start rounded-lg p-8 ${hover && 'text-white'} transition duration-500 ease-in-out transform hover:scale-105 border-2 border-mustard-100`}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          <ShopImage shop={shop} className='lg:w-20 lg:h-20 w-16 h-16 rounded-full absolute md:static top-3 left-3' />
          <div className="absolute lg:left-4 left-2 px-1 md:px-0 md:static pt-10 md:pt-5">
          <h4 className={`leading-tight text-base md:text-sm lg:text-base w-full pb-1 ${hover && 'text-white'}`}>
            {shop.shop_name}
          </h4>
          {integration.description && <IntegrationDescription isCard={true} text_length={80} integration={integration} descriptionClass={'text-xs lg:text-base font-thin break-all '}/>}
          </div>

        </div>
      </ShopLink>
    )
  )
}

export default ShopCard
