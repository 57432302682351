import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/pro-regular-svg-icons'

export const ShopImage = ({ shop, disableLabel, className }) => {
  return (
    <>
      {shop.shop_logo_path ? (
        <img
          className={`${className} object-cover rounded-lg`}
          src={shop.shop_logo_path}
          alt={shop.shop_name}
        />
      ) : (
        <div
          className={`flex flex-col items-center justify-center shadow-xl object-cover rounded-full bg-gray-200 items-center ${className}`}
        >
          <div
            className='flex flex-col items-center hover:text-cerulean-800 text-sm text-gray-500 transition ease-in duration-300'>
            <FontAwesomeIcon icon={faImage} className='text-xl' />
            {!disableLabel && <p className='leading-tight text-xs text-center'>MISSING LOGO</p>}
          </div>
        </div>
      )}
    </>
  )
}

export const ShopBanner = ({ shop }) => {
  return (
    <>
      {shop.shop_banner_path ? (
        <div className="w-full absolute md:h-56 h-24">
          <img className='w-full text-center h-full bg-light-orange flex object-center object-cover justify-center items-center overflow-hidden' src={shop.shop_banner_path} />
        </div>

      ) :
        <div className="w-full absolute h-full">
          <div
            className='w-full text-center lg:h-56 md:h-48 h-24 bg-light-orange flex justify-center items-center overflow-hidden'>
          </div>
        </div>}
    </>
  )
}


