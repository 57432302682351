import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom'
import axios from "axios";
import Product from "./product";
import Products from "./products";
import LoadingSpinner from "../shared_components/loadingSpinner";

const ProductPage = () => {
  let { product_slug } = useParams()
  let [product, setProduct] = useState(null)

  useEffect(() => {
    axios.get(`/products/${product_slug}.json?`).then(response => {
      setProduct(response.data)
    })
  }, [])

  return (
    <Products>
      <div className='p-2 md:p-8'>
        <div>
          {product ? <Product product={product} /> : <LoadingSpinner />}
        </div>
      </div>
    </Products>
  )
};

export default ProductPage;
