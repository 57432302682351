import Products from "../products/products";
import ProductsGrid from "../products/productsGrid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import getData from "../../../../utilities/getData";
import SubHeader from "../shared_components/subHeader";
import { SubCategories } from "../shared_components/subCategoryHeader";

const CategoryPage = () => {
  let { parent_slug, category_slug, market_slug } = useParams();
  let [category, setCategory] = useState(null)
  let [market, setMarket] = useState(null)

  useEffect(() => {
    whichCategoryPage(market_slug, category_slug)
  }, [category_slug, market_slug])

  const whichCategoryPage = (market_slug, category_slug) => {
    market_slug && market_slug && getData(`/markets/${market_slug}.json`, (data) => (setMarket(data)))
    category_slug && getData(`/categories/${category_slug}.json`, (data) => (setCategory(data)))
  }

  return <Products>
    {category && <>
      <SubHeader location={market && market.name} breadcrumbs={category.name} link={category.permalink} />
      <div className='p-2 md:p-8'>
        <div className='container mx-auto'>
          {category.children && category.children.length > 1 &&
            <div>
              <span className='hidden lg:block'><SubCategories category={category} /></span>
            </div>
          }
          <ProductsGrid category={category} />
        </div>
      </div>
    </>
    }
  </Products>
}

export default CategoryPage
