import React, { useContext } from 'react';
import MyProductsSelectStyle from '../../../constants/custom_styles/myProductsSelectStyle';
import { DateSortOptions } from '../../../constants/dropdown_options/dateSortOptions';
import Calendar from './calendar';
import SortDropdown from './sortDropdown';
import PaymentsContext from './paymentsContext';

const PaymentFilters = () => {
  const { dateRange, sortOrder, setDateRange, setSortOrder } = useContext(PaymentsContext);

  return (
    <>
      <section className='font-light text-base'>
        <Calendar startDate={dateRange.from} endDate={dateRange.to}
          wrapperClassName='md:max-w-2xs'
          placeholder='Select Dates'
          className='pl-3 pr-5 placeholder-black md:w-auto w-full'
          selectsRange={true}
          isClearable
          maxDate={new Date()}
          onChange={update => {
            const [from, to] = update;
            setDateRange({ from, to })
          }} />
      </section>
      <SortDropdown value={sortOrder} options={DateSortOptions} setValue={setSortOrder}
        styles={MyProductsSelectStyle} containerClassName='md:w-48 w-full' />
    </>
  )
}

export default PaymentFilters;
