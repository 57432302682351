import React from 'react';
export default function MoreCategoriesButton({ setIsButtonSelected }) {
  return (
    <div className={`ml-4 md:ml-0 lg:my-4 xl:mr-8 inline-block cursor-pointer min-w-md text-center absolute right-2`}>
      <div
        className='items-center'
        onMouseEnter={(e) => {
          e.stopPropagation()
          setIsButtonSelected(true)
        }}>
        <div className={`whitespace-no-wrap hover:text-mustard-400 text-medium-grey cursor-pointer md:mr-4 mr-2 capitalize`}>More</div>
      </div>
    </div>
  );
}
