import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

const LoadingSpinner = () => {
  return <div className='flex flex-col items-center text-2xl'>
    <FontAwesomeIcon icon={faSpinner} spin />
  </div>
}

export default LoadingSpinner
