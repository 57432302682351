import React from 'react'
import { formatPrice } from "../../../../utilities/formatPrice";

const ExtraChargesSection = ({ orderIntegration }) => {
  return (
    <>
      <div className='flex items-center justify-between w-full py-2'>
        <div className='flex flex-col items-start w-full'>
          <span>Taxes</span>
        </div>
        <div className='w-full flex items-end justify-end'>{formatPrice(orderIntegration.tax_cents)}</div>
      </div>
      <hr className='w-full' />
      <div className='flex items-center justify-between w-full py-2'>
        <div className='flex flex-col items-start w-full'>
          <span>Shipping</span>
        </div>
        <div className='w-full flex items-end justify-end'>{formatPrice(orderIntegration.shipping_cents)}</div>
      </div>
      <div className='flex items-center justify-between w-full py-2'>
        <div className='flex flex-col items-start w-full'>
          <span>Commission Local Shops</span>
        </div>
        <div className='w-full flex items-end justify-end'>{orderIntegration.commission * 100}% </div>
      </div>
      <div className='flex items-center justify-between w-full p-2'>
        <div className='flex flex-col items-start w-full'>
          <span>Total</span>
        </div>
        <div className='w-full flex items-end justify-end'>{formatPrice(orderIntegration.order_items_total)}
        </div>
      </div>
    </>
  )
}

export default ExtraChargesSection
