import React from 'react'
import ShopItem from "./integration_items/shop_item";
import { ShopIcon } from "./shared_components/shopIcon";
import AddShops from "./addShops";
import { PaypalConnect } from '../../main/payments_dashboard/paypal';
import CheckIncomingPaypalConnection from '../../main/payments_dashboard/paypal/checkIncomingPaypalConnection';

function shopListings(integrations) {
  return <>
    <div
      className='flex flex-col justify-between w-full my-4 pl-4 sm:pr-4'>
      <div className='mr-6'>
        <p className='text-3xl md:text-4xl tracking-tight leading-7 md:leading-10 mb-1 truncate'>
          Manage My Shop
        </p>
        <div className='font-base tracking-tight text-gray-600' />
      </div>
    </div>
    {integrations.length > 0 && (
      <div className='grid mt-8 gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3'>
        {integrations.map((integration) =>
          <div className='flex flex-col items-center'>
            <ShopItem key={integration.id} integration={integration} icon={<ShopIcon shop={integration} />} />
          </div>
        )}
      </div>
    )}
  </>
}

function Integrations({ integrations }) {
  return (
    <>
      <PaypalConnect>
        <CheckIncomingPaypalConnection />
      </PaypalConnect>
      <div className='min-h-screen flex flex-col'>
        {integrations.length > 0 ? shopListings(integrations) : <AddShops />}
      </div>
    </>
  )
}

export default Integrations;
