import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare, faShopify, faWordpress, faWix } from '@fortawesome/free-brands-svg-icons'
import {faStore} from '@fortawesome/pro-solid-svg-icons'

const IntegrationIcon = ({integration}) => {
  const integrationIcon = () => {
    console.log(integration)
    if (integration === 'woocommerce') {
      return (<FontAwesomeIcon icon={faWordpress} className='text-lg' alt={integration}/>)
    } else if (integration === 'shopify') {
      return (<FontAwesomeIcon icon={faShopify} className='text-lg' alt={integration}/>)
    } else if (integration === 'instagram') {
      return (<FontAwesomeIcon icon={faInstagramSquare} className='text-lg' alt={integration}/>)
    } else if (integration === 'wix') {
      return (<FontAwesomeIcon icon={faWix} className='text-lg' alt={integration}/>)
    } else if (integration === null) {
      return (<FontAwesomeIcon icon={faStore} className = 'text-lg' alt='Local Shop'/>)
    }
  }

  return (
    <td>
      {integrationIcon()}
    </td>
  )
}

export default IntegrationIcon
