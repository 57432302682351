import React, { useState, useEffect, useContext } from 'react'
import '../../../../css/pagination.css'
import { useParams } from 'react-router-dom';
import { CitiesContext } from "../../../../contexts/citiesContext";
import determineMarketContext from '../../../../utilities/determineMarketContext';
import { loadAlgoliaProducts } from "../shared_components/algoliaSearch";

export const ProductsContext = React.createContext({
  products: [],
  categoryId: null,
  searchParams: null,
  totalPages: 0,
  currentPage: 0,
  currentSort: 'asc'
})

const Products = ({ children, category_slug_manual }) => {
  let { category_slug, market_slug, integration_slug } = useParams();
  let { city } = useContext(CitiesContext)
  let { market } = useContext(determineMarketContext())

  let query = new URLSearchParams(location.search);
  let searchParams = query.get('search_params') || ""
  let isMadeLocally = query.get('made_locally')

  const [fetching, setFetching] = useState(false)
  const [products, setProducts] = useState(null)
  const [retryCounter, setRetryCounter] = useState(1)
  const [totalHits, setTotalHits] = useState(0)
  const [totalPages, setTotalPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(parseInt(query.get('page')) || 0)
  const [currentSort, setCurrentSort] = useState(query.get('order') || 'asc')

  function getProducts() {
    let searchOptions = { city, category_slug, integration_slug, market, currentPage, searchParams, currentSort, isMadeLocally}
    if (category_slug_manual) {
      searchOptions = {
        city,
        category_slug: category_slug_manual,
        integration_slug,
        market,
        currentPage,
        searchParams,
        currentSort,
        isMadeLocally
      }
    }
    loadAlgoliaProducts(searchOptions, retryCounter).then(({ hits, nbHits, nbPages }) => {
      setProducts(filterProducts(hits))
      setTotalHits(nbHits)
      setTotalPages(parseInt(nbPages))
      if (nbHits < 50 && retryCounter < 12) {
        setRetryCounter(prevState => prevState + 1)
      }
    })
  }

 const filterProducts = (hits) => {
    return hits.filter((hit) => (hit.integration.shipping.local_enabled === false) || (hit.integration.shipping.local_enabled === true && hit.city.name === city?.name))
  }

  useEffect(() => {
    if (!!city || !!market) {
      setRetryCounter(1)
      getProducts()
    }
  }, [searchParams, currentPage, category_slug, city, integration_slug, market, category_slug_manual, currentSort])

  useEffect(() => {
    if (totalHits < 50 && retryCounter < 12) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [retryCounter])

  useEffect(() => {
    if (fetching && retryCounter < 12) {
      getProducts()
    }
  }, [fetching, retryCounter])

  return (
    <ProductsContext.Provider value={{
      products: products,
      searchParams: searchParams,
      totalPages: totalPages,
      currentPage: currentPage,
      setCurrentPage: setCurrentPage,
      currentSort: currentSort,
      setCurrentSort: setCurrentSort,
      isMadeLocally: isMadeLocally,
      city: city
    }}>
      {children}
    </ProductsContext.Provider>
  )
}

export default Products
