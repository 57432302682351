import React from "react";

function HeartUnfilled() {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.973 1C12.4919 1 11.2072 4.42857 11 6.14286C10.7928 4.42857 9.50809 1 6.02698 1C1.67559 1 0.745548 4.877 1.05396 7.42857C1.67559 12.5714 8.51349 17.7143 11 19C13.4865 17.7143 20.3244 12.5714 20.946 7.42857C21.2545 4.877 20.3244 1 15.973 1Z"
        fill="white"
      />
      <path
        d="M11 6.14286L10.5036 6.20286C10.534 6.45397 10.7471 6.64286 11 6.64286C11.2529 6.64286 11.466 6.45397 11.4964 6.20286L11 6.14286ZM20.946 7.42857L21.4424 7.48857V7.48857L20.946 7.42857ZM11 19L10.7703 19.4441L11 19.5629L11.2297 19.4441L11 19ZM1.05396 7.42857L1.55035 7.36857L1.05396 7.42857ZM11.4964 6.20286C11.5921 5.41075 11.9414 4.21122 12.6626 3.22339C13.3721 2.25164 14.4252 1.5 15.973 1.5V0.5C14.0397 0.5 12.7099 1.46265 11.855 2.63375C11.0117 3.78878 10.6151 5.16068 10.5036 6.08286L11.4964 6.20286ZM15.973 1.5C17.9687 1.5 19.1239 2.37265 19.7731 3.50597C20.4424 4.67446 20.5951 6.1651 20.4497 7.36857L21.4424 7.48857C21.6054 6.14047 21.4473 4.41682 20.6408 3.00893C19.8142 1.56585 18.3287 0.5 15.973 0.5V1.5ZM20.4497 7.36857C20.1599 9.76602 18.3987 12.2359 16.3195 14.3064C14.2535 16.3639 11.9631 17.9391 10.7703 18.5559L11.2297 19.4441C12.5234 18.7752 14.8952 17.1361 17.0252 15.015C19.142 12.907 21.1106 10.234 21.4424 7.48857L20.4497 7.36857ZM11.4964 6.08286C11.3849 5.16068 10.9883 3.78878 10.145 2.63375C9.29005 1.46265 7.96026 0.5 6.02698 0.5V1.5C7.57482 1.5 8.62793 2.25164 9.33737 3.22339C10.0585 4.21122 10.4079 5.41075 10.5036 6.20286L11.4964 6.08286ZM6.02698 0.5C3.67129 0.5 2.18578 1.56585 1.35918 3.00893C0.552734 4.41682 0.394627 6.14047 0.557575 7.48857L1.55035 7.36857C1.40488 6.1651 1.55759 4.67446 2.22691 3.50596C2.87608 2.37265 4.03128 1.5 6.02698 1.5V0.5ZM0.557575 7.48857C0.889417 10.234 2.85801 12.907 4.97483 15.015C7.10475 17.1361 9.4766 18.7752 10.7703 19.4441L11.2297 18.5559C10.0369 17.9391 7.74653 16.3639 5.68047 14.3064C3.60131 12.2359 1.84013 9.76602 1.55035 7.36857L0.557575 7.48857Z"
        fill="#383838"
      />
    </svg>
  );
}

export default HeartUnfilled;
