import React, { useContext, useEffect, useState } from 'react';
import { SubmitAndCancel } from '../../buttonGroups';
import { CheckoutShippingContext } from '../../checkout/shipping/checkoutShippingContext';
import EditAddressForm from '../../checkout/shipping/forms/editAddressForm';
import ShippingAddressForm from '../../checkout/shipping/forms/shippingAddressForm';
import SavedShippingAddressesUI from '../../checkout/shipping/savedAddresses/savedShippingAddressesUI';
import SavedShippingAddressesUISkeleton from '../../checkout/skeletons/savedShippingAddressesUISkeleton';

const Addresses = ({ className }) => {
  const {
    isFetchingAddresses,
    isAddingAddress,
    fetchAddresses,
    hasAddresses,
    addAddress,
  } = useContext(CheckoutShippingContext);

  const [editingAddressId, setEditingAddressId] = useState(null);

  useEffect(() => {
    fetchAddresses();
  }, [])

  const inAddMode = !hasAddresses() && !isFetchingAddresses;
  const inEditMode = hasAddresses() && !!editingAddressId;
  const inViewMode = hasAddresses() && !editingAddressId;
  const isLoading = !hasAddresses() && isFetchingAddresses;

  return (
    <section className={className}>
      {isLoading && <SavedShippingAddressesUISkeleton />}
      {
        inAddMode &&
        <ShippingAddressForm
          formId='shipping-address-form'
          formTitle='Shipping Address'
          onSubmit={addAddress}
        >
          <SubmitAndCancel className='mt-4' cancelLabel='Cancel' submitLabel='Submit' canCancel
            formId='shipping-address-form' onCancel={() => { setEditingAddressId(null) }}
            isSubmitting={isAddingAddress}
          />
        </ShippingAddressForm>
      }
      {
        inEditMode &&
        <EditAddressForm
          id={editingAddressId}
          onAddressUpdated={() => { setEditingAddressId(null) }}
          onInvalidAddressRecieved={() => { setEditingAddressId(null) }}
        >
          <SubmitAndCancel className='mt-4' cancelLabel='Cancel' submitLabel='Submit' canCancel
            formId='shipping-address-form' onCancel={() => { setEditingAddressId(null) }}
            isSubmitting={isAddingAddress}
          />
        </EditAddressForm>
      }
      {
        inViewMode && <SavedShippingAddressesUI onEditAddress={id => setEditingAddressId(id)} />
      }
    </section>
  )
}

export default Addresses;
