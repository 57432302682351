import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ShippingAddressFormSkeleton = () => {
  return (
    <div className='border-2 border-gray-200 rounded-md p-10'>
      <Skeleton width='50%' height={28} />
      <section className='mt-16 mb-4 grid grid-cols-1 gap-y-4'>
        <Skeleton width='100%' height={32} />
        <Skeleton width='100%' height={32} />
        <Skeleton width='100%' height={32} />
        <Skeleton width='100%' height={32} />

        <section className='grid grid-cols-2 gap-x-4'>
          <Skeleton width='100%' height={32} />
          <Skeleton width='100%' height={32} />
        </section>

        <Skeleton width='100%' height={32} />
      </section>
    </div >
  )
}

export default ShippingAddressFormSkeleton;
