import React from 'react';
import FooterColumn from './shared/footerColumn';
import FooterLinkExternal from './shared/footerLinkExternal';
import FooterLinkInternal from './shared/footerLinkInternal';

const Explore = ({ className }) => {
  return (
    <FooterColumn className={className}>
      <span className='text-white font-semibold pb-2 text-sm'>Explore</span>
      <FooterLinkInternal title='Services' path='/services' />
      <FooterLinkInternal title='All Brands' path='/shops' />
      <FooterLinkInternal title='All Products' path='/products' />
      <FooterLinkInternal title='Locally Made Products' path='/products?made_locally=true' />
    </FooterColumn>
  )
}

export default Explore;
