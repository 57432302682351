import React from 'react';
import Skeleton from 'react-loading-skeleton';

const PaypalPaymentBlockUISkeleton = () => {
  return (
    <div>
      <Skeleton height={32} />
      <Skeleton height={32} />
    </div>
  )
}

export default PaypalPaymentBlockUISkeleton;
