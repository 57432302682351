import React from 'react'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const DeleteAlert = ({ handleDelete, header, children, handleClick }) => {
  return (
    <div>
      {confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='w-full flex bg-red-300 flex-col p-6 rounded-lg'>
              <h1 className='text-xl'>{header}</h1>
              <p className='text-base'>{children}</p>
              <div className='flex items-center'>
                <div className='mr-2 my-2'>
                  <button
                    className='focus:outline-none focus:shadow-none shadow-lg hover:shadow-xl
                      transition ease-in-out duration-300 
                      transform hover:scale-103 text-black font-semibold
                      rounded block text-center bg-red-400 border border-red-400 hover:bg-red-600 text-white py-2 px-4 border border-red-400 rounded w-full'
                    onClick={() => {
                      onClose()
                      handleClick()
                    }
                    }
                  >
                    Back
                  </button>
                </div>
                <div>
                  <button
                    className='focus:outline-none focus:shadow-none shadow-lg hover:shadow-xl
                    transition ease-in-out duration-300 
                    transform hover:scale-103  text-black font-semibold
                    rounded block text-center border border-red-400 bg-red-400 hover:bg-red-600 text-white py-2 px-4 border border-red-400 rounded w-full'
                    onClick={() => {
                      handleDelete()
                      onClose()
                    }}
                  >
                    Permanantly Delete
                  </button>
                </div>
              </div>
            </div>
          )
        },
      })}
    </div>
  )
}

export default DeleteAlert
