import React, { useState, useEffect } from 'react'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs';
import { initializeDescription } from '../../../../../utilities/contentStateHelpers'

export default function Description({ description, description_html, inputStyle, name, type }) {
  const [editorState, setEditorState] = useState(undefined)

  useEffect(() => {
    if (description_html) {
      const blocksFromHtml = htmlToDraft(description_html)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
      setEditorState(EditorState.createWithContent(contentState))
    } else if (description) {
      setEditorState(EditorState.createWithContent(initializeDescription(description)))
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [])

  return ( 
    <div className='mb-2 flex flex-col'>
      {editorState && 
      <>
        <Editor
          name={name}
          editorState={editorState}
          wrapperClassName="w-full h-48 mb-7 bg-light-orange"
          editorClassName={inputStyle}
          toolbarClassName='flex '
          onEditorStateChange={setEditorState}
          toolbar={{
            options: ['inline', 'list'],
            inline: { options: ['bold', 'italic', 'underline'] },
            list: { options: ['unordered', 'ordered'] },
          }}
        /> 
        <input
          type='hidden'
          value={JSON.stringify(convertToRaw(editorState.getCurrentContent()))}
          name={name}
        />
        {description_html && <input
          type='hidden'
          value={''}
          name={'product[description_html]'}
        /> }
      </>
      }
    </div>
  )
}
