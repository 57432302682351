import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem } from '@szhsin/react-menu';
import React from 'react';

export const AccountOption = props => {
  return (
    <MenuItem {...props} href={props.href} data-method={props.dataMethod} className='flex flex-col'>
      <div className='flex items-center'>
        <FontAwesomeIcon icon={props.icon} className={props.iconClassName} />
        <span className={`ml-2 font-light text-sm ${props.spanClassName}`}>{props.label}</span>
      </div>
    </MenuItem>
  )
}

export default AccountOption;
