import React, { useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import CheckoutUI from '../checkoutUI';
import SavedShippingAddressesUI from './savedAddresses/savedShippingAddressesUI';
import { CheckoutContext } from '../checkoutContext';
import SavedShippingAddressesUISkeleton from '../skeletons/savedShippingAddressesUISkeleton';
import { useHistory } from 'react-router-dom';
import { appendCurrentCheckoutMode } from '../../../../utilities/checkoutUtility';
import { CheckoutShippingContext } from './checkoutShippingContext';

const CheckoutShippingViewUI = () => {

  const history = useHistory();
  const isInitialRender = useRef(true);
  const { order, updateOrderAddress, isUpdatingOrder } = useContext(CheckoutContext);
  const {
    shippingAddresses,
    isFetchingAddresses,
    fetchAddresses,
    hasAddresses,
    findDefaultAddress,
  } = useContext(CheckoutShippingContext);

  useEffect(() => {
    fetchAddresses();
  }, [])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      onShippingAddressesUpdated();
    }
  }, [shippingAddresses])

  const onShippingAddressesUpdated = () => {
    if (!isFetchingAddresses) {
      handleOrderShippingUpdate();
      if (!hasAddresses())
        handleNoShippingAddresses();
    }
  }

  const handleNoShippingAddresses = () => {
    history.replace(appendCurrentCheckoutMode('/checkout/addresses/new'));
  }

  const handleEditAddress = addressId => {
    history.push(appendCurrentCheckoutMode(`/checkout/addresses/${addressId}/edit`))
  }

  const handleOrderShippingUpdate = () => {
    const defaultAddress = findDefaultAddress();
    updateOrderAddress(order.id, !defaultAddress ? null : defaultAddress.id);
  }

  const directToPayments = () => {
    if (!order.address) {
      toast.error('Please select a shipping address');
      return;
    }

    history.push(appendCurrentCheckoutMode('/checkout/payments'));
  }

  return (
    <CheckoutUI
      pageTitle='Shipping Information'
      hasShippingBeenVisited={true}
      onSubmitClicked={directToPayments}
      isSubmittingForm={isUpdatingOrder}
      hasPaymentBeenVisited={false}>
      {
        !hasAddresses() || isFetchingAddresses ?
          <SavedShippingAddressesUISkeleton /> :
          <SavedShippingAddressesUI onEditAddress={handleEditAddress} />
      }
    </CheckoutUI>
  )
}

export default CheckoutShippingViewUI;
