import React, { useContext, useState } from 'react'
import { CartContext } from "../../../../contexts/cartContext";
import analyticsData from "../../../../utilities/analyticsData";
import ContactInformation from "./contact_information";
import { CheckoutContext } from '../../../users/checkout/checkoutContext';
import FormButton from '../../../shared/form_fields/FormButton';
import { useHistory, useParams } from 'react-router-dom';
import { handleAPIError } from '../../../../utilities/apiErrorHandling';
import { CheckoutModes, CheckoutQueryParamNames } from '../../../../utilities/checkoutUtility';

import IcMadeLocalLogo from '../../../../../assets/images/made-locally-logo.png'
import { getCartItemByVariantId } from '../../../../utilities/cartUtility';
import QuantitySelect from '../../header/shared_components/quantitySelect';

const buttonClass = 'px-8 py-3 bg-yellow-600 text-white text-sm font-light rounded-full flex justify-center'

export const ContactInformationDisplay = ({ product }) => {
  const [contactVisible, setContactVisible] = useState(false)

  if (product.contact_information === undefined) {
    return
  }

  return !contactVisible ? (
    <button
      type='button'
      className={`${buttonClass}`}
      onClick={() => {
        setContactVisible(true)
        analyticsData("Product Contact Seller Button Clicked", {
          product_name: product.title,
          product_id: product.id,
          shop: product.shop_slug
        });
      }}
    >
      Contact Brand
    </button>
  ) : (
    <ContactInformation phoneNumber={product.contact_information.phoneNumber}
      email={product.contact_information.email} />
  )
}

export const MadeLocalIndicator = ({ product, className }) => {
  return product.is_made_locally &&
    <img className={className} src={IcMadeLocalLogo} />
}

export const GoToStoreButton = ({ product }) => {
  return (
    <button
      href={product.store_url}
      className={`${buttonClass}`}
      onClick={() => {
        analyticsData("Product Buy Now Button Clicked", {
          product_name: product.title,
          product_id: product.id,
          shop: product.shop_slug
        });
      }}
    >Go to Store
    </button>
  )
}

export const VariantQuantity = ({ integration, productVariant }) => {
  const { cart } = useContext(CartContext);
  const cartItem = cart && integration && productVariant ? getCartItemByVariantId(cart, integration, productVariant.id) : null;
  return cartItem && <QuantitySelect cartItem={cartItem} quantity={cartItem.quantity} />
}

export const AddToCartButton = ({ selectedVariant, className }) => {
  const { addToCart, isAddingToCart } = useContext(CartContext)
  const query = new URLSearchParams(location.search);
  const market_slug = query.get('market_slug') ? query.get('market_slug') : 'local-shops'

  return (
    <FormButton
      onClick={() => addToCart(selectedVariant, 1, market_slug)}
      className={`${buttonClass} ${className}`}
      loaderContainerClassName='w-5 self-center mr-1'
      isLoading={isAddingToCart}>
      <span className='text-sm text-white self-center'>Add To Cart</span>
    </FormButton>
  )
}

export const BuyNowButton = ({ selectedVariant, className }) => {
  const { createBuyNowOrder, isCreatingOrder } = useContext(CheckoutContext);
  const history = useHistory();

  const onClick = () => {
    createBuyNowOrder(selectedVariant.id).then(() => {
      history.push(`/checkout/addresses?${CheckoutQueryParamNames.mode}=${CheckoutModes.buyNow}`);
    }).catch(error => {
      console.error(error);
      handleAPIError(error, window.location.href);
    })
  }

  return (
    <FormButton
      onClick={onClick}
      className={`px-8 py-3 bg-yellow-600 text-white text-sm font-light rounded-full flex justify-center ${className}`}
      loaderContainerClassName='w-5 self-center mr-1'
      isLoading={isCreatingOrder}>
      <span className='text-sm text-white self-center'>Buy Now</span>
    </FormButton>
  )
}
