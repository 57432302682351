import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CityAutoComplete from "../cityAutoComplete"
import { Modal } from "../../../merchants/shared_components/modal";

const CitySelectModal = ({ setCity, onClose }) => {
  return (
    <Modal title='Where do you want to shop?' onClose={onClose}>
      <CityAutoComplete setCity={setCity} />
    </Modal>
  )
}

export default CitySelectModal
