import React, { useContext, useEffect } from 'react';
import CheckoutNavigationUI from './checkoutNavigationUI';
import CheckoutCartUI from './checkoutCartUI';
import { formatCurrency } from '../../../utilities/formatPrice';
import CheckoutDetailsUISkeleton from './skeletons/checkoutDetailsUISkeleton';
import FormButton from '../../shared/form_fields/FormButton';
import { CheckoutContext } from './checkoutContext';
import { useHistory } from 'react-router-dom';

const CheckoutUI = ({
  pageTitle,
  children,
  primaryFormId,
  isSubmittingForm,
  hasShippingBeenVisited,
  hasPaymentBeenVisited,
  onSubmitClicked,
  disableSubmit
}) => {

  const history = useHistory();
  const { order, isCreatingOrder } = useContext(CheckoutContext);

  const formButtonXPadding = isSubmittingForm ? '8' : '12';

  useEffect(() => {
    if (!isCreatingOrder && !order)
      handleNoOrder();
  }, [order])

  const handleNoOrder = () => {
    history.replace({ pathname: '/' })
  }

  const getShipping = () => {
    if (order.address === null)
      return '-';

    return formatCurrency(order.shipping);
  }

  const getTotal = () => {
    return formatCurrency(order.grand_total != null ? order.grand_total : order.sub_total)
  }

  return (
    <div>
      <CheckoutNavigationUI hasShippingBeenVisited={hasShippingBeenVisited} hasPaymentBeenVisited={hasPaymentBeenVisited} />
      <section className='xl:ml-20 xl:mr-20 lg:ml-18 lg:mr-18 md:ml-8 md:mr-8 ml-4 mr-4 grid grid-cols-1 gap-y-4 mt-4 mb-4'>
        <h2 className='mt-4 mb-4'>{pageTitle}</h2>
        <section className='grid grid-cols-1 xl:grid-cols-11 gap-x-4 gap-y-4'>
          <section style={{ height: 'fit-content' }} className='col-span-1 xl:col-span-6 grid grid-cols-1 gap-y-4'>
            {children}
          </section>
          {
            order && order.order_items ?
              <CheckoutCartUI className='col-span-1 xl:col-span-5'
                items={order.order_items}
                subtotal={formatCurrency(order.sub_total)}
                shipping={getShipping()}
                taxes={formatCurrency(order.taxes)}
                total={getTotal()}
              /> : <CheckoutDetailsUISkeleton className='col-span-1 xl:col-span-5' />
          }
        </section>
      </section>
      {
        !disableSubmit &&
        <FormButton type='submit' formId={primaryFormId} isLoading={isSubmittingForm} onClick={onSubmitClicked}
          loaderContainerClassName='w-5 h-5 mr-1'
          className={`rounded-l-full rounded-r-full px-${formButtonXPadding} py-4 relative 
        bg-yellow-600 text-white uppercase text-xs flex ml-auto mr-auto mt-8 mb-8 items-center`}>Continue</FormButton>
      }
    </div>
  )
}

export default CheckoutUI;
