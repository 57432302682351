import React, { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { WithOutContext as ReactTags } from "react-tag-input";

export default function InputTags({ tagNames }) {

  //the react-tag-input requires the tag.id to be a string to prevent duplicate tags
  const stringifyTagId = () => {
    if (tagNames) {
      tagNames.map((tag) => {
        return { ...tag, id: tag.id.toString(), text: tag.name }
      })
    } else {
      return []
    }
  }

  let [tags, setTags] = useState(tagNames)

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = (i) => {
    setTags(tags.filter((tags, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  function TagInputs() {
    return tags.length ? tags.map((tag) => (
      <input type="hidden" value={tag.text} name="product[tag_names][]" key={tag.id} />
    )) : <input type="hidden" value={""} name="product[tag_names][]" />
  }

  return (
    <div >
      <DndProvider backend={HTML5Backend}>
        <ReactTags
          classNames={{
            tagInputField:
              'w-full py-4 flex flex-col border border-mustard-200 border-opacity-50 rounded-md outline-none text-black p-2 mb-4 w-full',
            tag:
              'cursor-pointer tracking-wide inline-block bg-mustard-400 text-white rounded-lg px-3 py-1 m-1',
          }}
          placeholder='Tags'
          tags={tags}
          inputFieldPosition='top'
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          delimiters={delimiters}
        />
        <TagInputs />
      </DndProvider>
    </div>
  )
}
