import React, { useState } from "react";
import Logo from "../../../assets/images/logo_black.png";
import ShopSelection from "./shared_components/shopSelection";
import MobileSideBar from "./mobile_side_bar";
import UserCircleIcon from "../main/header/shared_components/UserCircleIcon";

const Header = ({ current_merchant, integration, current_path }) => {
  const [ isOpen, setIsOpen ] = useState(false)

  return (
    <header className="flex justify-between items-center px-2 lg:px-20 border">
      <div className="h-16 md:h-20 py-1 lg:py-2">
        <div className="flex items-center xl:ml-10 xl:mr-20 ">
            <div className="lg:flex">
              <div className="flex px-3 justify-between items-center">
                <div>
                  <button
                    type="button"
                    className="xl:hidden block text-gray-600  focus:outline-none"
                    onClick={() => setIsOpen(true)}
                  >
                    <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          <div className="flex flex-col md:flex-row-reverse">
            <div className="flex flex-col items-center md:flex-row md:pl-16 lg:pl-1">
              {isOpen && (
                <MobileSideBar
                  callback={() => setIsOpen(false)}
                  integration={integration}
                  current_path={current_path}
                />
              )}
            </div>
            <div className="flex flex-row-reverse justify-between">
            
                <a href="/">
                  <img className="h-12 md:h-16 ml-4" src={Logo} alt="Local Shops" />
                </a>

            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row px-2 pt-2 pb-4 gap-x-4 items-center">
        {integration && <ShopSelection currentShop={integration} />}

        {current_merchant.first_name && (
          <div className="flex flex-row items-center">
            <a
              className="hidden md:block text-cerulean-800 text-sm font-medium hover:text-black px-2 cursor-pointer"
              href="/merchants/edit"
            >
              {current_merchant.first_name}{" "}
              {current_merchant.last_name ? current_merchant.last_name : ""}
            </a>
              <a className="block md:hidden" href="/merchants/edit">
                  <UserCircleIcon />
              </a>

          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
