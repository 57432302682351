import React from 'react'
import SubHeaderImage from './subHeaderImage';

const SubHeader = ({ breadcrumbs, link, location }) => {
  const headerLocation = location ? location : 'Local Shops';
  return (
    <SubHeaderImage>
      <h1 className='text-black capitalize text-sm py-0 my-0 container mx-auto'>
        <a className='opacity-50' href='/'>{headerLocation}</a>&nbsp; / &nbsp;<a href={link} className='text-moody-blue'>{breadcrumbs}</a>
      </h1>
    </SubHeaderImage>
  )
}

export default SubHeader
