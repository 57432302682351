import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({color,icon,title, onClick, hover}) => {
  return (
    <div
      onClick={ onClick}
      className={`cursor-pointer ${hover}`}
    >
      <FontAwesomeIcon className={`${color}`} icon={icon} />
      {title}
    </div>
  )
}

export default Button;