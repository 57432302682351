import React, { useEffect, useState } from 'react';
import CategoryItem from './category_item';
import MoreCategoriesButton from './MoreCategoriesButton';
import SubCategoryItem from './subcategory_item';
import MobileCategories from './mobile/mobileCategories';
import axios from 'axios';
import CategoryMenu from './categoryMenu/categoryMenu';
import LazyLoad from 'react-lazyload';

import MoreMenuImage from '../../../../../assets/images/localShopsAboutUs.jpg';

export default function CategorySearch({ visible, setVisible }) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMoreButtonSelected, setIsMoreButtonSelected] = useState(false);
  const [categories, setCategories] = useState(null);
  const [topCategories, setTopCategories] = useState(null);
  const [bottomCategories, setBottomCategories] = useState(null);


  useEffect(() => {
    axios.get('/categories.json').then((response) => {
      setCategories(response.data);
      setTopCategories(response.data.slice(0, 8));
      setBottomCategories(response.data.slice(8));
    });
  }, []);


  return (
    <>
      {visible ? <div className='lg:hidden'>
        <MobileCategories categories={categories} setVisible={setVisible} />
      </div> : <div className='w-full flex flex-col hidden lg:block'>
        <div
          className={`lg:block relative group`}
          onMouseLeave={() => {
            setIsMoreButtonSelected(false);
          }}>
          <div className='flex lg:w-19/20 xl:w-10/12 mx-auto'>
            <div
              className={`w-auto flex flex-no-wrap mx-auto`}>
              {
                topCategories?.map((category) => {
                  return (
                    <CategoryMenu
                      key={category.id}
                      category={category}
                    />
                  );
                })}
            </div>
            {bottomCategories && <MoreCategoriesButton
              setIsButtonSelected={setIsMoreButtonSelected}
            />
            }
          </div>
          {
            isMoreButtonSelected && <MoreDropdown
              bottomCategories={bottomCategories}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          }
        </div>
      </div>}
    </>
  );
}

const MoreDropdown = ({ bottomCategories, selectedCategory, setSelectedCategory }) => {
  return (
    <div
      className={`w-full flex justify-between items-start lg:absolute left-0 top-10 z-10 bg-white`}>
      <div className='flex flex-row mx-auto'>
        <DropdownImage />
        <div className='mr-0 ml-auto'>
          <div className='mx-10 mb-10'>
            <div className='w-auto flex lg:mx-auto'>
              {
                bottomCategories?.map((category) => {
                  return (
                    <div className='flex flex-col'>
                      <CategoryItem
                        key={category.id}
                        topLevel={false}
                        category={category}
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                      />
                      {
                        category?.children.map((subcategory) => {
                          return <SubCategoryItem key={subcategory.id} subcategory={subcategory} topLevel={false} />;
                        })}
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const DropdownImage = () => {
  return (
    <div className={`w-73 h-40 my-6`}>
      <div
        className='z-10 w-full h-full relative items-center justify-center flex'>
      </div>
      <LazyLoad height={200}>
        <img className='z-0 w-73 h-40 mt-3 absolute top-0 object-cover'
             src={MoreMenuImage} />
      </LazyLoad>
    </div>
  )
}
