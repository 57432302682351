import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { LargeProductCardSkeleton } from "./skeletonHelpers";

const ProductsGridSkeleton = () => {
  return (
    <>
      <div className="items-center md:mb-2">
        <div className="flex flex-col md:flex-row w-full justify-between items-start md:items-center">
          <h1 className={`mb-6 md:mb-4 text-3xl`}>
            <Skeleton count={1} />
          </h1>
          <div className={"flex flex-col md:flex-row gap-2 items-center w-full md:w-auto"}>
            <Skeleton count={1} />
          </div>
        </div>
      </div>
      <div
        className="w-full text-gray-900 antialiased justify-items-auto grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 gap-x-4 gap-y-1 md:gap-x-5 md:gap-y-4">
        {new Array(10).fill(2).map(
          (product) =>
            <LargeProductCardSkeleton
            />
        )}
      </div>
    </>
  )
}

export default ProductsGridSkeleton
