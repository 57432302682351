import React from "react";

export const NoShopsFound = () => {
  return (
    <div className="flex flex-col ">
      <div className="p-4">
        <h4
          className=" text-base text-red-700 border border-red-400 rounded-lg p-2 hover:bg-red-300 bg-red-200 flex justify-center items-center">
          No matching shops found
        </h4>
      </div>
    </div>
  );
};
