import React from 'react';
import SubHeader from '../page_body/shared_components/subHeader';
import '../../../css/footer-page.css';
import Question from './shared/question';
import ScrollToTop from '../../../utilities/scrollToTop';

const RefundPolicy = () => {
  return (
    <ScrollToTop>
      <div className='footer-page'>
        <SubHeader breadcrumbs='Refund Policy' link='/refund-policy' />
        <div className="flex flex-col p-8 sm:px-32 lg:px-64 xl:px-80">
          <h1 className="font-bold text-3xl mx-auto my-4">Refunds for an Order</h1>
          <p className='text-lg font-medium mt-4'>
            If you want a refund for a Local Shops purchase, please ask the brand directly for help.
            Local Shops is a listing agent on behalf of local independent brands, all of whom have their
            own refund policies. Refund policies and returns are up to the brands, but many offer them.
            <br /><br />
            You can review a brands policy for refunds and exchanges directly on their website. If a brand
            does not have a website, contact them by phone or email for details on their refund policy.
            <br /><br />
            Steps to contact a brand:
            <ol className='px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 my-4'>
              <li>
                Sign in to your shopper account at <a href="/" target="_blank" className="text-yellow-600">localshops.com</a>.
              </li>
              <li>Go to your purchase history.</li>
              <li>Find your order and within it the name of the brand you want a refund from.</li>
              <li>Click on the brand name to visit their Shop Page.</li>
              <li>Click the email icon on their Shop Page.</li>
              <li>Email your refund request to the brand.</li>
            </ol>
          </p>
          <Question question='What are refund policies?'>
            Every brand on Local Shops is responsible for their own policies regarding refunds, exchanges,
            and returns. Policies vary from brand to brand.
            <br /><br />
            Specific questions about a brand’s policies are best answered by the brand. Please contact them
            directly before escalating communication to the Local Shops team.
          </Question>
          <Question question='What if the brand doesn’t refund my order?'>
            Brand’s are not required to provide a refund unless noted in their refund policies. If there is
            a problem with your order, we recommend contacting the brand to come up with a resolution.
            <br /><br />
            If you aren’t able to resolve the issue with the brand, you can escalate the communication to the
            Local Shops team for things like:
            <ul className="footer-page-list px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 my-4">
              <li>Never receiving your order.</li>
              <li>The order did not match how it was described on Local Shops.</li>
              <li>Your order arrived damaged.</li>
            </ul>
          </Question>
        </div>
      </div>
    </ScrollToTop>
  )
}

export default RefundPolicy;
