import React from 'react'
import TableHeader from './table_components/tableHeader'
import ProductVariantRow from "./table_components/productVariantRow";

const InventoryProductListing = ({ products }) => {
  return (
    products ?
      <>
        <table className='w-full'>
          <TableHeader
            labelClassName='uppercase text-xs text-gray-700 px-2 py-4 opacity-75 text-left'
          />
          <tbody>
          {products.map((product) => (
              product.product_variants.map((productVariant) => {
                return <ProductVariantRow rowClassName='odd:bg-white even:bg-beige-100 opacity-100 hover:opacity-90'
                                          labelClassName='px-2 py-4' product={product} productVariant={productVariant} />
              })
            )
          )}
          </tbody>
        </table>
        <div className='text-center py-2'>Don't see your product options? Make sure that you add them in the products tab!</div>
      </>
      :
      <div> There are no products! Please add them in the Products tab!</div>
  )
}

export default InventoryProductListing
