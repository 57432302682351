import React from "react";
import CategoryPage from "../main/page_body/categories/categoryPage";

const MarketCategoryPage = ({ current_merchant }) => {
  return <>
    <CategoryPage current_merchant={current_merchant} />
  </>
}

export default MarketCategoryPage
