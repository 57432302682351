import React from "react";
import Select from "react-select";
import axios from "axios";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyProductsSelectStyle from "../../../../../constants/custom_styles/myProductsSelectStyle";

const CategorySelect = ({ categories, selectedCategory, onChange, formClass }) => {
  const token = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

  const options = categories.map((category) => {
    return { label: category.dropdown_name, value: category.id }
  })

  const PlaceHolder = () => {
    return (
      <div className="flex flex-row items-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className='text-yellow-600 mr-2' />
        <span>Select Category</span>
      </div>
    )
  }

  return (
    <form className={formClass}>
      <Select
        placeholder={selectedCategory?.dropdown_name || <PlaceHolder />}
        components={{ IndicatorSeparator: () => null }}
        isClearable={false}
        onChange={onChange}
        className='w-full lg:w-64'
        styles={MyProductsSelectStyle}
        options={options}
        menuPortalTarget={document.querySelector('body')}
      />
    </form>
  )
};

export default CategorySelect;
