import axios from 'axios';
import React, { useEffect, useState } from 'react';
import  FavoritesContext  from '../../../../contexts/favoritesContext';

const Favorites = ({ current_user, authenticity_token, children }) => {
  const [userFavorites, setUserFavorites] = useState(null)
  const [shopFavorites, setShopFavorites] = useState(null)
  const getUserFavorites = () => {
    if (!current_user) return null

    axios({
      method: 'get',
      url: `/users/favorites.json`,
      data: { authenticity_token: authenticity_token }
    }).then(res => {
      setUserFavorites(res.data)
    })
  }
  const getShopFavorites = () => {
    if (!current_user) return null

    axios({
      method: 'get',
      url: `/users/saved_sellers.json`,
      data: { authenticity_token: authenticity_token }
    }).then(res => {
      setShopFavorites(res.data)
    })
  }
  useEffect(() => {
    getUserFavorites()
    getShopFavorites()
  }, [current_user])

  return (
    <FavoritesContext.Provider value={{
      token: authenticity_token,
      user: current_user,
      userFavorites: userFavorites,
      shopFavorites: shopFavorites
    }}>
      {children}
    </FavoritesContext.Provider>
  )
}

export default Favorites;
