import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import UserAccountSettingsValidation from '../../../../constants/form_validations/userAccountSettingsValidation';
import  FavoritesContext  from '../../../../contexts/favoritesContext';
import { SubmitAndCancel } from '../../buttonGroups';
import FormInputRow from '../../../shared/form_fields/FormInputRow';

const UserDetailForm = ({ className }) => {

  const { user, token } = useContext(FavoritesContext);
  const [initialValues, setInitialValues] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    current_password: ''
  })

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    axios.put('/users/', {
      authenticity_token: token,
      user: {
        ...values,
        phone_number: user.phone_number
      }
    }).then(() => {
      toast.success('Account settings updated.');
      setInitialValues(values);
      resetForm();
    }).catch(error => {
      console.error(error);
      //Temporary fix -- NEEDS TO GET FIXED DURING OUR REFACTORING SESSION!
      window.location.reload()
      // toast.error('Account settings could not be updated.');
    }).finally(() => {
      setSubmitting(false);
    })
  }

  return (
    <div className={`border border-beige-200 rounded-md p-10 ${className}`}>
      <h1 className='font-medium text-xl text-black'>Profile Information</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={UserAccountSettingsValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {
          ({ values, resetForm, dirty, handleChange, isSubmitting }) =>
            <Form className='mt-16 mb-4 grid grid-cols-1 gap-y-4'>
              <FormInputRow name='first_name' value={values.first_name} placeholder='First Name' onChange={handleChange} />
              <FormInputRow name='last_name' value={values.last_name} placeholder='Last Name' onChange={handleChange} />
              <FormInputRow name='email' value={values.email} placeholder='Email Address' onChange={handleChange} />
              <FormInputRow name='current_password' value={values.current_password} inputClassName='bg-white'
                placeholder='Password' onChange={handleChange} type='password'
              />
              <SubmitAndCancel className='mt-4' cancelLabel='Cancel' isSubmitting={isSubmitting}
                submitLabel='Update' canCancel={dirty} onCancel={resetForm} />
            </Form>
        }
      </Formik>
    </div>
  )
}

export default UserDetailForm;
