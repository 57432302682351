import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Divider } from '../checkoutCartUI';

const ItemRowSkeleton = () => {
  return (
    <div className='h-10 grid grid-cols-5'>
      <div className='col-span-4'>
        <Skeleton containerClassName='mr-4 inline-block align-top' width={50} height={50} />
        <div className='sm:w-2/3 w-1/2 h-auto inline-block'>
          <Skeleton height={18} />
          <Skeleton width='50%' height={12} />
        </div>
      </div>
      <Skeleton containerClassName='col-span-1 self-end mb-1' height={24} />
    </div>
  )
}

const LabelValueRowSkeleton = () => {
  return (
    <div className='grid grid-cols-5 gap-x-10'>
      <div className='col-span-2'>
        <Skeleton height={12} />
      </div>
      <Skeleton containerClassName='sm:col-start-5 sm:col-span-1 col-start-4 col-span-2 self-end' height={18} />
    </div>
  )
}

const CheckoutDetailsUISkeleton = ({
  className
}) => {
  return (
    <div style={{ height: "fit-content" }} className={`border-2 border-gray-200 rounded-md p-10 ${className}`}>
      <Skeleton className='ml-0 mr-auto' width='50%' height='24px' />
      <section className='mt-10 mb-4 grid grid-cols-1 gap-y-6'>
        <ItemRowSkeleton />
        <ItemRowSkeleton />
        <ItemRowSkeleton />
      </section>
      <Divider />
      <section className='grid grid-cols-1 gap-y-4 mt-6'>
        <LabelValueRowSkeleton />
        <LabelValueRowSkeleton />
      </section>
      <Divider />
      <section className='mt-6'>
        <LabelValueRowSkeleton />
      </section>
    </div >
  )
}

export default CheckoutDetailsUISkeleton;
