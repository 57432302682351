import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify'
import ShippingAddressFormSkeleton from '../../skeletons/shippingAddressFormSkeleton';
import { CheckoutShippingContext } from '../checkoutShippingContext';
import ShippingAddressForm from './shippingAddressForm';

const EditAddressForm = ({ id, children, onAddressUpdated, onInvalidAddressRecieved }) => {

  const { fetchAddress, updateAddress } = useContext(CheckoutShippingContext);

  const [oldAddress, setOldAddress] = useState(null);
  const [isFetchingOldAddress, setIsFetchingOldAddress] = useState(true);

  useEffect(() => {
    resetScrollPosition()
  }, [])

  useEffect(() => {
    fetchOldAddress();
  }, [id])

  useEffect(() => {
    if (!isFetchingOldAddress)
      handleNotValidAddressId();

  }, [oldAddress])

  const resetScrollPosition = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  const fetchOldAddress = () => {
    setIsFetchingOldAddress(true);
    fetchAddress(id)
      .then(response => {
        setOldAddress({
          id: id,
          name: response.data.name,
          country_id: response.data.city.country.id,
          street_address: response.data.line1,
          city_id: response.data.city.id,
          province: response.data.city.province,
          postal_code: response.data.postal_code,
          phone_number: response.data.phone_number,
          is_default: response.data.is_default
        });
        setIsFetchingOldAddress(false);
      }).catch(() => {
        setOldAddress(null);
        setIsFetchingOldAddress(false);
      })
  }

  const handleSubmit = values => {
    updateAddress(id, values).then(() => {
      onAddressUpdated();
    })
  }

  const handleNotValidAddressId = () => {
    if (!oldAddress)
      onInvalidAddressRecieved();
  }

  return !!isFetchingOldAddress ?
    <ShippingAddressFormSkeleton /> :
    <ShippingAddressForm
      formId='shipping-address-form'
      formTitle='Shipping Address'
      initialValues={oldAddress}
      onSubmit={handleSubmit}
    >
      {children}
    </ShippingAddressForm>
}

export default EditAddressForm;
