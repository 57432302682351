import React, {useState} from 'react'
import {formatPrice} from '../../../../utilities/formatPrice'
import CountryIcon from '../shared_components/countryIcon'
import ProductFavorite from '../shared_components/productFavorite'
import {Link} from 'react-router-dom'

const LargeProductCard = ({product}) => {
    let [productImage, setProductImage] = useState(product.thumbnail_image_url)

    const transitionGrowClass =
        'transition duration-500 ease-in-out hover:shadow-xl'
    return (
        product && (
            <Link
                className={`truncate rounded-t-xl`}
                to={`${product.path}${location.search}`}
                title={`${product.title}`}
            >
                <div style={{backgroundImage: `url('${productImage}')`}}
                     className={`w-full bg-cover bg-center p-1 rounded-xl h-56 sm:h-96 md:h ${transitionGrowClass}`}>
                    <ProductFavorite product={product}/>
                </div>
                <div className='flex justify-between pt-4 pb-6 max-w-full leading-6'>
                    <div className='w-full flex flex-col max-w-full'>
                        <div className='flex items-center justify-between'>
                            <div className='font-semibold truncate'>{product.title}</div>
                        </div>
                        <div className="flex flex-col justify-between">
                            <div
                                className='flex-grow text-mustard-400 text-sm truncate'>{product.integration.name}</div>
                            <div>
                                <div className='flex flex-row items-center'>
                                    <div
                                        className='flex-grow font-bold text-gray-700'>{product?.product_variant && formatPrice(product.product_variant.price_cents)}
                                    </div>
                                    <div className='flex flew-row'>
                                        <div>{product.is_made_locally && (
                                            <CountryIcon countrySlug={product.city.country.slug}/>
                                        )}</div>
                                        <div
                                            className='font-semibold truncate text-gray-500'
                                        >
                                            {product.city.name}, {product.city.country.name}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    )
}

export default LargeProductCard
