import React from 'react'

const CustomerContactInformation = ({ customer, deliveryAddress }) => {
  return (
    <div
      className='w-full mx-auto col-span-3 flex flex-col justify-between pt-4 pb-10 px-2 md:px-10 mb-4 md:mb-0 bg-white rounded-lg'>
      <div className='font-semibold py-4'>
        Contact Information
      </div>
      <div className='flex flex-col items-start text-md'>
        <span>Name: {customer.first_name} {customer.last_name}</span>
        <span>Phone#: {customer.phone_number} </span>
        <span>Email: {customer.email}</span>
      </div>
      <div className='font-semibold py-4'>
        Shipping Information
      </div>
      <div className='text-md'>
        <span>Delivery Address: {deliveryAddress}</span>
      </div>
    </div>
  )
}

export default CustomerContactInformation
