import React, { useState } from 'react'
import ProductVariantRow from "./product_variant_row";

export default function ProductVariants({ productVariants, isShopify }) {
  const [addProductVariant, setAddProductVariant] = useState(productVariants.length > 0 ? [...productVariants] : [{}])

  return (
    <div className='flex flex-col border border-mustard-200 border-opacity-50 rounded-md pt-4'>
      <h6 className="px-4 mr-6 pt-3 text-medium text-black font-semibold">Options</h6>
      <span className='px-4 mr-6 pt-1 text-sm text-gray-700'>This product has options, like
          size or colour</span>
      <>
        <hr />
        {addProductVariant.map((productVariant) => {
          return (<div className="px-4 pt-4">
            <p className="text-xs pb-2 text-gray-900 opacity-75 font-light">Option Values</p>
            <ProductVariantRow productVariant={productVariant} />
          </div>)
        })}
        {
          !isShopify &&
          <div className='px-4'>
          <span onClick={() => setAddProductVariant(prevState => [...prevState, {}])}
                className="inline-block px-2 py-3 mb-4 w-auto rounded-full text-white bg-mustard-400 cursor-pointer">Add another value</span>
          </div>
        }
      </>
    </div>
  )
}
