import React from 'react'
import { faStore} from '@fortawesome/pro-solid-svg-icons'
import ButtonWithIcon from '../../../merchants/integrations_dashboard/shared_components/button_with_icon'

import MarketItem from './market_item'
import CheckIncomingPaypalConnection from "../../../main/payments_dashboard/paypal/checkIncomingPaypalConnection"
import { PaypalConnect } from "../../../main/payments_dashboard/paypal"

export default function markets_index({ markets }) {
  const AddMarket = () => {
    return (
      <div>
        <div className='py-4'>
          <p className='font-semibold text-xl'>Welcome!</p>
          <p className='text-base'>Create a market to get started!</p>
          <p className='font-semibold text-base py-6'>Create your own market</p>
          <div className='flex items-center'>
            <div className='mr-2 bg-white w-full lg:w-2/6'>
              <ButtonWithIcon
                icon={faStore}
                onClick={() =>
                  (window.location.href = '/market_admins/markets/new')
                }
                name='My Market'
                color='text-black'
                children='Create'
              />
            </div>
          </div>
        </div>
      </div>
    )
  }


  return (
    <>
      <PaypalConnect>
        <CheckIncomingPaypalConnection />
      </PaypalConnect>
      <div className='min-h-screen'>
        <div className='flex flex-row justify-between items-baseline  py-2 px-10'>
          <h1 className='text-2xl font-semibold'>Dashboard</h1>
        </div>
        <div className='p-10'>
          {markets.length > 0 && (
            <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-2 gap-y-2'>
              {markets.map((market) => <MarketItem market={market}/>)}
            </div>
          )}
          <AddMarket />
        </div>
      </div>
    </>
  )
}
