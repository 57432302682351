import React, { useState } from 'react'
import SignInLocalShopsModal from '../../main/header/sign_in_modals/signInLocalShopsModal'

const SignInLink = () => {
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false);

  return (
    <>
      <div className='inline-block justify-center flex'>
        <div className='items-center xl:inline-block lg:mr-8'>
          <div className='flex items-center'>
            <a className='text-lg px-4 py-2 text-black' onClick={() => { setIsSignInModalOpened(true) }}>Sign In</a>
          </div>
        </div>
      </div>
      {isSignInModalOpened && <SignInLocalShopsModal onClose={() => { setIsSignInModalOpened(false) }} />}
    </>
  )
}

export default SignInLink;
