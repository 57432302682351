import React from 'react';

const TableHeader = ({ headerClassName, labelClassName }) => {
  return (
    <thead className={headerClassName}>
      <tr>
        <th className={`${labelClassName} text-left`}>Payout Date</th>
        <th className={`${labelClassName} text-left`}>Payout Amount</th>
        <th className={`${labelClassName} text-left`}>Order Number</th>
        <th className={`${labelClassName} text-left`}>Customer</th>
        <th className={`${labelClassName} text-right`}>Shipping</th>
      </tr>
    </thead >
  )
}

export default TableHeader;
