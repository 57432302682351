import * as Yup from 'yup';
import ErrorMessages from '../errorMessages';

const UserAccountSettingsValidation = Yup.object().shape({
  first_name: Yup.string().required(ErrorMessages.FieldRequired),
  last_name: Yup.string().required(ErrorMessages.FieldRequired),
  email: Yup.string().email(ErrorMessages.IsValidEmail).required(ErrorMessages.FieldRequired),
  current_password: Yup.string().required(ErrorMessages.PasswordRequired)
});

export default UserAccountSettingsValidation;
