import React from 'react'
import Select from "react-select";

export default function CategorySelect({categories, selectedCategory, setSelectedCategory, name}) {
  const options = categories.map((category) => {
    return { label: category.dropdown_name, value: category.id };
  });

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: '#f7fafc'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: '#f7fafc'
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: '0px',
    })
  }

  return (
    <div className='mb-2 flex flex-col overflow-visible'>
      <label className='text-sm font-semibold uppercase'>Product Category</label>
      <Select
        styles={customStyles}
        className='bg-gray-100'
        value={selectedCategory}
        onChange={setSelectedCategory}
        options={options}
        name={name}
        placeholder={'Select category'}
      />
    </div>
  )
}
