import React from 'react'
import ScrollToTop from '../../../utilities/scrollToTop'
import PageTitle from './shared/pageTitle'
import SubHeader from '../page_body/shared_components/subHeader'

const Section = ({ header, children }) => {
  return (
    <div className='flex flex-col mb-4'>
      <h2 className='text-xl font-normal'>{header}</h2>
      <p>{children}</p>
    </div>
  )
}

const Terms = () => {
  return (
    <ScrollToTop>
      <SubHeader breadcrumbs='Terms and Conditions' link='/tos' />
      <div className='w-full bg-white border-b border-gray-300 flex flex-col justify-center items-center py-6 mb-3'>
        <PageTitle title={'Terms of Service'} paragraph={''} />
      </div>
      <div className='flex flex-col bg-white'>
        <div className='container mx-auto my-4'>
          <h1 className='text-4xl font-normal pb-4'>Terms of Service</h1>
          <div className='flex flex-col'>
            <Section header='1. Using Local Shops' />
            <Section header='a. Who can use Local Shops '>
              You may use Local Shops only if you can form a binding contract
              with Local Shops, and only in compliance with these Terms and all
              applicable laws. When you create your Local Shops account, you
              must provide us with accurate and complete information. Any use or
              access by anyone under the age of 13 is prohibited. If you open an
              account on behalf of a company, organization, or other entity,
              then (a) "you" includes you and that entity, and (b) you represent
              and warrant that you are authorized to grant all permissions and
              licenses provided in these Terms and bind the entity to these
              Terms, and that you agree to these Terms on the entity's behalf.
              Local Shops may be software that is downloaded to your computer,
              phone, tablet, or other device. You agree that we may
              automatically upgrade those Local Shops products, and these Terms
              will apply to such upgrades.
            </Section>

            <Section header='b. Our license to you '>
              Subject to these Terms and our policies we grant you a limited,
              non-exclusive, non-transferable, and revocable license to use
              Local Shops.
            </Section>

            <Section header='c. Commercial use of Local Shops '>
              If you want to use Local Shops for business purposes you must
              create a Merchant account. Once you have signed up for a Merchant
              account, you can create product listings that represent your
              business and business's products.
            </Section>
            <Section header='2. Your Content' />
            <Section header='a. Posting content'>
              Local Shops allows you to post content, including photos, video,
              links and other materials. Anything that you post or otherwise
              make available on our Products page is referred to as "User
              Content." **You retain all rights in, and are solely responsible
              for, the User Content you post to Local Shops.** By using Local
              Shops, you represent and warrant that you are the owner of, and/or
              have the right to use the product(s) that you display on Local
              Shops. For images of your products, this applies to the access of
              those images from the hard drive of your computer, from your
              mobile device or through your Facebook, Instagram, YouTube,
              Dropbox (or similar) account. Professional images that are
              provided to you by professional photographers or made available
              through websites, magazines, books or other resources may not be
              used through the Product without expressed consent of the
              copyright holder. If your accepted User Content was taken with an
              unmanned aerial vehicle (drone) or other unmanned device, you
              represent and warrant that you have adhered with all local laws
              and access requirements in the operation of the drone and capture
              of the accepted User Content, including any necessary licenses,
              permissions and permits to own and operate such unmanned device.
            </Section>
            <Section header='b. How you, Local Shops and other members can use your content'>
              <p className='mb-4'>
                By using Local Shops, you grant Local Shops a non-exclusive,
                royalty-free, transferable, sublicensable, worldwide license to
                store, display, save, create derivative works (i.e. marketing
                images) and distribute your User Content on Local Shops solely
                for the purposes of operating, developing, providing, and using
                Local Shops. We reserve the right to remove User Content for any
                reason, including User Content that we believe violates these
                Terms or our policies. **Local Shops does not claim ownership
                rights in any of the digital images that you access, use, create
                or modify using Local Shops (the “Materials”).**
              </p>
              <p className='mb-4'>
                For the sole purpose of allowing Local Shops to display your
                product, including enabling us to display your photos and media
                through the Local Shops (for example, in Product Listings), you
                grant to Local Shops a non-exclusive, royalty-free license to
                use, copy, distribute and display those Materials (the
                “Materials License”), and the right to use your name in
                connection with the Materials. Local Shops use, copying,
                distribution and/or display of any Materials in connection with
                such Materials License shall be subject to Local Shops privacy
                policy, available at [localshops.com](http://localshops.com/) in
                the footer. For the avoidance of doubt, Local Shops will not,
                without your prior consent, use, display or modify any of your
                Materials other than in accordance with any activity initiated
                by you. Materials may be used by Local Shops for the purpose of
                advertising or promoting our Product to potential members.
              </p>
              <p className='mb-4'>
                Your use of the Product must not infringe the legal rights of
                any other person (including privacy and personality rights,
                copyright, trademark, trade secret, patent, moral rights, and
                other proprietary rights). You may not use Local Shops in any
                manner that may be indecent, offensive, defamatory, derogatory,
                fraudulent, deceptive, harmful, abusive, threatening, vulgar,
                profane, pornographic, obscene, sexually explicit, sexist,
                racist, hateful, offensive, harassing, invasive of the privacy
                rights of others, or that may otherwise be objectionable. This
                prohibition extends to the posting, printing, transmitting or
                communicating of any Materials that depict, encourage, indicate,
                advocate or tend to incite any such conduct. You may not use
                Local Shops in any way that is harmful to minors, including but
                not limited to, accessing, using or printing content that
                violates child pornography laws, child sexual exploitation laws
                and laws prohibiting the depiction of minors engaged in sexual
                conduct. Your use of Local Shops must not dilute or depreciate
                the name and reputation of Local Shops, its affiliates and/or
                associates.
              </p>
              <p className='pb-4'>
                You may not use Local Shops in any manner that invades or
                infringes the privacy of any other person. You represent and
                warrant to Local Shops that Local Shops use of the Materials, as
                contemplated herein, does not and will not violate or infringe
                the rights (including copyright, moral rights, and other
                intellectual property rights and privacy and personality rights)
                of any other person or any applicable laws; and you have all the
                rights necessary to grant all permissions, rights and licenses
                set forth above. You also agree not to use Local Shops in any
                manner that could damage, disable, overburden, or impair Local
                Shops or interfere with any other party's use and enjoyment of
                Local Shops. You also agree not to attempt to gain unauthorized
                access to any other Local Shops member accounts, computer
                systems or networks associated with Local Shops. You also agree
                not to obtain or attempt to obtain any Materials or information
                through any means not intentionally made available or provided
                for through Local Shops.
              </p>
            </Section>
            <Section header='c. How long we keep your content '>
              You may not use Local Shops in any manner that invades or
              infringes the privacy of any other person. You represent and
              warrant to Local Shops that Local Shops use of the Materials, as
              contemplated herein, does not and will not violate or infringe the
              rights (including copyright, moral rights, and other intellectual
              property rights and privacy and personality rights) of any other
              person or any applicable laws; and you have all the rights
              necessary to grant all permissions, rights and licenses set forth
              above. You also agree not to use Local Shops in any manner that
              could damage, disable, overburden, or impair Local Shops or
              interfere with any other party's use and enjoyment of Local Shops.
              You also agree not to attempt to gain unauthorized access to any
              other Local Shops member accounts, computer systems or networks
              associated with Local Shops. You also agree not to obtain or
              attempt to obtain any Materials or information through any means
              not intentionally made available or provided for through Local
              Shops.
            </Section>
            <Section header='d. Feedback you provide'>
              We value hearing from our users, and are always interested in
              learning about ways we can make Local Shops better. If you choose
              to submit comments, ideas or feedback, you agree that we are free
              to use them without any restriction or compensation to you. By
              accepting your submission, Local Shops does not waive any rights
              to use similar or related feedback previously known to Local
              Shops, or developed by its employees, or obtained from sources
              other than you.
            </Section>
            <Section header='3. Copyright Policy '>
              Local Shops has adopted and implemented the Local Shops Copyright
              Policy in accordance with the Digital Millennium Copyright Act and
              other applicable copyright laws. For more information, please
              contact us through our contact form located on
              localshops.com.
            </Section>
            <Section header='4. Security '>
              We care about the security of our users. While we work to protect
              the security of your content and account, Local Shops cannot
              guarantee that unauthorized third parties will not be able to
              defeat our security measures. We ask that you keep your password
              secure. Please notify us immediately of any compromise or
              unauthorized use of your account.
            </Section>
            <Section header='5. Third-party links, sites, and services'>
              Our Products may contain links to third-party websites,
              advertisers, services, special offers, or other events or
              activities that are not owned or controlled by Local Shops. We do
              not endorse or assume any responsibility for any such third-party
              sites, information, materials, products, or services. If you
              access any third-party website, service, or content from Local
              Shops, you do so at your own risk and you agree that Local Shops
              will have no liability arising from your use of or access to any
              third-party website, service, or content.
            </Section>
            <Section header='6. Termination '>
              Local Shops may terminate or suspend this license at any time,
              with or without cause or notice to you. Upon termination, you
              continue to be bound by Sections 2 and 6-12 of these Terms.
            </Section>
            <Section header='7. Indemnity '>
              If you use our Products for commercial purposes without agreeing
              to our Terms as determined in our sole and absolute discretion,
              you agree to indemnify and hold harmless Local Shops and its
              respective officers, directors, employees and agents, from and
              against any claims, suits, proceedings, disputes, demands,
              liabilities, damages, losses, costs and expenses, including,
              without limitation, reasonable legal and accounting fees
              (including costs of defense of claims, suits or proceedings
              brought by third parties), in any way related to (a) your access
              to or use of our Products, (b) your User Content, or (c) your
              breach of any of these Terms.
            </Section>
            <Section header='8. Disclaimers '>
              <p className='mb-4'>
                The Products and all included content are provided on an "as is"
                basis without warranty of any kind, whether express or implied.
              </p>
              <p className='mb-4'>
                LOCAL SHOPS SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND
                CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE
                OF DEALING OR USAGE OF TRADE.
              </p>
              <p className='mb-4'>
                Local Shops takes no responsibility and assumes no liability for
                any User Content that you or any other user or third-party posts
                or transmits using our Products. You understand and agree that
                you may be exposed to User Content that is inaccurate,
                objectionable, inappropriate for children, or otherwise unsuited
                to your purpose.
              </p>
            </Section>
            <Section header='9. Limitation of Liability '>
              TO THE MAXIMUM EXTENT PERMITTED BY LAW, LOCAL SHOPS SHALL NOT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
              PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER
              INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE,
              GOOD-WILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR
              ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE PRODUCTS;
              (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE PRODUCTS,
              INCLUDING WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL
              CONDUCT OF OTHER USERS OR THIRD PARTIES; OR (C) UNAUTHORIZED
              ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT. IN NO
              EVENT SHALL LOCAL SHOPS AGGREGATE LIABILITY FOR ALL CLAIMS
              RELATING TO THE PRODUCTS EXCEED ONE HUNDRED CANADIAN DOLLARS (CDN
              $100.00).
            </Section>
            <Section header='10. Arbitration '>
              For any dispute you have with Local Shops, you agree to first
              contact us and attempt to resolve the dispute with us informally.
              If Local Shops has not been able to resolve the dispute with you
              informally, we each agree to resolve any claim, dispute, or
              controversy (excluding claims for injunctive or other equitable
              relief) arising out of or in connection with or relating to these
              Terms by binding arbitration, except as provided herein. Unless
              you and Local Shops agree otherwise, the arbitration will be
              conducted in the county where you reside. Each party will be
              responsible for paying any filing, administrative and arbitrator
              fees, except that Local Shops will pay for your reasonable filing,
              administrative, and arbitrator fees if your claim for damages does
              not exceed $75,000 and is non-frivolous. The award rendered by the
              arbitrator shall include costs of arbitration, reasonable
              attorneys' fees and reasonable costs for expert and other
              witnesses, and any judgment on the award rendered by the
              arbitrator may be entered in any court of competent jurisdiction.
              Nothing in this Section shall prevent either party from seeking
              injunctive or other equitable relief from the courts for matters
              related to data security, intellectual property or unauthorized
              access to the Service. ALL CLAIMS MUST BE BROUGHT IN THE PARTIES'
              INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
              PURPORTED CLASS OR REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE
              OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
              PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU
              AND LOCAL SHOPS ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR
              TO PARTICIPATE IN A CLASS ACTION.
              <p className='mb-4'>
                To the extent any claim, dispute or controversy regarding Local
                Shops or our Products isn’t arbitrable under applicable laws or
                otherwise: you and Local Shops both agree that any claim or
                dispute regarding Local Shops will be resolved exclusively in
                accordance with Clause 11 of these Terms.
              </p>
            </Section>
            <Section header='11. Governing law and jurisdiction '>
              These Terms shall be governed by the laws of the Province of
              Alberta, without respect to its conflict of laws principles. We
              each agree to submit to the personal jurisdiction of a provincial
              court located in Calgary, Alberta, Canada for any actions not
              subject to Section 10 (Arbitration).
            </Section>
            <Section header='12. General Terms' />
            <Section header='Notification procedures and changes to these Terms'>
              Local Shops reserves the right to determine the form and means of
              providing notifications to you, and you agree to receive legal
              notices electronically if we so choose. We may revise these Terms
              from time to time and the most current version will always be
              posted on our website. If a revision, in our sole discretion, is
              material we will notify you. By continuing to access or use the
              Products after revisions become effective, you agree to be bound
              by the revised Terms. If you do not agree to the new terms, please
              stop using Local Shops Product and Services.
            </Section>
            <Section header='Assignment'>
              These Terms, and any rights and licenses granted hereunder, may
              not be transferred or assigned by you, but may be assigned by
              Local Shops without restriction. Any attempted transfer or
              assignment in violation hereof shall be null and void.
            </Section>
            <Section header='Entire agreement/severability '>
              These Terms, together with the Privacy Policy and any amendments
              and any additional agreements you may enter into with Local Shops
              in connection with the Products, shall constitute the entire
              agreement between you and Local Shops concerning the Products. If
              any provision of these Terms is deemed invalid, then that
              provision will be limited or eliminated to the minimum extent
              necessary, and the remaining provisions of these Terms will remain
              in full force and effect.
            </Section>
            <Section header='No waiver '>
              No waiver of any term of these Terms shall be deemed a further or
              continuing waiver of such term or any other term, and Local Shops
              failure to assert any right or provision under these Terms shall
              not constitute a waiver of such right or provision.
            </Section>
            <Section header='Parties'>
              Local Shops is a worldwide service and our reference to Local
              Shops in these Terms includes Local Shops and all of its worldwide
              subsidiaries. If you live in Canada or outside of Canada, these
              Terms are a contract between you and Local Shops, 421 7 Ave SW
              #1600, Calgary, AB T2P 4K9.
            </Section>
            <Section header='Copyright on Local Shops'>
              <p className='mb-4'>
                Local Shops respects the intellectual property rights of others
                and we expect people on Local Shops to do the same. It’s our
                policy—in appropriate circumstances and at our discretion—to
                disable or terminate the accounts of people who repeatedly
                infringe or are repeatedly charged with infringing copyrights or
                other intellectual property rights.
              </p>
              <p className='mb-4'>
                We’ll respond quickly to claims of copyright infringement on
                Local Shops that are reported to us.
              </p>
              <p className='mb-4'>
                If you’re a copyright owner or authorized to act on behalf of
                one, you can report alleged copyright infringements on Local
                Shops, and we’ll take whatever action we think is appropriate,
                which may include removing the reported content from Local
                Shops. If we remove content in response, we may notify the
                person who saved it on Local Shops so they have the opportunity
                to send a counter-notice. We may also send a complete copy of
                the notice to others, as appropriate.
              </p>
            </Section>
            <Section header='Submit a copyright complaint '>
              <p className='mb-4'>
                To submit a notice, please provide the below information:
              </p>
              <p className='mb-4'>
                1. Identify the copyrighted work you believe has been infringed.
                If you’re reporting multiple copyrighted works, you can provide
                a representative list of those copyrighted works.{' '}
              </p>
              <p className='mb-4'>
                2. Identify the content on Local Shops you claim to be
                infringing your rights, including the information we need to
                help us find it, like the URL to the page on Local Shops.
              </p>
              <p className='mb-4'>
                3. Provide your mailing address, phone number and email address.
              </p>
              <p className='mb-4'>
                4.Include both of these statements in the body of your notice:
              </p>
              <p className='mb-4'>
                &emsp; 1. "I hereby state that I have a good faith belief that
                the disputed use of the copyrighted material or reference or
                link to such material is not authorized by the copyright owner,
                its agent, or the law (for example, as a fair use)."
              </p>
              <p className='mb-4'>
                &emsp; 2. "I hereby state that the information in this notice is
                accurate and, under penalty of perjury, that I’m the owner, or
                authorized to act on behalf of the owner of the copyright or of
                an exclusive right under the copyright that’s allegedly
                infringed."
              </p>
              <p className='mb-4'>
                5. Include your full legal name and your electronic or physical
                signature (for example, by typing your full name).{' '}
              </p>
              <p className='mb-4'>
                If the work you’re reporting is an accepted form of media on our
                Product, you can ask for all copies of that media (image, video,
                PDF, Word Document etc.) to be removed. At the beginning of your
                notice, just ask us to “remove all copied media.” Please note
                that we can only remove identical copies of media. If a media
                file has been resized or altered, we won’t be able to find or
                remove it.
              </p>
              <p className='mb-4'>Send your completed notice to:</p>
              <p className='mb-4'>
                Local Shops 421 7 Ave SW #1600 Calgary, AB, Canada T2P 4K9
              </p>
              <p className='mb-4'>
                Email: please use our submission form under the “Contact” link.
              </p>
            </Section>
            <Section header='What if I get a copyright complaint notification? '>
              <p className='mb-4'>
                If you’ve gotten a notification that one of your Product
                Listings, Pictures or Profiles has been removed for a copyright
                complaint, it means the content owner asked us to remove it. If
                you want us to forward the information from the copyright
                complaint notification, just email us to let us know (we may
                remove some personal contact information).
              </p>
              <p className='mb-4'>
                If your account gets too many copyright complaints, you could
                lose the ability to save Products Listings on Local Shops, or we
                may disable your account altogether.
              </p>
              <p className='mb-4'>
                If you think we made a mistake removing your content, you can
                file a counter-notice. When we get a valid counter-notice, we’ll
                remove the complaint from your account's record.
              </p>
              <p className='mb-4'>
                Note: There are legal and financial consequences for fraudulent
                or bad faith counter-notices. Before submitting a
                counter-notice, make sure you have a good faith belief that we
                removed the content in error, and that you understand the
                repercussions of submitting a false claim.
              </p>
            </Section>
            <Section header='How to file a counter-notice'>
              <p className='mb-4'>
                Email your counter-notice to us and include all of the
                following:
              </p>
              <p className='mb-4'>1. Your name, address and phone number. </p>
              <p className='mb-4'>
                2. The web address of the content we removed (copy and paste the
                link from the notification email).{' '}
              </p>
              <p className='mb-4'>
                3. A statement under penalty of perjury that you have a good
                faith belief that the content was removed in error.{' '}
              </p>
              <p className='mb-4'>
                4. A statement that you consent to the jurisdiction of Supreme
                Court for the judicial district in which your address is
                located, or if your address is outside of Canada, for any
                judicial district in which Local Shops may be found, and that
                you’ll accept service of process from the person who provided
                the original complaint.
              </p>
              <p className='mb-4'>
                5. A physical or electronic signature (for example, by typing
                your full name).
              </p>
            </Section>
            <p>
              Local Shops is committed to providing a safe and responsible
              e-commerce solution. Our objective is to provide a simple and easy
              to use platform template, for users to share their Product
              Listings with their community and for consumers to easily support
              local businesses. We hope you can benefit from our design and
              functionality, just as many other users have. Please, if you have
              any questions or suggestions, write our President and Founder
              anytime (ryan@localshops.com).
            </p>
          </div>
        </div>
      </div>
    </ScrollToTop>
  )
}

export default Terms
