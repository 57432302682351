import React from 'react'

export default function PageTitle({title, paragraph}) {

  return (
    <>
      <h1 className='text-5xl font-medium'>{title}</h1>
        {paragraph && (
          <p>{paragraph}</p>
        )}
    </>
  )
}
