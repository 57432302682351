import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/pro-solid-svg-icons'
import Image from '../../../../assets/images/image-icon-productPage.png'

const Onboarding = ({url, title, action}) => {
  return (
    <div className='bg-white border border-gray-300 max-w-md flex flex-col mx-auto mt-24'>
      <div className='pt-4 px-24 flex flex-col items-center pb-12'>
        <p className='text-xs text-center '>No {title} have been added</p>
        <div className='p-3'>
          <img src={Image} className='w-24' />
        </div>
        <a
          className='btn btn-default btn-rounded text-white bg-black hover:bg-gray-800
          flex flex-row items-center align-middle'
          href={`${url}`}>
          <FontAwesomeIcon icon={faPlusCircle} className='text-lg' />
          <p className='ml-2 hidden md:block'>
            {action ? action : 'add'} {title}
          </p>
        </a>
      </div>
    </div>
  )
}
export default Onboarding
