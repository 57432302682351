import React, { useContext, useEffect, useState } from 'react';
import  FavoritesContext  from '../../../../contexts/favoritesContext';
import queryString from 'query-string';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import SavedSellersContext from './savedSellersContext';
import SavedSellersUI from './savedSellersUI';

const SavedSellers = () => {
  const history = useHistory();
  const parsedQuery = queryString.parse(window.location.search);

  const [savedSellers, setSavedSellers] = useState([]);
  const [currentPage, setCurrentPage] = useState(parsedQuery.page || 1);
  const [totalPages, setTotalPages] = useState(1);
  const [currentSort, setCurrentSort] = useState(parsedQuery.order);
  const [searchTerm, setSearchTerm] = useState(parsedQuery.search);
  const [fetchingSellers, setIsFetchingSellers] = useState(false);

  const { token } = useContext(FavoritesContext);

  useEffect(() => {
    fetchSellers();
  }, [currentPage, currentSort, searchTerm])

  const fetchSellers = () => {
    setIsFetchingSellers(true);
    axios({
      method: 'get',
      url: `/users/saved_sellers.json`,
      data: { authenticity_token: token },
      params: {
        page: currentPage,
        order: currentSort,
        search: searchTerm,
        per_page: 3
      }
    }).then(response => {
      setSavedSellers(response.data.shops);
      setTotalPages(response.data.pages);
    }).finally(() => {
      setIsFetchingSellers(false);
    })
  }

  const resetScroll = () => window.scrollTo({ top: 0, behavior: 'smooth' })
  const updateURL = () => history.push(`${location.pathname}?${queryString.stringify(parsedQuery)}`)

  const onSortChange = sort => {
    resetScroll();
    setCurrentSort(sort);
    setCurrentPage(1);
    parsedQuery.page = 1;
    parsedQuery.order = sort;
    updateURL();
  }

  const onSearchTermChange = search => {
    resetScroll();
    setSearchTerm(search);
    setCurrentPage(1);
    parsedQuery.page = 1;
    parsedQuery.search = search;
    updateURL();
  }

  const onPageChange = page => {
    resetScroll();
    setCurrentPage(page.selected + 1)
    parsedQuery.page = page.selected + 1;
    updateURL();
  }

  return (
    <SavedSellersContext.Provider value={{
      currentPage,
      fetchingSellers,
      savedSellers,
      currentSort,
      onSearchTermChange,
      onSortChange,
      searchTerm,
      totalPages,
      onPageChange
    }}>
      <SavedSellersUI />
    </SavedSellersContext.Provider>
  )
}

export default SavedSellers;
