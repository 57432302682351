import React, { useEffect, useRef } from 'react'
import axios from 'axios'
import Switch from 'react-switch'
import { getColor } from "../../../../../utilities/colors";

export default function ListedToggle({ toggled, setToggled, attribute, url }) {
  const token = document.querySelector("[name=csrf-token]").content;
  axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

  let color = 'yellow'
  let onColor = `bg-${color}-800`
  let onHandleColor = `bg-white`
  let offColor = `bg-gray-200`
  let offHandleColor = 'bg-white'

  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      axios({
        method: "PUT",
        url: url,
        data: { [`${attribute}`]: toggled },
      }).catch((err) => console.log(err))
    }
  }, [toggled]);

  return (
    <Switch
      onChange={setToggled}
      checked={toggled}
      onColor={getColor(onColor)}
      onHandleColor={getColor(onHandleColor)}
      offColor={getColor(offColor)}
      offHandleColor={getColor(offHandleColor)}
      handleDiameter={24}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      className="react-switch"
    />
  )
}
