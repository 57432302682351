import React, { useContext } from "react"
import { useHistory } from "react-router-dom"
import { handleAPIError } from "../../../../utilities/apiErrorHandling"
import { getCartTotal } from "../../../../utilities/cartUtility"
import { CheckoutModes, CheckoutQueryParamNames } from "../../../../utilities/checkoutUtility"
import { formatCurrency } from "../../../../utilities/formatPrice"
import FormButton from "../../../shared/form_fields/FormButton"
import { CheckoutContext } from "../../../users/checkout/checkoutContext"
import { CartContext } from "../../../../contexts/cartContext"
import CartItems from "./cartItems"

const CartUI = ({ onOrderCreated }) => {

  const { createCheckoutOrder, isCreatingOrder } = useContext(CheckoutContext);
  const { cart } = useContext(CartContext);

  const history = useHistory();

  const onCheckoutClick = () => {
    createCheckoutOrder(cart).then(response => {
      onOrderCreated();
      history.push(`/checkout/addresses?${CheckoutQueryParamNames.mode}=${CheckoutModes.cart}`);
    }).catch(error => {
      console.error(error);
      handleAPIError(error, window.location.href);
    })
  }

  //empty cart check
  if (!cart || !cart.cart_shops.length)
    return <div className='px-4 py-6 flex flex-col'>No items yet!</div>;

  const total = getCartTotal(cart);

  return (
    <div className='p-4 flex flex-col mb-4'>
      <div className='flex flex-col'>
        <CartItems />
      </div>
      <div className='flex flex-row justify-between px-2 my-3 text-sm'>
        <p className=''>Subtotal</p>
        <p className='text-right'>{formatCurrency(total)}</p>
      </div>
      <hr className='my-3 bg-gray-500 h-0.5' />
      <div className='flex flex-row justify-between p-2 my-4 text-lg'>
        <p className='font-semibold'>Total</p>
        <p className='font-semibold text-right'>{formatCurrency(total)}</p>
      </div>
      <FormButton
        onClick={onCheckoutClick}
        className='w-full h-12 my-4 rounded-full bg-blue-grey flex justify-center'
        loaderContainerClassName='w-6 self-center mr-1'
        isLoading={isCreatingOrder}>
        <span className='font-semibold text-lg text-white self-center'>Checkout</span>
      </FormButton>
    </div>
  )
}

export default CartUI
