import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DeleteAlert from "../../../../shared_components/delete_alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";

const BulkDeleteButton = ({ integration, products }) => {
  const [alertOpened, setAlertOpened] = useState(false);
  const deleteProducts = () => {
    const formData = new FormData();

    const ids = products.map((product) => product.id);
    formData.append("ids", ids);

    axios({
      method: "DELETE",
      url: `/merchants/integrations/${integration.id}/products/bulk_destroy`,
      data: formData,
    })
      .then(() => {
        toast.success(`Successfully deleted ${products.length} products`);
        window.location.href = `/merchants/integrations/${integration.id}/products`;
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div
        className="fa-layers fa-fw fa-2x cursor-pointer"
        onClick={() => setAlertOpened(!alertOpened)}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="35" height="35" rx="8" fill="#C95931" />
          <path
            d="M12 13H13.3333H24"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.333 12.9997V11.6663C15.333 11.3127 15.4735 10.9736 15.7235 10.7235C15.9736 10.4735 16.3127 10.333 16.6663 10.333H19.333C19.6866 10.333 20.0258 10.4735 20.2758 10.7235C20.5259 10.9736 20.6663 11.3127 20.6663 11.6663V12.9997M22.6663 12.9997V22.333C22.6663 22.6866 22.5259 23.0258 22.2758 23.2758C22.0258 23.5259 21.6866 23.6663 21.333 23.6663H14.6663C14.3127 23.6663 13.9736 23.5259 13.7235 23.2758C13.4735 23.0258 13.333 22.6866 13.333 22.333V12.9997H22.6663Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M19.333 16.333V20.333"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.667 16.333V20.333"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {alertOpened && (
        <DeleteAlert
          handleDelete={deleteProducts}
          handleClick={() => setAlertOpened(!alertOpened)}
          header={`Are you sure you want to delete ${products.length} products?`}
        >
          This action will delete these products permanently. Are you sure you
          want to continue?
        </DeleteAlert>
      )}
    </div>
  );
};

export default BulkDeleteButton;
