// calculates total for all items in a cart. Doesn't include shipping.
export const getCartTotal = (cart) => {
  let total = 0;
  if (!cart)
    return total;

  cart.cart_shops.forEach(shop => {
    total += shop.cart_items.map(cartItem => cartItem.total_cents).reduce((x, y) => x + y);
  })
  return total;
}

// Returns all the items in a cart in array format.
export const getAllCartItems = cart => {
  let items = [];
  if (!cart)
    return items;

  cart.cart_shops.forEach(shop => {
    items.push(...shop.cart_items)
  })

  return items;
}

export const getCartItemByVariantId = (cart, integration, variantId) => {
  if (cart && cart.cart_shops) {
    const shop = cart.cart_shops.find(cartShop => cartShop.integration.id === integration.id);
    if (shop)
      return shop.cart_items.find(item => item.product_variant.id === variantId) || null;
  }

  return null;
}
