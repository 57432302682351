import React, { useEffect, useState } from 'react'
import '../../../../css/pagination.css'
import { useParams } from 'react-router-dom';
import axios from "axios";

export const ShopsContext = React.createContext({
  shops: [],
  searchParams: null,
  totalPages: 0,
  currentPage: 1,
  currentSorting: 'ASC'
})

const Shops = ({ children }) => {
  let { market_slug } = useParams();
  let query = new URLSearchParams(location.search);
  let searchParams = query.get('search_params')

  let [shops, setShops] = useState(null);
  let [totalPages, setTotalPages] = useState(null);
  let [currentPage, setCurrentPage] = useState(query.get('page') || 1);
  let [currentSorting, setCurrentSorting] = useState(query.get('order') || '');

  let [isGettingShops, setIsGettingShops] = useState(false);

  function getShops() {
    setIsGettingShops(true);

    let params = {};
    if (searchParams) {
      params['search_params'] = searchParams
    }

    if (market_slug) {
      params['market_slug'] = market_slug
    }

    if (currentSorting !== '') {
      params['order'] = currentSorting;
    }

    params['page'] = currentPage;

    axios({
      method: "GET",
      url: `/integrations.json`,
      params: params,
    }).then((res) => {
      setShops(res.data.shops);
      setTotalPages(res.data.pages)
      setCurrentPage(res.data.current_page)
    }).finally(() => {
      setIsGettingShops(false);
    })
  }

  useEffect(() => {
    if (isGettingShops)
      return;

    setShops(undefined)
    getShops()
  }, [currentPage, currentSorting])

  return (
    <ShopsContext.Provider
      value={{
        shops: shops,
        searchParams: searchParams,
        totalPages: totalPages,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        currentSorting: currentSorting,
        setCurrentSorting: setCurrentSorting,
        setShops: setShops
      }}>
      {children}
    </ShopsContext.Provider>
  )
}

export default Shops
