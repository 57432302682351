import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomCarousel from "../customCarousel";
import carouselBreakpoints from "../../../../../utilities/carouselBreakpoints";
import { LargeProductCardSkeleton, SmallProductCardSkeleton } from "./skeletonHelpers";

const MadeLocallyProductsSkeleton = () => {

  const MobileMadeLocallySkeleton = () => {
    return (
      <div className="grid grid-cols-2 justify-items-center">
        {[0, 1, 2].map(
          (product, index) =>
            index < 2 && (
              <SmallProductCardSkeleton />
            )
        )}
        <div className="col-span-2 py-10">
          <a
            className="border border-black opacity-50 w-full px-32 py-3 rounded-full"
          >
            <Skeleton />
          </a>
        </div>
      </div>
    );
  };

  return (
    <section
      id="madelocally"
      className="md:my-8 py-8 lg:w-full w-screen flex flex-col items-center justify-center bg-cover"
    >
      <div className="my-auto flex flex-col xl:flex-row p-2 xl:py-8 xl:pl-8 w-full">
        <div className="flex flex-col justify-center md:py-3 pb-3 mr-28">
          <div className="md:py-2 items-end flex w-screen flex-row-reverse lg:block lg:w-full justify-between ">
            <p
              className="clear-right items-center text-2xl md:text-4xl"
            >
              <Skeleton />
            </p>
          </div>
          <p className="md:text-lg font-thin text-gray-700 py-2 md:w-96">
            <Skeleton count={2} />
          </p>
        </div>
        <div className='hidden md:block w-full'>
          {(
            <div
              className="xl:w-2/3 text-gray-900 antialiased justify-items-auto col-span-2 md:px-10 xl:px-0 xl:pl-4">
              <CustomCarousel
                breakpoints={carouselBreakpoints(3, 3, 3, 1)}
                length="lg:w-2/3 md:w-1/2 relative"
              >
                {[0, 1, 2].fill(3).map((product) => (
                  <LargeProductCardSkeleton />
                ))}
              </CustomCarousel>
            </div>
          )}
        </div>
        <div className='block md:hidden'>
          <MobileMadeLocallySkeleton />
        </div>
      </div>
    </section>
  )
}

export default MadeLocallyProductsSkeleton
