import { createContext } from 'react';

const PaymentsContext = createContext({
  payouts: [],
  integration: null,
  fetchingPayouts: false,
  dateRange: { from: null, to: null },
  setDateRange: null,
  sortOrder: null,
  setSortOrder: null,
  baseUrl: null,
  isMarketAdmin: false,
})

export default PaymentsContext;
