import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { PaypalConnectContext } from './index';
import { FullScreenLoader } from '../../../merchants/shared_components/fullScreenLoader';
import {spliceBaseUrl} from "../../../../utilities/paymentUtility"

const CheckIncomingPaypalConnection = () => {
  const baseUrl = spliceBaseUrl()
  const { connectPaypalToUser, isConnecting, getCurrentIntegrationId } = useContext(PaypalConnectContext);

  useEffect(() => {
    checkForPaypalCode();
  }, [])

  const checkForPaypalCode = () => {
    const query = new URLSearchParams(window.location.search);
    const paypalCode = query.get('code');

    if (paypalCode) {
      connectPaypalToUser(paypalCode).then(() => {
        toast.success('Paypal successfully authenticated.');
        setTimeout(() => {
          window.location.replace(`${baseUrl}/${getCurrentIntegrationId()}/payments`);
        }, 1000)
      }).catch(error => {
        const errorMessage = error.response && error.response.data ? error.response.data.error_description : error;

        console.error(errorMessage);
        toast.error('Unable to authenticate your paypal account.');
      })
    }
  }

  return isConnecting && <FullScreenLoader title={'Connecting to Paypal...'} />;
}

export default CheckIncomingPaypalConnection;
