import React from 'react'

const BetaBadge = ({name}) => {
  const beta = ["Woo Commerce", "Wix"]
  const isBeta = beta.includes(name)
  return (
    <>
      {isBeta && 
      (<span className="px-2 mr-4 inline-flex text-xs font-semibold rounded-full bg-blue-200 text-blue-800">
        Beta
      </span>)
      }
    </>
  )
}

export default BetaBadge
