import React from 'react'
import ScrollToTop from "../../../utilities/scrollToTop"
import SubHeader from "../page_body/shared_components/subHeader"
import CommonSpaces from '../../../../assets/images/common-spaces.png'
import { CalendarButton } from "./shared/CommunityMarketHelpers";
import RightSpiral from '../../../../assets/images/warehousing-images/right-spiral-warehouse.png'
import LeftAmenitySpiral from '../../../../assets/images/warehousing-images/left-amenity-spiral.png'
import WarehousePods from '../../../../assets/images/warehousing-images/warehouse-pods.png'
import Shipping from '../../../../assets/images/warehousing-images/shipping.png'
import CoWorking from '../../../../assets/images/warehousing-images/co-working.png'
import Gym from '../../../../assets/images/warehousing-images/gym.png'
import Bathrooms from '../../../../assets/images/warehousing-images/bathrooms.png'
import Kitchen from '../../../../assets/images/warehousing-images/kitchen.png'
import Podcast from '../../../../assets/images/warehousing-images/podcast.png'
import Breakout from '../../../../assets/images/warehousing-images/breakout.png'
import Retail from '../../../../assets/images/warehousing-images/retail.png'

const Warehousing = () => {
  return (
    <ScrollToTop>
      <SubHeader breadcrumbs='Co-Warehousing' link='/co-warehousing' />
      <div className='bg-white w-full flex flex-col items-center px-2'>
        <div className={centerSectionClass}>
          <h1 className={headerClass}>Collective <br/>
            Work, Warehouse, 3PL</h1>
          <p className={paragraphClass}>Local Shops Co. offers a wide variety of co-warehouse and private spaces to
            inventory product and store business essentials. Enjoy a collaborative and hybrid-work environment, or be
            completely hands off through our third party logistics (3PL) service.
          </p>
          <p className={paragraphClass}>Collective members can enjoy some or all the amenities we provide for a healthy
            business and lifestyle. Including, monthly pricing, scalabale spaces, operational support, kitchen and
            gym.</p>
        </div>
        <div className={`bg-light-orange ${leftSectionClass}`}>
          <h2 className={`${headerClass} w-full font-semibold`}>Common Spaces</h2>
          <p className={paragraphClass}>Our flexible warehouse spaces comes in a variety of sizes and dimensions
            to 
            suit your specific needs and growth path.</p>
        </div>
        <img alt='retail collective images' className='w-full' src={CommonSpaces} />
        <div className={`${leftSectionClass} relative block`}>
          <img alt='right spiral' className='absolute top-0 right-0' src={RightSpiral} />
          <h2 className={`${headerClass} text-saucy-red font-semibold w-full`}> Monthly Pricing</h2>
          <p className={`${paragraphClass}`}>Collective Warehouse Memberships are monthly, but long term
            collaborations can
            be  negotiated. With your
            membership you gain access to all facility amenities, 24/7 entry  and on demand or long term 3PL.</p>
          <hr className='border-8 border-mustard-300 w-16' />
          <div className='grid grid-cols-1 md:grid-cols-2 py-6 '>
            <div>
              <p className="text-4xl font-bold">Shared</p>
              <PricingBlock rackMeasurements="3'x2'x10' rack" price='$100 + GST/month' />
              <PricingBlock rackMeasurements="6'x2'x10' rack" price='$200 + GST/month' />
              <PricingBlock rackMeasurements="12'x2'x10' rack" price='$350 + GST/month' />
              <PricingBlock rackMeasurements="24'x2'x10' rack" price='$500 + GST/month' />
            </div>
            <div>
              <p className="text-4xl font-bold">Private</p>
              <PricingBlock rackMeasurements="144 sq ft (12' x 12')" price='$675 + GST/month ($4.86 sq ft)' />
              <PricingBlock rackMeasurements="288 sq ft (12' x 24')" price='$1250 + GST/month ($4.34 sq ft)' />
              <PricingBlock rackMeasurements="576 sq ft (24' x 24')" price='$2250 + GST/month ($3.91 sq ft)' />
              <PricingBlock rackMeasurements="Above 1000 sq ft & Full Bays" price='Contact us' />
            </div>
            <p className={paragraphClass}>*custom configuration available when in between square footages.</p>
          </div>
        </div>
        <div className={leftSectionClass}>
          <h2 className={`${headerClass} font-semibold`}>Fulfillment </h2>
          <p className={paragraphClass}>We offer short term or long term fulfillment support through our white glove
            3PL service.</p>
          <p className={paragraphClass}>If you are going on a vacation or want to be completely hands off, we can
            manage your orders and shipment with ease.</p>
          <p className={paragraphClass}>Just plug into our software. Our pricing is simple, $2 per label</p>
        </div>
        <div className='flex items-center justify-center py-4'>
          <CalendarButton text='Inquire' className='bg-saucy-red px-6 py-2 text-white' />
        </div>
        <div className={`${leftSectionClass} relative block`}>
          <hr className='border border-4 border-moody-blue w-full' />
          <img alt='right spiral' className='absolute top-0 left-0 hidden lg:block' src={LeftAmenitySpiral} />
          <h2 className={`${headerClass} text-saucy-red font-semibold w-full`}>Member Amenities</h2>
          <p className={paragraphClass}>Local Shops Warehouse Memberships come with unrivaled access and value to ensure
            you and your business are operating as efficiently as possible. </p>
          <hr className='border-8 border-moody-blue w-16' />
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center justify-center'>
            <ImageWithTitleBlock title='Warehouse Pods' src={WarehousePods} />
            <ImageWithTitleBlock title='Shipping/Receiving' src={Shipping} />
            <ImageWithTitleBlock title='Co-Working' src={CoWorking} />
            <ImageWithTitleBlock title='Gym' src={Gym} />
            <ImageWithTitleBlock title='Bathrooms' src={Bathrooms} />
            <ImageWithTitleBlock title='Kitchen' src={Kitchen} />
            <ImageWithTitleBlock title='Podcast Studio' src={Podcast} />
            <ImageWithTitleBlock title='Breakout Spaces' src={Breakout} />
            <ImageWithTitleBlock title='Retail Collective' src={Retail} />
          </div>
        </div>
        <div className={leftSectionClass}>
          <h2 className={`${headerClass} text-saucy-red font-semibold w-full`}>There's more with your membership...</h2>
          <div className='grid grid-cols-1 lg:grid-cols-2'>
            <div>
              <label className={labelClass}>Event Space</label>
              <p className={paragraphClass}>Coming in Spring 2023, Members will have full  access to a massive
                event
                space and bar for pop-ups,  sample sales, and launch parties.</p>
            </div>
            <div>
              <label className={labelClass}>Printing & Internet</label>
              <p className={paragraphClass}>All utilities are covered by your membership through  your flat rate
                monthly
                lease. This includes Bluetooth  printing and internet.</p>
            </div>
          </div>
          <div className='flex items-center justify-center py-8'>
            <CalendarButton text='Inquire' className='bg-saucy-red px-6 py-2 text-white' />
          </div>
        </div>
      </div>
    </ScrollToTop>

  )
}
const paragraphClass = 'py-4 text-2xl text-gray-800 leading-loose'
const headerClass = 'font-light font-thin text-6xl mx-auto my-4 text-gray-900'
const centerSectionClass = 'text-center max-w-4xl py-8 pb-32 '
const leftSectionClass = 'w-full px-2 lg:px-32 pt-16'
const labelClass = 'font-bold text-2xl text-gray-800'

const PricingBlock = ({ rackMeasurements, price }) => {
  const pricingTitle = 'text-4xl font-bold'
  const pricingSubTitle = 'text-2xl font-thin text-gray-700'
  return (
    <div className='py-4'>
      <p className={pricingTitle}> {rackMeasurements}</p>
      <p className={pricingSubTitle}>{price}</p>
    </div>
  )
}

const ImageWithTitleBlock = ({ title, src }) => {
  return (
    <div className='py-4 z-10'>
      <label className={labelClass}>{title}</label>
      <img src={src} />
    </div>
  )
}

export default Warehousing
