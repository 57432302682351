import React, { useState } from 'react'
import FulfillItemsAlert from './fulfillItemsAlert'
import axios from 'axios'
import { toast } from "react-toastify";


const FulfillButton = ({ integrationID, orderIntegrationID, orderID }) => {
  const [isClicked, setIsClicked] = useState(false)

  const handleFulfillment = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios.put(`/merchants/integrations/${integrationID}/order_integrations/${orderIntegrationID}`, {
      fulfillment_status: true,
      order_integration: {
        integration_id: integrationID,
        id: orderID
      }
    })
      .then(() => {
        toast.success(`Items Fulfilled`)
      })
      .catch((err) => {
        console.log(err)
        toast.error(`There has been an error. Please contact support`)
      })
  }
  return (
    <>
      <div onClick={() => setIsClicked((prevState) => !prevState)} title="Delete Item"
           className='p-2 border bg-green-600 font-bold flex items-center rounded cursor-pointer'>
        <span className='text-white px-2'>Fulfill Items</span>
      </div>
      {isClicked && <FulfillItemsAlert handleFulfillment={handleFulfillment}
                                       handleClick={() => setIsClicked((prevState => !prevState))} />}
    </>
  )
}
export default FulfillButton
