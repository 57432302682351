import React from 'react';
import MobileCardRow from '../../../merchants/shared_components/mobileCardRow';

const MobileCard = ({ data }) => {
  return (
    data &&
    <section className='rounded-md border border-beige-200 bg-white px-2 py-4 flex flex-col gap-y-2'>
      <MobileCardRow label='Payout Date'>{data.payoutDate} </MobileCardRow>
      <MobileCardRow label='Payout Amount'>{data.payoutAmount} </MobileCardRow>
      <MobileCardRow label='Order Number'>{data.orderNumber} </MobileCardRow>
      <MobileCardRow label='Customer'>{data.customerName} </MobileCardRow>
      <MobileCardRow label='Shipping'>{data.shipping} </MobileCardRow>
    </section>
  )
}

export default MobileCard;
