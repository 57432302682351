import React, { useContext } from 'react'
import useViewport from '../../../hooks/useViewport'
import { doPayoutsExist } from '../../../utilities/payoutUtility'
import PaymentsTable from './paymentsTable'
import PaymentsTableMobile from './paymentsMobile'
import NoData from './noData'
import { PaypalBlock, PaypalConnect } from './paypal'
import PaymentsContext from './paymentsContext'
import PaymentFilters from './paymentFilters'

const PaymentsUI = () => {
  const { payouts } = useContext(PaymentsContext);
  const { isMobile } = useViewport()
  const noDataCSSModifier = isMobile() ? '' : '-b'
  return (
    <div>
      <h1 className='text-2xl font-medium'>Payment Information</h1>
      <section>
        <PaypalConnect>
          <PaypalBlock className='w-full h-auto p-4 md:pt-10 md:pb-10 my-4 bg-white rounded-md ' />
        </PaypalConnect>
        <div className='w-full mb-4 p-4 bg-white rounded-md flex md:flex-row flex-col justify-end gap-x-4 gap-y-2'>
          <PaymentFilters />
        </div>
        {isMobile()
          ? <PaymentsTableMobile />
          : <PaymentsTable />
        }
        {!doPayoutsExist(payouts) && (
          <NoData className={`bg-white py-24 m-auto rounded${noDataCSSModifier}-md`} />
        )}
      </section>
    </div>
  )
}

export default PaymentsUI
