import React from 'react';
import { doOrdersExist, makeOrderTableReadable } from '../../../../../utilities/purchaseHistoryUtility';
import OrderCard from './orderCard';

const PurchaseHistoryCards = ({ orders, onDetailsClicked }) => {
  return (
    doOrdersExist(orders) &&
    <div className='flex flex-col gap-y-3'>
      {orders.map(order => <OrderCard data={makeOrderTableReadable(order)} onDetailsClicked={onDetailsClicked} />)}
    </div>
  )
}

export default PurchaseHistoryCards;
