import React from 'react'
import Logo from '../../../assets/images/logo_black'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'

const Header = ({ current_user }) => {
  return (
    <header className='flex justify-between items-center px-2 lg:px-4'>
      <div className='h-24 py-1 lg:py-2'>
        <a href='/'>
          <img className='h-16 ml-4' src={Logo} alt='Local Shops' />
        </a>
      </div>
      <div className='flex flex-row px-2 pt-2 pb-4 gap-x-4 items-center'>
        {current_user.first_name ||
          current_user.email && (
            <div className='flex flex-row items-center'>
              <FontAwesomeIcon icon={faUser} className='text-xl' />
              <a
                className='text-cerulean-800 text-sm font-medium hover:text-cerulean-500 px-2 cursor-pointer'
                href='/users/edit'
              >
                {current_user.first_name || current_user.email}{' '}
                {current_user.last_name ? current_user.last_name : ''}
              </a>
            </div>
          )}
      </div>
    </header>
  )
}

export default Header
