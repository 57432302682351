import React from 'react'
import { Link } from 'react-router-dom';

export default function SplashBanner({ link, tagline, title, market }) {
  const transition = 'transition duration-500 ease-in-out transform hover:scale-105 text-xl'

  return (
    <div className="relative bg-gray-50">
      <main className="lg:relative">
        <div style={{ height: '30rem' }}
             className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center bg-light-orange">
          <div className="px-6 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1
              className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block xl:inline">{title}</span>{' '}
            </h1>
            <p className="mx-auto mt-3 max-w-md text-lg text-gray-600 sm:text-xl md:mt-5 md:max-w-3xl">
              {tagline}
            </p>
            <div className="mt-10 sm:flex justify-center">
              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3">
                <Link
                  to={link}
                  className={`inline-block bg-mustard-400 text-white rounded-full mt-3 px-6 py-3 text-sm lg:text-base lg:px-10 lg:mt-8  ${transition} `}
                >
                  Go to Catalog
                </Link>
              </div>
            </div>
            <div
              className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
              <img
                style={{ height: '30rem' }}
                className='w-full cursor-pointer hidden xl:block object-cover'
                src={`${market.market_banner}`}
                alt={`${market.name} Banner`}
              />
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
