import React, { useContext } from "react"
import { CartContext } from "../../../../contexts/cartContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { formatCurrency } from "../../../../utilities/formatPrice"
import QuantitySelect from "./quantitySelect";

const CartItem = ({ cartItem, shopName }) => {
  let { removeFromCart } = useContext(CartContext)

  return (
    <>
      <img
        className='w-20 object-cover border-2 rounded-xl'
        src={cartItem.product_image}
        alt={cartItem.product.title}
      />
      <div className='flex flex-col gap-y-1 w-full'>
        <div className='flex flex-row items-center justify-between'>
          <a href={`/products/${cartItem.product.slug}`} className='flex flex-row gap-x-3 m-2'>
            {cartItem.product.title} - {cartItem.product_variant.title}
          </a>
          <button
            className='text-sm'
            onClick={() => removeFromCart(cartItem)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <p className='text-sm text-gray-500 my-2'>{shopName}</p>
        <div className='flex flex-col lg:flex-row lg:items-center mt-5'>
          <div className='w-full flex flex-row gap-x-2 items-center'>
            <p className='text-gray-500 text-xs'>Quantity: </p>
            <QuantitySelect cartItem={cartItem} quantity={cartItem.quantity} />
          </div>
          <p className='my-1 lg:text-sm'>{formatCurrency(cartItem.total_cents)}</p>
        </div>
      </div>
    </>
  )
}

export default CartItem
