import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'

import CrossableModal from '../../../merchants/shared_components/crossableModal';
import BgModalFooter from '../../../../../assets/images/bg-modal-footer.png';

const SignInModal = ({ title, children, onClose }) => {

  return (
    <CrossableModal
      title={title} onClose={onClose}
      footer={
        <div className='relative h-12 flex items-center bottom-0'>
          <img className='absolute w-full h-full' src={BgModalFooter} />
          <div className='relative flex ml-auto mr-auto'></div>
        </div>}>
      {children}
    </CrossableModal>
  )
}

export default SignInModal;
