import React from 'react'
import CategoryLink from '../../../page_body/shared_components/categoryLink'

export default function MobileSubCategoryItem({ subcategory, setVisible }) {
  return (
    <div>
      <div className='flex flex-row justify-end'>
        <CategoryLink
          category={subcategory}
          className='py-2 w-5/6 text-sm cursor-pointer whitespace-no-wrap text-medium-grey hover:text-mustard-400 cursor-pointer md:mr-4 mr-2'>
          <span onClick={setVisible}>{subcategory.name}</span>
        </CategoryLink>
      </div>
    </div>
  )
}
