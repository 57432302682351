import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeOrderDetailReadable } from '../../../../utilities/purchaseHistoryUtility';
import Divider from '../../divider';
import NavigationBar from '../navigationBar';
import { AddressDetails, ItemUI, OrderInfo, RefundModal } from './details';

const PurchaseHistoryDetails = () => {
  const { id } = useParams();
  const history = useHistory();

  const [order, setOrder] = useState(null);
  const [isFetchingOrder, setIsFetchingOrder] = useState(true);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  useEffect(() => {
    fetchOrder();
  }, [id]);

  const fetchOrder = () => {
    setIsFetchingOrder(true);
    axios.get(`/users/orders/${id}.json`)
      .then(response => {
        setOrder(makeOrderDetailReadable(response.data));
      }).finally(() => {
        setIsFetchingOrder(false);
      })
  }

  const onBack = () => {
    history.goBack();
  }

  return (
    <div className='w-full mx-auto'>
      <NavigationBar activeKey='/users/orders' />
      <section className='lg:w-4/5 xl:w-2/3 w-full px-4 mx-auto md:my-12 my-6'>
        <button onClick={onBack} className='lg:absolute relative flex flex-row lg:my-0 my-4 items-center'>
          <FontAwesomeIcon className='text-gray-500 w-3 h-3' icon={faArrowLeft} />
          <span className='font-light text-sm text-gray-500 ml-3'>Back to Purchase History</span>
        </button>
        {
          order &&
          <div className='grid grid-cols-1 gap-y-4 justify-items-center'>
            <div className='border rounded-md border-beige-200 p-10 grid grid-cols-1 gap-y-3 lg:w-1/2 md:w-3/4 w-full'>
              {order.items.map(item => <ItemUI item={item} />)}
              <Divider className='h-px px-2 my-4 bg-beige-200' />
              <OrderInfo data={order} />
              <Divider className='h-px px-2 mt-6 mb-4 bg-beige-200' />
              <AddressDetails data={order.deliveryAddress} />
            </div>
            <button onClick={() => { setIsRefundModalOpen(true) }} className='mt-4 px-10 py-3 bg-yellow-600 rounded-full flex items-center'>
              <span className='w-full inline-block text-sm text-white text-center'>Refund</span>
            </button>
            {isRefundModalOpen && <RefundModal items={order.items} onClose={() => { setIsRefundModalOpen(false) }} />}
          </div>
        }
      </section>
    </div>
  )
}

export default PurchaseHistoryDetails;
