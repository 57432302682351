import { format } from 'date-fns';
import { formatPhoneNumber } from 'react-phone-number-input'
import { formatCurrency } from './formatPrice';

const extractDeliveryAddress = (order) => {
  const addressString = order.delivery_address;
  const addressStringSplit = addressString.split('\n');

  const [profileName, phoneNumber] = addressStringSplit[0].split(',');
  const shippingAddress = addressStringSplit[2].split(',').filter((value) => { return value.trim() !== '' }).join(',');

  return {
    profileName,
    phoneNumber: formatPhoneNumber(phoneNumber),
    shippingAddress
  }
}

const makeOrderEssentialsReadable = (order) => {
  return {
    orderNumber: order.id,
    date: format(new Date(order.updated_at), 'dd.MM.yyyy'),
    grandTotal: formatCurrency(order.grand_total),
    subTotal: formatCurrency(order.sub_total),
    shipping: formatCurrency(order.shipping),
    taxes: formatCurrency(order.taxes)
  }
}

export const makeOrderTableReadable = (order) => {
  const primaryItem = order.order_items[0];

  return {
    ...makeOrderEssentialsReadable(order),
    primaryItem: primaryItem,
    seller: primaryItem.shop_name,
    totalItems: order.order_items.length,
  }
}

export const makeOrderDetailReadable = (order) => {
  return {
    ...makeOrderEssentialsReadable(order),
    items: order.order_items,
    deliveryAddress: extractDeliveryAddress(order)
  }
}

export const doOrdersExist = (orders) => {
  return !!(orders && orders.length);
}
