import React, { useContext, useState } from 'react'
import axios from "axios"
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { CitiesContext } from "../../../contexts/citiesContext";

const CityAutoComplete = () => {
  const { setCity } = useContext(CitiesContext)
  const [address, setAddress] = useState('')

  const onChangeHandler = address => {
    setAddress(address)
  }

  const onSelectHandler = address => {
    geocodeByAddress(address)
      .then(results =>
        axios
          .put('/cities/set_city.json', results[0])
          .then(res => {
            setCity(res.data)
            window.location.href = `/`
          })
      )
      .catch(error => console.error('Error', error))
  }

  return (
    <PlacesAutocomplete
      value={address}
      onChange={onChangeHandler}
      onSelect={onSelectHandler}
      searchOptions={{ types: ['(regions)'], componentRestrictions: { country: ['ca', 'us'] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search...',
              className: 'appearance-none border border-gray-300 w-full py-3 px-3 text-gray-700 leading-tight text-sm rounded',
            })}
          />
          <div className="py-2">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              return (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion)}
                  className='cursor-pointer p-2 hover:bg-gray-200'
                >
                  <span className='text-sm leading-tight text-gray-600'>{suggestion.description}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default CityAutoComplete
