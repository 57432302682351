import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomCarousel from "../customCarousel";
import carouselBreakpoints from "../../../../../utilities/carouselBreakpoints";
import { LargeProductCardSkeleton } from "./skeletonHelpers";
import { SmallProductCardSkeleton } from "./skeletonHelpers";

const DiscoverProductsSkeleton = () => {
  const MobileDiscoverProductsSkeleton = () => {
    return (
      <div className="grid grid-cols-2">
        {[0, 1, 2].map(
          (product, index) =>
            index < 4 && (
              <div className="px-2 py-2">
                <SmallProductCardSkeleton
                />
              </div>
            )
        )}
        <div className="flex justify-tems-center col-span-2 py-10">
          <a

            className="border border-black opacity-50 w-full px-32 py-3 rounded-full "
          >
            <Skeleton />
          </a>
        </div>
      </div>
    );
  };
  return (
    <section id="discover" className="py-6 px-4 md:px-8">
      <h2 className="flex flex-row py-4 px-2 text-2xl lg:text-4xl">
        <Skeleton />
      </h2>
      <div className={`text-gray-900`}>
        <div className='hidden md:block'>
          {(
            <CustomCarousel
              breakpoints={carouselBreakpoints(4, 4, 3, 1)}
            >
              {[0, 1, 2].fill(4).map((product) => (
                <div className="px-3 ">
                  <LargeProductCardSkeleton />
                </div>
              ))}
            </CustomCarousel>
          )}
        </div>
        <div className='block md:hidden'>
          <MobileDiscoverProductsSkeleton />
        </div>
      </div>
    </section>
  )
}

export default DiscoverProductsSkeleton
