import React, { useEffect, useState } from 'react'
import { formatPrice } from '../../../../utilities/formatPrice'
import CountryIcon from '../shared_components/countryIcon'
import ProductFavorite from '../shared_components/productFavorite'
import missingImage from '../../../../../assets/images/missing-image-ecee8bb0.png'
import { Link } from 'react-router-dom'

const WideProductCard = ({ product }) => {
  let [productImage, setProductImage] = useState(null)
  const transitionGrowClass =
    'transition duration-500 ease-in-out transform hover:scale-105'

  useEffect(() => {
    if (product?.thumbnail_image_url) {
      setProductImage(product.thumbnail_image_url)
    } else {
      setProductImage(missingImage)
    }
  }, [product])


  return (
    product && (
      <Link
        className={` ${transitionGrowClass}`}
        to={`${product.path}${location.search}`}
      >
        <div style={{backgroundImage: `url('${productImage}')`}} className='border-2 h-64 bg-cover bg-center p-8 rounded-xl transition duration-500 ease-in-out transform hover:scale-105 '>
        </div>
          <div className='flex justify-between pt-2 text-gray-700'>
          <div className='flex flex-col'>
            <div className='font-semi-bold'>{product.title}</div>
          <div className='text-sm'>{product.shop_name}</div>
              <div className='font-bold'>{product?.product_variant && formatPrice(product.product_variant.price_cents)}</div>
          </div>
            {product.is_made_locally && (
              <CountryIcon countrySlug={product.city.country.slug} />
            )}

            <ProductFavorite product={product}/>
          </div>
          <div className='font-semi-bold text-xs'>{product.city.name}</div>

      </Link>
    )
  )
}

export default WideProductCard
