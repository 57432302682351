import { toast } from "react-toastify";

export const handleAPIError = (error, redirectTo = '') => {
  if (!error.response)
    return;

  const redirectQuery = !redirectTo || redirectTo === '' ? '' : `?return_to=${redirectTo}`;

  if (error.response.status === 401) {
    toast.success('Please sign in to continue.');
    setTimeout(() => {
      window.location.href = `/users/sign_in${redirectQuery}`;
    }, 1000)
  }
  else {
    toast.error(error.response.data.error);
  }
}
