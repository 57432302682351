import React, { useState } from "react";
import { ProductImage } from "../shared_components/product_image";
import ToggleSwitch from "../shared_components/toggleSwitch";
import DeleteButton from "../shared_components/deleteButton";
import AnalyticsButton from "../shared_components/analyticsButton";
import CategoryEdit from "./subComponents/categoryEdit";
import MadeLocallyToggle from "./madeLocallyToggle";
import { ShopIcon } from "../../../integrations_dashboard/shared_components/shopIcon";

const IntegrationProductRow = ({
  product,
  categories,
  productsState,
  setProductsState,
  integration,
  isSelected,
  selectProduct,
  deSelectProduct,
  rowClassName,
  labelClassName,
  isMobile
}) => {
  const [listed, setListed] = useState(product.is_listed);
  const opacityLevel = listed ? "" : "opacity-50";

  const removeProduct = (id) => {
    setProductsState(
      productsState.filter((currentProduct) => currentProduct.id !== id)
    );
  };

  const toggleSelectProduct = () => {
    if (!isSelected) {
      selectProduct(product);
    } else {
      deSelectProduct(product);
    }
  };

  const EditIcon = () => {
    return (
        <div title="Edit Product">
          <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M12.2501 5.25043C12.0974 5.40621 12.0119 5.61564 12.0119 5.83376C12.0119 6.05189 12.0974 6.26132 12.2501 6.4171L13.5835 7.75043C13.7392 7.90312 13.9487 7.98865 14.1668 7.98865C14.3849 7.98865 14.5943 7.90312 14.7501 7.75043L17.8918 4.60876C18.3108 5.53476 18.4377 6.56646 18.2555 7.56639C18.0733 8.56631 17.5907 9.48697 16.872 10.2057C16.1533 10.9244 15.2327 11.407 14.2327 11.5891C13.2328 11.7713 12.2011 11.6445 11.2751 11.2254L5.51679 16.9838C5.18527 17.3153 4.73563 17.5015 4.26679 17.5015C3.79795 17.5015 3.34831 17.3153 3.01679 16.9838C2.68527 16.6522 2.49902 16.2026 2.49902 15.7338C2.49902 15.2649 2.68527 14.8153 3.01679 14.4838L8.77512 8.72543C8.35609 7.79944 8.22922 6.76774 8.41141 5.76781C8.5936 4.76788 9.0762 3.84723 9.79489 3.12853C10.5136 2.40984 11.4342 1.92724 12.4342 1.74505C13.4341 1.56286 14.4658 1.68973 15.3918 2.10876L12.2585 5.2421L12.2501 5.25043Z"
                stroke="#383838"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
          </svg>
        </div>
    );
  }

  const MobileProductRows = () => {
    return (
      <div className="flex flex-col px-5 mb-3 gap-3">
        <div className="flex flex-row items-center">
          <input
            type="checkbox"
            className="h-5 w-6"
            checked={isSelected}
            onChange={() => toggleSelectProduct()}
          />
          <ProductImage product={product} className="h-10 ml-5" />
          <div className="flex flex-row justify-between w-full">
          <span className="md:inline ml-4 text-base font-medium text-gray-800 max-w-xs">
            {product.title}
          </span>
        <ShopIcon shop={integration} className="text-lg" />
        
          </div>
          
        </div>
        <CategoryEdit
          className="w-full"
          categories={categories}
          integration={integration}
          product={product}
        />
        <div>
          <table className="w-full">
              <tr >
                <th className="uppercase text-xs text-gray-700 px-2 opacity-75 text-left">
                  Action
                </th>
                <th className="uppercase text-xs text-gray-700 px-2 opacity-75 text-left">
                  Listed
                </th>
              </tr>

            <tr>
              <td className={labelClassName}>
                <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
                  {integration.city && (
                    <MadeLocallyToggle
                      integration={integration}
                      product={product}
                      iconClassName="text-lg"
                    />
                  )}
                  <a
                    href={`/merchants/integrations/${integration.id}/products/${product.id}/edit`}
                  >
                  <EditIcon />
                  </a>
                  <AnalyticsButton product={product} />
                  <DeleteButton
                    removeItem={() => removeProduct(product.id)}
                    deletionURL={`/merchants/integrations/${integration.id}/products/${product.id}`}
                    header={`Delete ${product.title}?`}
                    warningMessage="This action will delete the product permanently. Are you sure you want to continue?"
                  />
                </div>
              </td>
              <td className={labelClassName}>
                <ToggleSwitch
                  toggled={listed}
                  setToggled={setListed}
                  attribute="is_listed"
                  url={`/merchants/integrations/${integration.id}/products/${product.id}`}
                />
              </td>
            </tr>
          </table>
          <div className="border"/>
        </div>
      </div>
    );
  };

  return (
    (isMobile && 
<MobileProductRows />
    )
     || (
      <tr
        className={`cursor-pointer text-sm ${opacityLevel} items-center ${rowClassName}`}
        key={product.id}
      >
        <td className={labelClassName}>
          <div className="flex justify-center align-center">
            <input
              type="checkbox"
              className="h-5 w-6"
              checked={isSelected}
              onChange={() => toggleSelectProduct()}
            />
          </div>
        </td>
        <td colSpan={2} className={labelClassName}>
          <div className="flex md:flex-row flex-col md:items-center md:mr-12">
            <ProductImage product={product} className="h-10" />
            <span className="md:inline hidden ml-4 text-base font-medium text-gray-800 max-w-xs">
              {product.title}
            </span>
          </div>
        </td>
        <td className={`hidden md:table-cell ${labelClassName}`}>
          <CategoryEdit
            categories={categories}
            integration={integration}
            product={product}
          />
        </td>
        <td className={`hidden md:table-cell ${labelClassName}`}>
          <ShopIcon shop={integration} className="text-lg" />
        </td>
        <td className={labelClassName}>
          <div className="grid grid-flow-col auto-cols-auto lg:gap-x-px gap-x-1">
            {integration.city && (
              <MadeLocallyToggle
                integration={integration}
                product={product}
                iconClassName="text-lg"
              />
            )}
            <a
              href={`/merchants/integrations/${integration.id}/products/${product.id}/edit`}
            >
              <div title="Edit Product">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.2501 5.25043C12.0974 5.40621 12.0119 5.61564 12.0119 5.83376C12.0119 6.05189 12.0974 6.26132 12.2501 6.4171L13.5835 7.75043C13.7392 7.90312 13.9487 7.98865 14.1668 7.98865C14.3849 7.98865 14.5943 7.90312 14.7501 7.75043L17.8918 4.60876C18.3108 5.53476 18.4377 6.56646 18.2555 7.56639C18.0733 8.56631 17.5907 9.48697 16.872 10.2057C16.1533 10.9244 15.2327 11.407 14.2327 11.5891C13.2328 11.7713 12.2011 11.6445 11.2751 11.2254L5.51679 16.9838C5.18527 17.3153 4.73563 17.5015 4.26679 17.5015C3.79795 17.5015 3.34831 17.3153 3.01679 16.9838C2.68527 16.6522 2.49902 16.2026 2.49902 15.7338C2.49902 15.2649 2.68527 14.8153 3.01679 14.4838L8.77512 8.72543C8.35609 7.79944 8.22922 6.76774 8.41141 5.76781C8.5936 4.76788 9.0762 3.84723 9.79489 3.12853C10.5136 2.40984 11.4342 1.92724 12.4342 1.74505C13.4341 1.56286 14.4658 1.68973 15.3918 2.10876L12.2585 5.2421L12.2501 5.25043Z"
                    stroke="#383838"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </a>
            <AnalyticsButton product={product} />
            <DeleteButton
              removeItem={() => removeProduct(product.id)}
              deletionURL={`/merchants/integrations/${integration.id}/products/${product.id}`}
              header={`Delete ${product.title}?`}
              warningMessage="This action will delete the product permanently. Are you sure you want to continue?"
            />
          </div>
        </td>
        <td className={labelClassName}>
          <ToggleSwitch
            toggled={listed}
            setToggled={setListed}
            attribute="is_listed"
            url={`/merchants/integrations/${integration.id}/products/${product.id}`}
          />
        </td>
      </tr>
    )
  );
};

export default IntegrationProductRow;
