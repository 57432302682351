import React from "react";
import { faImages } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ProductImage = ({ product, className }) => {
  return (
    (product.product_images && product.product_images.length > 0) ?
      <img
        className={`w-10 rounded object-cover ${className}`}
        src={product.product_images[0]}
        alt={product.type === "InstagramProduct" ? "Instagram image URL expired" : `Image for ${product.title}`}
      />
      :
      <FontAwesomeIcon className="mr-2" icon={faImages} />
  )
}
