import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ShopInfoCardSkeleton } from "./skeletonHelpers";

const ShopsGridSkeleton = () => {
  return (
    <>
      <div className='flex flex-col lg:flex-row w-full justify-between items-center'>
        <h1 className='mb-2 md:mb-4 text-3xl'><Skeleton /></h1>
      </div>
      <div
        className='w-full text-gray-900 antialiased justify-items-auto gap-x-1 gap-y-1 md:gap-x-4 md:gap-y-4'>
        {new Array(10).fill(2).map((shop) => (
          <div className='py-1'><ShopInfoCardSkeleton /></div>
        ))}
      </div>
    </>
  )
}

export default ShopsGridSkeleton
