import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import MyProductsSelectStyle from "../../../../../../constants/custom_styles/myProductsSelectStyle";
import { MadeLocallyFilter } from "../../../../../../constants/dropdown_options/productFilters";
import { ProductSortOptions } from "../../../../../../constants/dropdown_options/productSortOptions";
import {FilterIcon} from "./filter_icon";
import SearchSelectModal from "./SearchSortModal"

const SearchAndSort = ({
  currentParams,
  reqProduct,
  categories,
  searchQuery,
  tableCategory,
  sortbyOrder,
  filterByFeature,
  setSearchQuery,
  setSearchEmpty,
  setSortbyOrder,
  setTableCategory,
  setFilterByFeature,
  setCanada,
}) => {
  const [productFilters, setProductFilters] = useState([]);

  useEffect(() => {
    fetchFilters();
  }, []);

  const fetchFilters = () => {
    axios.get("/feature_tags.json").then((response) => {
      const filters = response.data.map((filter) => {
        return {
          label: filter.name,
          value: filter.name,
        };
      });
      setProductFilters([MadeLocallyFilter, ...filters]);
    });
  };

  const updateQuery = (searchParam) => {
    let params = {
      ...currentParams,
      search: searchParam,
    };
    reqProduct(params);
  };

  const delayedQuery = React.useCallback(debounce(updateQuery, 500), []);

  const changeHandler = (e) => {
    setSearchQuery(e.target.value);
    setSearchEmpty(e.target.value !== "");
    delayedQuery(e.target.value);
  };

  const changeSortOrder = (selectedOption) => {
    setSortbyOrder(selectedOption);
    let params = {
      ...currentParams,
      order: selectedOption ? selectedOption.value : "",
    };
    reqProduct(params);
  };

  const catList = categories.map((category) => {
    return { label: category.dropdown_name, value: category.id };
  });

  const changeCategory = (selectedOption) => {
    setSearchEmpty(false);
    setTableCategory(selectedOption);

    const params = {
      ...currentParams,
      category_id: selectedOption ? selectedOption.value : "",
    };

    reqProduct(params);
  };

  const changeFilter = (selectedOption) => {
    const isMadeLocallySelected =
      selectedOption && selectedOption.value === MadeLocallyFilter.value;

    setSearchEmpty(false);
    setFilterByFeature(selectedOption);
    setCanada(isMadeLocallySelected);

    const paramAddition = {feature_tag: isMadeLocallySelected && "" ||  selectedOption && selectedOption.value || "", canada_products: isMadeLocallySelected || false}


    const params = {
      ...currentParams,
      ...paramAddition,
    };

    reqProduct(params);
  };


  const [isOpened, setIsOpened] = useState(false);

  const toggleModal = () => {
    setIsOpened(!isOpened);
  };


  const SortDropdowns = ({modalClass}) => {
    
    return (
      <div className={modalClass}>
        <Select
          className="xl:w-96"
          components={{ IndicatorSeparator: () => null }}
          styles={MyProductsSelectStyle}
          isClearable={false}
          placeholder={"Category"}
          onChange={changeCategory}
          value={tableCategory}
          options={catList}
        />
        <Select
          className=" lg:w-48"
          components={{ IndicatorSeparator: () => null }}
          styles={MyProductsSelectStyle}
          isClearable={false}
          placeholder={"Sort By"}
          onChange={changeSortOrder}
          value={sortbyOrder}
          options={ProductSortOptions}
        />
        <Select
          className="lg:w-48"
          components={{ IndicatorSeparator: () => null }}
          styles={MyProductsSelectStyle}
          isClearable={false}
          placeholder={"Filter By"}
          onChange={changeFilter}
          options={productFilters}
          value={filterByFeature}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-cols-1 md:flex-cols-4 justify-between gap-2 lg:gap-x-4 gap-y-4 items-center rounded-t-xl bg-white p-4">
      <div className="w-64 border border-mustard-200 border-opacity-50 flex flex-row rounded-lg p-2">
        <button className="px-1 focus:outline-none hover:text-gray-400">
          <FontAwesomeIcon
            icon={faSearch}
            className="text-base text-base text-medium-grey mt-1"
          />
        </button>
        <input
          className="w-full px-2 font-light focus:outline-none placeholder-medium-grey"
          type="text"
          value={searchQuery}
          onChange={changeHandler}
          placeholder="Search Product"
          title="Type in a name"
        />
      </div>
      <SortDropdowns modalClass={"hidden lg:flex flex-row gap-5"} />

      <div className="block lg:hidden px-4 justify-between w-28 items-center border border-mustard-200 border-opacity-50 flex flex-row rounded-lg p-2" onClick={toggleModal}>
        {isOpened && <SearchSelectModal onClose={toggleModal}>
          <SortDropdowns modalClass="flex flex-col gap-3 text-left px-4"/>
        </SearchSelectModal>}
        <FilterIcon />
        <h6>Filter</h6>
      </div>
    </div>
  );
};

export default SearchAndSort;
