import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons'
import { faShopify, faWix } from '@fortawesome/free-brands-svg-icons'
import IcWooCommerce from '../../../../assets/images/static_content_images/woo_commerce/logo.png';
import IcNoIntegration from '../../../../assets/images/static_content_images/no-integration-logo.png';

const AddShopItem = ({ url, icon, name, beta = false, text }) => {
  return (
    <div
      className='flex flex-col cursor-pointer w-full'
      onClick={() => (window.location.href = url)}>
      <div
        className='flex flex-col items-center p-4 rounded-xl bg-white w-full overflow-hidden rounded-lg text-gray-800 border-3 border-mustard-light'>
        <div
          className='flex items-center px-2 pb-2 rounded-xl bg-white w-full overflow-hidden rounded-lg'>
          <div className='relative flex items-center space-x-3 '>
            <div
              className='flex leading-normal px-3 mr-3 w-16 h-16  justify-center items-center text-4xl rounded-2xl'>
              {icon}
            </div>
          </div>
          <div className='flex-grow'>
            <div className='flex items-center flex-row'>
              <div className='text-xl mr-4'>{name}</div>
              {beta && (
                <span className='rounded-full px-4 py-1 text-black bg-beige-200 text-sm font-light'>Beta</span>
              )}
            </div>
          </div>
          <div className='mr-4'>
            <FontAwesomeIcon
              icon={faChevronRight}
              className='text-md text-gray-800'
            />
          </div>
        </div>
        {
          text &&
          <div className='w-full'>
            <div className='h-px mx-4 my-2 py-px bg-mustard-light'></div>
            <p className='border-gray-400 pt-4 pb-2 mx-4 text-lg text-gray-600 font-light'>{text}</p>
          </div>
        }
      </div>
    </div>
  )
}

const AddShops = () => {
  return (
    <div className='bg-white shadow-md justify-center rounded-3xl p-5'>
      <h2 className='font-semibold mt-8 text-3xl text-center'>
        Connect to Local Shops &amp; <br /> Select Your Ecommerce Store{' '}
      </h2>
      <div className='flex flex-col items-center w-full md:px-20 py-12 gap-6'>
        <AddShopItem
          url={process.env.SHOPIFY_URL}
          name='Shopify'
          icon={<FontAwesomeIcon icon={faShopify} className='text-green-700' />}
          text='Install the free Shopify app to automatically connect your catalog and tags'
        />
        <AddShopItem
          url={process.env.WIX_URL}
          name='Wix'
          icon={<FontAwesomeIcon icon={faWix} className='text-black' />}
          beta={true}
          text='Install the free Wix app to automatically connect your catalog and tags'
        />
        <AddShopItem
          url='/merchants/woocommerce_integrations/new'
          name='WooCommerce'
          icon={<img className='w-12 h-auto absolute' src={IcWooCommerce} />}
          beta={true}
          text='Install the free WooCommerce app to automatically connect your catalog and tags'
        />
        <AddShopItem
          url='/merchants/integrations/new'
          name='No Integration'
          icon={<img className='w-12 h-auto absolute' src={IcNoIntegration} />}
        />
      </div>
    </div>
  )
}

export default AddShops
