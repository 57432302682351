import React from 'react'
import TableHeader from './table_components/tableHeader'
import OrderRow from "./table_components/orderRow"

const OrderListings = ({ orderIntegrations }) => {
  return (
    <>
      <table className='w-full'>
        <TableHeader
          labelClassName='uppercase text-xs text-gray-700 px-2 py-4 opacity-75 text-left'
        />
        <tbody>
        {orderIntegrations.map((orderIntegration) => {
          return <OrderRow
            rowClassName='odd:bg-white even:bg-beige-100 opacity-100 hover:opacity-90'
            labelClassName='px-2 py-4' orderIntegration={orderIntegration} />
        })}
        </tbody>
      </table>
    </>
  )
}

export default OrderListings
