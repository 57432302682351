import React from 'react'
import Logo from "../../../../assets/images/logo_black.png";
import { Link } from "react-router-dom";

export default function MadeLocalLogo() {
  return (
    <Link className='flex flex-row justify-center sm:p-0' to={`/`}>
      <img className='cursor-pointer h-auto w-24' src={Logo} alt='Local Shops' />
    </Link>
  )
}
