import React, { useEffect, useState } from 'react'
import axios from "axios";
import CrossableModal from '../crossableModal';
import AnalyticsRow from './analyticsRow';

const AnalyticsModal = ({ onClose, product }) => {
  const [analyticData, setAnalyticData] = useState(null)

  useEffect(() => {
    axios.get(`/merchants/product_stats/${product.id}`).then((response) => {
      setAnalyticData(response.data)
    })
  }, [])

  const getStoreDependentRow = () => {
    return (product.store_url) ? {
      label: 'Clicks to Buy Now',
      value: analyticData.click_buy_now_count
    } : {
      label: 'Clicks to Contact Seller',
      value: analyticData.click_buy_now_count
    }
  }

  const modalTitle = <h1 className='text-xl font-semibold my-2'>{`Product Stats for ${product.title}`}</h1>
  const storeDependentRow = analyticData ? getStoreDependentRow() : null;

  return (
    analyticData &&
    <CrossableModal title={modalTitle} onClose={onClose}>
      <div className='flex flex-col items-center'>
        <div className='w-full grid grid-cols-1 gap-y-4 p-4'>
          <AnalyticsRow label='Page Views' value={analyticData.view_count} />
          <div className='border-b'/>
          {storeDependentRow && <AnalyticsRow label={storeDependentRow.label} value={storeDependentRow.value} />}
          <div className='border-b'/>
        </div>
        <button className='rounded-full my-4 px-10 py-3 bg-yellow-600 text-white' onClick={onClose}>Back</button>
      </div>
    </CrossableModal>
  )
}

export default AnalyticsModal;
