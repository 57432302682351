import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagramSquare,
  faLinkedinIn,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBrowser } from "@fortawesome/pro-solid-svg-icons";

const SocialMediaIcons = ({ icon, color }) => {
  const renderIcon = (socialMedia) => {
    switch (socialMedia) {
      case "facebook":
        return (
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4678 17.4375C20.8376 17.4375 21.1522 17.1679 21.2089 16.8024L21.7422 13.3639C21.7757 13.1475 21.713 12.9272 21.5705 12.7609C21.4281 12.5947 21.22 12.4989 21.001 12.4989H18.4169V11.0176C18.4169 10.658 18.507 10.3944 18.6512 10.2295C18.7787 10.0836 19.0396 9.90986 19.6259 9.90986H21.1416C21.5559 9.90986 21.8916 9.57408 21.8916 9.15986V6.2323C21.8916 5.86629 21.6275 5.55373 21.2665 5.49278L21.1416 6.2323C21.2665 5.49278 21.2665 5.49277 21.2664 5.49276L21.2663 5.49273L21.2658 5.49266L21.2646 5.49246L21.2607 5.4918L21.247 5.48954L21.1971 5.48152C21.1541 5.47473 21.0923 5.4652 21.0143 5.45384C20.8585 5.43114 20.6377 5.40106 20.3747 5.371C19.8518 5.31126 19.1481 5.25 18.451 5.25C16.9288 5.25 15.5866 5.70909 14.6233 6.66328C13.6583 7.61919 13.1605 8.98568 13.1605 10.6283V12.4989H10.8584C10.4442 12.4989 10.1084 12.8347 10.1084 13.2489V16.6875C10.1084 17.1017 10.4442 17.4375 10.8584 17.4375H13.1605V25C13.1605 25.4142 13.4963 25.75 13.9105 25.75H17.6669C18.0811 25.75 18.4169 25.4142 18.4169 25V17.4375H20.4678Z"
                stroke={color || "black"}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "instagram":
        return (
          <div>
            <svg
              width="20"
              height="22"
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1286_5060)">
                <path
                  d="M19.1879 7.06195L19.1878 7.06195L19.1882 7.06925C19.2803 8.62968 19.2803 13.3611 19.1882 14.9216L19.188 14.9267C19.1128 16.3692 18.7798 17.5178 17.8008 18.4601L17.7997 18.4611C16.8159 19.4117 15.6083 19.7377 14.0948 19.8067L14.0948 19.8067L14.088 19.8071C12.4635 19.8958 7.53989 19.8958 5.91535 19.8071L5.9106 19.8068C4.39647 19.7338 3.19102 19.4079 2.20155 18.4591C1.21913 17.5171 0.88638 16.3696 0.8155 14.9289L0.815536 14.9289L0.815106 14.9216C0.72309 13.3611 0.72309 8.62539 0.815106 7.06495L0.815123 7.06495L0.815391 7.05982C0.890624 5.6156 1.21989 4.46865 2.20155 3.52739C3.19071 2.57894 4.40013 2.25303 5.90869 2.18408L5.90869 2.18411L5.91535 2.18375C7.53989 2.095 12.4635 2.095 14.088 2.18375V2.18376L14.0928 2.18399C15.6079 2.25711 16.8165 2.58337 17.8008 3.5307L17.8018 3.53169C18.7842 4.47367 19.117 5.62126 19.1879 7.06195Z"
                  stroke={color || "black"}
                  strokeWidth="1.5"
                />
                <path
                  d="M5.625 10.9957C5.625 8.70386 7.55212 6.80859 10.0045 6.80859C12.4568 6.80859 14.3839 8.70386 14.3839 10.9957C14.3839 13.2875 12.4568 15.1828 10.0045 15.1828C7.55212 15.1828 5.625 13.2875 5.625 10.9957Z"
                  stroke={color || "black"}
                  strokeWidth="1.5"
                />
                <path
                  d="M15.2475 6.90508C15.8549 6.90508 16.3475 6.41664 16.3475 5.80508C16.3475 5.19762 15.8549 4.70508 15.2475 4.70508C14.64 4.70508 14.1475 5.19762 14.1475 5.80508C14.1475 6.41254 14.6359 6.90508 15.2475 6.90508Z"
                  fill={color || "black"}
                />
              </g>
              <defs>
                <clipPath id="clip0_1286_5060">
                  <rect width="20" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        );
      case "linkedin":
        return (
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9341 24.2511C11.3484 24.2511 11.6841 23.9153 11.6841 23.5011V11.8175C11.6841 11.4033 11.3484 11.0675 10.9341 11.0675H7.29031C6.8761 11.0675 6.54031 11.4033 6.54031 11.8175V23.5011C6.54031 23.9153 6.8761 24.2511 7.29031 24.2511H10.9341ZM21.1858 23.5011C21.1858 23.9153 21.5216 24.2511 21.9358 24.2511H25.5718H25.5758C25.99 24.2511 26.3258 23.9153 26.3258 23.5011V17.0831C26.3258 15.4964 26.1629 13.9164 25.4097 12.7253C24.6066 11.4551 23.2376 10.7745 21.2101 10.7745C20.0872 10.7745 19.1497 11.1103 18.43 11.6022C18.3375 11.2929 18.0508 11.0675 17.7114 11.0675H14.2166C13.8024 11.0675 13.4666 11.4033 13.4666 11.8175V23.5011C13.4666 23.9153 13.8024 24.2511 14.2166 24.2511H17.8565C18.2708 24.2511 18.6065 23.9153 18.6065 23.5011V17.7159C18.6065 16.9681 18.6849 16.3886 18.8946 16.01C18.9897 15.8383 19.1079 15.716 19.2628 15.6305C19.4222 15.5427 19.6647 15.4698 20.0414 15.4698C20.3891 15.4698 20.5829 15.5492 20.6972 15.6286C20.8128 15.7089 20.9118 15.8343 20.9918 16.0356C21.1693 16.4817 21.1858 17.103 21.1858 17.8136V23.5011ZM6.25 8.10264C6.25 9.67085 7.52474 10.9737 9.11027 10.9737C10.6953 10.9737 11.9705 9.67094 11.9705 8.10264C11.9705 7.34541 11.6684 6.61986 11.1316 6.08541C10.595 5.55107 9.86781 5.25146 9.11027 5.25146C8.35272 5.25146 7.62555 5.55107 7.0889 6.08541C6.55214 6.61986 6.25 7.34541 6.25 8.10264Z"
                stroke={color || "black"}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "youtube":
        return (
          <div>
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M26.8313 10.4171C26.5748 9.4657 25.8193 8.71637 24.86 8.46208C23.1211 8 16.1486 8 16.1486 8C16.1486 8 9.1761 8 7.43723 8.46208C6.47791 8.71641 5.72236 9.4657 5.46592 10.4171C5 12.1417 5 15.7397 5 15.7397C5 15.7397 5 19.3378 5.46592 21.0624C5.72236 22.0138 6.47791 22.7319 7.43723 22.9862C9.1761 23.4483 16.1486 23.4483 16.1486 23.4483C16.1486 23.4483 23.1211 23.4483 24.86 22.9862C25.8193 22.7319 26.5748 22.0138 26.8313 21.0624C27.2972 19.3378 27.2972 15.7397 27.2972 15.7397C27.2972 15.7397 27.2972 12.1417 26.8313 10.4171ZM13.8682 19.0065V12.473L19.6958 15.7398L13.8682 19.0065Z"
                stroke={color || "black"}
                strokeWidth="1.5"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      case "twitter":
        return (
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.0413 8.92759C25.1048 8.78914 25.161 8.6467 25.2094 8.50064C25.3073 8.20525 25.2126 7.88008 24.9714 7.68343C24.7302 7.48678 24.3926 7.4595 24.123 7.61488C23.5911 7.92137 23 8.15928 22.3812 8.31003C21.5972 7.64588 20.5729 7.25 19.4607 7.25C17.0306 7.25 15.0301 9.15395 15.0216 11.5473C12.53 11.2047 10.3377 9.96243 8.83058 8.16831C8.67492 7.98301 8.43942 7.88421 8.19815 7.90298C7.95688 7.92175 7.73949 8.05578 7.61436 8.26292C7.22707 8.90402 7.00379 9.64398 7.00379 10.4485C7.00379 11.1041 7.15422 11.7246 7.42238 12.2796C7.39653 12.2913 7.37118 12.3045 7.34648 12.3193C7.11977 12.4546 6.98094 12.6992 6.98094 12.9632V13.0073C6.98094 14.2633 7.52617 15.3804 8.39117 16.1635C8.26908 16.3529 8.23773 16.589 8.3097 16.806C8.67241 17.8995 9.46828 18.7922 10.4957 19.308C9.69552 19.6337 8.81609 19.8125 7.89086 19.8125C7.60091 19.8125 7.3445 19.8021 7.083 19.773C6.73471 19.7342 6.40598 19.9418 6.2913 20.273C6.17661 20.6041 6.30654 20.9705 6.60422 21.1555C8.35485 22.243 10.4333 22.8676 12.665 22.8676C19.9263 22.8676 23.9112 17.0396 23.9112 11.9816V11.9711C23.9112 11.9441 23.9112 11.916 23.9111 11.887C24.5635 11.3779 25.1379 10.7761 25.611 10.1114C25.8051 9.83875 25.795 9.47047 25.5863 9.20881C25.4498 9.03761 25.2498 8.93916 25.0413 8.92759Z"
              stroke={color || "black"}
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </svg>
        );
      default:
        return (
          <FontAwesomeIcon className="text-lg text-gray-700" icon={faBrowser} />
        );
    }
  };
  return (

      <div className={icon == "instagram" && "cursor-pointer pl-5 pr-2" || "cursor-pointer pl-3"}>{renderIcon(icon)}</div>

  );
};

export default SocialMediaIcons;
