 
import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle, faTimesCircle} from '@fortawesome/pro-solid-svg-icons'
import 'react-toastify/dist/ReactToastify.css';
import '../../css/toastify-success.css';
import '../../css/toastify-error.css';



export default function Notice({notice, alert}) {
  const successStyle = "text-white text-base "
  const alertStyle = "text-white text-base "


  const notify = () => {
    if (notice) toast.success(
      <div className='flex items-center'>
        <FontAwesomeIcon icon={faCheckCircle} />
        <p className='ml-1'>{notice}</p>
      </div>
    )
    else if (alert) toast.error(
      <div className='flex items-center'>
        <FontAwesomeIcon icon={faTimesCircle} />
        <p className='ml-1'>{alert}</p>
      </div>
    )
  };

  useEffect(() => {
    if (notice || alert) notify()
  }, [])

  return (
    <ToastContainer
      style={notice && { marginTop: '4em' }}
      bodyClassName={notice ? `${successStyle}` : `${alertStyle}`}
      position='top-center'
      autoClose={3000}
      hideProgressBar={true}
      closeOnClick={true}
    />
  )
}
