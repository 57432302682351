import { createContext } from 'react';

const ShippingFormContext = createContext({
  currencyPrefix: '',
  countries: [],
  hasCountries: false,
  hasInternationalShippings: false,
  getErrorsInInternationalShippingAt: null,
  updateInternationalShippingAt: null,
  deleteInternationalShippingAt: null,
  getPrefixedFieldName: null
})

export default ShippingFormContext;
