import { getParamByParam } from 'iso-country-currency';

export const getCountryCode = (countryName) => {
  const lookup = require('country-code-lookup');
  const country = lookup.byCountry(countryName);
  return !!country ? country.fips : null;
}

export const getCountryCurrencyCode = (countryName) => {
  return getParamByParam('countryName', countryName, 'currency')
}

export const sortShippingAddresses = (addresses) => {
  return addresses.sort((a, b) => { return b.is_default ? 1 : 0 })
}

export const updateFormikField = (formProps, name, value) => {
  formProps.values[name] = value;
  formProps.setFieldValue(name, value);
}

export const regionsAsDropdownItems = regions => {
  return regions.map(region => {
    return {
      label: region.name,
      value: region.id
    }
  })
}

export const getRegionById = (regions, id) => {
  return regions.find(region => { return region.id === id });
}

export const getCountryCodeById = (countries, id) => {
  const country = getRegionById(countries, id)
  return !country ? null : getCountryCode(country.name);
}
