import React, { useContext } from 'react';
import { CartContext } from '../../../../contexts/cartContext';
import CartItem from './cartItem';

const CartItems = () => {

  const { cart } = useContext(CartContext);
  const items = [];

  cart.cart_shops.forEach(shop => {
    items.push(...shop.cart_items.map(cartItem => (
      <div key={`cart_item_${cartItem.id}`} >
        <CartItem cartItem={cartItem} shopName={shop.integration.shop_name} />
        <hr className='my-3 bg-gray-300 h-0.5' />
      </div>
    )));
  })

  return items;
}

export default CartItems;
