import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ProductsLandingPage from './page_body/products/productsLandingPage'
import ProductPage from './page_body/products/productPage'
import ShopPage from './page_body/brands/shopPage'
import CategoryPage from './page_body/categories/categoryPage'
import ProductsSearchResults from './page_body/products/productsSearchResults'
import ShopBrowse from './page_body/brands/shopBrowse'
import DefaultMarket from '../../contexts/defaultMarketContext'
import CityContext from '../../contexts/citiesContext'
import ProductsBrowse from './page_body/products/productsBrowse'
import AllCategories from './page_body/categories/allCategories'
import SubCategoryPage from "./page_body/categories/subCategoryPage";
import CheckoutShippingViewUI from '../users/checkout/shipping/checkoutShippingViewUI'
import CheckoutPaymentUI from '../users/checkout/payment/checkoutPaymentUI'
import CheckoutShippingAddFormUI from '../users/checkout/shipping/checkoutShippingAddFormUI'
import CheckoutShippingEditFormUI from '../users/checkout/shipping/checkoutShippingEditFormUI'
import UserDashboard from '../users/dashboard/userDashboard'
import Favorites from './page_body/shared_components/favorites'
import FAQ from './footer/faq'
import Terms from './footer/terms_and_conditions'
import Privacy from './footer/privacy'
import ShippingInfo from './footer/shippingInfo'
import ReturnsAndExchanges from './footer/returnsAndExchanges'
import RefundPolicy from './footer/refundPolicy'
import CommunityMarkets from "./footer/communityMarkets"
import RetailCollective from "./footer/retailCollective"
import Warehousing from "./footer/warehousing";

function Cities() {

  return (
    <Switch>
      <Route path='/products/:product_slug'>
        <ProductPage />
      </Route>
      <Route path='/products'>
        <ProductsBrowse />
      </Route>
      <Route path='/search'>
        <ProductsSearchResults />
      </Route>
      <Route path='/shops/:integration_slug/:category_slug?'>
        <ShopPage  />
      </Route>
      <Route path='/shops'>
        <ShopBrowse />
      </Route>    
      <Route path='/categories'>
        <AllCategories />
      </Route>
      <Route path='/:parent_slug/:category_slug'>
        <SubCategoryPage />
      </Route>
      <Route path='/:category_slug'>
        <CategoryPage />
      </Route>
      <Route path='/'>
        <ProductsLandingPage />
      </Route>
    </Switch>
  )
}

function CheckoutRoutes() {
  return (
    <Switch>
      <Route exact path='/checkout/addresses'>
        <CheckoutShippingViewUI />
      </Route>
      <Route exact path='/checkout/addresses/new'>
        <CheckoutShippingAddFormUI />
      </Route>
      <Route path='/checkout/addresses/:id/edit'>
        <CheckoutShippingEditFormUI />
      </Route>
      <Route exact path='/checkout/payments'>
        <CheckoutPaymentUI />
      </Route>
    </Switch>
  )
}

function StaticRoutes() {
  return (
    <Switch>
      <Route exact path='/faq'>
        <FAQ />
      </Route>
      <Route exact path='/tos'>
        <Terms />
      </Route>
      <Route exact path='/privacy'>
        <Privacy />
      </Route>
      <Route exact path='/shipping-info'>
        <ShippingInfo />
      </Route>
      <Route exact path='/community-markets'>
        <CommunityMarkets />
      </Route>
      <Route exact path='/returns-policy'>
        <ReturnsAndExchanges />
      </Route>
      <Route exact path='/refund-policy'>
        <RefundPolicy />
      </Route>
      <Route exact path='/retail-collective'>
        <RetailCollective/>
      </Route>
      <Route exact path='/co-warehousing'>
        <Warehousing/>
      </Route>
    </Switch>
  )
}

export default function MainCities({ current_merchant, authenticity_token, current_user }) {
  return (
    <Router>
      <Switch>
        <Favorites current_user={current_user} authenticity_token={authenticity_token}>
          <CityContext current_merchant={current_merchant} current_user={current_user}>
            <StaticRoutes />
            <Route path='/checkout'>
              <CheckoutRoutes />
            </Route>
            <Route path={['/users', '/user']}>
              <UserDashboard />
            </Route>
            <Route path='/'>
              <DefaultMarket>
                <Cities current_merchant={current_merchant} authenticity_token={authenticity_token} />
              </ DefaultMarket>
            </Route>
          </CityContext>
        </Favorites>
      </Switch >
    </Router >
  )
}
