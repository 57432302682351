import React from 'react';
import { pluralizeString } from '../../../../../../utilities/stringManipulations';
import BulkCategoryEdit from './bulkCategoryEdit';
import BulkDeleteButton from './bulk_delete_button';

const BulkSelectRow = ({ selectedProducts, categories, integration }) => {
  return <div className='z-50 flex flex-col fixed inset-0 md:static shadow-xl md:shadow-none justify-end h-28 md:h-auto'><div className='bg-white rounded-t-xl flex flex-col sm:flex-row justify-between p-4 items-center'>
    <div className="flex items-center mt-2 mb-4 sm:my-0">
      <span className='text-black font-semibold text-base'>{pluralizeString(selectedProducts.length, 'product', 's')} selected</span>
    </div>
    {selectedProducts.length > 0 && (
      <div className='flex items-center'>
        <div className='flex flex-col items-center'>
          <BulkCategoryEdit
            categories={categories}
            integration={integration}
            products={selectedProducts}
          />
        </div>
        <div className='flex flex-col items-center w-full ml-4'>
          <BulkDeleteButton
            integration={integration}
            products={selectedProducts}
          />
        </div>
      </div>
    )}
  </div>
  </div>
}

export default BulkSelectRow;