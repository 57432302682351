import React from 'react';

const AnalyticsRow = ({ label, value }) => {
  return (
    <div className='flex justify-between'>
      <h2 className='text-sm font-light'>{label}</h2>
      <span className='text-sm font-light'>{value}</span>
    </div>
  )
}

export default AnalyticsRow;
