import React from "react";

const Button = ({ color, hover, children, onClick, props, textC, className }) => {
  let bgColor = color === undefined ? 'yellow-600' : color
  let bgHover = hover === undefined ? 'yellow-700' : hover
  let textColor = textC === undefined ? 'white' : textC


  return <button className={`rounded-full py-3 px-8 text-sm font-light ${className} text-${textColor} hover:bg-${bgHover} text-white bg-${bgColor}`} onClick={onClick} {...props}>
    {children}
  </button>
}

export default Button;
