import React from 'react';
import CategoryLink from '../../page_body/shared_components/categoryLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

export default function SubCategoryItem({ subcategory, topLevel = true, setSelectedSubcategory, selectedSubcategory }) {

  const categoryLinkCSS = !subcategory.children.length ? 'font-light' : 'font-semibold';

  return (
    <div className={` lg:inline-block cursor-pointer tracking-med text-sm`}>
      <CategoryLink
        category={subcategory}
        className={`capitalize ${categoryLinkCSS}` +
          `whitespace-no-wrap text-medium-grey hover:text-mustard-400 cursor-pointer md:mr-4 mr-2`}>
        {subcategory.name}
      </CategoryLink>
      <div className={`font-light w-auto flex flex-col flex-no-wrap mx-auto`}>
        {subcategory.children.length > 0 && subcategory.children.map(category =>
          <CategoryLink
            category={category}
            className={`capitalize mr-1 mx-2 font-light text-base my-1 hidden sm:block whitespace-no-wrap cursor-pointer hover:text-mustard-400`}>
            {category.name}
          </CategoryLink>
        )}
      </div>
    </div>
  );
}
