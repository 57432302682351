import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Header from '../markets/header'
import Footer from '../markets/footer'
import HeaderMain from '../main/header/header'
import FooterMain from '../main/footer/footer'
import PageNotFound from '../shared/pageNotFound'
import Cart from '../../contexts/cartContext'
import Checkout from '../users/checkout/checkoutContext'
export const MarketContext = React.createContext({
  market: null,
})

const Market = ({ current_merchant, current_user, children }) => {
  let { market_slug } = useParams()
  let query = new URLSearchParams(location.search);

  market_slug = market_slug ? market_slug : query.get('market_slug')

  let [market, setMarket] = useState(null)
  let [pageNotFound, setPageNotFound] = useState(false)

  useEffect(() => {
    axios
      .get(`/markets/${market_slug}.json`)
      .then((response) => setMarket(response.data))
      .catch(() => setPageNotFound(true))
  }, [])

  return (
    <MarketContext.Provider value={{ market: market }}>
      <Cart>
        <Checkout>
          {!pageNotFound ? (
            <div>
              <Header current_merchant={current_merchant} current_user={current_user} />
              {children}
              <Footer />
            </div>
          ) : (
            <div>
              <HeaderMain current_merchant={current_merchant} current_user={current_user} />
              <PageNotFound />
              <FooterMain />
            </div>
          )}
        </Checkout>
      </Cart>
    </MarketContext.Provider>
  )
}

export default Market
