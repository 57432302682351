import React from 'react'
import Logo from '../../../../assets/images/local-shops-logo.png'
import MerchantSignUp from "../page_body/callToActionBanners/merchantSignUp"
import FooterBanner from "../../../../assets/images/footer.svg"
import { About, Explore, Info, LetsTalk } from './index'
import { Link } from 'react-router-dom'
import Collective from "./collective"
import { useLocation } from 'react-router-dom'
import JoinTheCollective from "../page_body/callToActionBanners/joinTheCollective";
import BookATour from "../page_body/callToActionBanners/bookATour";

const Footer = () => {
  const location = useLocation()
  const whichLocation = (location) => {
    const isBackground = location.pathname === '/community-markets'
    switch (location.pathname) {
      case '/retail-collective':
        return <div className='w-full bg-light-orange'>
          <div className='lg:mx-20'><JoinTheCollective /></div>
        </div>
      case '/co-warehousing':
        return <div className='lg:mx-20'><BookATour /></div>
      default:
        return <div className={ isBackground && 'bg-light-orange w-full'}>
          <div className='lg:mx-20 pt-32'><MerchantSignUp /></div>
        </div>
    }
  }
  return (
    <>
      {whichLocation(location)}
      <div className='bg-light-orange'>
        <div
          style={{ backgroundImage: `url(${FooterBanner})` }}
          className='w-full h-full mt-6 md:-mt-40 pt-4 bg-light-orange'>
          <div className='flex flex-col w-full'>
            <div
              className='flex flex-col md:flex-row justify-evenly items-center lg:items-start lg:items-center md:pt-32'>
              <div className='flex flex-row items-start gap-x-4 mx-4 mt-12'>
                <img className='lg:mt-0 mt-8 ml-10' src={Logo} alt='local-shops-logo' />
                <div className='lg:hidden block'>
                  <LetsTalk />
                </div>
              </div>
              <div className='flex flex-row items-baseline mt-12'>
                <div className='lg:block hidden mr-8'>
                  <About />
                </div>
                <div className='lg:block hidden mr-8'>
                  <Explore />
                </div>
                <div className='lg:block hidden mr-8'>
                  <Info />
                </div>
                <div className='lg:block hidden mr-8'>
                  <Collective />
                </div>
                <div className='lg:block hidden mr-8'>
                  <LetsTalk />
                </div>
              </div>
            </div>
            <div className='pt-12 md:pt-18 pb-4'>
              <hr className='border border-white opacity-20' />
            </div>
            <div
              className=' flex flex-col lg:flex-row items-start gap-x-16 lg:gap-y-2 gap-y-6 text-gray-500 text-sm font-light py-4 lg:mx-20 mx-4'>
            <span className='cursor-pointer hidden lg:block text-white text-sm opacity-50 ml-10'>
              © 2022 Local Shops. All rights reserved
            </span>
              <section className='flex flex-row gap-x-6 text-sm text-white'>
                <Link to='/tos' className='hover:text-gray-200 '>
                  Terms of Service
                </Link>
                <Link to='/privacy' className='hover:text-gray-200 text-sm '>
                  Privacy Policy
                </Link>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
