import axios from 'axios';
import { Formik } from 'formik';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import RefundRequestInitialValues from '../../../../../../constants/form_initial_values/refundRequestInitialValues';
import RefundRequestValidation from '../../../../../../constants/form_validations/refundRequestValidation';
import FavoritesContext from '../../../../../../contexts/favoritesContext';
import CrossableModal from '../../../../../merchants/shared_components/crossableModal';
import RefundForm from './refundForm';

const RefundModal = ({ items, onClose }) => {

  const { token } = useContext(FavoritesContext);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    axios.post('/refunds', {
      authenticity_token: token,
      refund: values
    }).then(() => {
      toast.success('Refund request raised.');
      onClose();
    }).catch(error => {
      console.error(error);
      toast.error('Refund request could not be placed');
    }).finally(() => {
      setSubmitting(false);
    })
  }

  return (
    <CrossableModal
      title='Raise Refund Request' onClose={onClose}>
      <Formik
        initialValues={RefundRequestInitialValues}
        onSubmit={onSubmit}
        validationSchema={RefundRequestValidation}>
        {() => <RefundForm items={items} />}
      </Formik>
    </CrossableModal>
  )
}

export default RefundModal;
