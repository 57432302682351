import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CustomCarousel from "../customCarousel";
import carouselBreakpoints from "../../../../../utilities/carouselBreakpoints";
import { LargeProductCardSkeleton, SmallProductCardSkeleton } from "./skeletonHelpers";

const FeaturedBrandsSkeleton = () => {
  const MobileFeaturedBrandsSkeleton = () => {
    return (
      <div className="grid grid-cols-2 justify-items-center">
        {[0, 1, 2].map(
          (shop, index) => index < 4 && <SmallProductCardSkeleton/>
        )}
        <div className="col-span-2 py-4">
          <a
            className="border border-black opacity-50 w-full px-32 py-3 rounded-full"
          >
            <Skeleton />
          </a>
        </div>
      </div>
    );
  };
  return (
    <section id="shops" className="lg:p-8 md:py-8 md:px-1 py-8">
      <div className="flex flex-col lg:flex-row justify-between items-start whitespace-pre lg:items-center">
        <div className="flex flex-row">
          <div className="px-1 self-center">
          </div>
          <h2 className="text-3xl lg:text-4xl"><Skeleton /></h2>
        </div>

        <a
          className=" hidden md:block inline-block m-3 transition ease-in-out duration-500 transform hover:scale-105 hover:text-mustard-400"
        >
          <Skeleton />
        </a>
      </div>
      <div className='hidden md:block'>
        {(
          <CustomCarousel
            CarouselClass={"pb-10"}
            breakpoints={carouselBreakpoints(4, 4, 3, 1)}
            length="w-full"
          >
            {[0, 1, 2].fill(3).map((shop) => (
              <LargeProductCardSkeleton/>
            ))}
          </CustomCarousel>
        )}
      </div>
      <div className='block md:hidden'><MobileFeaturedBrandsSkeleton /></div>
    </section>
  )
}

export default FeaturedBrandsSkeleton
