import React, { useState } from 'react'
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-solid-svg-icons";
import InstagramImageItem from "./instagram_image_item"

export default function InstagramImagesDashboard({ image_data, integration_id }) {

  const [selected, setSelected] = useState([])

  const selectImage = (image_data) => {
    setSelected(currentlySelected => currentlySelected.concat([image_data]))
  }

  const deSelectImage = (image_data) => {
    setSelected(prevSelected => prevSelected.filter(image => image.id !== image_data.id))
  }

  const submitImages = () => {
    let selectedImageData = selected.map((image) => {
      return {
        id: image.id,
        description: image.caption,
        image_url: image.media_url
      }
    })

    axios({
      method: "POST",
      url: `/merchants/integrations/${integration_id}/instagram_products`,
      data: { instagram_products: selectedImageData },
      header: { "Content-Type": "application/json" }
    }).then(() => window.location.href = `/merchants/integrations/${integration_id}/products`)
  }

  return (
    <div>
      <div className="w-full flex justify-between items-end">
        <h1 className="text-4xl">Import from Instagram</h1>
        <sub>Select the images you would like to import as products to Local Shops.</sub>
        <div
          className={`focus:outline-none focus:shadow-none shadow-lg ${selected.length ? "hover:shadow-xl transition ease-in-out duration-300 transform hover:scale-103 bg-yellow-500" : "bg-gray-500 opacity-50"} text-white rounded block text-center py-2 px-4 rounded align-middle`}
          onClick={() => selected.length ? submitImages() : null}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Import Selected Products
        </div>
      </div>
      {image_data.length ? (
        <div
          className="text-gray-900 antialiased grid lg:grid-cols-5 p-4 md:grid-cols-3 grid-cols-1 ">
          {image_data.map((image, index) => (
            <InstagramImageItem
              key={index}
              image_data={image}
              selectImage={selectImage}
              deSelectImage={deSelectImage}
            />)
          )}
        </div>
      ) : <p className="text-2xl">No images found on your Instagram account</p>
      }
    </div>
  )
}

