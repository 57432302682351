import {useFormikContext} from 'formik';
import React, {useContext} from 'react';
import MobileCardRow from '../../shared_components/mobileCardRow';
import CountrySelect from '../shared/countrySelect';
import ShippingFormContext from '../shippingFormContext';
import ShippingCard from './shippingCard';

const InternationalShippingCards = () => {
    const {values} = useFormikContext();
    const {
        countries,
        currencyPrefix,
        hasCountries,
        hasInternationalShippings,
        getErrorsInInternationalShippingAt,
        updateInternationalShippingAt,
        deleteInternationalShippingAt
    } = useContext(ShippingFormContext);

    return (
        <>
            {
                hasCountries && hasInternationalShippings &&
                values.international_shippings.map((shipping, index) =>
                    !shipping._destroy &&
                    <ShippingCard
                        label='International'
                        name='flat_rate'
                        currencyPrefix={currencyPrefix}
                        values={shipping}
                        errors={getErrorsInInternationalShippingAt(index)}
                        enableDeletion={true}
                        enableActions={true}
                        getFieldName={partialFieldName => partialFieldName}
                        updateFormikValue={(fieldName, fieldValue) => {
                            updateInternationalShippingAt(index, fieldName, fieldValue)
                        }}
                        onDelete={() => deleteInternationalShippingAt(index)}
                    >
                        <CountrySelect
                            className='w-full text-left'
                            countries={countries}
                            countryId={shipping.country_id}
                            onChange={updatedId => {
                                updateInternationalShippingAt(index, 'country_id', updatedId);
                            }}
                        />
                    </ShippingCard>
                )
            }
        </>
    )
}

export default InternationalShippingCards;
