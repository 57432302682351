import React, { useState } from 'react'
import JoinLocalShopsModal from '../sign_up_modals/joinLocalShopsModal'
import SignInLocalShopsModal from "../sign_in_modals/signInLocalShopsModal"
import UserCircleIcon from './UserCircleIcon'

const LoginButton = () => {
  const [isJoinLocalShopsModalOpened, setIsJoinLocalShopsModalOpened] = useState(false);
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false);

  return (
    <>
      <div className='inline-block'>
        <div className='lg:w-full lg:flex lg:justify-end lg:mr-8'>
          <div className='lg:pb-1 w-full justify-center flex text-center'>
            <div className='flex items-center'>
              <div className='items-center hidden md:inline-block lg:mr-8'>
                <div className='flex items-center md:space-x-3 whitespace-pre'>
                  <button className='text-lg text-mustard-400 px-3 py-2 rounded-3xl' onClick={() => { setIsJoinLocalShopsModalOpened(true) }}>Sign Up</button>
                  <button className='text-lg bg-mustard-400 px-4 py-2 rounded-3xl text-white' onClick={() => { setIsSignInModalOpened(true) }}>Sign In</button>
                </div>
              </div>
              <button onClick={() => { setIsSignInModalOpened(true) }} className='block md:hidden'>
                <UserCircleIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isJoinLocalShopsModalOpened && <JoinLocalShopsModal onClose={() => { setIsJoinLocalShopsModalOpened(false) }} />}
      {isSignInModalOpened && <SignInLocalShopsModal onClose={() => { setIsSignInModalOpened(false) }} />}
    </>
  )
}

export default LoginButton;
