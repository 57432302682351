import React, { useState, createContext } from 'react'
import axios from 'axios'
import {spliceBaseUrl, includesMarketAdmin} from "../../../../utilities/paymentUtility"

export const PaypalConnectContext = createContext({
  isConnecting: false,
  connectPaypalToUser: null,
  getLoginWithPaypalLink: null,
  setCurrentIntegrationId: null,
  getCurrentIntegrationId: null
})

const PaypalConnect = ({ children }) => {
  const PAYPAL_PROCESSING_INTEGRATION_ID = 'PAYPAL_PROCESSING_INTEGRATION_ID';
  const [isConnecting, setIsConnecting] = useState(false);
  const baseUrl = spliceBaseUrl()
  const isMarketAdmin = includesMarketAdmin(baseUrl)

  const paypalURL = process.env.REACT_APP_PAYPAL_URL
  const paypalApiUrl = process.env.REACT_APP_PAYPAL_API_URL
  const paypalClientId = isMarketAdmin ? process.env.REACT_APP_PAYPAL_CLIENT_ID_MARKET_ADMINS : process.env.REACT_APP_PAYPAL_CLIENT_ID
  const paypalRedirectUrl = isMarketAdmin ? process.env.REACT_APP_PAYPAL_REDIRECT_URL_MARKET_ADMINS : process.env.REACT_APP_PAYPAL_REDIRECT_URL
  const paypalSecret = isMarketAdmin ? process.env.REACT_APP_PAYPAL_SECRET_MARKET_ADMINS : process.env.REACT_APP_PAYPAL_SECRET

  const setCurrentIntegrationId = id => {
    localStorage.setItem(PAYPAL_PROCESSING_INTEGRATION_ID, id);
  }

  const getCurrentIntegrationId = () => {
    return localStorage.getItem(PAYPAL_PROCESSING_INTEGRATION_ID);
  }

  const connectPaypalToUser = code => {
    setIsConnecting(true);

    return getPaypalAccessToken(code)
      .then(tokenResponse => {
        return getPaypalCustomerInfo(tokenResponse.data.access_token)
          .then(infoResponse => {
            const primaryEmail = infoResponse.data.emails.find(x => x.primary);
            const integrationId = getCurrentIntegrationId();

            if (!integrationId)
              throw new Error('Integration ID not provided');

            return updatePaypalEmailForIntegration(integrationId, primaryEmail.value);
          })
      })
      .finally(() => {
        setIsConnecting(false);
      })
  }

  const updatePaypalEmailForIntegration = (integrationId, email) => {
    // If admin, return a market object. Otherwise, return an integration object.
    const dataResponse = {
      [isMarketAdmin ? 'market' : 'integration']: {
        paypal_email: email
      }
    }

    return axios.put(
      `${baseUrl}/${integrationId}.json`,
      dataResponse,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
  }

  const getPaypalAccessToken = code => {
    return axios.post(`${paypalApiUrl}/v1/oauth2/token`, new URLSearchParams({
      grant_type: 'authorization_code',
      code: code
    }), {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: paypalClientId,
        password: paypalSecret
      }
    })
  }

  const getPaypalCustomerInfo = token => {
    return axios.get(`${paypalApiUrl}/v1/identity/oauth2/userinfo?schema=paypalv1.1`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
  }

  const getLoginWithPaypalLink = () => {
    return `${paypalURL}/signin/authorize?flowEntry=static&response_type=code&client_id=${paypalClientId}&scope=email&redirect_uri=${paypalRedirectUrl}`;
  }

  return (
    <PaypalConnectContext.Provider
      value={{
        isConnecting,
        connectPaypalToUser,
        getLoginWithPaypalLink,
        setCurrentIntegrationId,
        getCurrentIntegrationId
      }}>
      {children}
    </PaypalConnectContext.Provider>
  )
}

export default PaypalConnect;
