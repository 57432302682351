import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import ShippingFormContext from '../shippingFormContext';
import InternationalShippingCards from './internationalShippingCards';
import ShippingCard from './shippingCard';

const ShippingCards = ({ className, currencyPrefix, countries }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { hasInternationalShippings, getPrefixedFieldName } = useContext(ShippingFormContext);

  return (
    <div className={`grid grid-cols-1 gap-y-3 ${className}`}>
      <ShippingCard
        label='Local'
        name='local'
        currencyPrefix={currencyPrefix}
        values={values}
        errors={errors}
        enableActions={hasInternationalShippings}
        getFieldName={partialFieldName => getPrefixedFieldName('local', partialFieldName)}
        updateFormikValue={setFieldValue}
      />
      <ShippingCard
        label='Domestic'
        name='domestic'
        currencyPrefix={currencyPrefix}
        values={values}
        errors={errors}
        enableActions={hasInternationalShippings}
        getFieldName={partialFieldName => getPrefixedFieldName('domestic', partialFieldName)}
        updateFormikValue={setFieldValue}
      />
      <InternationalShippingCards currencyPrefix={currencyPrefix} countries={countries} />
    </div>
  )
}

export default ShippingCards;