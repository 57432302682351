import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import FulfillmentAlertUI from "./fulfillmentAlertUI";

const FulfillItemsAlert = ({ handleFulfillment, handleClick }) => {
  return (
    <div>
      {confirmAlert({
        customUI: ({ onClose }) => {
          return <FulfillmentAlertUI onClose={onClose} handleFulfillment={handleFulfillment} handleClick={handleClick} />
        }
      })}
    </div>
  )
}

export default FulfillItemsAlert
