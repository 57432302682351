import React from 'react'

const FulfillmentAlertUI = ({ onClose, handleClick, handleFulfillment }) => {
  return (
    <div className='w-full flex border border-gray-700 flex-col p-6 rounded-lg'>
      <h1 className='text-xl'>Do you wish to fulfill these items?</h1>
      <p className='text-base'>This will send an email to the buyer letting them know their items are on their
        way</p>
      <div className='flex items-center justify-end'>
        <div className='mr-2 my-2'>
          <button
            className='text-base text-mustard-400 px-3 py-2 rounded'
            onClick={() => {
              onClose()
              handleClick()
            }
            }
          >
            Back
          </button>
        </div>
        <div>
          <button
            className='text-base bg-mustard-400 px-4 py-2 rounded text-white'
            onClick={() => {
              handleFulfillment()
              onClose()
            }}
          >
            Fulfill Items
          </button>
        </div>
      </div>
    </div>
  )
}


export default FulfillmentAlertUI
