import React, { useState, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { MarketContext } from '../../markets/market';
import { useHistory } from 'react-router-dom';
import { CitiesContext } from '../../../contexts/citiesContext';

const Search = () => {
  const queryString = require('query-string');
  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  let parsedQuery = queryString.parse(location.search);
  let { market } = useContext(MarketContext);
  let { city } = useContext(CitiesContext);
  let history = useHistory();

  const search = () => {
    parsedQuery['search_params'] = searchValue;
    parsedQuery['city_slug'] = city?.slug;
    const market_slug = market?.slug;

    if (market_slug) {
      history.push(`/markets/${market_slug}/search?${queryString.stringify(parsedQuery)}`);
    } else {
      history.push(`/search?${queryString.stringify(parsedQuery)}`);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
  };

  return (
    <div className='relative'>
      <div className='flex xl:flex-row lg:w-full justify-center items-center text-gray-800'>
        
        <input
          ref={inputRef}
          className='px-6 py-2 text-lg w-28 md:w-48 bg-white md:border md:border-medium-grey rounded-lg focus:outline-none text-medium-grey lg:w-full lg:inline-block'
          type='text'
          value={searchValue}
          placeholder={"Search"}
          onChange={handleChange}
          onKeyPress={(event) => (event.which === 13 ? search() : '')}
        />
        <div className='text-gray-500 cursor-pointer p-0 pr-0 absolute items-center right-3'>
          <FontAwesomeIcon className='text-base text-medium-grey' onClick={() =>  search() } icon={faSearch} />
        </div>
      </div>
    </div>
  );
};

export default Search;
