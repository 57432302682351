import React, { useContext } from 'react'
import { doPayoutsExist, makePayoutReadable } from '../../../../utilities/payoutUtility'
import PaymentsContext from '../paymentsContext'
import MobileCard from './mobileCard'

const PaymentsTableMobile = () => {
  const { payouts } = useContext(PaymentsContext);
  return (
    <>
      {doPayoutsExist(payouts) && (
        <div className='w-full'>
          <div className='grid grid-cols-1 gap-y-2'>
            {payouts.map((payout) => (
              <MobileCard data={makePayoutReadable(payout)} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default PaymentsTableMobile
