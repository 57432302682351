import Products from "../products/products";
import ProductsGrid from "../products/productsGrid";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SubHeader from "../shared_components/subHeader";
import getData from "../../../../utilities/getData";
import { ParentSubCategories, SubCategories } from "../shared_components/subCategoryHeader";

const SubCategoryPage = () => {
  let { parent_slug, category_slug } = useParams();
  let [category, setCategory] = useState(null)
  let [parentCategory, setParentCategory] = useState(null)

  useEffect(() => {
    category_slug && getData(`/categories/${category_slug}.json`, (data) => (setCategory(data)))
  }, [category_slug])

  useEffect(() => {
    parent_slug && getData(`/categories/${parent_slug}.json`, (data) => (setParentCategory(data)))
  }, [category_slug, parent_slug])

  return (
    <Products>
      {category && parentCategory && <>
        <span className='hidden lg:block'><SubHeader breadcrumbs={category.name} link={category.permalink} /></span>
        <div className={"flex flex-col gap-1 px-2"}>
          <span className='block md:hidden text-2xl'>{parentCategory.name}</span>
          <h1 className='block md:hidden text-xl'>{parent_slug ? category.name : "All Products"}</h1>
        </div>

        <div className='p-2 md:p-8'>
          <div className='container mx-auto'>
            {category.children_with_products && category.children_with_products.length > 1 ?
              <div>
                <span className='hidden lg:block'><SubCategories category={category} /></span>
              </div>
              : <div>
                <span className='hidden lg:block'><ParentSubCategories category={parentCategory} /></span>
              </div>
            }
            <ProductsGrid subCategory={category} category={parentCategory} />
          </div>
        </div>
      </>
      }
    </Products>
  )
}

export default SubCategoryPage
