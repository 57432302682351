import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { appendCurrentCheckoutMode, CheckoutModes, getCheckoutModeFromQuery } from '../../../../utilities/checkoutUtility';
import { getCountryCurrencyCode } from '../../../../utilities/shippingUtility';
import { CartContext } from '../../../../contexts/cartContext';
import { CheckoutContext } from '../checkoutContext';
import CheckoutUI from '../checkoutUI';
import OrderPlacedUI from '../orderPlacedUI';
import PaypalPaymentBlockUI from './paypalPaymentBlockUI';

const CheckoutPaymentUI = () => {
  const { order, payForOrder, isFetchingOrder, isUpdatingOrder, hasOrderBeenPaidFor, clearOrder } = useContext(CheckoutContext);
  const { clearCart } = useContext(CartContext);

  const history = useHistory();

  useEffect(() => {
    resetScrollPosition();
  }, [])

  useEffect(() => {
    if (!isFetchingOrder && !isUpdatingOrder)
      handleOrderInconsistencies();
    return () => {
      if (hasOrderBeenPaidFor())
        clearOrder();
    }
  }, [order])

  const handleOrderInconsistencies = () => {
    if (!order || order.address === null)
      history.replace(appendCurrentCheckoutMode('/checkout/addresses'));
  }

  const resetScrollPosition = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  const onHandleSubmit = () => {
    history.replace('/');
  }

  const onHandlePaypalPayment = (paymentId, amount, currencyCode, status) => {
    payForOrder(order.id, paymentId, amount, currencyCode, status)
      .then(response => {
        toast.success('Order Placed!');
        if (getCheckoutModeFromQuery() === CheckoutModes.cart)
          clearCart();
      }).catch(error => {
        toast.error('Order could not be placed.');
      })
  }

  return (
    <CheckoutUI
      hasShippingBeenVisited={true}
      hasPaymentBeenVisited={true}
      disableSubmit={!hasOrderBeenPaidFor()}
      onSubmitClicked={onHandleSubmit}
      pageTitle='Payment'>
      {
        hasOrderBeenPaidFor() ?
          <OrderPlacedUI /> :
          <PayPalScriptProvider options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            "currency": order && order.address ? getCountryCurrencyCode(order.address.city.country.name) : "CAD"
          }}>
            <PaypalPaymentBlockUI handlePayment={onHandlePaypalPayment} />
          </PayPalScriptProvider>
      }
    </CheckoutUI>
  )
}

export default CheckoutPaymentUI;
