import React, { useEffect, useState } from 'react'
import Onboarding from '../../merchants/shared_components/onboarding'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, faPlusCircle, faSearch } from "@fortawesome/pro-solid-svg-icons";
import ReactPaginate from "react-paginate";
import LoadingSpinner from "../../main/page_body/shared_components/loadingSpinner";
import { formatPrice } from "../../../utilities/formatPrice";
import 'css/pagination.css'
import axios from 'axios'
import {toast} from 'react-toastify'
import { ShopImage } from '../../main/page_body/shared_components/shopLogo'

const FavoriteSavedSellers = ({authenticity_token}) => {
  const [shopsState, setShopsState] = useState(null)
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);



  const reqShop = (params) => {
    axios.get(`/users/saved_sellers.json`, { params: params }).then((res) => {
      setShopsState(res.data)
      setTotalPages(res.data.pages)
      setCurrentPage(res.data.current_page)
    })
  }

  const unfavorite = (shop) => {
    axios.delete(`/users/saved_sellers/${shop.slug}`, { data: {authenticity_token: authenticity_token} }).then((res) => {

      toast.success('Shop was removed from your favorites')
            let params = {
              page: currentPage,
              per_page: 50,
            }

            reqShop(params)
    })
  }

  useEffect(() => {
    let params = {
      page: currentPage,
      per_page: 50,
    }

    reqShop(params)
  }, [currentPage], [shopsState])


  const FavoriteShopRow = ({shop}) => {
    return (
      <tr
        className={`bg-white hover:bg-gray-100 cursor-pointer text-sm mb-2 items-center`}
        key={shop.id}
      >
        <td>
          <ShopImage shop={shop} className='rounded-full w-16 h-16' />
        </td>
        <td className='max-w-xs'>
          <a href={`/shops/${shop.slug}`}>
            <span className='font-semibold text-gray-800'>{shop.shop_name}</span>
          </a>
        </td>
        <td><a className="btn" href="#" onClick={() => unfavorite(shop)}>Unfavorite</a></td>
      </tr>
    )
  }

  const ShopsTable = () => {
    const handlePageChange = (page) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setCurrentPage(page.selected + 1)
      }

    return (
      <>
        <div>
          <table className='table table-fixed w-full border-collapse'>
            <thead className='bg-gray-100'>
              <tr>
                <th colSpan='2' className='py-4'>Shop Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {shopsState.map((shop) => (
              <FavoriteShopRow
                key={shop.id}
                shop={shop}
                shopsState={shopsState}
                setShopsState={setShopsState}
              />
            ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }

  return (
    <>
      {!shopsState && <LoadingSpinner />}
      {!shopsState?.length && <Onboarding url='/shops' title='Shops' action='Browse for' />}
      {shopsState?.length && <ShopsTable />}
    </>
  )
}

export default FavoriteSavedSellers
