import React, { useState } from 'react'
import axios from "axios";
import { toast } from "react-toastify";
import CategorySelect from "../../shared_components/categorySelect";

const CategoryEdit = ({ integration, product, categories }) => {
  const [selectedCat, setSelectedCat] = useState(product.category);

  const handleCategoryChange = (selectedOption) => {
    const option = selectedOption;
    setSelectedCat(categories.find(c => c.id === selectedOption.value))
    handleSubmit(option);
  };

  const handleSubmit = (option) => {
    const params = {};
    if (option.value)
      params["product[category_id]"] = option.value;

    const formData = new FormData();

    Object.entries(params).forEach(([key, value]) =>
      formData.append(key, value)
    );

    axios({
      method: "PUT",
      url: `/merchants/integrations/${integration.id}/products/${product.id}`,
      data: formData,
    })
      .then(() => {
        toast.success("Category successfully changed!");
      })
      .catch((err) => console.log(err));
  };

  return <div className='flex flex-row items-center'>
    <CategorySelect formClass={"w-full"} integration={integration} product={product} categories={categories}
      selectedCategory={selectedCat} onChange={handleCategoryChange} /></div>
}

export default CategoryEdit
