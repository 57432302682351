import React, { useContext } from "react"
import { CartContext } from "../../../../contexts/cartContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import CartUI from "./cartUI"

export default function CartDrawer() {
  let { isOpen, setIsOpen } = useContext(CartContext)

  return (
    <main
      className={
        " fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out " +
        (isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          " lg:w-2/5 md:w-3/5 sm:w-4/5 w-full right-0 absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
          (isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article className="relative w-full pb-10 flex flex-col space-y-6 overflow-y-scroll h-full">
          <div className='flex flex-row justify-between items-baseline'>
            <h3 className="p-4 mt-3 text-2xl text-gray-700">Added to Cart</h3>
            <button
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="mr-3 mt-1 text-2xl"
              />
            </button>
          </div>
          <CartUI onOrderCreated={() => { setIsOpen(false) }} />
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer"
        onClick={() => {
          setIsOpen(false);
        }}
      />
    </main>
  );
}
