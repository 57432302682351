import React, { useContext } from 'react'
import { ShopsContext } from "./shops";
import { NoShopsFound } from "./noShopsFound";
import ShopInfoCard from './shopInfoCard'
import Pagination from '../shared_components/pagination'
import ShopsGridSkeleton from "../shared_components/skeletons/shopsGridSkeleton";

const ShopsGrid = () => {
  const {
    shops,
    totalPages,
    currentPage,
    setCurrentPage
  } = useContext(ShopsContext);

  function shopsGrid() {
    return <>
      {shops.length > 0 ? (
        <>
          <div className='flex flex-col lg:flex-row w-full justify-between items-center'>
            <h1 className='mb-2 md:mb-4 text-3xl'>{`All Brands`}</h1>
            <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}  />
          </div>
          <div
            className='w-full text-gray-900 antialiased justify-items-auto gap-x-1 gap-y-1 md:gap-x-4 md:gap-y-4'>
            {shops.map((shop) => (
              <div className='py-1'><ShopInfoCard shop={shop} /></div>
            ))}
          </div>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </>
      ) : (
        <NoShopsFound />
      )}
    </>;
  }

  return (
    <div className='w-full'>
      {shops ? shopsGrid() : <ShopsGridSkeleton />}
    </div>
  )
}

export default ShopsGrid
