import { faBagsShopping, faDoorOpen, faStore, faUser } from '@fortawesome/pro-regular-svg-icons';
import { applyStatics, ControlledMenu, MenuDivider, MenuItem } from '@szhsin/react-menu';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AccountOption from './accountOption';

const MerchantAccountMenu = ({ anchorRef, isOpen, setIsOpen }) => {

  const [merchantShop, setMerchantShop] = useState(null);

  applyStatics(MenuItem)(AccountOption);

  useEffect(() => {
    fetchIntegration();
  }, [])

  const fetchIntegration = () => {
    axios.get('/merchants/integrations.json').then(response => {
      const integrations = response.data;
      if (integrations.length)
        setMerchantShop(integrations[0]);
    })
  }

  return (
    <ControlledMenu
      state={isOpen ? 'open' : 'closed'}
      arrow
      anchorRef={anchorRef}
      onMouseLeave={() => { setIsOpen(false) }}
      onClose={() => { setIsOpen(false) }}
    >
      <AccountOption icon={faUser} label="Account" href='/merchants/edit' />
      <MenuDivider />
      <AccountOption icon={faStore} label="My Shop" href='/merchants/integrations' />
      <MenuDivider />
      {
        merchantShop?.merchant?.status === 'approved' &&
        <>
          <AccountOption icon={faBagsShopping} label="Sales Channels"
            href={`/merchants/integrations/${merchantShop.id}/market_memberships`}
          />
          <MenuDivider />
        </>
      }
      <AccountOption icon={faDoorOpen} label="Log Out" href='/merchants/sign_out' dataMethod="delete" />
    </ControlledMenu >
  )
}

export default MerchantAccountMenu;
