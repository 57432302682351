import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-regular-svg-icons'
import React from 'react'

// From https://tailwindui.com/components/application-ui/overlays/modals
export const FullScreenLoader = ({ title }) => {
  return <div className="fixed z-10 inset-0 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        className="inline-block self-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex items-center">
            <div className="mt-3 text-center w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                <span className='text-lg'>{title}</span>
              </h3>
              <div className="m-8">
                <FontAwesomeIcon icon={faSpinner} className='text-4xl animate-spin' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
