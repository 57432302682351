import React from "react"
import axios from "axios"
import CountryIconMerchants from "./countryIconMerchants"

const token = document.querySelector("[name=csrf-token]").content
axios.defaults.headers.common["X-CSRF-TOKEN"] = token

export default function ToggleMadeLocally({
  integration,
  product,
  madeLocally,
  setMadeLocally,
  iconClassName
}) {
  const toggleLocal = () => {
    axios({
      method: "PUT",
      url: `/merchants/integrations/${integration.id}/products/${product.id}`,
      data: { is_made_locally: !madeLocally },
    }).catch((err) => console.log(err))

    setMadeLocally(!madeLocally)
  }

  return (
    <div onClick={toggleLocal}>
      <CountryIconMerchants countrySlug={product.city.country.slug} iconClassName={iconClassName} />
    </div>
  )
}
