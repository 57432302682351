import React, { useContext } from 'react'
import { MarketContext } from './market'
import IconSelect from '../main/footer/shared/iconSelect'
import {
  FooterColumn,
  FooterLinkExternal,
  FooterLinkInternal,
  Info,
} from '../main/footer'
import FooterBanner from "../../../assets/images/footer.svg";
import MerchantSignUp from "../main/page_body/callToActionBanners/merchantSignUp";
import { Link } from "react-router-dom";

const Footer = () => {
  let { market } = useContext(MarketContext)

  const hasSocialMedia = (socialMediaLink, iconName) => {
    if (socialMediaLink !== '') {
      return <IconSelect
        path={socialMediaLink}
        iconName={iconName}
      />
    }
  }

  if (market) {
    return (
      <div>
        <div className='py-6 lg:mx-20'><MerchantSignUp /></div>
        <div
          style={{ backgroundImage: `url(${FooterBanner})` }}
          className='w-full h-full mt-6 md:-mt-40 pt-4'>
          <div className='flex flex-col w-full'>
            <div
              className='flex flex-col md:flex-row justify-evenly items-center lg:items-start lg:items-center md:pt-32'>
              <div className='flex flex-row items-start gap-x-4 mx-4 mt-12'>
                <img className='h-12 md:h-20 lg:h-20 cursor-pointer' src={market.market_icon} alt='market logo' />
              </div>
              <div className='flex flex-row items-baseline mt-12'>
                <div className='lg:block hidden mr-8'>
                  <div className={market.label1 || market.label2 || market.label3 ? 'block' : 'hidden'}>
                    <FooterColumn>
                      <span className='text-white font-semibold pb-2 text-sm'>About</span>
                      <FooterLinkExternal title={market.label1} path={market.link1} />
                      <FooterLinkExternal title={market.label2} path={market.link2} />
                      <FooterLinkExternal title={market.label3} path={market.link3} />
                    </FooterColumn>
                  </div>
                </div>
                <div className='lg:block hidden mr-8'>
                  <FooterColumn>
                    <span className='text-white font-semibold pb-2 text-sm'>Explore</span>
                    <FooterLinkInternal title='Catalogue' path={`/markets/${market.slug}/products`} />
                    <FooterLinkInternal title='Services' path={`/markets/${market.slug}/services`} />
                    <FooterLinkInternal title='All Brands' path={`/markets/${market.slug}/shops`} />
                  </FooterColumn>
                </div>
                <div className='lg:block hidden mr-8'>
                  <FooterColumn>
                    <span className='text-white font-semibold pb-2 text-sm'>More</span>
                    <FooterLinkInternal title='Catalogue' path={`/markets/${market.slug}/products`} />
                    <FooterLinkInternal title='Services' path={`/markets/${market.slug}/services`} />
                    <FooterLinkInternal title='All Brands' path={`/markets/${market.slug}/shops`} />
                  </FooterColumn>
                </div>
                <div className='lg:block hidden'>
                  <Info />
                </div>
              </div>
              <FooterColumn>
                <span className='text-white font-semibold pb-4'>Socials</span>
                <div className='flex justify-between items-center gap-x-4'>
                  {hasSocialMedia(market.facebook, 'facebook')}
                  {hasSocialMedia(market.instagram, 'instagram')}
                  {hasSocialMedia(market.linkedin, 'linkedin')}
                  {hasSocialMedia(market.youtube, 'youtube')}
                  {hasSocialMedia(market.twitter, 'twitter')}
                </div>
              </FooterColumn>
            </div>
            <div className='pt-12 md:pt-18 pb-4'>
              <hr className='border border-white opacity-20' />
            </div>
            <div
              className=' flex flex-col lg:flex-row items-start gap-x-16 lg:gap-y-2 gap-y-6 text-gray-500 text-sm font-light py-4 lg:mx-20 mx-4'>
            <span className='cursor-pointer hidden lg:block text-white text-sm opacity-50 ml-10'>
              © 2022 Local Shops. All rights reserved
            </span>
              <section className='flex flex-row gap-x-6 text-sm text-white'>
                <Link to='/tos' className='hover:text-gray-200 '>
                  Terms of Service
                </Link>
                <Link to='/privacy' className='hover:text-gray-200 text-sm '>
                  Privacy Policy
                </Link>
              </section>
            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Footer
