import React from 'react';

const FooterLinkExternal = ({ title, path }) => {
  return (
    <div className='truncate text-white font-hairline text-sm mt-2'>
      {path === '' ? (
        <a
          onClick={() => toast.success('Coming Soon!')}
          className='hover:text-mustard-400'>
          {title}
        </a>
      ) : (
        <a
          href={path}
          target='_blank'
          className='hover:text-mustard-400'>
          {title}
        </a>
      )}
    </div>
  )
}

export default FooterLinkExternal;
