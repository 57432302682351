import React, { useState, useEffect } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { initializeDescription } from '../../../../../utilities/contentStateHelpers'
import {
  handleBeforeInput,
  handlePastedText
} from './helpers/helpers'
export default function Description({
  description,
  description_html,
  name,
}) {
  const [editorState, setEditorState] = useState(undefined)
  const maxCharacterLength = 288

  useEffect(() => {
    if (description_html) {
      const blocksFromHtml = htmlToDraft(description_html)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      setEditorState(EditorState.createWithContent(contentState))
    } else if (description) {
      setEditorState(
        EditorState.createWithContent(initializeDescription(description))
      )
    } else {
      setEditorState(EditorState.createEmpty())
    }
  }, [])

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row items-center'></div>
      {editorState && (
        <>
          <Editor
            name={name}
            editorState={editorState}
            wrapperClassName='w-full h-32 '
            editorClassName='border-mustard-light border-2 rounded-md p-2'
            placeholder='Brand Story'
            onEditorStateChange={setEditorState}
            handleBeforeInput={() =>
              handleBeforeInput(
                JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                editorState.getCurrentContent().getPlainText(''),
                maxCharacterLength
              )
            }
            handlePastedText={handlePastedText(
              JSON.stringify(convertToRaw(editorState.getCurrentContent())),
              editorState.getCurrentContent().getPlainText(),
              maxCharacterLength
            )}
            toolbar={{
              options: ['inline', 'list'],
              inline: { options: ['bold', 'italic', 'underline'] },
              list: { options: ['unordered', 'ordered'] },
            }}
          />
          <input
            type='hidden'
            value={JSON.stringify(
              convertToRaw(editorState.getCurrentContent())
            )}
            name={name}
          />
          {description_html && (
            <input
              type='hidden'
              value={''}
              name={'integration[description_html]'}
            />
          )}
          <div className='flex items-center justify-end'>
            <p className='text-sm text-gray-600 mt-12'>
              {' '}
              {editorState.getCurrentContent().getPlainText('').length}{' '}
              /288
            </p>
          </div>
        </>
      )}
    </div>
  )
}
