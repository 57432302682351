import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Products from "../products/products";
import ProductsGridCarousel from "../products/productsGridCarousel";
import PreviousButtonLabel from "./pagination/previousButtonLabel";
import NextButtonLabel from "./pagination/nextButtonLabel";

const getQueryParams = (query) => {
  return {
    order: query.order || null,
    page: query.page ? parseInt(query.page) : 0
  }
}

const PaginatedCategories = () => {
  const categoriesPerPage = 4;

  const queryString = require('query-string')
  const parsedQuery = queryString.parse(location.search);
  const queryParams = getQueryParams(parsedQuery);

  const [currentItems, setCurrentItems] = useState(null);
  const [currentSort, setCurrentSort] = useState(queryParams.order);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(queryParams.page * categoriesPerPage);
  const history = useHistory()

  useEffect(() => {
    const requestQuery = currentSort ? `?order=${currentSort}` : '';
    axios.get(`/categories.json${requestQuery}`)
      .then(res => {
        const endOffset = itemOffset + categoriesPerPage;
        setCurrentItems(res.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(res.data.length / categoriesPerPage));
      })
  }, [itemOffset, currentSort]);

  const handlePageClick = (page) => {
    const newOffset = (page.selected) * categoriesPerPage;
    window.scrollTo({ top: 0, behavior: 'smooth' })
    parsedQuery['page'] = page.selected
    history.push(`${location.pathname}?${queryString.stringify(parsedQuery)}`)
    setItemOffset(newOffset);
  }

  return (
    <div className='container mx-auto'>
      {currentItems && currentItems.map((category, index) => {
        return (
          <div className='py-4'>
            <Products category_slug_manual={category.slug}>
              <div className="flex py-2 justify-between">
                <h2 className='text-2xl'>{category.name}</h2>
                <ReactPaginate
                  previousLabel={<PreviousButtonLabel />}
                  nextLabel={<NextButtonLabel />}
                  breakLabel={'...'}
                  forcePage={queryParams.page}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages-pagination'}
                  activeClassName={'active'}
                  pageClassName={'pages'}
                  nextClassName={'next'}
                  previousClassName={'previous'}
                />
              </div>
              <ProductsGridCarousel show_more_link={`${category.slug}`}/>
            </Products>
          </div>
        )
      })
      }
      <div className='flex justify-center sm:justify-start'>
        <ReactPaginate
          previousLabel={<PreviousButtonLabel />}
          nextLabel={<NextButtonLabel />}
          breakLabel={'...'}
          forcePage={queryParams.page}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          subContainerClassName={'pages-pagination'}
          activeClassName={'active'}
          pageClassName={'pages'}
          nextClassName={'next'}
          previousClassName={'previous'}
        />
      </div>
    </div>
  )
}

export default PaginatedCategories
