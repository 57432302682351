import React from "react";
import { ShopCity } from "../shared_components/shopCity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/pro-regular-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { IntegrationDescription } from "../../../main/page_body/shared_components/shopPageHelper";
import ReactTooltip from "react-tooltip";

const ShopItem = ({ integration, icon }) => {
  const Logo = ({ shop }) => {
    return (
      <a
        className="h-full w-full lg:h-48 lg:w-48 lg:mb-0 mb-3"
        href={`/merchants/integrations/${shop.id}/edit`}
      >
        {shop.shop_logo_path ? (
          <img
            className="w-full object-scale-down lg:object-cover lg:h-48 rounded-2xl"
            src={shop.shop_logo_path}
            alt={shop.shop_name}
          />
        ) : (
          <div className="w-full border-2 object-scale-down lg:object-cover mx-auto items-center bg-gray-200 flex justify-center lg:h-48 rounded-2xl">
            <div className="flex flex-col items-center  hover:text-cerulean-800 text-sm text-gray-500 transition ease-in duration-300">
              <FontAwesomeIcon icon={faImage} className="text-4xl" />
              <p className="leading-tight">MISSING LOGO</p>
            </div>
          </div>
        )}
      </a>
    );
  };

  const IsListedBadge = ({ isListed }) => {
    return <div
      className="text-sm md:mb-0 text-black">
      {
        (isListed ? <div className='flex flex-row gap-x-2 items-center'>
          <FontAwesomeIcon icon={faCircle} className='text-green-500' />
          <div className='uppercase'>Live</div>
        </div> : <div data-tip
                      data-for=' offline-badge'
                      className='flex flex-row gap-x-2 items-center'
        >
          <FontAwesomeIcon icon={faCircle} className='text-red-500' />
          <ReactTooltip id=' offline-badge'>Please contact support to have your shop listed on our
            market.</ReactTooltip>
          <div className='uppercase'>Offline</div>
        </div>)
      }
    </div>
  }

  return (
    <div className="bg-white w-5/6 shadow-md rounded-lg p-4 mb-4 flex flex-col flex-1 h-full">
      <div className="flex flex-col justify-between h-full">
        <div className=" flex-auto ml-3 justify-evenly py-2">
          <Logo shop={integration} />
          <div className="flex flex-wrap mt-2 mb-1">
            <a
              href={`/merchants/integrations/${integration.id}/products`}
              className=" flex-auto text-lg font-medium"
            >
              {integration.shop_name}
            </a>
          </div>
          <IsListedBadge isListed={integration.is_listed} />
          <div className="flex flex-row py-4 text-sm text-mustard-400 ">
            <ShopCity shop={integration} />
            <div className="flex-1 gap-1 inline-flex items-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016C14.6666 4.31826 11.6818 1.3335 7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668Z"
                  stroke="#DEA448"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 4V8L10.6667 9.33333"
                  stroke="#DEA448"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <p>
                 {integration.created_at
                  .split("T")[0]
                  .replace("-", ".")
                  .replace("-", ".")}
              </p>
            </div>
          </div>
          {integration.description && (
            <IntegrationDescription
              isCard={true}
              text_length={100}
              integration={integration}
              descriptionClass={"text-xs font-thin break-all "}
            />
          )}
        </div>

        <div>
          <div className="flex flex-col gap-y-2 text-sm font-medium pt-2 items-center">
            <div className=" flex-col flex w-full">
              <div className="py-3 mb-2 md:mb-0 bg-white w-full text-center shadow-sm hover:shadow-lg tracking-wider border border-mustard-100 text-mustard-400 rounded-full">
                <a href={`/merchants/integrations/${integration.id}/products`}>
                  {integration.product_count} {integration.product_count == 1 && "product" || "products"}
                </a>
              </div>
            </div>
            <a
              href={`/merchants/integrations/${integration.id}/edit`}
              className="py-3 mb-2 md:mb-0 bg-mustard-400 px-20 shadow-sm hover:shadow-lg tracking-wider text-white rounded-full"
            >
              Edit Shop
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopItem;
