export const ShippingRatesInitialValues = {
  local: 0,
  local_enabled: false,
  local_shipping_type: "per_order",
  domestic: 0,
  international: 1,
  domestic_enabled: false,
  domestic_shipping_type: "per_order",
  local_pickup: false,
  international_shippings: []
}

export const InternationalRatesInitialValues = {
  country_id: 1,
  flat_rate: 0,
  enabled: false,
  shipping_type: "per_order"
}
