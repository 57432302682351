import { format } from "date-fns"
import { formatCurrency } from "./formatPrice"

export const makePayoutReadable = (payout) => {
  const initialPayout = {
    customerName: `${payout.customer.first_name} ${payout.customer.last_name}`,
    payoutDate: payout.payout_date ? format(new Date(payout.payout_date), 'MMM d, yyyy') : '-'
  }
  const orderNumber = payout?.shopify_order ? payout.shopify_order.order_id : payout.order.id
  const payoutAmount = payout?.shopify_order ? formatCurrency(payout.shopify_order.net_order_value) : formatCurrency(payout.order.net_order_value)
  const shipping = payout?.shopify_order ? formatCurrency(payout.shopify_order.shipping) : formatCurrency(payout.order.shipping)

  return { ...initialPayout, orderNumber, payoutAmount, shipping, }
}

export const doPayoutsExist = (payouts) => {
  return !!(payouts && payouts.length);
}
