import React from 'react';
import { checkIfContentState } from '../../../../utilities/contentStateHelpers';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import ScrollFade from "@benestudioco/react-scrollfade";

const ProductDescription = ({ product }) => {
  const productDescriptionLength = parseDescription()

  function parseDescription() {
    try {
      return JSON.parse(product.description)
    } catch (e) {
      return product.description
    }
  }

  const DescriptionHTML = () => {
    if (product.description_html)
      return <div dangerouslySetInnerHTML={{ __html: product.description_html }} />
    else if (checkIfContentState(product.description)) {
      return <div
        dangerouslySetInnerHTML={{
          __html: stateToHTML(
            convertFromRaw(productDescriptionLength)
          ),
        }}
      />
    }

    return product.description;
  }

  return (
    <div className={'mt-4 mb-4 text-sm text-gray-700'}>
      <label className='text-sm uppercase text-black font-semibold'>Details</label>
      <div className='overflow-y-auto my-3 h-48 bg-white'>
        <ScrollFade />
        <DescriptionHTML />
      </div>
    </div>
  )
}

export default ProductDescription;
