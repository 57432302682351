import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import CategoryLink from "../../page_body/shared_components/categoryLink";

export default function CategoryItem({ category, selectedCategory, setSelectedCategory, topLevel }) {

  const isCategorySelected = () => {
    return (selectedCategory && selectedCategory.id === category.id)
  }

  const onSelectCategory = event => {
    event.stopPropagation();
    setSelectedCategory(selectedCategory ? null : category)
  }

  return (
    <div
      className={`relative ${topLevel ? "my-4 xl:mr-12 text-sm" : "my-2 xl:mr-8 text-xxs"} text-gray-700 cursor-pointer min-w-md tracking-med`}>
      <div className={`flex flex-row items-center ${topLevel ? "justify-center" : "justify-start"}`}>
        <CategoryLink category={category} onMouseEnter={onSelectCategory}
          className='whitespace-no-wrap text-base text-medium-grey hover:text-mustard-400 cursor-pointer md:mr-4 mr-2'>{category.name}</CategoryLink>
        {category.children.length > 0 && topLevel && (
          <FontAwesomeIcon
            className={`hidden md:block text-xs ml-1`}
            icon={isCategorySelected() ? faChevronUp : faChevronDown}
            onClick={onSelectCategory}
          />
        )}
      </div>
    </div>
  )
}
