import React from 'react'
import { Link } from "react-router-dom";

const MarketLogo = ({ market }) => {
  return (
    <Link className='w-28 flex justify-center sm:p-0' to={`/markets/${market.slug}`}>
      <img className='h-8 lg:h-12 cursor-pointer'
           src={`${market.market_icon}`}
           alt={`${market.name} Logo`} />
    </Link>
  )
}

export default MarketLogo
