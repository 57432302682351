import React from "react";

export const FacebookIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.242 13.6667C16.4885 13.6667 16.6983 13.4869 16.7361 13.2433L17.1934 10.294C17.2158 10.1498 17.174 10.0029 17.079 9.89208C16.984 9.78122 16.8453 9.71742 16.6993 9.71742H14.3396V8.30356C14.3396 7.97397 14.422 7.70954 14.576 7.53333C14.7189 7.3698 14.9864 7.21021 15.5199 7.21021H16.8199C17.0961 7.21021 17.3199 6.98636 17.3199 6.71021V4.19925C17.3199 3.95524 17.1438 3.74686 16.9032 3.70623L16.8199 4.19925L16.9031 3.70621L16.903 3.70619L16.9027 3.70614L16.9017 3.70598L16.8986 3.70545L16.8872 3.70358L16.8451 3.69682C16.8088 3.69108 16.7563 3.68299 16.6901 3.67334C16.5576 3.65404 16.3697 3.62844 16.1458 3.60286C15.7001 3.55193 15.1025 3.5 14.5122 3.5C13.2351 3.5 12.1235 3.88472 11.33 4.67069C10.5353 5.45781 10.1178 6.58984 10.1178 7.96968V9.71742H8C7.72386 9.71742 7.5 9.94128 7.5 10.2174V13.1667C7.5 13.4428 7.72386 13.6667 8 13.6667H10.1178V20.2963C10.1178 20.5724 10.3417 20.7963 10.6178 20.7963H13.8396C14.1158 20.7963 14.3396 20.5724 14.3396 20.2963V13.6667H16.242Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const InstagramIcon = () => {
  return (
    <div>
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.4532 5.01907C16.3963 3.86213 16.1217 2.83733 15.2411 1.99299C14.3639 1.14866 13.2992 0.884399 12.0972 0.826392C10.8583 0.758716 7.14516 0.758716 5.90632 0.826392C4.70766 0.881177 3.64293 1.14543 2.76235 1.98977C1.88177 2.83411 1.61056 3.85891 1.55029 5.01584C1.47998 6.20823 1.47998 9.78215 1.55029 10.9745C1.60721 12.1315 1.88177 13.1563 2.76235 14.0006C3.64293 14.8449 4.70431 15.1092 5.90632 15.1672C7.14516 15.2349 10.8583 15.2349 12.0972 15.1672C13.2992 15.1124 14.3639 14.8482 15.2411 14.0006C16.1184 13.1563 16.3929 12.1315 16.4532 10.9745C16.5235 9.78215 16.5235 6.21145 16.4532 5.01907Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
        <path
          d="M13.3406 4.14248C13.3406 4.34458 13.1791 4.50615 12.9756 4.50615C12.7713 4.50615 12.6106 4.34378 12.6106 4.14248C12.6106 3.94239 12.7733 3.77881 12.9756 3.77881C13.1779 3.77881 13.3406 3.94239 13.3406 4.14248Z"
          fill="#383838"
          stroke="#383838"
          strokeLinejoin="round"
        />
        <path
          d="M5.65625 8.09395C5.65625 6.26669 7.129 4.79395 8.95625 4.79395C10.7835 4.79395 12.2562 6.26669 12.2562 8.09395C12.2562 9.9212 10.7835 11.3939 8.95625 11.3939C7.129 11.3939 5.65625 9.9212 5.65625 8.09395Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const LinkedinIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.38863 19.5731C7.66477 19.5731 7.88863 19.3492 7.88863 19.0731V9.00955C7.88863 8.73341 7.66477 8.50955 7.38863 8.50955H4.25006C3.97392 8.50955 3.75006 8.73341 3.75006 9.00955V19.0731C3.75006 19.3492 3.97392 19.5731 4.25006 19.5731H7.38863ZM16.3648 19.0731C16.3648 19.3492 16.5887 19.5731 16.8648 19.5731H19.9966H20C20.2761 19.5731 20.5 19.3492 20.5 19.0731V13.545C20.5 12.1815 20.3584 10.8577 19.7336 9.86948C19.0755 8.82864 17.9503 8.25721 16.2397 8.25721C15.1939 8.25721 14.3443 8.60607 13.7261 9.09242V9.00955C13.7261 8.73341 13.5023 8.50955 13.2261 8.50955H10.216C9.93982 8.50955 9.71596 8.73341 9.71596 9.00955V19.0731C9.71596 19.3492 9.93982 19.5731 10.216 19.5731H13.3512C13.6273 19.5731 13.8512 19.3492 13.8512 19.0731V14.0901C13.8512 13.4433 13.9175 12.9111 14.1175 12.55C14.211 12.3812 14.3315 12.2545 14.4919 12.166C14.6554 12.0759 14.8897 12.0095 15.233 12.0095C15.5557 12.0095 15.7534 12.0835 15.8812 12.1723C16.0099 12.2617 16.1106 12.3958 16.1874 12.5888C16.3524 13.0038 16.3648 13.5685 16.3648 14.1742V19.0731ZM3.5 5.80982C3.5 7.08114 4.53381 8.1368 5.81765 8.1368C7.1011 8.1368 8.13531 7.0812 8.13531 5.80982C8.13531 5.19652 7.8906 4.60877 7.45572 4.17577C7.02091 3.74283 6.43164 3.5 5.81765 3.5C5.20367 3.5 4.6144 3.74283 4.17959 4.17577C3.74471 4.60877 3.5 5.19652 3.5 5.80982Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const TwitterIcon = () => {
  return (
    <div>
      <svg
        width="22"
        height="18"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.0413 2.92759C20.1048 2.78914 20.161 2.6467 20.2094 2.50064C20.3073 2.20525 20.2126 1.88008 19.9714 1.68343C19.7302 1.48678 19.3926 1.4595 19.123 1.61488C18.5911 1.92137 18 2.15928 17.3812 2.31003C16.5972 1.64588 15.5729 1.25 14.4607 1.25C12.0306 1.25 10.0301 3.15395 10.0216 5.54725C7.52997 5.20472 5.33774 3.96243 3.83058 2.16831C3.67492 1.98301 3.43942 1.88421 3.19815 1.90298C2.95688 1.92175 2.73949 2.05578 2.61436 2.26292C2.22707 2.90402 2.00379 3.64398 2.00379 4.4485C2.00379 5.1041 2.15422 5.72456 2.42238 6.27964C2.39653 6.29132 2.37118 6.30453 2.34648 6.31928C2.11977 6.45462 1.98094 6.69921 1.98094 6.96325V7.00735C1.98094 8.26327 2.52617 9.38037 3.39117 10.1635C3.26908 10.3529 3.23773 10.589 3.3097 10.806C3.67241 11.8995 4.46828 12.7922 5.49574 13.308C4.69552 13.6337 3.81609 13.8125 2.89086 13.8125C2.60091 13.8125 2.3445 13.8021 2.083 13.773C1.73471 13.7342 1.40598 13.9418 1.2913 14.273C1.17661 14.6041 1.30654 14.9705 1.60422 15.1555C3.35485 16.243 5.43331 16.8676 7.66499 16.8676C14.9263 16.8676 18.9112 11.0396 18.9112 5.98162V5.97114C18.9112 5.94407 18.9112 5.91598 18.9111 5.88704C19.5635 5.3779 20.1379 4.7761 20.611 4.11142C20.8051 3.83875 20.795 3.47047 20.5863 3.20881C20.4498 3.03761 20.2498 2.93916 20.0413 2.92759Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export const YoutubeIcon = () => {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9582 7.87759C19.759 7.13853 19.1721 6.55647 18.4269 6.35894C17.0762 6 11.6601 6 11.6601 6C11.6601 6 6.24394 6 4.8932 6.35894C4.14802 6.5565 3.56112 7.13853 3.36192 7.87759C3 9.21719 3 12.0121 3 12.0121C3 12.0121 3 14.8071 3.36192 16.1467C3.56112 16.8857 4.14802 17.4435 4.8932 17.6411C6.24394 18 11.6601 18 11.6601 18C11.6601 18 17.0762 18 18.4269 17.6411C19.1721 17.4435 19.759 16.8857 19.9582 16.1467C20.3201 14.8071 20.3201 12.0121 20.3201 12.0121C20.3201 12.0121 20.3201 9.21719 19.9582 7.87759ZM9.88868 14.5497V9.47453L14.4155 12.0122L9.88868 14.5497Z"
          stroke="#383838"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

