import React from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { useHistory } from 'react-router-dom';

const UserTabs = ({ className, activeKey = '' }) => {

  const history = useHistory();

  const onChange = (updatedKey) => {
    history.push(updatedKey)
  }

  return (
    <Tabs className={`md:mx-12 mx-auto my-auto ${className}`} defaultActiveKey={activeKey} onChange={onChange}>
      <TabPane tab="Wishlist" key="/users/" />
      <TabPane tab="Saved Sellers" key="/users/saved_sellers" />
      <TabPane tab="Purchase History" key="/users/orders" />
      <TabPane tab="Account Settings" key="/user/edit" />
    </Tabs>
  )
}

export default UserTabs;
