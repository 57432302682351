import React, { useState } from 'react';
import Select from 'react-select';

import RailsFormSelectStyle from '../../../constants/custom_styles/railsFormSelectStyle';

const FormMultiSelect = ({
  name,
  isLoading,
  placeholder,
  options,
  isDisabled,
  values,
  containerClassName,
}) => {

  const [selectedValues, setSelectedValues] = useState(values || [])

  return (
    <section className={containerClassName}>
      <Select name={name} placeholder={placeholder} options={options}
        isMulti
        components={{IndicatorSeparator: () => null}}
        value={selectedValues}
        isLoading={isLoading}
        styles={RailsFormSelectStyle}
        isDisabled={isDisabled}
        onChange={setSelectedValues} />
    </section>
  )
}

export default FormMultiSelect;
