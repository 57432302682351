import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { checkIfContentState } from "../../../../utilities/contentStateHelpers";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import ContactInformation from "./contact_information";
import analyticsData from "../../../../utilities/analyticsData";
import { TwitterIcon, FacebookIcon, InstagramIcon, YoutubeIcon, LinkedinIcon } from './SocialMediaIcons';


export const Tags = ({ integration }) => {
  const [toggleTags, setToggleTags] = useState(false)

  const tagsToDisplay = (first, last) => {
    return integration.tag_names?.slice(first, last).map((tag) => {
      return (
        <div
          key={tag}
          className='cursor-pointer tracking-wide bg-light-orange inline-block bg-mustard-400 rounded-full px-4 py-2 m-1 text-md font-semibold'
        >
          {tag}
        </div>
      )
    })
  }
  return (
    <div className='my-2 text-xs uppercase'>
      {integration.tag_names?.length > 3 ? (
        <div>
          {tagsToDisplay(0, 3)}

          {toggleTags ? tagsToDisplay(3, integration.tag_names?.length) : null}
          <p
            className='cursor-pointer tracking-wide px-1 py-1 m-1 text-mustard-400'
            onClick={() => setToggleTags(!toggleTags)}
          >
            {toggleTags ? 'see less' : 'see more'}
          </p>
        </div>
      ) : (
        tagsToDisplay(0, integration.tag_names?.length)
      )}
    </div>
  )
}

export const IntegrationDescription = ({ integration, html_style, descriptionClass, styles, isCard, text_length }) => {
  if (integration.description_html) {
    return (
      <div className={"my-4 text-base text-gray-700"}>
        <div
          dangerouslySetInnerHTML={{ __html: integration.description_html }}
        />
      </div>
    )
  } else {


    return (
      <div className={descriptionClass} style={styles}>
        {checkIfContentState(integration.description) ? (
          <div
            dangerouslySetInnerHTML={{
              __html: isCard ? stateToHTML(
                convertFromRaw(JSON.parse(integration.description))
              ).substring(0, text_length) + '...' : stateToHTML(
                convertFromRaw(JSON.parse(integration.description))
              ) ,
            }}
          />
        ) : (
          integration.description
        )}
      </div>
    )
  }
}

export const ContactInformationDisplay = ({ phoneNumber, email, integration_slug }) => {
  const [contactVisible, setContactVisible] = useState(false)

  if (phoneNumber || email) {
    return contactVisible ? (
      <ContactInformation phoneNumber={phoneNumber} email={email} />
    ) : (
      <div
        className='focus:outline-none focus:shadow-none hover:shadow-xl transition ease-in-out duration-300
              transform hover: scale-103 border text-white text-sm lg:text-base rounded-full py-2 px-4 m-2 text-center cursor-pointer flex-1 md:flex-none bg-mustard-400'
        onClick={() => {
          setContactVisible(true)
          analyticsData('Store Contact Seller Button Clicked', {
            shop_slug: integration_slug,
          })
        }}
      >
        <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
        Email
      </div>
    )
  } else {
    return null
  }
}

export const SocialMediaLinks = ({ integration }) => {
  return (
    <div className='w-full flex items-center justify-around py-2'>
      {integration.facebook && (
        <a
          href={`${integration.facebook}`}
          target='_blank'
          className='flex items-center justify-center border border-mustard-200 rounded-full w-4 h-4 p-4 hover:text-gray-700'
        >
          <FacebookIcon />
        </a>
      )}
      {integration.instagram && (
        <a
          href={`${integration.instagram}`}
          target='_blank'
          className='flex items-center justify-center border border-mustard-200 rounded-full w-4 h-4 p-4 hover:text-gray-700'
        >
          <InstagramIcon />
        </a>
      )}
      {integration.linkedin && (
        <a
          href={`${integration.linkedin}`}
          target='_blank'
          className='flex items-center justify-center border border-mustard-200 rounded-full w-4 h-4 p-4 hover:text-gray-700'
        >
          <LinkedinIcon />
        </a>
      )}
      {integration.youtube && (
        <a
          href={`${integration.youtube}`}
          target='_blank'
          className='flex items-center justify-center border border-mustard-200 rounded-full w-4 h-4 p-4 hover:text-gray-700'
        >
          <YoutubeIcon />
        </a>
      )}
      {integration.twitter && (
        <a
          href={`${integration.twitter}`}
          target='_blank'
          className='flex items-center justify-center border border-mustard-200 rounded-full w-4 h-4 p-4 hover:text-gray-700'
        >
          <TwitterIcon />
        </a>
      )}
    </div>
  )
}
