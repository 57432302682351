import React from "react";
import {
  UserIcon,
  ShopIcon,
  SalesIcon,
  LogoutIcon,
  ArrowNav,
} from "./side-bar-icons";

const DashboardLinks = ({ current_path, integration }) => {
  const MerchantLink = ({
                          url,
                          text,
                          className,
                          dataMethod,
                          isSubLink,
                          children,
                          height,
                          id,
                        }) => {


    const isCurrentPath =
      (id === "subedit" && current_path === "integrations") ||
      ((id !== "integrations") && (id === current_path)) ||
      (id === "edit" && current_path === "registrations") ||
      (id === "manage" && url === window.location.pathname)

    const boldCurrentPath =
      (isCurrentPath &&
        "text-black hover:text-light-orange") ||
      "text-gray-600 hover:text-black";
    return (
      (isSubLink && (
        <div className="flex flex-row w-full items-center justify-between">
          <a href={url} className={`${boldCurrentPath}`}>
            {text}
          </a>
          {
            <div className={(isCurrentPath && "invisible xl:block xl:visible") || "invisible"}>
              <ArrowNav />
            </div>
          }
        </div>
      )) || (
        <li
          className={`pl-3 xl:pl-20 list-none whitespace-no-wrap ${className} text-gray-600 rounded-full hover:text-black hover:bg-light-orange hover:shadow-item`}
        >
          <div
            className={`flex flex-row w-full items-center justify-between ${height}`}
          >
            <a href={url} data-method={dataMethod} className={boldCurrentPath}>
              <span className="flex items-center">
                {children}
                {children ? (
                  <p className="ml-2">{text}</p>
                ) : (
                  <p className="w-full text-center">{text}</p>
                )}
              </span>
            </a>

            {
              <div className={(isCurrentPath && "invisible xl:visible") || "invisible"}>
                <ArrowNav />
              </div>
            }
          </div>
        </li>
      )
    );
  };

  return (
    <div>
      <div>
        <MerchantLink id="edit" url="/merchants/edit" text="Account">
          <UserIcon />
        </MerchantLink>
        <MerchantLink
          id="manage"
          url="/merchants/integrations"
          text="My Shop"
          height="pt-2"
        >
          <ShopIcon />
        </MerchantLink>
        <div className="flex flex-col items-start pl-10 xl:pl-28 text-sm text-gray-600">
          {integration?.id && (
            <>
              <MerchantLink
                isSubLink={true}
                id="products"
                url={`/merchants/integrations/${integration.id}/products`}
                text="Products"
              />
              {integration.provider !== 'shopify' && (
                <>
                  <MerchantLink
                    isSubLink={true}
                    id="inventory"
                    url={`/merchants/integrations/${integration.id}/inventories`}
                    text="Inventory"
                  />
                  <MerchantLink
                    isSubLink={true}
                    id="orders"
                    url={`/merchants/integrations/${integration.id}/order_integrations`}
                    text="Orders"
                  />
                </>
              )}
              <MerchantLink
                isSubLink={true}
                id="subedit"
                url={`/merchants/integrations/${integration.id}/edit`}
                text="Shop Settings"
              />
              <MerchantLink
                isSubLink={true}
                id="payments"
                url={`/merchants/integrations/${integration.id}/payments`}
                text="Payments"
              />
              <MerchantLink
                isSubLink={true}
                id="shippings"
                url={`/merchants/integrations/${integration.id}/shippings`}
                text="Shipping"
              />
            </>
          )}
        </div>
        {integration?.id && (
          <>
            <MerchantLink
              id="market_memberships"
              url={`/merchants/integrations/${integration.id}/market_memberships`}
              text="Sales Channels"
              height="py-3"
            >
              <SalesIcon />
            </MerchantLink>
          </>
        )}
      </div>

      <MerchantLink dataMethod="delete" url="/merchants/sign_out" text="Logout">
        <LogoutIcon />
      </MerchantLink>
    </div>
  );
};

export default DashboardLinks
