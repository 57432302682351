import React from 'react';
import { ErrorMessage } from 'formik';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input/input';

const FormPhoneInputRow = ({
  name,
  country,
  placeholder,
  onChange,
  value,
  containerClassName,
  placeholderClassName,
  labelClassName,
  inputClassName,
  errorClassName
}) => {
  const isFloatingClassName = !!value ? 'floating' : '';
  return (
    <section className={containerClassName}>
      <label className={`border border-beige-200 rounded-md floating-label ${isFloatingClassName} ${labelClassName}`}>
        <PhoneInput
          country={country}
          name={name}
          id={name}
          className={inputClassName}
          value={value}
          onChange={onChange}
        />
        <span className={placeholderClassName}>{placeholder}</span>
      </label>
      <ErrorMessage
        name={name}
        render={errorMessage => (
          <span className={`text-red-400 text-sm ${errorClassName}`}>{errorMessage}</span>
        )}
      />
    </section>
  )
}

export default FormPhoneInputRow;
