import React from 'react'

const NoSearchResult = ({ className }) => {
  return (
    <div className={className}>
      <h3 className='text-lg text-center px-8 py-4 font-light'>The product does not exist in this shop</h3>
      <a href={window.location.href}
        className='text-sm text-center px-8 py-3 text-white bg-yellow-600 font-light rounded-full'>Back
      </a>
    </div>
  )
}
export default NoSearchResult
