import React from 'react';
import OrderInfoRow from './orderInfoRow';

const OrderInfo = ({ data }) => {
  return (
    data &&
    <section>
      <h2 className='my-2 text-2xl'>Purchase Information</h2>
      <div className='grid grid-cols-1 gap-y-6 mt-8'>
        <OrderInfoRow label='Order Number' value={data.orderNumber} />
        <OrderInfoRow label='Date' value={data.date} />
        <OrderInfoRow label='Sub Total' value={data.subTotal} />
        <OrderInfoRow label='Shipping' value={data.shipping} />
        <OrderInfoRow label='Taxes' value={data.tax} />
        <OrderInfoRow label='Total Price' value={data.grandTotal} />
      </div>
    </section>
  )
}

export default OrderInfo;
