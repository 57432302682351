import React from 'react'
import {
  faAnalytics,
  faBuilding,
  faCreditCard,
  faStore,
  faTachometer,
  faUsers,
  faUserCog,
  faExternalLink
} from "@fortawesome/pro-regular-svg-icons";
import Link from './links'

const DashboardLinks = ({ current_path, market }) => {
  return (
    <div>
      <Link
        id='markets'
        url='/market_admins'
        text='My Markets'
        icon={faTachometer}
        current_path={current_path}
      />
      {market && (
        <>
          <Link
            id='market_memberships'
            url={`/market_admins/markets/${market.id}/market_memberships`}
            text='Memberships'
            icon={faBuilding}
            current_path={current_path}
          />
          <Link
            id='market_admins'
            url={`/market_admins/markets/${market.id}/market_admins`}
            text='Admins'
            icon={faUsers}
            current_path={current_path}
          />
          <Link
            id='analytics'
            url={`/market_admins/markets/${market.id}/analytics`}
            text='Market Analytics'
            icon={faAnalytics}
            current_path={current_path}
          />
          <Link
            id='market_edit'
            url={`/market_admins/markets/${market.id}/edit`}
            text='Market Settings'
            icon={faStore}
            current_path={current_path}
          />
          <Link
            id='payments'
            url={`/market_admins/markets/${market.id}/payments`}
            text='Payments'
            icon={faCreditCard}
            current_path={current_path}
          />
        </>
      )}
      <div className='p-2'>
        <Link
          id='user_edit'
          url='/market_admins/edit'
          text='User Settings'
          icon={faUserCog}
          current_path={current_path}
        />
        <Link
          dataMethod='delete'
          url='/market_admins/sign_out'
          text='Logout'
          icon={faExternalLink}
          current_path={current_path}
        />
      </div>
    </div>
  )
}
export default DashboardLinks
