import React, { useContext, useEffect, useState } from "react";
import { loadAlgoliaProducts } from "../shared_components/algoliaSearch";
import determineMarketContext from '../../../../utilities/determineMarketContext';
import { CitiesContext } from "../../../../contexts/citiesContext";
import CustomCarousel from "../shared_components/customCarousel";
import LargeProductCard from "../products/largeProductCard";
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import SmallProductCard from "../products/smallProductCard";
import shuffle from "../../../../utilities/shuffle";
import DiscoverProductsSkeleton from "../shared_components/skeletons/discoverProductsSkeleton";

const DiscoverProducts = ({ show_more_link }) => {
  const { market } = useContext(determineMarketContext())
  const { city } = useContext(CitiesContext)

  const [fetching, setFetching] = useState(false)
  const [products, setProducts] = useState(null)
  const [productsInCity, setProductsInCity] = useState(false)
  const [retryCounter, setRetryCounter] = useState(1)
  const [totalHits, setTotalHits] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (city || market) {
      setRetryCounter(1)
      getProducts()
    }
  }, [market, city])

  useEffect(() => {
    if (totalHits < 50 && retryCounter < 12) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [retryCounter])

  useEffect(() => {
    if (fetching && retryCounter < 12) {
      getProducts()
    }
  }, [fetching, retryCounter])

  useEffect(() => {
    setLoading(!(products && city))
  }, [products, city])

  useEffect(() => {
    if (!loading) {
      setProductsInCity(isSameCity())
    }
  }, [loading])

  const isSameCity = () => !!products?.filter((product) => product.city.name === city?.name).length

  const getProducts = () => {
    const searchOptions = { market, city }
    loadAlgoliaProducts(searchOptions, retryCounter).then(({ hits, nbHits }) => {
      setProducts(shuffle(hits))
      setTotalHits(nbHits)
      if (nbHits < 50 && retryCounter < 12) {
        setRetryCounter(prevState => prevState + 1)
      }
    })
  }

  const MobileDiscoverProducts = () => {
    return (
      <div className="grid grid-cols-2">
        {products.map(
          (product, index) =>
            index < 4 && (
              <div className="px-2 py-2" key={'div' + product.objectID}>
                <SmallProductCard
                  product={product}
                  key={product.objectID}
                  heart_pos="px-2"
                />
              </div>
            )
        )}
        <div className="flex justify-tems-center col-span-2 py-10">
          <a
            href={show_more_link}
            className="border border-black opacity-50 w-full px-32 py-3 rounded-full "
          >
            Show More
          </a>
        </div>
      </div>
    );
  };

  return (
    products ? (
      <section id="discover" className="py-6 px-4 md:px-8">
        <h2 className="flex flex-row py-4 px-2 text-2xl lg:text-4xl font-semi-bold">
          Discover Products
        </h2>
        <div className={`text-gray-900`}>
          <div className='hidden md:block'>
            {(
              <CustomCarousel
                breakpoints={carouselBreakpoints(4, 4, 3, 1)}
              >
                {products.map((product) => (
                  <div key={`discover_${product.objectID}`}>
                    <LargeProductCard product={product} key={product.objectID} />
                  </div>
                ))}
              </CustomCarousel>
            )}
          </div>
          <div className='block md:hidden'>
            <MobileDiscoverProducts />
          </div>
        </div>
      </section>
    ) : <DiscoverProductsSkeleton />
  )
}
export default DiscoverProducts;
