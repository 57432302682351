import React from 'react'
import ReactPaginate from "react-paginate";
import PreviousButtonLabel from "./pagination/previousButtonLabel";
import NextButtonLabel from "./pagination/nextButtonLabel";
import { useHistory } from "react-router-dom";

const Pagination = ({totalPages, currentPage, setCurrentPage}) => {
  let history = useHistory()
  const queryString = require('query-string')
  let parsedQuery = queryString.parse(location.search)

  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    setCurrentPage(page.selected + 1)
    parsedQuery['page'] = page.selected + 1
    history.push(`${location.pathname}?${queryString.stringify(parsedQuery)}`)
  }
  return(
    <div className='flex justify-center sm:justify-start'>
      <ReactPaginate
        previousLabel={<PreviousButtonLabel />}
        nextLabel={<NextButtonLabel />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPages}
        forcePage={currentPage - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={10}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages-pagination'}
        activeClassName={'active'}
        pageClassName={'pages'}
        nextClassName={'next'}
        previousClassName={'previous'}
      />
    </div>
  )
}

export default Pagination
