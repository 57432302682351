import React from 'react';
import ReactSwitch from 'react-switch';
import { getColor } from '../../../../utilities/colors';

const ToggleButton = ({ isChecked, onChange, width, iconChecked, iconUnchecked, height, diameter }) => {
  return (
    <ReactSwitch
      onChange={onChange}
      checked={isChecked}
      onColor={getColor('text-mustard-400')}
      onHandleColor={getColor('text-white')}
      offColor={getColor('text-gray-200')}
      offHandleColor={getColor('text-white')}
      handleDiameter={diameter || 20}
      uncheckedIcon={iconUnchecked || false}
      checkedIcon={iconChecked || false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
      height={height || 27 }
      width={width || 48}
      className="react-switch"
    />
  )
}

export default ToggleButton;
