import React, { useState } from 'react'
import DeleteConfirm from "../../users/deleteConfirm";

const RevokeMembership = ({ doRevoke }) => {
  let [isOpen, setIsOpen] = useState(false)

  return <>
    <div
      className='cursor-pointer bg-tomato-600 text-center px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-white rounded-full'
      onClick={() => setIsOpen(true)}>Revoke
    </div>
    {isOpen && <DeleteConfirm doDelete={doRevoke} cancel={() => setIsOpen(false)} />}
  </>
}

export default RevokeMembership
