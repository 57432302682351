import React, { useEffect, useState } from 'react'
import axios from 'axios'

export const CartContext = React.createContext({
  cart: null,
  setCart: null,
  isOpen: false,
  setIsOpen: null,
  addToCart: null,
  removeFromCart: null,
  increaseQuantity: null,
  decreaseQuantity: null,
  clearCart: null,
  isUpdatingCart: false,
  isAddingToCart: false
})

const Cart = ({ children }) => {
  const [cart, setCart] = useState()
  const [cartId, setCartId] = useState(localStorage.getItem('cartID'))
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdatingCart, setIsUpdatingCart] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);

  useEffect(() => {
    if (cartId) {
      loadCart();
    } else {
      setIsUpdatingCart(true);
      axios.post(`/carts.json`)
        .then((response) => {
          setCart(response.data)
          localStorage.setItem('cartID', response.data.id)
          return response.data
        }).finally(() => {
        setIsUpdatingCart(false);
      })
    }
  }, [cartId])

  function addToCart(productVariant, quantity, market_slug) {
    setIsUpdatingCart(true);
    setIsAddingToCart(true);
    axios.post(`/carts/${cartId}/cart_items`, { cart_item: {
        product_variant_id: productVariant.id,
        quantity: quantity,
        market_slug: market_slug
      }
    }).then((response) => {
      setCart(response.data)
      setIsOpen(true)
    }).finally(() => {
      setIsUpdatingCart(false);
      setIsAddingToCart(false);
    })
  }

  function increaseQuantity(cartItem) {
    setIsUpdatingCart(true);
    axios.post(`/carts/${cartId}/cart_items`, {
      product_variant_id: cartItem.product_variant.id,
      quantity: 1
    }).then((response) => {
      setCart(response.data)
    }).finally(() => {
      setIsUpdatingCart(false);
    })
  }

  function decreaseQuantity(cartItem) {
    if (cartItem.quantity <= 1) {
      removeFromCart(cartItem);
      return;
    }

    setIsUpdatingCart(true);
    axios.post(`/carts/${cartId}/cart_items`, {
      product_variant_id: cartItem.product_variant.id,
      quantity: -1
    }).then((response) => {
      setCart(response.data)
    }).finally(() => {
      setIsUpdatingCart(false)
    })
  }

  function removeFromCart(cartItem) {
    setIsUpdatingCart(true);
    axios.delete(`/carts/${cartId}/cart_items/${cartItem.id}`)
      .then((response) => {
        setCart(response.data)
      }).finally(() => {
      setIsUpdatingCart(false);
    })
  }

  function loadCart() {
    setIsUpdatingCart(true);
    axios.get(`/carts/${cartId}.json`).then(res => {
      setCart(res.data)
    }).finally(() => {
      setIsUpdatingCart(false);
    })
  }

  function clearCart() {
    setIsUpdatingCart(true)
    axios.delete(`/carts/${cartId}/cart_items/destroy_all`).then(res => {
      setCart(res.data);
    }).finally(() => {
      setIsUpdatingCart(false);
    })
  }

  return (
    <CartContext.Provider
      value={{
        cart: cart,
        setCart: setCart,
        isOpen: isOpen,
        setIsOpen: setIsOpen,
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        increaseQuantity: increaseQuantity,
        decreaseQuantity: decreaseQuantity,
        clearCart: clearCart,
        isUpdatingCart: isUpdatingCart,
        isAddingToCart: isAddingToCart
      }}>
      {children}
    </CartContext.Provider>
  )
}

export default Cart
