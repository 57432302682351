import React from 'react';

const MobileCardRow = ({ label, children, labelClassName, childrenClassName }) => {
  return (
    <section className='odd:white even:bg-beige-150 rounded-md'>
      <section className='grid grid-cols-2'>
        <span className={`text-sm text-left font-light opacity-75
        px-5 py-2 text-gray-700 ${labelClassName}`}>{label}</span>
        <span className={`text-sm text-right font-light px-5 py-2 ${childrenClassName}`}>{children}</span>
      </section>
    </section>
  )
}

export default MobileCardRow;
