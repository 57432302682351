import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons'

const MarketMemberships = ({ integration_id }) => {
  return (
    <div className='py-1'>
      <a
        className='btn btn-default btn-rounded text-white bg-black hover:bg-gray-800 
          flex flex-row justify-center items-center align-middle'
        href={`/merchants/integrations/${integration_id}/market_memberships/new`}
      >
        <FontAwesomeIcon icon={faPlusCircle} className='text-lg' />
        <span className='ml-2 hidden md:block'>Add Market</span>
      </a>
    </div>
  )
}

export default MarketMemberships
