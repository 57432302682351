import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/pro-regular-svg-icons'

const NoData = ({ className }) => {
  return (
    <div className={className}>
      <div className='flex justify-center'>
        <FontAwesomeIcon size='3x' icon={faWallet} />
      </div>
      <h3 className='text-lg text-center px-8 py-4 font-light'>You don't have any payments</h3>
    </div>
  )
}

export default NoData;
