import React from "react";
import PageTitle from './shared/pageTitle'
import SubHeader from "../page_body/shared_components/subHeader";
import ScrollToTop from '../../../utilities/scrollToTop';

const Section = ({ header, children }) => {
  return (
    <div className="flex flex-col mb-4">
      <h2 className="text-xl font-normal">{header}</h2>
      <p>{children}</p>
    </div>
  );
};

const ListSection = ({ marker, children, indent, bottom }) => {
  return (
    <div className={`flex flex-row mb-${bottom} ml-${indent} justify-start items-start`}>
      <p className="mr-1">{marker}</p>
      <p>{children}</p>
    </div>
  )
}

const Privacy = () => {
  return (
    <ScrollToTop>
      <SubHeader breadcrumbs='Privacy Policy' link='/privacy' />
      <div className='w-full bg-white border-b border-gray-300 flex flex-col justify-center items-center py-6 mb-3'>
        <PageTitle title={"Privacy Policy"} paragraph={""} />
      </div>
      <div className="flex flex-col bg-white">
        <div className="container mx-auto my-4">
          <h1 className="text-4xl font-normal pb-4">Privacy Policy</h1>

          <div className="flex flex-col">
            <Section>
              We wrote this policy to help you understand what information we
              collect, how we use it, and what choices you have. We welcome your
              questions and suggestions on our policy. A summary of the privacy
              policy and practices of the Local Shops website and app, in
              accordance with the standards set out by the Alberta Information and
              Privacy Commissioner pursuant to the Personal Information Protection
              Act and the GDPR (General Data Protection Regulation).
            </Section>

            <Section header="GDPR Compliance">
              Any personal information you are asked to provide via this website is
              collected in compliance with the GDPR (General Data Protection Regulation).
              Local Shops shall implement appropriate technical and organizational measures
              to ensure and to be able to demonstrate that the processing of User Data is
              performed in accordance with this Regulation. Those measures shall be reviewed
              and updated where necessary.
            </Section>

            <Section header="Our Commitment to Protect Your Personal Information">
              Local Shops is committed to providing our visitors with a website
              that respects their privacy. We do not sell this information to
              third parties. We gather some personal information from you, such as
              your name, location, e-mail address, and additional profile content
              for the purpose of managing our admin panel and providing
              location-based Products to shoppers within our platform. This
              information is only obtained when you supply it voluntarily at sign
              up, by contacting us by e-mail, or by sending us a message.
            </Section>

            <Section header="Local Shops collects information in a few different ways" />

            <Section header="1. When you give it to us or give us permission to obtain it">
              <p className="mb-4">
                When you sign up for or use our service, you voluntarily give us
                certain information. This can include your name, profile photo,
                Product Listings, the email address or phone number you used to
                sign up with, and any other information you provide. If you’re
                using Local Shops on your mobile device, you can also choose to
                provide us with location data. And if you choose to purchase
                something on Local Shops, you may provide us with payment
                information, contact information (ex, address and phone number),
                and what you purchased.
              </p>
              <p className="mb-4">
                You also may give us permission to access your information in
                other services. For example, you may link your Facebook, Google,
                YouTube or Twitter account to Local Shops, which allows us to
                obtain information from those accounts (like your friends or
                contacts). The information we get from those services often
                depends on your settings or their privacy policies, so be sure to
                check what those are.
              </p>
            </Section>

            <Section header="Personal Information Management">
              <p className="mb-4">
                Any personal information you do provide is managed according to
                the Personal Information Protection Act (Alberta) (“PIPA Act”) and GDPR.
                This means that, at the point of collection, you will be informed
                that your personal information is being collected, the purpose for
                which it is being collected, and a contact person who can answer
                any questions you may have regarding the collection activity will
                be identified. We collect only what is necessary for the operation
                of the website and to respond to your needs. Our use and
                disclosure of your personal information is limited by the privacy
                protection provisions of the PIPA Act and GDPR. When you visit this website
                and app, our server automatically collects a limited amount of
                standard information essential to the operation and evaluation of
                Local Shops. This information includes:
              </p>
              <ListSection marker="•" indent="2" bottom="2">
                the page from which you arrived
              </ListSection>

              <ListSection marker="•" indent="2" bottom="2">
                the date and time of your page request
              </ListSection>

              <ListSection marker="•" indent="2" bottom="2">
                the IP address your computer is using to
                receive information
              </ListSection>

              <ListSection marker="•" indent="2" bottom="2">
                the type and version of your browser
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                the name and size of the file you request
              </ListSection>

              <p className="mb-4">
                This information helps us assess our information services and is
                used only for this purpose. It is collected in compliance with the
                PIPA Act and GDPR. <br /> If you choose to provide your personal
                information in an e-mail to someone else, or by filling out a form
                with your personal information included and submitting it through
                our website – Local Shops will use that information to respond to
                your message and to help provide you with the information you have
                requested.
              </p>
            </Section>

            <Section header="2. We also get technical information when you use our products">
              <p className="mb-4">
                These days, whenever you use a website, mobile application, or
                other Internet service, there’s certain information that almost
                always gets created and recorded automatically. The same is true
                when you use our products. Here are some of the types of
                information we collect:
              </p>
              <p className="mb-4">
                <b>Log data.</b> When you use Local Shops, our servers
                automatically record information (“log data”), including
                information that your browser sends whenever you visit a website
                or your mobile app sends when you’re using it. This log data may
                include your Internet Protocol (IP) address, the address of the
                web pages you visited that had Local Shops featured, browser type
                and settings, the date and time of your request, how you used
                Local Shops, and cookie data.
              </p>
              <p className="mb-4">
                <b>Cookie data.</b> Cookies are small amounts of information that
                are distributed to web browsers to assist you when you return to
                this site or a specific area on the site. The cookie used here
                retains session information, or more specifically, the options
                selected to determine your itinerary or to keep track of the items
                you wish to purchase (session cookies remain active only until you
                close your browser). Local Shops does not keep a database of
                information obtained from these cookies.
              </p>
              <p className="mb-4">
                Cookies are small amounts of information that are distributed to
                web browsers to assist you when you return to this site or a
                specific area on the site. The cookie used here retains session
                information, or more specifically, the options selected to
                determine your itinerary or to keep track of the items you wish to
                purchase (session cookies remain active only until you close your
                browser). Local Shops does not keep a database of information
                obtained from these cookies.
              </p>
              <p className="mb-4">
                <b>Device information.</b> In addition to log data, we may also
                collect information about the device you’re using Local Shops on,
                including what type of device it is, what operating system you’re
                using, device settings, unique device identifiers, and crash data.
                Whether we collect some or all of this information often depends
                on what type of device you’re using and its settings. For example,
                different types of information are available depending on whether
                you’re using a Mac or a PC, or an iPhone or an Android phone. To
                learn more about what information your device makes available to
                us, please also check the policies of your device manufacturer or
                software provider.
              </p>
            </Section>

            <Section header="3. Online Transactions">
              When you ask to purchase a Product from this site, you will have to
              submit personally identifying information, such as your name, the
              name of your company or organization (where applicable) your
              shipping and billing address(es), your phone and fax numbers, your
              e-mail address, the service(s) you have purchased, your credit card
              preference, and your credit card number and expiry date. We require
              this information so that we can deliver the product you request and
              collect payment from you for the product. When you submit this
              information, it is stored in a secure server. We use Stripe for
              payment processing and our server is Amazons Secure Server (AWS).
              The information collected is used to process and deliver your order
              and collect payment, and it is collected in compliance with the PIPA
              Act. This information is not disclosed to any Third Party except to
              authorized employees of Local Shops Inc. who need it to process your
              order and bill or as required by law. Third Party payment gateways,
              like PayPal, Square and Shopify Pay may also be used by shoppers at
              checkout, please refer to their privacy policy for details on use.
              The contact information you provide may also be used to contact you
              in the future about your order.
            </Section>

            <Section header="4. Alternate purchasing options">
              Although our online purchase system is secure, some versions of
              browsers as well as some firewalls will not permit on-line
              transactions. This feature protects you from placing orders through
              other servers that are not secure. If you are unable to access our
              purchase payment system for whatever reason, we encourage you to
              place your order in person, or by fax, mail, or telephone.
            </Section>

            <Section header="5. Our partners and advertisers may share information with us">
              <p className="mb-4">
                We may get information about you and your activity off Local Shops
                from our affiliates, advertisers, partners and other third parties
                we work with. For example:
              </p>
              <ListSection marker="•" indent="2" bottom="4">
                Online advertisers typically share information with the websites
                or apps where they run ads to measure and/or improve those ads. We
                also receive this information, which may include information like
                whether clicks on ads led to purchases or a list of criteria to
                use in targeting ads.
              </ListSection>
            </Section>

            <Section header="How do we use the information we collect?">
              <p className="mb-4">
                We use the information we collect to provide our services to you
                and make them better, develop new products, and protect Local
                Shops and our users. For example, we may log how often people use
                two different versions of a Product, which can help us understand
                which version is better. If you make a purchase on Local Shops, we
                may save your payment information and contact information so that
                you can use them the next time you want to buy something on Local
                Shops.
              </p>
              <p className="mb-4">
                We also use the information we collect to offer you customized
                content, including:
              </p>
              <ListSection marker="•" indent="2" bottom="4">
                Suggesting Products that you may like and businesses you may
                want to connect with. For example, if you’ve indicated that you’re
                interested in home décor or visited home décor websites that have
                Local Shops features, we may suggest home décor products or
                businesses that we think may be valuable to connect with..
              </ListSection>

              <p className="mb-4">We also use the information we collect to:</p>

              <ListSection marker="•" indent="2" bottom="2">
                Send you notifications (such as when certain activity happens on
                Local Shops) for newsletters, marketing materials, engagement
                updates and other information that may be of interest to you. For
                example, depending on your email notification settings, we may
                send you weekly updates that includes content you may like. You
                can decide to stop getting these updates by updating your account
                and email settings (or through other settings we may provide).
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                Respond to your questions or comments.
              </ListSection>
            </Section>

            <Section header="Transferring your information">
              <p className="mb-4">
                Local Shops is a worldwide service. By using our products or
                services, you authorize us to transfer and store your information
                outside of your home country, including in Canada, for the
                purposes described in this policy. The privacy protections and the
                rights of authorities to access your personal information in such
                countries may not be equivalent to those in your home country.
                What choices do you have about your information? <br /> Our goal
                is to give you simple and meaningful choices over your
                information. If you have a Local Shops account, many of the
                choices you have on Local Shops are built directly into the
                product or your account settings. For example, you can:
              </p>

              <ListSection marker="•" indent="2" bottom="0">
                Access and change information in your profile page at any time.
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                Close your account at any time. You will need to contact
                use directly to close your account, we’ll deactivate it and remove
                your Products and profile from Local Shops. We may retain archived
                copies of your information as required by law or for legitimate
                business purposes (including to help address fraud and spam).
              </ListSection>

              <p className="mb-4">
                You may have choices available to you through the device or
                software you use to access Local Shops. For example:
              </p>

              <ListSection marker="•" indent="2" bottom="0">
                The browser you use may provide you with the ability to control
                cookies or other types of local data storage.
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                Your mobile device may provide you with choices around how and whether
                location or other data is shared with us.
              </ListSection>

              <p className="mb-4">
                To learn more about these choices, please see the information
                provided by the device or software provider.
              </p>
            </Section>

            <Section header="How and when do we share information?">
              <p className="mb-4">
                Anyone can see your Public Profile and the Products you create and
                the information you share on your page. We may also make this
                public information available through what are called “APIs”
                (basically a technical way to share information quickly). For
                example, a partner company might use a Local Shops API to study
                what their most popular Products are or how their Products are
                being engaged on Local Shops. The other limited instances where we
                may share your personal information include:
              </p>
              <ListSection marker="•" indent="2" bottom="4">
                When we have your consent. This includes sharing information
                with other services (like Facebook, Google, YouTube or Twitter)
                when you’ve chosen to link to your Local Shops account to those
                services or publish your activity on Local Shops to them. Online
                advertisers typically use third party companies to audit the
                delivery and performance of their ads on websites and apps. We
                also allow these companies to collect this information on Local
                Shops.
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                We may employ third party companies or individuals to process
                personal information on our behalf based on our instructions and
                in compliance with this Privacy Policy. For example, we share
                credit card information with the payment companies we use to store
                your payment information. Or, we may share data with a security
                consultant to help us get better at identifying spam. In addition,
                some of the information we request may be collected by third party
                providers on our behalf. For more information about the providers
                we use, please see our FAQs page.
              </ListSection>

              <ListSection marker="•" indent="2" bottom="4">
                If we believe that disclosure is reasonably necessary to comply
                with a law, regulation or legal request; to protect the safety,
                rights, or property of the public, any person, or Local Shops; or
                to detect, prevent, or otherwise address fraud, security or
                technical issues. We may share the information described in this
                Policy with our wholly-owned subsidiaries and affiliates. We may
                engage in a merger, acquisition, bankruptcy, dissolution,
                reorganization, or similar transaction or proceeding that involves
                the transfer of the information described in this Policy. We may
                also share aggregated or non-personally identifiable information
                with our partners, advertisers or others. For example, we may tell
                an advertiser on Local Shops how many people saved something they
                shared, or the percentage of people who click on a Product after
                viewing it.
              </ListSection>
            </Section>

            <Section header="6. Security">
              For site security purposes and to ensure that this service remains
              available to all users, the Local Shops computer system employs
              software programs to monitor network traffic to identify
              unauthorized attempts to upload or change information, or otherwise
              cause damage. Except for authorized law enforcement investigations,
              no other attempts are made to identify individual users or their
              usage habits.
            </Section>

            <Section header="7. Links to Other Sites">
              This website contains links to external sites. Local Shops is not
              responsible for the content and the privacy practices of other
              websites, and encourages you to examine each site’s privacy policy
              and disclaimers in order to make your own decisions regarding the
              accuracy, reliability and correctness of material and information
              found there.
            </Section>

            <Section header="8. Retention of Your Information">
              Any personal information you are asked to provide via this web site
              is collected in compliance with the PIPA Act and GDPR. Only information
              necessary for the operation of the website and the provision of
              requested materials or services to you is collected. The information
              is used only for the purpose it was collected or for a consistent
              purpose. Furthermore, the information is only retained for the
              length of time necessary to fulfill the purpose for which it was
              collected.
            </Section>

            <Section header="Data Erasure">
              <p className="mb-2">The data subject shall have the right to obtain from Local Shops the erasure
                of personal data concerning him or her without undue delay and Local Shops shall
                have the obligation to erase personal data without undue delay where one of the
                following applies:</p>
              <ListSection marker="1." indent="2" bottom="2">
                The personal data collected is no longer necessary in relation to the
                purposes for which it was collected or otherwise processed.
              </ListSection>

              <ListSection marker="2." indent="2" bottom="2">
                The data subject withdraws consent on which the processing of their data
                is based for one or more specific purposes or the processing of personal data
                revealing racial or ethnic origin, political opinions, religious or philosophical
                beliefs, or trade union membership, and the processing of genetic data, biometric
                data for the purpose of uniquely identifying a natural person, data concerning
                health or data concerning a natural person's sex life or sexual orientation that
                has been explicitly consented to processing by the data subject for one or more
                specific purposes, except when Union or Member State law provides that the
                prohibition referred to may not be lifted by the data subject, and there is no
                other legal ground for the processing of their data.
              </ListSection>

              <ListSection marker="3." indent="2" bottom="2">
                The data subject shall have the right to object, on grounds relating to their
                particular situation, at any time to processing of personal data concerning him or
                her that is based on 3(1) and 3(2) including profiling based on those provisions.
                Local Shops shall no longer process the personal data unless Local Shops demonstrates
                compelling legitimate grounds for the processing which override the interests, rights
                and freedoms of the data subject or for the establishment, exercise or defence of
                legal claims.

                <ListSection marker="1." indent="2" bottom="2">
                  For the performance of a task carried out in the public interest or in the exercise
                  of official authority vested in Local shops
                </ListSection>

                <ListSection marker="2." indent="2" bottom="0">
                  For the purposes of the legitimate interests pursued by Local Shops or by a third party,
                  except where such interests are overridden by the interests or fundamental rights and freedoms
                  of the data subject which require protection of personal data, in particular where the data
                  subject is a child.
                </ListSection>
              </ListSection>

              <ListSection marker="4." indent="2" bottom="2">
                The personal data being collected has been unlawfully processed;
              </ListSection>

              <ListSection marker="5." indent="2" bottom="2">
                The personal data have to be erased for compliance with a legal obligation in Union or Member
                State law to which the controller is subject;
              </ListSection>

              <ListSection marker="6." indent="2" bottom="4">
                The personal data is collected in relation to the offer of information society services when the
                data subject has given consent to the processing of his or her personal data for one or more specific
                purposes, when concerning the offer of information services directly to a child, the processing of
                the personal data of a child shall be lawful where the child is at least 16 years old. Where the child
                is below the age of 16 years, such processing shall be lawful only if and to the extent that consent
                is given or authorised by the holder of parental responsibility over the child. Member States may provide
                by law for a lower age for those purposes provided that the lower age is not below 13 years.
              </ListSection>
              <a className="text-blue-700 hover:underline" href="https://gdpr-info.eu/chapter-1/">General Data Protection Regulation (GDPR)</a>
            </Section>

            <Section header="Our policy on children’s information">
              Local Shops is not directed to children under the age of 14. We are
              an available service for all shoppers with a valid credit card and
              for businesses with valid products. If you learn that your minor
              child has provided us with personal information without your
              consent, please contact us. We aim to protect our users by
              monitoring for malicious content, abusive content and fraudulent
              content. Any user found to abuse our policy will be removed from the
              Local Shops community and subsequent legal action my follow.
            </Section>

            <Section header="How do we make changes to this policy?">
              We may change this policy from time to time, and if we do we’ll post
              any changes on this page. If you continue to use Local Shops after
              those changes are in effect, you agree to the revised policy. If the
              changes are significant, we may provide more prominent notice or get
              your consent as required by law.
            </Section>

            <Section header="How can you contact us?">
              The best way to contact us is online through the Contact tab found
              at the bottom of the main page. If you live in Canada or outside,
              Local Shops is responsible for your information. Our President and
              Founder can be reached directly by phone at 1-587-255-5681, or email
              [ryan@madeinlocalshops.com](mailto:ryan@madeinlocalshops.com) to
              discuss any questions or issues you may have.
            </Section>
          </div>
        </div>
      </div>
    </ScrollToTop>
  );
};

export default Privacy;
