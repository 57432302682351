import React from 'react';

const IMGButton = ({
  src,
  label,
  onClick,
  className,
  imgClassName,
  labelClassName,
  isDisabled
}) => {
  return (
    <button className={className} onClick={onClick} disabled={isDisabled} >
      <img src={src} className={`inline-block ${imgClassName}`} />
      <span className={labelClassName}>{label}</span>
    </button>
  )
}

export default IMGButton;
