import React, { useState } from 'react'
import { formatPrice } from '../../../../utilities/formatPrice'
import CountryIcon from '../shared_components/countryIcon'
import ProductFavorite from '../shared_components/productFavorite'

const SmallProductCard = ({ product, hideCity, heart_pos }) => {
  let [productImage, setProductImage] = useState(product.thumbnail_image_url)
  const transitionGrowClass =
    'transition duration-500 ease-in-out hover:shadow-xl'
  return (
    product && (
      <a
        className={`truncate rounded-t-xl`}
        href={`${product.path}${location.search}`}
        title={`${product.title}`}
      >
        <div className={heart_pos || 'absolute top-4 right-5'}>
          <ProductFavorite product={product}/>
        </div>
        <div style={{ backgroundImage: `url('${productImage}')` }}
          className={`h-64 w-full bg-cover bg-center p-8 rounded-xl ${transitionGrowClass}`}>
        </div>
        <div className='flex justify-between pt-2 max-w-full'>
          <div className='flex flex-col w-full'>
            <div className='flex items-center justify-between'>
              <div className='font-semibold truncate pr-2'>{product.title}</div>
             </div>
            <div className="flex flex-col justify-between">
              <div className=' flex-grow text-mustard-400 text-sm truncate'>{product.integration.name}</div>
              <div className='flex items-center justify-between mt-1'>
                <div className=' flex-grow font-bold text-sm text-black'>{product?.product_variant && formatPrice(product.product_variant.price_cents)}</div>
                <div>{product.is_made_locally && (
                  <CountryIcon countrySlug={product.city.country.slug} />
                )}</div>
                {!hideCity && <div className='font-semi-bold truncate text-xs text-gray-700'>{product.city.name}</div>}
              </div>
            </div>
          </div>
        </div>
      </a>
    )
  )
}

export default SmallProductCard
