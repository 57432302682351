import React from 'react'
import { statusLabel } from "./shared_components/statusLabel";
import FulfillButton from "./shared_components/fulfillButton";
import CustomerContactInformation from "./customerContactInformation";
import OrderSection from "./table_components/orderSection";
import ExtraChargesSection from "./table_components/extraChargesSection";

const OrderPage = ({ orderIntegration }) => {
  return (
    <div className="flex items-center w-full flex-col-reverse md:flex-row">
      <div
        className='w-full mx-auto col-span-3 flex flex-col justify-between pt-4 pb-10 px-2 md:px-10 mb-4 md:mb-0 bg-white rounded-lg'>
        <div className='flex flex-col items-start'>
          <div className='flex items-center justify-between w-full'>
            <p className='px-2'>Order ID: {orderIntegration.order.id}</p>
            <p>{statusLabel(orderIntegration.order.fulfillment_status)}</p>
          </div>
          <p className='text-gray-700 p-2'>{orderIntegration.order.format_date}</p>
        </div>
        <hr />

        <div className='flex items-center justify-between flex-col p-2'>
          {orderIntegration.order_items.map((item) => {
            return (
              <OrderSection item={item} />
            )
          })}
        </div>
        <div className='flex items-center justify-between flex-col p-2'>
          <ExtraChargesSection orderIntegration={orderIntegration} />
        </div>
        <div className='flex flex-col items-end justify-end w-full'>
          <FulfillButton orderID={orderIntegration.order.id} integrationID={orderIntegration.integration_id}
                         orderIntegrationID={orderIntegration.id} />
        </div>
      </div>
      <div className="md:w-1/2 w-full px-4">
        <CustomerContactInformation deliveryAddress={orderIntegration.order.delivery_address}
                                    customer={orderIntegration.customer} />
      </div>
    </div>
  )
}

export default OrderPage
