import React from 'react';
import MyProductsSelectStyle from "../../../../constants/custom_styles/myProductsSelectStyle";
import { ShippingRateTypes } from '../../../../constants/dropdown_options/shippingRateTypes';
import Select from 'react-select';

const RateTypeSelect = ({ values, getFieldName, updateFormikValue, className }) => {
  return (
    <Select
      value={ShippingRateTypes.find(option => option.value === values[getFieldName('shipping_type')]) || ShippingRateTypes[0]}
      styles={MyProductsSelectStyle}
      components={{IndicatorSeparator: () => null}}
      isClearable={false}
      options={ShippingRateTypes}
      className={className}
      onChange={option => updateFormikValue(getFieldName('shipping_type'), option.value)}
    />
  )
}

export default RateTypeSelect;
