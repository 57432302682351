import React from 'react';
import FormButton from '../shared/form_fields/FormButton';

export const SubmitAndCancel = ({
  formId,
  className,
  onCancel,
  cancelLabel,
  submitLabel,
  isSubmitting,
  canCancel
}) => {
  return (
    <section className={`flex flex-row justify-end ${className}`}>
      {
        canCancel &&
        <button type='button' onClick={onCancel}
          className='rounded-full px-10 py-3 mr-2 relative bg-gray-200 text-sm'>{cancelLabel}</button>
      }
      <FormButton type='submit' isLoading={isSubmitting} formId={formId}
        className='ml-1 rounded-full bg-mustard-400 flex justify-center px-10 py-3'
        loaderContainerClassName='w-5 self-center mr-1'>
        <span className='text-sm text-white self-center'>{submitLabel}</span>
      </FormButton>
    </section>
  )
}
