import React, { useState } from 'react';
import FloatingLabel from "floating-label-react";

const FormInputRow = ({
  name,
  type,
  placeholder,
  value,
  isDisabled,
  inputClassName,
}) => {

  const [inputValue, setInputValue] = useState(value)

  return (
    <section>
      <FloatingLabel
        id={name}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={event => setInputValue(event.target.value)}
        value={inputValue}
        disabled={isDisabled}
        className={`border-2 border-mustard-light rounded-md ${inputClassName}`}
      />
    </section>
  )
}

export default FormInputRow;
