import * as Yup from 'yup';
import ErrorMessages from '../errorMessages';

const RefundRequestValidation = Yup.object().shape({
  order_item_id: Yup.number().typeError(ErrorMessages.ItemNotSelected),
  reason: Yup.string().required(ErrorMessages.ReasonNotProvided),
  quantity: Yup.number().required(ErrorMessages.FieldRequired)
});

export default RefundRequestValidation;
