import React, { useState } from 'react'
import useInterval from '../../../../hooks/use_interval'
import { toast } from 'react-toastify'
import Button from '../../products_dashboard/forms/shared_components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faSyncAlt } from '@fortawesome/pro-duotone-svg-icons'
import axios from 'axios'

const SynchronizeProducts = ({ integration }) => {
  const [jobId, setJobId] = useState(integration.active_job_id)
  const [loading, setLoading] = useState(false)

  useInterval(async () => {
    if (jobId && loading) {
      const response = await pollJobStatus()
      if (response.data.status === 'complete') {
        toast.success(
          'Your products have been successfully imported and are now being listed on Local Shops! Go to Your Products to view your listed items.'
        )
        setLoading(false)
        window.location.href = `/merchants/integrations/${integration.id}/products`
      }
    }
  }, 3000)

  const ImportingSpinner = () => {
    return (
      <Button color='yellow-600' hover='yellow-500'>
        <FontAwesomeIcon
          icon={faSpinner}
          spin={true}
          className='text-white text-xl'
        />
      </Button>
    )
  }
  const loadProducts = () => {
    setLoading(true)
    toast.success(
      'Your products are being imported. This may take a few minutes, depending on the size of your shop.'
    )

    axios
      .get(`/merchants/integrations/${integration.id}/import_products`)
      .then((res) => {
        setJobId(res.data.id)
      })
      .catch((err) => console.log(err))
  }

  const pollJobStatus = async () => {
    return axios.get(`/merchants/import_product_jobs/${jobId}`)
  }

  const ImportProductsButton = () => {
    return (
      <Button color='yellow-600' hover='yellow-500' onClick={loadProducts}>
        <FontAwesomeIcon icon={faSyncAlt} /> Refresh
      </Button>
    )
  }

  const InstagramButton = () => {
    return (
      <Button
        color='yellow-600'
        hover='yellow-500'
        onClick={() =>
          (window.location.href = `/merchants/integrations/${integration.id}/load_images`)
        }>
        <FontAwesomeIcon icon={faSyncAlt} /> Refresh
      </Button>
    )
  }

  const ImportButton = () => {
    switch (integration.provider) {
      case 'instagram_graph':
        return <InstagramButton />
      case null:
        return null
      default:
        return <ImportProductsButton />
    }
  }

  return loading ? <ImportingSpinner /> : <ImportButton />
}

export default SynchronizeProducts
