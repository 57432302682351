import React, { useContext, useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { faStar as faStarFilled } from "@fortawesome/pro-solid-svg-icons";
import axios from "axios";
import FavoritesContext from "../../../../contexts/favoritesContext";
import { toast } from 'react-toastify'

const ShopFavorite = ({ shop }) => {
  let { token, shopFavorites } = useContext(FavoritesContext)
  let [isFavorited, setIsFavorited] = useState(shop.is_favorited)

  useEffect(() => {
    setIsFavorited(shopFavorites?.shops.find(fav => fav.slug === shop.slug))
  }, [shopFavorites])


  const toggleFavorite = (e) => {
    e.preventDefault()
    if (isFavorited) {
      unfavorite(shop)
      return
    }

    axios({
      method: 'post',
      url: `/users/saved_sellers`,
      data: { authenticity_token: token, integration_id: shop.slug }
    }).then((resp) => {
      if (resp.request.responseURL.includes('saved_sellers')) {
      toast.success(`Successfully saved`);
      setIsFavorited(true)
    } else {
      toast.error('Please log in if you want to save shops')
    }
    }).catch((err) => {
      toast.error('There was an error')
    })
  }

  const unfavorite = () => {
    axios
      .delete(`/users/saved_sellers/${shop.slug}`, { data: { authenticity_token: token } })
      .then((res) => {
        toast.success('Seller was removed from your favorites');
        setIsFavorited(false)
      })
  }

  return (
    <div onClick={toggleFavorite}
      className='border border-beige-200 rounded-full ml-2 mr-2 py-2 px-3 cursor-pointer transition duration-500 ease-in-out transform hover:-translate-y-1'>
      {isFavorited ?
        <FontAwesomeIcon icon={faStarFilled} className='text-base text-yellow-500' />
        :
        <FontAwesomeIcon icon={faStar} className='text-base' />
      }
    </div>
  )

}

export default ShopFavorite
