import React, {useState} from "react";
import DashboardLinks from "./dashboardLinks"

const MobileSideBar = ({current_path, integration, callback}) => {
  
  
  return (
    <div className="block xl:hidden flex flex-row h-screen fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="py-4 pl-4 w-5/6 md:w-1/2 h-screen shadow-lg bg-white fixed">
        <div className="flex flex-col">
          <div className="flex flex-row w-full items-center justify-between">
            <h4>Menu</h4>
            <div className="pr-3" onClick={callback}>
              <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                <path
                  fillRule="evenodd"
                  d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                />
              </svg>
            </div>
          </div>
          <DashboardLinks current_path={current_path} integration={integration}/>
          </div>
      </div>
    </div>
  );
};

export default MobileSideBar;
