import React from 'react'
import PaypalLogo from "../../../../../assets/images/paypal_logo.png";
import ShopifyLogo from "../../../../../assets/images/shopify_logo.png";
import WixLogo from "../../../../../assets/images/wix_logo.png";
import WooLogo from "../../../../../assets/images/woo_logo.png";

export const InformationBlock = ({ bgColor, title, children, price }) => {
  return (
    <div
      className={`${bgColor} rounded-lg text-left z-10 border-4 border-white p-2 w-full`}
    >
      <div className="p-2 lg:p-6 text-xl">
        <Label title={title} />
        <div className='py-4'>
          {children}
        </div>
        <hr className='border border-white' />
        <Integrations />
        <hr className='border border-white' />
        <div className='py-4'>
          <div className='flex items-center'>
            <Price price={price} />
          </div>
        </div>
      </div>
      <div className='flex'>
        <CalendarButton />
      </div>
    </div>
  )
}

export const Label = ({ title }) => {
  return (
    <label className="text-md text-light-orange lg:text-5xl py-4">
      {title}
    </label>
  )
}

export const Integrations = () => {
  return (
    <div className='py-6 xl:block hidden'>
      <p className='font-semibold pb-2 text-center text-xl'>Integrates with</p>
      <div className='flex items-center gap-x-2 w-full justify-around'>
        <img alt='payapl logo' src={PaypalLogo} />
        <img alt='shopify logo' src={ShopifyLogo} />
        <img alt='wix logo' src={WixLogo} />
        <img alt='woo logo' src={WooLogo} />
      </div>
    </div>
  )
}

export const Price = ({ price }) => {
  return (
    <>
      <p className='font-semibold text-4xl'> {price ? price : 'Custom Rate'} </p>
      <span>{price && '/monthly'}</span>
    </>
  )
}

export const CalendarButton = ({ text, className }) => {
  return (
    <a href='https://calendly.com/localshops' target='_blank' rel="noopener noreferrer"
       className={`${className ? className : 'bg-light-orange w-full '} text-black rounded-md font-semibold text-center p-2`}>
      {text ? text : 'Select'}
    </a>
  )
}
