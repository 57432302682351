import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Carousel from "react-multi-carousel";
import carouselBreakpoints from "../../../../../utilities/carouselBreakpoints";

export const ShopInfoCardSkeleton = () => {
  return (
    <div className='border border-gray-300 rounded-lg p-2 '>
      <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between p-2'>
        <div className='flex items-center'>
          <Skeleton width={64} height={64} circle={true} />
          <h3 className='px-2 text-sm lg:text-lg font-semibold'><Skeleton width={250} /></h3>
        </div>
        <div className='flex flex-col text-sm'>
          <span className='text-gray-600'><Skeleton count={2} width={80} /></span>
        </div>
        <div className='flex items-center'>
          <Skeleton
            width={200}
            className='rounded-full py-2 px-4 m-2 text-center cursor-pointer flex-1 md:flex-none'
          />
          <Skeleton circle={true} width={30} height={30} />
        </div>
      </div>
      <div
        className='w-full text-gray-900 antialiased  py-4 '>
        <Carousel responsive={carouselBreakpoints(6, 5, 2, 1)}>
          {[0, 1, 2, 3, 4, 5].map(() => (
            <div className='px-2'>
              <Skeleton width={200} height={250} />
              <SmallProductCardSkeleton />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  )
}

export const SmallProductCardSkeleton = () => {
  return (
    <a
      className={`truncate rounded-t-xl`}
    >
      <div className='flex justify-between pt-2 max-w-full'>
        <div className='flex flex-col max-w-full'>
          <div className='flex items-center justify-between'>
            <div className='font-semi-bold truncate px-2'>
              <Skeleton width={80} />
            </div>
            <Skeleton circle={true} width={20} height={20} />
          </div>
          <div className="flex flex-col justify-between">
            <div className='flex items-center justify-between'>
              <div className=' flex-grow font-semibold text-sm text-gray-700 px-2'>
                <Skeleton width={80} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </a>
  )
}

export const LargeProductCardSkeleton = () => {
  return (
    <div className='flex flex-col gap-y-2'>
      <Skeleton height={240} />
      <Skeleton height={18} width='70%' />
      <Skeleton height={12} count={2} width='50%' />
    </div>
  )
}

export const ImageCardSkeleton = () => {
  return (
    <>
      <div className="hidden md:block">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`cursor-pointer  rounded-xl py-1 flex flex-row items-start lg:flex-row lg:items-center lg:justify-start lg:gap-x-1 p-5 py-6 w-full h-44  `}
        >
          <div>
            <p className='text-lg font-semibold mb-1 text-gray-900 mb-1 mt-2'><Skeleton /></p>
            <p className='text-xs text-gray-800'><Skeleton count={2} /></p>
          </div>
        </a>
      </div>

      <div className="visible md:hidden">
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={`cursor-pointer rounded-xl border border-light-orange flex flex-row lg:flex-row lg:items-center lg:justify-start lg:gap-x-1 items-center p-6 w-full `}
        >
          <div>
            <p className='text-lg font-semibold text-gray-900'><Skeleton /></p>
            <p className='text-xs text-gray-800'><Skeleton count={2} /></p>
          </div>
        </a>
      </div>
    </>
  )
}

