import React from 'react'
import PropTypes from 'prop-types'

const Widget = ({ title = null, description = null, right = null, children, className }) => {
  return (
    <div className={`bg-white border border-gray-100 ${className}`}>
      {(title || description || right) && (
        <div className="flex flex-row  items-center justify-between mb-6">
          <div className="flex flex-col">
            <div className="text-sm font-normal text-gray-900">{title}</div>
            <div className="text-sm font-light">{description}</div>
          </div>
          {right}
        </div>
      )}
      {children}
    </div>
  )
}

export default Widget
