import React from 'react';
import { ErrorMessage } from 'formik';

const FormCheckbox = ({
  name,
  onChange,
  isChecked,
  errorClassName
}) => {
  return (
    <section>
      <label className='flex'>
        <input className='w-5 h-5 mr-2' type="checkbox" name={name} checked={isChecked} onChange={onChange} />
        <span className='text-sm font-hairline'>Select as default delivery address</span>
      </label>
      <ErrorMessage
        name={name}
        render={errorMessage => (
          <span className={`text-red-400 text-sm ${errorClassName}`}>{errorMessage}</span>
        )}
      />
    </section>
  )
}

export default FormCheckbox;
