import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagramSquare, faShopify, faWix, faWordpress } from "@fortawesome/free-brands-svg-icons";
import { faStore } from "@fortawesome/pro-solid-svg-icons";

export const ShopIcon = ({ shop, className }) => {
  switch (shop.provider) {
    case 'woocommerce':
      return <FontAwesomeIcon icon={faWordpress} className={`text-blue-700 ${className}`} />
    case 'wix':
      return <FontAwesomeIcon icon={faWix} className={`text-black ${className}`} />
    case 'shopify':
      return <FontAwesomeIcon icon={faShopify} className={`text-green-500 ${className}`} />
    case 'instagram_graph':
      return <FontAwesomeIcon icon={faInstagramSquare} className={`text-pink-600${className}`} />
    default:
      return <FontAwesomeIcon icon={faStore} className={className} />
  }
}
