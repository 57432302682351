import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-light-svg-icons";

export const statusLabel = (status) => {
  return (
    status ?
      <div className='p-1 border bg-green-700 font-bold flex items-center rounded'>
        <FontAwesomeIcon className='text-white' icon={faCircle} />
        <span className='text-white px-2'>Fulfilled</span>
      </div>
      :
      <div className='p-1 border bg-yellow-600 font-bold flex items-center rounded'>
        <FontAwesomeIcon className='text-white' icon={faCircle} />
        <span className='text-white px-2'>Unfulfilled</span>
      </div>
  )
}
