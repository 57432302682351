import React from 'react'
import MobileSubCategoryItem from './mobileSubCategoryItem'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons'
import CategoryLink from "../../../page_body/shared_components/categoryLink";

export default function MobileCategoryItem({ category, selectedCategory, setSelectedCategory, setVisible}) {
  const isCategorySelected = (category) => {
    return (selectedCategory && selectedCategory.id === category.id)
  }

  return (
    <div
      key={category.id}
    >
      <div className="w-full flex flex-row justify-end text-sm">
        {category.children.length > 0 && (
           <button
             className="flex flex-row items-center py-4"
             onClick={(e) => {
               e.stopPropagation()
               category.children.length > 0 && (
                 setSelectedCategory(category)
               )
             }}
           >
             {<FontAwesomeIcon className='text-medium-grey' icon={isCategorySelected(category) ? faMinus : faPlus} />}
            </button>
        )}
        <div className={`w-11/12 ml-4 py-4`}>
          <CategoryLink
            category={category}
            className={`hover:underline ${isCategorySelected(category) && "font-semibold"} cursor-pointer text-medium-grey`}
          >
            <span onClick={setVisible}>{category.name}</span>
          </CategoryLink>
        </div>
      </div>
      { isCategorySelected(category) && (
        category.children.map((subcategory) => {
          return (
            <MobileSubCategoryItem
              key={subcategory.id}
              subcategory={subcategory}
              setVisible={setVisible}
            />
          )})
      )}
    </div>
  )
}
