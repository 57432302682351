import React from 'react';

const TableCell = ({ headerClassName, labelClassName, data }) => {
  return (
    <tr className={headerClassName}>
      <td className={`${labelClassName} text-left`}>{data.payoutDate}</td>
      <td className={`${labelClassName} text-left`}>{data.payoutAmount}</td>
      <td className={`${labelClassName} text-left`}>{data.orderNumber}</td>
      <td className={`${labelClassName} text-left`}>{data.customerName}</td>
      <td className={`${labelClassName} text-right`}>{data.shipping}</td>
    </tr>
  )
}

export default TableCell;
