import React from 'react';

const TableHeader = ({ labelClassName, areAllSelected, onSelectAll }) => {
  return (
    <thead className='bg-beige-150'>
      <tr className='mb-2'>
        <th className={`${labelClassName} text-center`}>
          <div className='flex justify-center'>
            <input type='checkbox' value='isSelectAll'
              checked={areAllSelected} onChange={onSelectAll}
              className='h-5 w-6'
            />
          </div>
        </th>
        <th colSpan='2' className={labelClassName}>Product Name</th>
        <th className={`hidden md:table-cell ${labelClassName}`}>Category</th>
        <th className={`hidden md:table-cell ${labelClassName}`}>Store</th>
        <th className={labelClassName}>Action</th>
        <th className={labelClassName}>Listed</th>
      </tr>
    </thead>
  )
}

export default TableHeader;
