import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons'

export default function QuestionDropdown({question, children}) {
  const [isVisible, setIsVisible] = useState(false)

  const Dropdown = () => {
    return (
      <div className="text-gray-800 border-t border-gray-300 px-6 py-4">
        <p>{children}</p>
      </div>
    )
  }

  return (
    <div 
      className="w-full bg-white rounded-lg flex flex-col text-black cursor-pointer mb-3 shadow" 
      onClick={() => setIsVisible(prev => !prev)}
    >
      <div className="flex flex-row justify-between items-center px-6 py-4 text-lg">
        <span>{question}</span>
        <span className="flex flex-row items-center">[{<FontAwesomeIcon icon={isVisible ? faMinus : faPlus} />}]</span>
      </div>
      { isVisible && (
        <Dropdown />
      )}
    </div>
  )
}
