import React from 'react'
import IntegrationProductListing from "./product_listings/integration_products/integration_product_listing";

const MerchantProducts = ({ integration, categories }) => {
  return (
    <div>
      <IntegrationProductListing categories={categories} integration={integration} />
    </div>
  )
}

export default MerchantProducts
