import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowNav } from "../../merchants/side-bar-icons";

const Link = ({
                url,
                text,
                className,
                dataMethod,
                isSubLink,
                children,
                height,
                id,
                icon,
                current_path
              }) => {


  const isCurrentPath =
    (id === "markets" && current_path === "markets") ||
    (id === "market_memberships" && current_path === "market_memberships") ||
    (id === "market_admins" && current_path === "market_admins") ||
    (id === "analytics" && current_path === "analytics") ||
    (id === "market_edit" && current_path === "edit") ||
    (id === "user_edit" && current_path === "edit")

  const boldCurrentPath =
    (isCurrentPath &&
      "text-black hover:text-light-orange") ||
    "text-gray-600 hover:text-black";
  return (
    (
      <li
        className={`pl-3 xl:pl-20 list-none whitespace-no-wrap ${className} text-gray-600 rounded-full hover:text-black hover:bg-light-orange hover:shadow-item`}
      >
        <div
          className={`flex flex-row w-full items-center justify-between ${height}`}
        >
          <a href={url} data-method={dataMethod} className={boldCurrentPath}>
              <span className="flex items-center">
                <FontAwesomeIcon icon={icon} className='px-2' />
                  <p className="w-full text-center">{text}</p>

              </span>
          </a>

          {
            <div className={(isCurrentPath && "invisible xl:visible") || "invisible"}>
              <ArrowNav />
            </div>
          }
        </div>
      </li>
    )
  )
}

export default Link
