import React from 'react'

const ComingSoon = () => {
  return (
    <div className='max-w-lg flex flex-col mx-auto lg:my-4'>
      <div className='bg-white border border-gray-300'>
        <div className='py-4 px-4 lg:px-24 flex flex-col items-center pb-12'>
          <p className='text-2xl text-center'>
            Something exciting is coming soon!
          </p>
          <p className='text-base text-center py-1 '>
            Please come back at a later date!
          </p>
          <a
            className='btn btn-default btn-rounded text-white bg-black hover:bg-gray-800
                  flex flex-row text-center align-middle md:w-auto mt-2 py-2'
            href='/'
          >
            Back to Local Shops
          </a>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon
