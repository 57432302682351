import React from 'react';
import { formatCurrency } from '../../../../../utilities/formatPrice';
import { pluralizeString } from '../../../../../utilities/stringManipulations';
import IcPlaceholder from '../../../../../../assets/images/image-icon-productPage.png';

const ItemUI = ({ item }) => {
  return (
    item &&
    <div className='h-10 grid grid-cols-4'>
      <div className='col-span-3'>
        <img src={item.product_image || IcPlaceholder} className='w-10 h-10 mr-4 inline max-w-none rounded-lg align-top object-cover' />
        <div className='w-2/3 h-auto inline-block'>
          <div className='text-sm truncate'>{item.product.title} - {item.product_variant.title}</div>
          <div className='text-xs text-teal-700 font-light'>{`${pluralizeString(item.quantity, 'Pc', 's')}`}</div>
        </div>
      </div>
      <span className='col-span-1 text-base font-medium text-right self-end mb-1'>{formatCurrency(item.total_cents)}</span>
    </div>
  )
}

export default ItemUI;
