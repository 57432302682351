import React, { useState } from 'react'

const Tags = ({ product }) => {
  const [toggleTags, setToggleTags] = useState(false)

  const tagsToDisplay = (first, last) => {
    <label className='uppercase font-semibold'>Tags</label>
    return product.tag_names.slice(first, last).map((tag) => {
      return (
        <div
          key={tag}
          className='cursor-pointer tracking-wide bg-light-orange inline-block bg-mustard-400 rounded-full px-4 py-2 m-1 text-md font-semibold'
        >
          {tag}
        </div>
      )
    })
  }
  return (
    <div className='my-2 text-xs capitalize'>
      {product.tag_names.length > 3 ? (
        <div>
          {tagsToDisplay(0, 3)}

          {toggleTags ? tagsToDisplay(3, product.tag_names.length) : null}
          <p
            className='cursor-pointer tracking-wide px-1 py-1 m-1 text-mustard-400'
            onClick={() => setToggleTags(!toggleTags)}
          >
            {toggleTags ? 'see less' : 'see more'}
          </p>
        </div>
      ) : (
        tagsToDisplay(0, product.tag_names.length)
      )}
    </div>
  )
}

export default Tags
