import React from 'react'

const OrderInfoRow = ({ label, value }) => {
  return (
    <div className='grid grid-cols-5 gap-x-8'>
      <span className='font-light text-sm text-gray-500 xl:col-span-1  col-span-2'>{label}</span>
      <div className='xl:col-span-4  col-span-3'>{value}</div>
    </div>
  )
}

export default OrderInfoRow;
