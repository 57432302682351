import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import IcPlaceHolder from '../../../../assets/images/mountain-placeholder.png'

const createFileDependentUIProperties = (fileURL) => {
  return fileURL ? {
    url: fileURL,
    containerClassName: 'bg-white',
    containerLabel: '',
    imageClassName: 'object-cover rounded-full w-full h-full',
    rendersAddButton: false
  } : {
    url: IcPlaceHolder,
    containerClassName: 'bg-beige-300 p-2 items-center',
    containerLabel: 'File not selected',
    imageClassName: 'mx-3 pr-6',
    rendersAddButton: true
  }
}

const FormImageInput = ({ name, fileURL, className, label }) => {

  const hiddenFileInput = useRef(null);
  const [fileInput, setFileInput] = useState(null);
  const [uiProperties, setUIProperties] = useState(createFileDependentUIProperties(fileURL));

  const onClick = () => {
    hiddenFileInput.current.click();
  }

  useEffect(() => {
    hiddenFileInput.current.files = fileInput;
  }, [fileInput, uiProperties])

  const HiddenFileInput = () => {
    return (
      <input hidden id={name}
        name={name} type='file' ref={hiddenFileInput} accept="image/*"
        onChange={event => {
          setFileInput(event.target.files)
          setUIProperties(createFileDependentUIProperties(URL.createObjectURL(event.target.files[0])))
        }}
      />
    )
  }

  const AddButton = () => {
    return (
      <div className='w-5 h-5 absolute bottom-0 right-0 rounded-full bg-yellow-600 flex align-center p-1'>
        <FontAwesomeIcon className='text-white w-full h-full' icon={faPlus} />
      </div>
    )
  }

  return (
    <section className={className}>
      <div className='flex flex-row items-center'>
        <button type='button' onClick={onClick}
          className={`w-16 h-16 mr-4 rounded-full flex align-center relative ${uiProperties.containerClassName}`}>
          <img src={uiProperties.url} className={uiProperties.imageClassName} />
          {uiProperties.rendersAddButton && <AddButton />}
        </button>
        <div className="flex flex-col">
          <span className='font-light text-sm text-gray-600'>{uiProperties.containerLabel}</span>
          <span className="text-thin text-xs text-gray-600">{label}</span>
        </div>
      </div>
      <HiddenFileInput />
    </section>
  )
}

export default FormImageInput;
