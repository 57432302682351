import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashUndo } from "@fortawesome/pro-regular-svg-icons";

const ProductVariantRow = ({ productVariant }) => {
  const inputClasses = "w-full p-2 flex flex-col border border-mustard-200 border-opacity-50 rounded-md outline-none text-black mr-4"
  const [productVariantTitle, setProductVariantTitle] = useState(productVariant.title)
  const [productVariantPriceCents, setProductVariantPriceCents] = useState(productVariant.price_cents)
  const [productVariantPrice, setProductVariantPrice] = useState(productVariant.price_cents / 100)
  const [productVariantInventory, setProductVariantInventory] = useState(productVariant.inventory)
  const [deleteProductVariant, setDeleteProductVariant] = useState(0)

  useEffect(() => {
    setProductVariantPriceCents(productVariantPrice * 100)
  },[productVariantPrice])

  return (
    <div className={deleteProductVariant && 'opacity-50'}>
      <div className={`flex flex-row items-center justify-between mb-4`}>
        <input id={'product-variant-title'}
               type="text"
               value={productVariantTitle}
               placeholder="Add Value"
               className={inputClasses}
               onChange={(e) => setProductVariantTitle(e.target.value)}
               required
               disabled={deleteProductVariant}
        />
        <input name='product[product_variants_attributes][][id]' type='hidden' value={productVariant.id} />
        <input name='product[product_variants_attributes][][title]' type='hidden' value={productVariantTitle} />
        <input id={`product-variant-price`}
               type="number"
               inputMode="numeric"
               placeholder="$ 0.00"
               value={productVariantPrice}
               min="0"
               className={inputClasses}
               onChange={(e) => setProductVariantPrice(e.target.value)}
               required
               disabled={deleteProductVariant}
               step="0.01"
        />
        <input name='product[product_variants_attributes][][price_cents]' type='hidden'
               value={productVariantPriceCents} />
        <input id={`product-variant-quantity`}
               type="number"
               value={productVariantInventory}
               placeholder="Quantity"
               min="0"
               step="1"
               onChange={(e) => setProductVariantInventory(e.target.value)}
               className={inputClasses}
               disabled={deleteProductVariant}
               required
        />
        <input name='product[product_variants_attributes][][inventory]' type='hidden'
               value={productVariantInventory} />
        {deleteProductVariant ?  <FontAwesomeIcon className='text-lg' icon={faTrashUndo} onClick={() => setDeleteProductVariant(0)} /> :  <FontAwesomeIcon  className='text-lg'  icon={faTrash} onClick={() => setDeleteProductVariant(1)} /> }
        <input name='product[product_variants_attributes][][_destroy]' type='hidden'
               value={deleteProductVariant} />
      </div>
    </div>
  )
}

export default ProductVariantRow
