import React from 'react'
import SpiralLine from '../../../../../assets/images/line.svg';
import SubHeaderBackgroundImage from '../../../../../assets/images/subheaderBackground.svg';

const SubHeaderImage = ({ className, children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${SubHeaderBackgroundImage})`
      }}
      className={`hidden md:block bg-cover bg-bottom p-8 flex flex-col justify-end items-start bg-light-orange relative ${className}`}
    >
      {children}
      <div className='inline-block absolute right-0 bottom-0 -mt-18 lg:mt-8 '
        style={{ width: '32%', height: '96%' }}></div>
    </div>
  )
}

export default SubHeaderImage;
