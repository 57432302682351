import { useFormikContext } from 'formik';
import React from 'react';
import ToggleButton from './shared/toggleButton';

const LocalPickup = ({ className }) => {

  const { values, setFieldValue } = useFormikContext();

  const updateFormikValue = checked => {
    setFieldValue('local_pickup', checked);
  }

  return (
    <div className={`w-full flex gap-x-2 bg-white p-3 gap-5 lg:pr-pls justify-between rounded-md ${className}`}>
      <span className='text-base text-black font-bold'>Local Pickup</span>
      <ToggleButton
        isChecked={values.local_pickup}
        onChange={updateFormikValue}
        iconChecked={<h2 className='text-sm absolute pt-2 left-4 text-white'>Enabled</h2>}
        iconUnchecked={<h2 className='text-sm absolute pt-2 right-4 text-gray-600 '>Disabled</h2>}
        width={105}
        height={33}
        diameter={24}
      />
    </div>
  )
}

export default LocalPickup;
