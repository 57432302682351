import { createContext } from 'react';

const SavedSellersContext = createContext({
  savedSellers: [],
  currentPage: 1,
  totalPages: 1,
  currentSort: null,
  searchTerm: null,
  fetchingSellers: false,
  onSortChange: null,
  onSearchTermChange: null,
  onPageChange: null,
  setCurrentPage: null
})

export default SavedSellersContext;
