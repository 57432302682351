import React, { useState } from 'react'
import RevokeMembership from "./revokeMembership";
import axios from "axios";

const MarketMembership = ({ market, membership, routes }) => {
  let [marketMembership, setMarketMembership] = useState(membership)

  const doJoin = () => {
    axios.post(routes.joinRoute, { market_id: market.id }).then(res => setMarketMembership(res.data))
  }

  const doRevoke = () => {
    axios.delete(routes.revokeRoute).then(res => setMarketMembership(res.data))
  }
  if (marketMembership?.status === 'accepted') {
    return <RevokeMembership doRevoke={doRevoke} />
  } else if (marketMembership?.status === 'pending') {
    return <div
      className="whitespace-no-wrap bg-yellow-600 text-center px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-white rounded-full">
      Request Sent
    </div>
  } else {
    return <div
      className='cursor-pointer bg-mustard-400 text-center px-5 py-3 text-sm shadow-sm font-medium tracking-wider text-white rounded-full'
      onClick={() => doJoin()}>Join
    </div>
  }
}

export default MarketMembership
