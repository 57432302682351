import React, { useContext } from 'react'
import { Link} from "react-router-dom";
import * as queryString from "query-string";
import { MarketContext } from "../../../markets/market";

const ShopLink = ({ shop_slug, children, className }) => {
  let { market } = useContext(MarketContext)

  let linkParams = () => {
    if (market) {
      return { market_slug: market.slug }
    } else {
      return {}
    }
  }

  return <Link to={{ pathname: `/shops/${shop_slug}`, search: `?${queryString.stringify(linkParams())}` }} className={className}>
    {children}
  </Link>
}

export default ShopLink
