import React from 'react';

const TableHeader = ({ labelClassName}) => {
  return (
    <thead className='bg-beige-150'>
    <tr className='mb-2'>
      <th colSpan='2' className={labelClassName}>Product Name</th>
      <th className={labelClassName}>Quantity</th>
      <th className={labelClassName}>Action</th>
    </tr>
    </thead>
  )
}

export default TableHeader;

