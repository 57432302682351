import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faMegaphone} from "@fortawesome/pro-solid-svg-icons";

const Banner = () => {
  return(
    <div className="bg-light-orange w-full hidden md:block">
      <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg  p-2">
              <FontAwesomeIcon icon={faMegaphone} className='text-lg'/>
            </span>
            <p className="ml-3 flex-wrap font-medium text-sm lg:text-lg text-black">
              We have expanded your search! You can now see local brands from nearby locations to improve search results
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Banner
