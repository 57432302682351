import React, { useState, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'

const PhotoCarousel = ({ photoUrls, itemChild }) => {
  let [images, setImages] = useState([])

  const populateImages = (images) => {
    images.map((img) => {
      setImages(prevState => {
        return [...prevState, { original: img, thumbnail: img }]
      })
    })
  }

  useEffect(() => {
    if (photoUrls) {
      populateImages(photoUrls)
    }
  }, [photoUrls])

  const renderItem = item => {
    return (
      <div className='relative'>
        <img src={item.original}
          alt={item.originalAlt}
          className={`image-gallery-image ${item.originalClass}`}
          title={item.originalTitle}
          width={item.originalWidth}
          height={item.originalHeight} />
        {itemChild}
      </div>
    )
  }

  return (
    <div className='w-full'>
      <ImageGallery showPlayButton={false} showFullscreenButton={false} items={images}
        renderItem={renderItem}
      />
    </div>
  )
}

export default PhotoCarousel
