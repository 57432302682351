import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const NextButtonLabel = () => {
  return (
    <div className='flex flex-row items-center'>
      <FontAwesomeIcon className='w-5 h-full mb-px' icon={faLongArrowRight} />
    </div>
  )
}

export default NextButtonLabel;
