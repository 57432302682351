import { useParams } from "react-router-dom"

export const getCategoryURL = permalink => {
  const query = new URLSearchParams(location.search)
  const isMarketQuery = query.get('market_slug');

  let { market_slug } = useParams()

  if (market_slug) {
    return `/markets/${market_slug}/${permalink}`
  } else if (isMarketQuery) {
    return `/markets/${isMarketQuery}/${permalink}`
  }

  return `/${permalink}`
}
