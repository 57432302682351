import React, { useEffect, useState } from 'react'
import { ShopImage } from "../shared_components/shopLogo";
import ShopLink from "../shared_components/shopLink";
import { loadAlgoliaProducts } from "../shared_components/algoliaSearch";
import Carousel from "react-multi-carousel";
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import SmallProductCard from "../products/smallProductCard";
import ShopFavorite from "../shared_components/shopFavorite";

const ShopInfoCard = ({ shop }) => {
  let [products, setProducts] = useState(null)

  useEffect(() => {
    const searchOptions = { integration_slug: shop.slug, hits: 8 }
    loadAlgoliaProducts(searchOptions).then(({ hits }) => {
      setProducts(hits);
    })
  }, [shop])

  return (
    products && <>
      <div className='border border-beige-200 rounded-lg p-2 '>
        <div className='flex flex-col lg:flex-row lg:items-center lg:justify-between p-2'>
          <div className='flex items-center'>
            <ShopImage shop={shop} className='rounded-full w-16 h-16' />
            <h3 className='px-2 text-lg font-semibold'>{shop.shop_name}</h3>
          </div>
          <div className='flex flex-col text-sm mr-40'>
            <span className='text-gray-600 font-medium'>Location</span>
            <span className='font-semibold'>{shop.city_name}</span>
          </div>
          <div className='flex items-center'>
            <ShopLink
              shop_slug={shop.slug}
              className='bg-moody-blue focus:outline-none 
              focus:shadow-none hover:shadow-sm transition 
              ease-in-out duration-300 transform hover:scale-103 
              text-white rounded-full py-3 px-12 m-2 text-center 
              font-bold text-sm cursor-pointer flex-1 md:flex-none'
            >
              Go to Store
            </ShopLink>
            <ShopFavorite shop={shop} />
          </div>
        </div>
        <div
          className='w-full text-gray-900 antialiased justify-items-auto py-4'>
          <Carousel responsive={carouselBreakpoints(6, 5, 2, 1)}>
            {products.map((product) => (
              <div className='px-2'>
                <SmallProductCard product={product} hideCity />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  )
}

export default ShopInfoCard
