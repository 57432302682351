import React from 'react';
import { pluralizeString } from '../../../utilities/stringManipulations';
import { formatCurrency } from '../../../utilities/formatPrice';

const ItemRow = ({ item }) => {
  return (
    <div className='h-10 grid grid-cols-4'>
      <div className='col-span-3'>
        <img src={item.product_image} className='w-10 h-10 mr-4 inline max-w-none rounded-lg align-top object-cover' />
        <div className='w-2/3 h-auto inline-block'>
          <div className='text-sm truncate'>{item.product.title} - {item.product_variant.title}</div>
          <div className='text-xs text-teal-700 font-light'>{`${pluralizeString(item.quantity, 'Pc', 's')}`}</div>
        </div>
      </div>
      <span className='col-span-1 text-base font-medium text-right self-end mb-1'>{formatCurrency(item.total_cents)}</span>
    </div>
  )
}

const LabelValueRow = ({ label, value, labelClassName, valueClassName }) => {
  return (
    <div className='grid grid-cols-4'>
      <div className={`col-span-3 ${labelClassName}`}>
        {label}
      </div>
      <span className={`col-span-1 font-medium text-right self-end ${valueClassName}`}>{value}</span>
    </div>
  )
}

export const Divider = () => {
  return (
    <div className='w-full h-0 border border-beige-200 mt-8 mb-4' />
  )
}

const CheckoutCartUI = ({
  className,
  items,
  subtotal,
  shipping,
  taxes,
  total
}) => {

  if (!items || !items.length)
    return null;

  return (
    <div style={{ height: "fit-content" }} className={`border border-beige-200 rounded-md p-10 ${className}`}>
      <h4 className='float-left'>Checkout Details</h4>
      <section className='mt-16 mb-4 grid grid-cols-1 gap-y-6'>
        {items.map(item => <ItemRow item={item} key={item.product_variant.id} />)}
      </section>
      <Divider />
      <section className='grid grid-cols-1 gap-y-4 mt-6'>
        <LabelValueRow label="Subtotal" value={subtotal} labelClassName='text-base' valueClassName='text-base' />
        <LabelValueRow label="Taxes" value={taxes} labelClassName='text-base' valueClassName='text-base' />
        <LabelValueRow label="Shipping" value={shipping} labelClassName='text-base' valueClassName='text-base' />
      </section>
      <Divider />
      <section className='mt-6'>
        <LabelValueRow label="Total" value={total} labelClassName='text-lg font-semibold' valueClassName='text-lg font-semibold' />
      </section>
    </div >
  )
}

export default CheckoutCartUI;
