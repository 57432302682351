import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SavedShippingAddressUISkeleton = () => {
  return (
    <section className='border-2 border-gray-200 rounded-md p-4'>
      <Skeleton width='35%' height={20} />
      <Skeleton width='80%' height={20} count={2} />
      <section className='flex justify-between mt-4'>
        <section>
          <Skeleton width={70} height={20} />
        </section>
        <section className='ml-auto mr-0 grid grid-cols-2 gap-x-2 content-center'>
          <Skeleton width={70} height={20} />
          <Skeleton width={70} height={20} />
        </section>
      </section>
    </section>
  )
}

const SavedShippingAddressesUISkeleton = () => {
  return (
    <div className='border-2 border-gray-200 rounded-md p-10'>
      <Skeleton className='ml-0 mr-auto' width='50%' height='24px' />
      <div className='w-full mt-12 grid grid-cols-1 gap-y-4'>
        <SavedShippingAddressUISkeleton />
        <SavedShippingAddressUISkeleton />
        <SavedShippingAddressUISkeleton />
      </div>
    </div>
  )
}

export default SavedShippingAddressesUISkeleton;
