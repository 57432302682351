import React from 'react'

const UserCircleIcon = () => {
    return (<svg width="34" height="35" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="33.3333" height="34.6667" rx="16.6667" fill="#DEA448"/>
    <path d="M16.6663 16.6667C18.5073 16.6667 19.9997 15.1743 19.9997 13.3333C19.9997 11.4924 18.5073 10 16.6663 10C14.8254 10 13.333 11.4924 13.333 13.3333C13.333 15.1743 14.8254 16.6667 16.6663 16.6667Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M23.3333 24.6667V23C23.3333 22.1159 22.9821 21.2681 22.357 20.643C21.7319 20.0178 20.8841 19.6667 20 19.6667H13.3333C12.4493 19.6667 11.6014 20.0178 10.9763 20.643C10.3512 21.2681 10 22.1159 10 23V24.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>);
}

export default UserCircleIcon