import React from 'react';
import FooterColumn from './shared/footerColumn';
import FooterLinkInternal from './shared/footerLinkInternal';

const Info = () => {
  return (
    <FooterColumn>
      <span className='text-white font-semibold pb-2 text-sm'>Info</span>
      <FooterLinkInternal title='Shipping Info' path='/shipping-info' />
      <FooterLinkInternal title='Returns & Exchanges Policy' path='/returns-policy' />
      <FooterLinkInternal title='Refund Policy' path='/refund-policy' />
    </FooterColumn>
  )
}

export default Info;
