import React from 'react'

const FooterBanner = ({ footer_banner }) => {
  return (
    <div
      style={{
        height: '50vh',
        backgroundImage: `url(${footer_banner})`,
      }}
      className='bg-cover flex flex-col justify-end items-start'
    ></div>
  )
}

export default FooterBanner
