import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Step = ({icon, instructions, color, className}) => {
  return (
    <div className={` ${className} flex flex-col justify-start items-center bg-white p-4 shadow-lg hover:shadow-xl`}>
      <div className='bg-gray-200 rounded-full p-6 shadow-xl m-8 border border-gray-200'>
        <FontAwesomeIcon
          className={`text-6xl ${color}`}
          icon={icon}
        />
      </div>
      <p className='text-gray-600'>
        {instructions}
      </p>
    </div>
  )
}

export default Step;