import React, { useContext } from "react"
import { CartContext } from "../../../../contexts/cartContext";
import PlusMinus from "./plusMinus";

const QuantitySelect = ({ cartItem, quantity }) => {
  let { increaseQuantity, decreaseQuantity, isUpdatingCart } = useContext(CartContext)

  return <PlusMinus
    quantity={quantity}
    plusDisabled={isUpdatingCart}
    minusDisabled={isUpdatingCart}
    onPlusClicked={() => increaseQuantity(cartItem)}
    onMinusClicked={() => decreaseQuantity(cartItem)}
  />
}


export default QuantitySelect
