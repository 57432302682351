import { createContext } from 'react';

const WishlistContext = createContext({
  wishlist: [],
  totalPages: 1,
  currentPage: 1,
  categorySelected: null,
  currentSort: null,
  searchTerm: null,
  fetchingWishlist: false,
  fetchWishlist: null,
  onPageChange: null,
  onCategoryChange: null,
  onSortChange: null,
  onSearchTermChange: null,
  setCurrentPage: null
})

export default WishlistContext;
