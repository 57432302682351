import React from 'react';
import { doOrdersExist, makeOrderTableReadable } from '../../../../../utilities/purchaseHistoryUtility';
import TableCell from './tableCell';
import TableHeader from './tableHeader';

const PurchaseHistoryTable = ({ orders, onDetailsClicked }) => {

  return (
    <table className={`w-full rounded-md bg-beige-200 p-2`}>
      <TableHeader
        labelClassName='rounded-md bg-beige-200 uppercase text-xs text-left font-medium px-5 py-6 text-gray-700' />
      <tbody>
        {
          doOrdersExist(orders) &&
          orders.map((order, index) => {
            return (
              <TableCell
                headerClassName='odd:bg-white even:bg-beige-100'
                labelClassName='text-sm font-medium px-5 py-4 rounded-b-md'
                data={makeOrderTableReadable(order)}
                onDetailsClicked={onDetailsClicked}
              />
            )
          })
        }
      </tbody>
    </table>
  )
}

export default PurchaseHistoryTable;
