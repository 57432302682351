import React from "react";

function FacebookIconUnfilled() {
    return(
    <svg 
        className="h-5 w-6 text-white hover:fill-current hover:text-mustard-400"
        viewBox="0 0 14 21" 
        stroke="currentColor"
        fill="none" 
        strokeWidth="1.5"
        xmlns="http://www.w3.org/2000/svg"
    >
    <path 
        d="M11.4678 12.1875C11.7143 12.1875 
        11.9241 12.0078 11.9619 11.7641L12.4951 
        8.32557C12.5175 8.1813 12.4757 8.03447 
        12.3807 7.92361C12.2857 7.81275 12.147 
        7.74895 12.001 7.74895H9.1669V6.01756C9.1669 
        5.62104 9.2658 5.29052 9.46293 5.06496C9.64896 
        4.85207 9.98617 4.65986 10.6259 4.65986H12.1416C12.4178 
        4.65986 12.6416 4.43601 12.6416 4.15986V1.2323C12.6416 
        0.988293 12.4655 0.779921 12.2249 0.739286L12.1416 
        1.2323L12.2248 0.739271L12.2247 0.73925L12.2244 
        0.739193L12.2233 0.739015L12.2197 0.738414L12.2066 
        0.736261L12.1581 0.728456C12.116 0.721812 12.0552 
        0.712431 11.9783 0.701229C11.8245 0.678837 11.6063 
        0.6491 11.3463 0.619385C10.8283 0.560196 10.1349 0.5 
        9.45102 0.5C7.97859 0.5 6.70543 0.943306 5.79925 
        1.84089C4.89191 2.73963 4.41052 4.03632 4.41052 
        5.62828V7.74895H1.8584C1.58226 7.74895 1.3584 
        7.9728 1.3584 8.24895V11.6875C1.3584 11.9636 
        1.58226 12.1875 1.8584 12.1875H4.41052V20C4.41052 
        20.2761 4.63438 20.5 4.91052 20.5H8.6669C8.94304 
        20.5 9.1669 20.2761 9.1669 20V12.1875H11.4678Z" 
        strokeLinejoin="round"/>
    </svg>
    );
}

export default FacebookIconUnfilled;
