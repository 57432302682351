import React from 'react';
import IcPlaceholder from '../../../../../../assets/images/image-icon-productPage.png'

const TableCell = ({ headerClassName, labelClassName, data, onDetailsClicked }) => {
  return (
    data &&
    <tr className={headerClassName}>
      <td className={labelClassName}>
        <div className='flex flex-row'>
          <img src={data.primaryItem.product_image || IcPlaceholder} className='w-10 h-10 mr-4 inline max-w-none rounded-lg align-top object-cover' />
          <span className='text-sm max-w-xs'>{data.primaryItem.product.title}</span>
        </div>
      </td>
      <td className={labelClassName}>{data.orderNumber}</td>
      <td className={`xl:table-cell hidden ${labelClassName}`}>{data.seller}</td>
      <td className={labelClassName}>{data.date}</td>
      <td className={`xl:table-cell hidden ${labelClassName}`}>{data.totalItems}</td>
      <td className={labelClassName}>{data.subTotal}</td>
      <td className={labelClassName}>{data.shipping}</td>
      <td className={labelClassName}>{data.taxes}</td>
      <td className={labelClassName}>{data.grandTotal}</td>
      <td className={labelClassName}>
        <button onClick={() => { onDetailsClicked(data.orderNumber) }} className='rounded-full px-6 py-3 text-white bg-blue-grey'>Details</button>
      </td>
    </tr>
  )
}

export default TableCell;
