import React, { useEffect, useState } from 'react';
import Select from 'react-select';

const CategorySelect = ({ value, placeholder, onChange, containerClassName, styles, wishlist }) => {

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isFetching, setIsFetching] = useState(false);


  useEffect(() => {
    fetchCategories();
  }, [wishlist])

  useEffect(() => {
    updateSelectedCategory(categories, value);
  }, [categories])

  const updateSelectedCategory = (options, selectedValue) => {
    setSelectedCategory(options.find(option => option.value === selectedValue))
  }


  const fetchCategories = () => {
    setIsFetching(true);
    const options = wishlist?.map(object => {
      return {
        label: object.category?.name,
        value: object.category?.id
      }
    })
    setCategories(options);
    setIsFetching(false);
  }

  return (
    <section className={containerClassName}>
      <Select placeholder={placeholder || 'Category'} options={categories}
              styles={styles}
              value={selectedCategory}
              isSearchable={false}
              isLoading={isFetching}
              isClearable
              onChange={(option) => {
                const optionValue = option ? option.value : null
                updateSelectedCategory(categories, optionValue);
                onChange(optionValue)
              }} />
    </section>
  )
}

export default CategorySelect;
