import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAsia } from "@fortawesome/pro-regular-svg-icons";
import { faKiwiBird, faMugTea } from "@fortawesome/pro-light-svg-icons";
import { faFlagUsa } from "@fortawesome/pro-solid-svg-icons";
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";

const selectLocalIcon = function (countrySlug) {
  switch (countrySlug) {
    case 'australia':
      return faGlobeAsia
    case 'new-zealand':
      return faKiwiBird
    case 'united-states':
      return faFlagUsa
    case 'united-kingdom':
      return faMugTea
    case 'canada':
      return faCanadianMapleLeaf
  }
}

const CountryIconMerchants = ({ countrySlug, iconClassName }) => {
  return (
    <div data-tip data-for='madeLocally'>
      <FontAwesomeIcon
        icon={selectLocalIcon(countrySlug)}
        className={`text-red-700 ${iconClassName}`}
      />
    </div>
  )
}

export default CountryIconMerchants
