import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faPhone } from '@fortawesome/pro-solid-svg-icons'


function ContactInformation({ phoneNumber, email }) {
  let style = "px-2 hover:underline text-blue-700"
  return (
    <div className='flex flex-col gap-y-1'>
      <div className='flex items-center gap-x-2'>
        <FontAwesomeIcon icon={faPaperPlane} />
        <a className='pl-1' href={`mailto:`}>
          {email}
        </a>
      </div>
      {phoneNumber && (
        <div className='flex items-center gap-x-2'>
          <FontAwesomeIcon icon={faPhone} />
          <a className='pl-1' href={`tel:`}>
            {phoneNumber}
          </a>
        </div>
      )}
    </div>
  )
}

export default ContactInformation
