import React from 'react';
import OrderInfoRow from './orderInfoRow';

const AddressDetails = ({ data }) => {
  return (
    data &&
    <section>
      <h2 className='my-2 text-2xl'>Purchase Details</h2>
      <div className='grid grid-cols-1 gap-y-6 mt-8'>
        <OrderInfoRow label='Profile Name' value={data.profileName} />
        <OrderInfoRow label='Phone Number' value={data.phoneNumber} />
        <OrderInfoRow label='Shipping Address' value={data.shippingAddress} />
      </div>
    </section>
  )
}

export default AddressDetails;
