import React, { useContext, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import  FavoritesContext  from '../../../contexts/favoritesContext';
import AccountSettings from './accountSettings';
import PurchaseHistoryDetails from './purchase_history/purchaseHistoryDetails';
import UserPurchaseHistory from './purchase_history/userPurchaseHistory';
import SavedSellers from './savedSellers';
import Wishlist from './wishlist';

const UserDashboard = () => {

  const history = useHistory();
  const { user } = useContext(FavoritesContext);

  useEffect(() => {
    checkLoggedInUser();
  }, [])

  const checkLoggedInUser = () => {
    if (!user)
      history.replace(`/users/sign_in?return_to=${history.location}`);
  }

  return (
    <Switch>
      <Route exact path='/users'>
        <Wishlist />
      </Route>
      <Route exact path='/users/saved_sellers'>
        <SavedSellers />
      </Route>
      <Route path={['/users/edit', '/user/edit']}>
        <AccountSettings />
      </Route>
      <Route exact path='/users/orders'>
        <UserPurchaseHistory />
      </Route>
      <Route path='/users/orders/:id'>
        <PurchaseHistoryDetails />
      </Route>
    </Switch>
  )
}

export default UserDashboard;
