import React, { useContext } from 'react'
import Products from "./products";
import ProductsGrid from "./productsGrid";
import { CitiesContext } from "../../../../contexts/citiesContext";
import SubHeader from "../shared_components/subHeader";

const ProductsBrowse = () => {
  const SearchResults = () => {
    let { city } = useContext(CitiesContext)
    return <>
      <ProductsGrid />
    </>
  }
  return <Products>
    <SubHeader breadcrumbs='All Products' link='/products' />
    <div className='p-2 md:p-8 bg-white'>
      <div className='container mx-auto'>
        <SearchResults />
      </div>
    </div>
  </Products>
}

export default ProductsBrowse
