import React from 'react'

import IcCross from '../../../../assets/images/ic-cross.png';

// From https://tailwindui.com/components/application-ui/overlays/modals
const CrossableModal = ({ title, children, footer, onClose }) => {
  return <div className="z-50 fixed inset-0 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-black opacity-50" />
      </div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        className="inline-block self-center bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className='w-9 h-9 flex content-center absolute top-2 right-2' onClick={onClose} role='button'>
            <img className='p-3' src={IcCross} />
          </div>
          <div className="flex items-center">
            <div className="mt-3 text-center w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {title}
              </h3>
              <div className="my-2">
                {children}
              </div>
            </div>
          </div>
        </div>
        {footer}
      </div>
    </div>
  </div>
}

export default CrossableModal;
