import React from 'react'
import Onboarding from '../../merchants/shared_components/onboarding'

const MyCart = ({}) => {
  return (
    <div>
      <Onboarding url='/products' title='Products' action='Browse for' />
    </div>
  )
}

export default MyCart
