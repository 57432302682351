import React, { useContext } from 'react';
import MyProductsSelectStyle from '../../../../constants/custom_styles/myProductsSelectStyle';
import { ProductSortOptions } from '../../../../constants/dropdown_options/productSortOptions';
import SearchInput from '../../searchInput';
import SortDropdown from '../../../main/payments_dashboard/sortDropdown';
import SavedSellersContext from './savedSellersContext';

const SavedSellersFilters = () => {
  const { currentSort, onSortChange, searchTerm, onSearchTermChange } = useContext(SavedSellersContext);
  return (
    <>
      <SearchInput onChange={onSearchTermChange} value={searchTerm} />
      <SortDropdown options={ProductSortOptions} value={currentSort} setValue={onSortChange}
        containerClassName="md:w-48 w-full relative z-10" styles={MyProductsSelectStyle} />
    </>
  )
}

export default SavedSellersFilters;
