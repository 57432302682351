import React from 'react'

export function handleBeforeInput(input, currentEditorState, maxCharacterLength) {
  const textLength = currentEditorState.length
  if (input && textLength >= maxCharacterLength) {
    return (
      'handled'
    )
  } else {
    return (
      'not handled'
    )
  }
}

export function handlePastedText(pastedText, currentEditorState, maxCharacterLength) {
  const currentContent = currentEditorState
  const currentContentLength = currentContent.length

  if (currentContentLength + pastedText.length > maxCharacterLength) {
    return 'handled'
  }
}

