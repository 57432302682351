import { useFormikContext } from 'formik';
import React, { useContext } from 'react';
import ShippingFormContext from '../shippingFormContext';
import InternationalShippings from './internationalShippings';
import ShippingRow from './shippingRow';
import TableHeader from './tableHeader';

const ShippingTable = ({ className, currencyPrefix, countries }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const { hasInternationalShippings, getPrefixedFieldName } = useContext(ShippingFormContext);

  return (
    <div className={`${className}`}>
      <div>
        <ShippingRow
          label='Local'
          name='local'
          currencyPrefix={currencyPrefix}
          labelClassName='flex flex-row gap-2'
          rowClassName='pt-8 bg-white flex items-start justify-between'
          values={values}
          errors={errors}
          enableActions={hasInternationalShippings}
          getFieldName={partialFieldName => getPrefixedFieldName('local', partialFieldName)}
          updateFormikValue={setFieldValue}

        />
        <ShippingRow
          label='Domestic'
          name='domestic'
          currencyPrefix={currencyPrefix}
          labelClassName='flex flex-row gap-2'
          rowClassName='pt-8 bg-white flex items-start justify-between'
          values={values}
          errors={errors}
          enableActions={hasInternationalShippings}
          getFieldName={partialFieldName => getPrefixedFieldName('domestic', partialFieldName)}
          updateFormikValue={setFieldValue}
        />
        <InternationalShippings currencyPrefix={currencyPrefix} countries={countries} />
      </div>
    </div>
  )
}

export default ShippingTable;