import React from 'react';
import IconSelect from './shared/iconSelect';
import FooterColumn from './shared/footerColumn';
import FacebookIconUnfilled from './shared/FacebookUnfilled';
import LinkedInIconUnfilled from './shared/LinkedInUnfilled';
import YoutubeIconUnfilled from './shared/YoutubeUnfilled';
import TwitterIconUnfilled from './shared/TwitterUnfilled';

const LetsTalk = () => {
  return (
    <FooterColumn>
      <span className='text-white font-semibold lg:pb-4 text-sm pb-2'>Contacts</span>
      <span className=' text-white font-hairline cursor-pointer text-sm pb-4'>
        hello@localshops.com
      </span>
      <span className='text-white font-semibold lg:pb-4 text-sm pb-1'>Socials</span>
      <div className='flex justify-between items-center lg:gap-x-4 gap-x-0'>
        <a href="https://www.facebook.com/localshopsco">
          <FacebookIconUnfilled/>
        </a>
        <IconSelect
          href='https://www.instagram.com/localshopsco/'
          iconName='instagram'
        />
        <a href='app/javascript/components/main/footer/letsTaks'>
          <LinkedInIconUnfilled/>
        </a>
        <a href='https://www.youtube.com/channel/UCE4c_iulU7UHIv3qUz6Mkxg' className='ml-3'>
          <YoutubeIconUnfilled/>
        </a>
        <a href='https://twitter.com/localshopsco' className='ml-3 mr-4'>
          <TwitterIconUnfilled/>
        </a>
      </div>
    </FooterColumn>
  )
}

export default LetsTalk;
