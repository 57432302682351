const ShippingAddressInitialValues = {
  email: '',
  name: '',
  country_id: null,
  street_address: '',
  city_id: null,
  province: '',
  postal_code: '',
  phone_number: '',
  is_default: false
}

export default ShippingAddressInitialValues;
