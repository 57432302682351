const ErrorMessages = {
  FieldRequired: 'This field is required',
  IsValidEmail: 'Should be a valid Email Address',
  IsValidRate: 'Should be a valid rate',
  ItemNotSelected: 'Select an item',
  ReasonNotProvided: 'Provide a reason',
  PasswordRequired: 'Password required.'
}

export default ErrorMessages;
