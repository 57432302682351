import React, { useEffect, useState } from "react";

import { ResponsiveLine } from "@nivo/line";
import axios from "axios";

const ReferrerChart = ({ dataRoute }) => {
  let [data, setData] = useState()
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const minDate = data?.[0]?.data?.[0]?.x
  const maxDate = new Date().toISOString().split("T")[0];

  useEffect(() => {
    setTo(formatDate())
    axios.get(dataRoute).then(res => {
      setData(res.data)
      setFrom(res?.data[0]?.data?.[0]?.x)
    })
  }, [])

  const formatDate = (date = new Date()) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const filteredData = data?.map ( d => {
    return {
      id: d.id,
      data: d.data.filter( v => {
        return (new Date(from) <= new Date(v.x)) && (new Date(to) >= new Date(v.x))
      })
    }
  })

  return <>
    <div className={'flex flex-col sm:flex-row justify-end'}>
      <div className={'flex items-center mr-6 mt-3 sm:mt-1'}>
        <span className={'flex-1'}>From</span>
        <input
          type={'date'}
          value={from}
          className={'ml-3 flex-1'}
          onChange={ e => setFrom(e.target.value)}
          max={maxDate}
          min={formatDate(minDate)}
        />
      </div>
      <div className={'flex items-center mr-6 mt-3 sm:mt-1'}>
        <span className={'flex-1'}>To</span>
        <input
          type={'date'}
          value={to}
          className={'ml-3 flex-1'}
          onChange={ e => setTo(e.target.value) }
          max={maxDate}
          min={formatDate(minDate)}
        />
      </div>
    </div>
    {data && <ResponsiveLine
      data={filteredData}
      margin={{ top: 50, right: 110, bottom: 100, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -35,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "count",
        legendOffset: -40,
        legendPosition: "middle"
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "seriesColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          itemDirection: "left-to-right",
          translateX: 100,
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                fontWeight: "bold",
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
    />}
  </>
};

export default ReferrerChart;
