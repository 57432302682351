import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
  faShopify,
  faWix,
  faWordpress,
  faYoutube,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

const IconSelect = ({ path, iconName }) => {
  const selectIcon = (iconName) => {
    switch (iconName) {
      case 'shopify':
        return faShopify
      case 'instagram':
        return faInstagram
      case 'linkedin':
        return faLinkedin
      case 'wix':
        return faWix
      case 'wordpress':
        return faWordpress
      case 'youtube':
        return faYoutube
      case 'twitter':
        return faTwitter
      default:
        return faFacebookF
    }
  }
  return (
    <a
      href={path}
      target='_blank'
      className='h-10 w-10 justify-center flex items-center rounded-full'>
      <FontAwesomeIcon
        icon={selectIcon(iconName)}
        className='text-xl text-white hover:text-mustard-400 cursor-pointer '
      />
    </a>
  )
}

export default IconSelect
