import axios from 'axios';
import React, { useEffect, useState } from 'react';
import SortDropdown from '../../../main/payments_dashboard/sortDropdown';
import { useHistory } from 'react-router-dom';
import useViewport from '../../../../hooks/useViewport';
import { doOrdersExist } from '../../../../utilities/purchaseHistoryUtility';
import NavigationBar from '../navigationBar';
import PurchaseHistoryCards from './mobile/purchaseHistoryCards';
import NoData from './noData';
import PurchaseHistoryTable from './table/purchaseHistoryTable';
import queryString from 'query-string';
import MyProductsSelectStyle from '../../../../constants/custom_styles/myProductsSelectStyle';
import { DateSortOptions } from '../../../../constants/dropdown_options/dateSortOptions';

const UserPurchaseHistory = () => {

  const parsedQuery = queryString.parse(window.location.search);

  const history = useHistory();
  const { isMobile } = useViewport();
  const [orders, setOrders] = useState([]);
  const [currentSort, setCurrentSort] = useState(parsedQuery.order);
  const [isFetchingOrders, setIsFetchingOrders] = useState(true);

  useEffect(() => {
    fetchOrders();
  }, [currentSort]);

  const fetchOrders = () => {
    setIsFetchingOrders(true);
    axios({
      method: 'get',
      url: `/users/orders.json`,
      params: {
        order: currentSort,
      }
    }).then(response => {
      setOrders(response.data);
    }).finally(() => {
      setIsFetchingOrders(false);
    })
  }

  const onDetailsClicked = (orderNumber) => {
    history.push(`/users/orders/${orderNumber}`);
  }

  const onSortChange = sort => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentSort(sort);
    parsedQuery.order = sort;
    history.push(`${location.pathname}?${queryString.stringify(parsedQuery)}`)
  }

  return (
    <div className='w-full mx-auto'>
      <NavigationBar activeKey={location.pathname} />
      <section className='lg:w-4/5 xl:w-2/3 w-full px-4 mx-auto md:my-12 my-6'>
        <div className='flex md:flex-row md:justify-between flex-col'>
          <h2 className='text-3xl font-medium text-black'>Purchase History</h2>
          <SortDropdown options={DateSortOptions} value={currentSort} setValue={onSortChange}
            containerClassName="md:w-48 w-full md:mb-4 mt-4" styles={MyProductsSelectStyle} />
        </div>

        <div className='my-8'>
          {isMobile() ?
            <PurchaseHistoryCards orders={orders} onDetailsClicked={onDetailsClicked} /> :
            <PurchaseHistoryTable orders={orders} onDetailsClicked={onDetailsClicked} />
          }
          {!doOrdersExist(orders) && <NoData className='flex flex-col items-center p-12 my-12' />}
        </div>
      </section>
    </div>
  )
}

export default UserPurchaseHistory;
