import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Products from '../products/products'
import ProductsGrid from '../products/productsGrid'
import { ShopImage, ShopBanner } from "../shared_components/shopLogo";
import ShopFavorite from '../shared_components/shopFavorite'
import LoadingSpinner from '../shared_components/loadingSpinner'
import ReactPixel from "react-facebook-pixel"

import {
  Tags,
  IntegrationDescription,
  ContactInformationDisplay,
  SocialMediaLinks
} from '../shared_components/shopPageHelper'

const ShopPage = () => {
  let { integration_slug } = useParams()
  const [integration, setIntegration] = useState({ name: '', city: { name: '' } })
  const query = new URLSearchParams(location.search)
  const isPreview = query.get('preview')

  useEffect(() => {
    axios
      .get(`/shops/${integration_slug}.json`)
      .then((response) => {
        setIntegration(response.data)
      })
  }, [])

  if (isPreview || integration) {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };
    ReactPixel.init(integration.pixel_id, {}, options);
    ReactPixel.pageView(window.location.pathname)
    ReactPixel.fbq('track', 'PageView')

      return (
        <Products>
          <ShopBanner shop={integration} />
          <div className='container relative mx-auto lg:pt-0'>
            <div className='flex flex-col md:py-8 px-2 lg:px-0 justify-between'>
              <div className='flex flex-col pt-12 md:pt-20 justify-start'>
                <ShopImage shop={integration}
                           className='lg:h-48 lg:w-48 md:h-40 md:w-40 w-20 h-20 rounded-full shadow-none border-solid border-white border-4' />
                <div className='flex flex-col mx-4 py-2 pb-8'>
                  <div className='flex flex-col'>
                    <div className='sm:grid lg:grid-cols-5 gap-4'>
                      <div
                        className='col-start-1 col-span-3 pb-4 border-b border-gray-400 sm:border-r sm:border-b-0'>
                        <div className='flex flex-row items-center justify-start'>
                          <h1 className='text-lg pr-4 md:text-2xl'>{integration.shop_name}</h1>
                          <ShopFavorite shop={integration} />
                        </div>
                        <div className='flex-grow'>
                          <div className='max-w-2xl lg:pt-6'>
                            {integration.description && <IntegrationDescription integration={integration}
                                                                                descriptionClass={"font-thin break-all md:mr-2 lg:mr-0"} />}
                          </div>
                        </div>
                        <Tags integration={integration} />
                      </div>
                      <div className='col-start-4 col-end-5 md:pl-10'>
                        <div className='flex flex-col items-stretch py-2'>
                          <div className='flex flex-row block sm:flex-col'>
                            <div className='sm: flex flex-col w-1/2 pr-2 sm:w-full sm:p-0'>
                              <div className='font-semibold flex flex-start pt-5 sm:pt-3'>
                                Location
                              </div>
                              <div className='flex flex-rows items-center py-2'>
                                <div className='text-base text-gray-700'>
                                  {integration.street_address
                                    ? integration.street_address
                                    : null}{' '}
                                  {integration.city.name}
                                </div>
                              </div>
                            </div>
                            <div className='sm: flex flex-col w-1/2 pl-2 sm:w-full sm:p-0'>
                              <div className='font-semibold flex flex-start pt-5 sm:pt-3'>
                                Social links
                              </div>
                              <SocialMediaLinks integration={integration} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-end-6 col-span-1'>
                        <div className='hidden sm:inline-block flex flex-start pt-5 md:pb-3 pl-2'>
                          Contacts
                        </div>
                        <div className='flex flex-row md:flex-col md:justify-end'>
                          {integration.merchant && (
                            <ContactInformationDisplay
                              phoneNumber={integration.merchant.phone_number}
                              email={integration.merchant.email}
                              integration_slug={integration_slug}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-light-grey py-8 p-4'>
            <div className='container mx-auto'>
              <ProductsGrid />
            </div>
          </div>
        </Products>
      )
  } else {
    return <LoadingSpinner />
  }
}

export default ShopPage
