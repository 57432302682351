import React, { useState, useEffect } from "react"
import Select from "react-select"
import getData from "../../../../../utilities/getData"

export default function CitySelect({
  selectedCity,
  setSelectedCity,
  name,
}) {
  const [cities, setCities] = useState([])
  const [options, setOptions] = useState([])

  const findSelectedCity = (id) => {
    return cities.find((city) => city.id === id)
  }

  useEffect(() => {
    getData("/cities.json", (data) => setCities(data))
  }, [])

  useEffect(() => {
    if (cities) {
      setOptions(cities.map((city) => ({ label: city.name, value: city.id })))
    }
  }, [cities])

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      backgroundColor: "#f7fafc",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: "#f7fafc",
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: "0px",
    }),
  }

  return (
    <Select
      styles={customStyles}
      value={
        selectedCity ? { label: selectedCity.name, value: selectedCity.id } : ""
      }
      onChange={(selectedOption) => setSelectedCity(findSelectedCity(selectedOption.value))
      }
      options={options}
      name={name}
      placeholder={"Select new city"}
    />
  )
}
