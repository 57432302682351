import React from 'react'
import Shops from "../main/page_body/brands/shops";
import ShopBrowse from "./shopBrowse";

const MarketShopBrowse = () => {
  return <Shops>
    <ShopBrowse />
  </Shops>
}

export default MarketShopBrowse
