import React from 'react';
import MobileCardRow from '../../shared_components/mobileCardRow';
import DeleteButton from '../shared/deleteButton';
import RateTypeSelect from '../shared/rateTypeSelect';
import ToggleButton from '../shared/toggleButton';
import ValueInput from '../shared/valueInput';

const ShippingCard = ({
  name,
  label,
  currencyPrefix,
  values,
  errors,
  getFieldName,
  updateFormikValue,
  enableActions,
  enableDeletion,
  onDelete,
  children
}) => {

  const hasErrorInRates = !!(errors && errors[name])

  return (
   <div className={"flex flex-col bg-white rounded-lg p-4 gap-5"}>
       <div className={"flex flex-row w-full justify-between"}>
           <h2 className="text-base">{label}</h2>
           <div className={"flex gap-2 items-center"}>
               <DeleteButton onDelete={onDelete} className={`${enableDeletion && "visible"|| "invisible"}`} />
               <ToggleButton
                   isChecked={values[getFieldName('enabled')]}
                   onChange={checked => updateFormikValue(getFieldName('enabled'), checked)}
                   iconChecked={<h2 className='text-sm absolute pt-2 left-4 text-white'>Enabled</h2>}
                   iconUnchecked={<h2 className='text-sm absolute pt-2 right-4 text-gray-600 '>Disabled</h2>}
                   width={105}
                   height={33}
                   diameter={24}
               />
           </div>
       </div>
       <div className={"flex flex-row w-full items-center justify-between "}>
           <h2 className={"text-base"}>Flat Rate</h2>
           <div className='flex flex-col items-center gap-2'>
               <RateTypeSelect getFieldName={getFieldName} updateFormikValue={updateFormikValue} values={values} className='w-full text-left' />
               <ValueInput currencyPrefix={currencyPrefix} value={values[name]}
                           onValueChange={value => updateFormikValue(name, value)}
               />
               <span className={`${hasErrorInRates && "visible" || "invisible"} text-red-600 text-xs font-light`}>should be a valid rate</span>
           </div>
       </div>

       {children}
   </div>
  )
}

export default ShippingCard;
// <div className='flex flex-col bg-white p-1 rounded-md last:rounded-b-none'>
//     <MobileCardRow label='Shipping Type' labelClassName='py-6' childrenClassName='flex items-center justify-end font-bold text-base'>
//         {label}
//     </MobileCardRow>
//     {children}
//     <MobileCardRow label='Flat Rate' labelClassName='py-6' childrenClassName='flex items-center justify-end'>
//
//     </MobileCardRow>
//     <MobileCardRow label='Price' labelClassName='py-6' childrenClassName='flex items-center justify-end'>
//
//     </MobileCardRow>
//
//     <MobileCardRow label='Enabled' labelClassName='py-6' childrenClassName='flex items-center justify-end'>
//
//     </MobileCardRow>
// </div>
