import React, { useContext } from 'react';
import MyProductsSelectStyle from '../../../../constants/custom_styles/myProductsSelectStyle';
import { ProductSortOptions } from '../../../../constants/dropdown_options/productSortOptions';
import CategorySelect from '../../../main/page_body/shared_components/categorySelect';
import SearchInput from '../../searchInput';
import SortDropdown from '../../../main/payments_dashboard/sortDropdown';
import WishlistContext from './wishlistContext';

const WishlistFilters = () => {
  const { currentSort, onSortChange, categorySelected, onCategoryChange, searchTerm, onSearchTermChange, wishlist } = useContext(WishlistContext);
  return (
    <>
      <SearchInput onChange={onSearchTermChange} value={searchTerm} />
      <CategorySelect wishlist={wishlist} styles={MyProductsSelectStyle} containerClassName='md:w-64 w-full' value={categorySelected} onChange={onCategoryChange} />
      <SortDropdown options={ProductSortOptions} value={currentSort} setValue={onSortChange}
        containerClassName="md:w-48 w-full" styles={MyProductsSelectStyle} />
    </>
  )
}

export default WishlistFilters;
