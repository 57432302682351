export const CheckoutQueryParamNames = {
  mode: 'mode'
}

export const CheckoutModes = {
  cart: 'cart',
  buyNow: 'buy-now'
}

export const appendCurrentCheckoutMode = (path) => {
  const checkoutMode = getCheckoutModeFromQuery();
  return checkoutMode ? `${path}?${CheckoutQueryParamNames.mode}=${getCheckoutModeFromQuery()}` : path;
}

export const getCheckoutModeFromQuery = () => {
  return new URLSearchParams(window.location.search).get(CheckoutQueryParamNames.mode);
}
