import React, { useContext, useEffect, useState } from "react";
import { CitiesContext } from "../../../../contexts/citiesContext";
import Background from "../../../../../assets/images/madeLocally_bg.svg";
import { loadAlgoliaProducts } from "../shared_components/algoliaSearch";
import CustomCarousel from "../shared_components/customCarousel";
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import MadeInCanada from "../../../../../assets/images/mainPageFeatured/made-in-canada.png"
import LocalProductCard from "../products/localProductCard";
import shuffle from "../../../../utilities/shuffle";
import determineMarketContext from '../../../../utilities/determineMarketContext';
import MadeLocallyProductsSkeleton from "../shared_components/skeletons/madeLocallyProductsSkeleton";
import LargeProductCard from "../products/largeProductCard";

const MadeLocallyProducts = () => {
  const { city } = useContext(CitiesContext)
  const { market } = useContext(determineMarketContext())

  const [fetching, setFetching] = useState(false)
  const [products, setProducts] = useState(null)
  const [retryCounter, setRetryCounter] = useState(1)
  const [totalHits, setTotalHits] = useState(0)

  useEffect(() => {
    if (city || market) {
      setRetryCounter(1)
      getProducts()
    }
  }, [city, market]);


  useEffect(() => {
    if (totalHits < 20 && retryCounter < 12) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [retryCounter])

  useEffect(() => {
    if (fetching && retryCounter < 12) {
      getProducts()
    }
  }, [fetching, retryCounter])

  const getProducts = () => {
    const searchOptions = { city, market, isMadeLocally: true, hits: 20 }
    loadAlgoliaProducts(searchOptions, retryCounter).then(({ hits, nbHits }) => {
      setProducts(shuffle(hits))
      setTotalHits(nbHits)
      if (nbHits < 20 && retryCounter < 12) {
        setRetryCounter(prevState => prevState + 1)
      }
    })
  }

  const MobileMadeLocally = () => {
    return (
      <div className="grid grid-cols-2 justify-items-center">
        {products.map(
          (product, index) =>
            index < 2 && (
              <LocalProductCard
                key={`made_locally_${product.objectID}`}
                product={product}
              />
            )
        )}
        <div className="col-span-2 py-10">
          <a
            href={(market && `/markets/${market.slug}/products`) || "/products?made_locally=true"}
            className="border border-black opacity-50 w-full px-32 py-3 rounded-full"
          >
            Show More
          </a>
        </div>
      </div>
    );
  };

  return (
    products ? (
      <section
        id="madelocally"
        style={{
          height: "50rem",
          backgroundImage: `url(${
            Background
          })`,
        }}
        className="w-full flex flex-col items-start bg-cover"
      >
        <div className="flex flex-col mx-2 xl:pl-8">
          <div className="flex w-full flex-row-reverse lg:block lg:w-full justify-between ">
            {city?.country?.name === 'Canada' && <img
              src={MadeInCanada}
              alt="Made In Canada"
              className="md:mr-24 lg:mr-0 lg:ml-48 md:h-32 h-24"
            />}
            <p
              className=" text-2xl md:text-4xl"
              style={{ color: "#383838" }}
            >
              Locally Made
            </p>
            <a
              href={"/products?made_locally=true"}
              className=" hidden md:block inline-block m-3 transition ease-in-out duration-500 transform hover:scale-105 hover:text-mustard-400"
            >
              Browse More &rarr;
            </a>
          </div>
          <p className="md:text-xl text-gray-700 py-2 ">
            For your ease of browsing, you will find locally-owned products
            and companies in this section
          </p>
        </div>
        <div className="my-auto flex flex-col xl:flex-row p-2 xl:py-8 xl:pl-8 w-full">
          <div className='hidden md:block w-full'>
            {(
              <div
                className="text-gray-900 antialiased">
                <CustomCarousel
                  breakpoints={carouselBreakpoints(4, 4, 3, 1)}
                >
                  {products.map((product) => (
                    <LargeProductCard
                      key={`made_locally_${product.objectID}`}
                      product={product}
                    />
                  ))}
                </CustomCarousel>
              </div>
            )}
          </div>
          <div className='block md:hidden'>
            <MobileMadeLocally />
          </div>
        </div>
      </section>
    ) : <MadeLocallyProductsSkeleton />
  );
};

export default MadeLocallyProducts;
