import React, { useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

const ValueInput = ({ currencyPrefix, value, onValueChange }) => {

  const [input, setInput] = useState(value);

  useEffect(() => {
    setInput(value);
  }, [value])

  return (
    <CurrencyInput decimalsLimit={2} decimalScale={2} allowNegativeValue={false}
      prefix={currencyPrefix} value={input}
      className='py-2 px-3 border-2 rounded-md border-beige-200 w-32'
      onValueChange={newValue => {
        setInput(newValue)
        onValueChange(newValue ? parseFloat(newValue) : 0);
      }}
    />
  )
}

export default ValueInput;
