import React from "react";
import Product from "./product";

export const NoProductsFound = ({ products, searchParams }) => {
  const randomItems = (array) => {
    let max = array.length - 1;
    for (; max > 0; max--) {
      const random = Math.floor(Math.random() * (max + 1));
      const temp = array[max];
      array[max] = array[random];
      array[random] = temp;
    }
    return array;
  };

  const shuffledList = randomItems(products);

  return (
    <div className="flex flex-col ">
      {
        searchParams.length > 0 &&
        <div className="text-center p-4">
          <p className="text-base items-center">
            We couldn't find exact matches for "{searchParams}"
          </p>
          <p className="text-base items-center mb-8">
            Try searching other keywords.
          </p>
          <a
            href='javascript:history.back()'
            className='text-xs bg-mustard-400 px-4 py-2 rounded-3xl text-white'
          >
            Back
          </a>
        </div>
      }
      <div className="text-gray-900 antialiased grid row-gap-16 col-gap-8 lg:grid-cols-4 px-6 py-4 md:grid-cols-3 grid-cols-1">
        {shuffledList.slice(0, 8).map((product) => (
          <Product product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};
