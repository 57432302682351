import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import ShopCard from "../brands/shopCard";
import carouselBreakpoints from "../../../../utilities/carouselBreakpoints";
import { CitiesContext } from "../../../../contexts/citiesContext";
import Award from "../../../../../assets/images/featured-br-ribbon";
import CustomCarousel from "../shared_components/customCarousel";
import FeaturedBrandsSkeleton from "../shared_components/skeletons/featuredBrandsSkeleton";

export default function FeaturedBrands({ market }) {
  const [shops, setShops] = useState([]);
  let { city } = useContext(CitiesContext);

  const [isMobile, setIsMobile] = useState(false);

  const url = market ? `/markets/${market.slug}/shops.json` : `/shops.json`

  useEffect(() => {
    axios({
      method: "GET",
      url: url,
      params: {
        limit: 8,
        with_logo: true,
        is_featured: true,
        city_slug: city?.slug,
      },
    }).then((response) => {
      setShops(response.data.shops);
    });
  }, [city]);


  const MobileFeaturedBrands = () => {
    return (
      <div className="grid grid-cols-2 justify-items-center">
        {shops.map(
          (shop, index) => index < 4 && <ShopCard shop={shop} key={shop.id} />
        )}
        <div className="col-span-2 py-4">
          <a
            href={(market && `/markets/${market.slug}/shops`) || "/shops"}
            className="border border-black opacity-50 w-full px-32 py-3 rounded-full"
          >
            Show More
          </a>
        </div>
      </div>
    );
  };

  return (
    shops.length > 0 ? (
      <section id="shops" className="lg:p-8 md:py-8 md:px-1 py-8">
        <div className="flex flex-col lg:flex-row justify-between items-start whitespace-pre lg:items-center">
          <div className="flex flex-row">
            <div className="px-1 self-center">
              <div
                style={{ backgroundImage: `url(${Award})` }}
                className="z-50 h-8 w-10 bg-no-repeat bg-contain"
              />
            </div>
            <h2 className="text-3xl lg:text-4xl">Featured Brands</h2>
          </div>

          <a
            href={(market && `/markets/${market.slug}/shops`) || "/shops"}
            className=" hidden md:block inline-block m-3 transition ease-in-out duration-500 transform hover:scale-105 hover:text-mustard-400"
          >
            All Brands &rarr;
          </a>
        </div>
        <div className='hidden md:block'>
          {(
            <CustomCarousel
              CarouselClass={"pb-10"}
              breakpoints={carouselBreakpoints(4, 4, 3, 1)}
              length="w-full"
            >
              {shops.map((shop) => (
                <ShopCard shop={shop} key={shop.id} />
              ))}
            </CustomCarousel>
          )}
        </div>
        <div className='block md:hidden'><MobileFeaturedBrands /></div>
      </section>
    ) : <FeaturedBrandsSkeleton />
  );
}
