import { convertFromRaw, ContentState } from 'draft-js'

function checkIfContentState(description) {
  return description.includes('{\"blocks\":[{\"key\"')
}

function initializeDescription(description) {
  if (checkIfContentState(description)) {
    return convertFromRaw(JSON.parse(description))
  } else {
    return ContentState.createFromText(description)
  }
}

export {
  checkIfContentState,
  initializeDescription
}
