import React, { useState } from "react";
import AnalyticsModal from "../../../shared_components/analyticsModal/analyticsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/pro-solid-svg-icons";

const AnalyticsButton = ({ product}) => {
  const [isOpened, setIsOpened] = useState(false);

  const toggleModal = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div>
      <div onClick={toggleModal} title="Analytics" >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.1668 5L11.2502 12.9167L7.0835 8.75L0.833496 15"
            stroke="#383838"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.1665 5H19.1665V10"
            stroke="#383838"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {isOpened && <AnalyticsModal product={product} onClose={toggleModal} />}
    </div>
  );
};

export default AnalyticsButton;
