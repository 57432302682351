import React from 'react';

const TableHeader = ({ headerClassName, labelClassName, enableActions }) => {
  return (
    <thead className={headerClassName}>
      <tr>
        <th className={`rounded-tl-md ${labelClassName} pl-5 lg:w-1/3 w-2/5`}>Type Shipping</th>
        <th className={`${labelClassName} w-1/4`}>Flat Rates</th>
        <th className={`${labelClassName} w-1/4`}>Price</th>
        {
          enableActions &&
          <th className={`${labelClassName} text-center w-0`}>Actions</th>
        }
        <th className={`rounded-tr-md ${labelClassName} text-right pr-5 w-0`}>Enabled</th>
      </tr>
    </thead >
  )
}

export default TableHeader;
