import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

const SearchInput = ({
  containerClassName,
  icon,
  iconClassName,
  buttonClassName,
  inputClassName,
  placeholder,
  debounceDelay = 500,
  onChange,
  value
}) => {
  const delayedQuery = useCallback(debounce(onChange, debounceDelay), []);

  const [input, setInput] = useState(value || '');

  useEffect(() => {
    setInput(value);
  }, [value])

  const onInputChange = event => {
    setInput(event.target.value);
    delayedQuery(event.target.value);
  }

  return (
    <div className={`border border-mustard-200 border-opacity-50 flex flex-row rounded-md p-2 ${containerClassName}`}>
      <button type='button' className={`px-1 focus:outline-none cursor-pointer hover:text-gray-400 ${buttonClassName}`}>
        <FontAwesomeIcon icon={icon || faSearch} className={`text-lg mt-1 ${iconClassName}`} />
      </button>
      <input className={`w-full px-2 text-base font-light focus:outline-none placeholder-black ${inputClassName}`}
        type='text' value={input} onChange={onInputChange} placeholder={placeholder || 'Search...'} />
    </div>
  )
}

export default SearchInput;
