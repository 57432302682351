import React from 'react';
import { faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PlusMinus = ({ quantity, plusDisabled, minusDisabled, onPlusClicked, onMinusClicked, className }) => {
  return (
    <div className={`flex flex-row gap-x-2 ${className}`}>
      <button
        type='button'
        disabled={minusDisabled}
        className='btn w-8 p-2 bg-white text-center border border-grey-500 rounded-lg flex justify-center items-center'
        onClick={onMinusClicked}>
        <FontAwesomeIcon icon={faMinus} size='sm' />
      </button>
      <p className='flex items-center'>{quantity}</p>
      <button
        type='button'
        disabled={plusDisabled}
        className='btn w-8 p-2 bg-white text-center border border-grey-500 rounded-lg flex justify-center items-center'
        onClick={onPlusClicked}>
        <FontAwesomeIcon icon={faPlus} size='sm' />
      </button>
    </div>
  )
}

export default PlusMinus;
