import React from "react";
import Select from "react-select";

const SortDropdown = ({
  options,
  value,
  setValue,
  containerClassName,
  styles,
  placeholder,
  menuPortalTarget,
}) => {
  return (
    <section className={containerClassName}>
      <Select
        placeholder={placeholder || "Sort By"}
        options={options}
        styles={styles}
        components={{ IndicatorSeparator: () => null }}
        isClearable={false}
        value={options.find((option) => option.value === value) || null}
        isSearchable={false}
        onChange={(option) => {
          setValue(option && option.value);
        }}
        menuPortalTarget={menuPortalTarget}
      />
    </section>
  );
};

export default SortDropdown;
