import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import CustomCarousel from "../customCarousel";
import carouselBreakpoints from "../../../../../utilities/carouselBreakpoints";
import { ImageCardSkeleton } from "./skeletonHelpers";

const CommunityMarketsSkeleton = () => {

  const MobileCommunityMarketsSkeleton = () => {
    return (
      <div className=''>
        <section id='markets' className='p-4'>
          <div className="flex flex-col">
            <div className="px-2 py-2 space-y-3">
              {[0,1,2].map((market, index) => (
                (
                  <ImageCardSkeleton
                  />
                )
              ))}
            </div>
            <div className="flex justify-tems-center col-span-2 py-10">
              <a
                className="border border-black opacity-50 w-full px-32 py-4 rounded-full break-normal items-center"
              >
                <Skeleton/>
              </a>
            </div>
          </div>
        </section>
      </div>
    )
  }
  return(
    <div className='hidden md:block w-full'>
      <CustomCarousel
        breakpoints={carouselBreakpoints(4, 4, 3, 1)}
      >
        {[0,1,2].fill(3).map((market) => (
          <div  className="px-3 ">
            <ImageCardSkeleton/>
          </div>
        ))}
      </CustomCarousel>
      <div className='block md:hidden'>
        <MobileCommunityMarketsSkeleton/>
      </div>
    </div>
  )
}

export default CommunityMarketsSkeleton
