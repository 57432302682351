import React, { useContext } from 'react'
import { MarketContext } from "./market";
import Shops from "../main/page_body/brands/shops";
import ShopsGrid from "../main/page_body/brands/shopsGrid";

const ShopBrowse = () => {
  let { market } = useContext(MarketContext)

  return market && <Shops>
    <div className='p-2 md:p-8 bg-white'>
      <div className='container mx-auto'>
        <h1 className='mb-2 md:mb-4'>{`Browse Shops in ${market.name}`}</h1>
        <ShopsGrid />
      </div>
    </div>
  </Shops>
}

export default ShopBrowse
