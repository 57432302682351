import React from 'react'

import 'react-confirm-alert/src/react-confirm-alert.css'

import CustomerBanner from '../../../../../assets/images/customer-banner.png';
import BrandBanner from '../../../../../assets/images/brand-banner.png';

import BgBlueRectangle from '../../../../../assets/images/bg-blue-rectangle.png';
import IcWhiteArrow from '../../../../../assets/images/ic-white-arrow.png';
import SignUpModal from './signUpModal';

const JoinLocalShopsModal = ({ onClose }) => {

  const CardImage = ({ imgURL, redirectLink, onClick }) => {
    return (
      <a href={redirectLink}>
        <img role='button' src={imgURL} onClick={onClick}
          className='transform scale-100 hover:transition duration-500 hover:scale-102'
        />
      </a>
    )
  }

  return (
    <SignUpModal
      title={<div className='font-bold'>Join Local Shops!</div>} onClose={onClose}
    >
      <div className='font-light mb-5'>Shop independent brands or showcase your own!</div>
      <div className='grid grid-cols-2 gap-4'>
        <CardImage redirectLink="/users/sign_up" imgURL={CustomerBanner} onClick={onClose} />
        <CardImage redirectLink="/merchants/sign_up" imgURL={BrandBanner} onClick={onClose} />
      </div>
      <div className='mt-4 h-10 relative flex items-center' role='button' onClick={onClose}>
        <img className='absolute' src={BgBlueRectangle} />
        <div className='flex justify-between sm:px-5 pl-5 pr-4 w-full'>
          <span className='z-0 text-white sm:text-sm text-xs'>Continue as Guest</span>
          <img className='sm:w-3 w-2 h-full transform scale-75' src={IcWhiteArrow} />
        </div>
      </div>
    </SignUpModal>
  )
}

export default JoinLocalShopsModal;
