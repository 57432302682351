import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';

import FormInputRow from '../../../../shared/form_fields/FormInputRow';
import ShippingAddressValidation from '../../../../../constants/form_validations/shippingAddressValidation';
import ShippingAddressInitialValues from '../../../../../constants/form_initial_values/shippingAddressInitialValues';
import FormPhoneInputRow from '../../../../shared/form_fields/FormPhoneInputRow';
import FormSelect from '../../../../shared/form_fields/FormSelect';
import axios from 'axios';
import FormCheckbox from '../../../../shared/form_fields/FormCheckbox';
import {
  updateFormikField, regionsAsDropdownItems,
  getRegionById, getCountryCodeById
} from '../../../../../utilities/shippingUtility';

import ShippingAddressDropdownStyle from '../../../../../constants/custom_styles/shippingAddressDropdownStyle';

const RegionFormSelect = ({
  formProps,
  name,
  placeholder,
  options,
  isLoading,
  isDisabled,
  onOptionValueChange
}) => {
  return (
    <FormSelect name={name} placeholder={placeholder} options={options} isLoading={isLoading} isDisabled={isDisabled}
      styles={ShippingAddressDropdownStyle}
      value={options.find((option) => option.value === formProps.values[name]) || null}
      onChange={option => {
        updateFormikField(formProps, name, option.value);
        onOptionValueChange(option.value)
      }} />
  )
}

const ShippingAddressForm = ({
  formId,
  reference,
  formTitle,
  className,
  onSubmit,
  children,
  initialValues
}) => {

  const [countriesList, setCountriesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  const [isFetchingCountriesList, setIsFetchingCountriesList] = useState(false);
  const [isFetchingCitiesList, setIsFetchingCitiesList] = useState(false);

  useEffect(() => {
    fetchRegions('/countries', setIsFetchingCountriesList, setCountriesList);
    fetchRegions('/cities', setIsFetchingCitiesList, setCitiesList);
  }, [])

  const fetchRegions = (query, loaderFunc, setRegion) => {
    loaderFunc(true);
    return axios.get(query)
      .then(response => {
        setRegion(response.data);
        return response;
      }).finally(() => {
        loaderFunc(false);
      })
  }

  return (
    <div ref={reference} className={`border border-beige-200 rounded-md p-10 ${className}`}>
      <h4 className='ml-0 mr-auto'>{formTitle}</h4>
      <Formik
        initialValues={!initialValues ? ShippingAddressInitialValues : initialValues}
        validationSchema={ShippingAddressValidation}
        onSubmit={onSubmit}
      >
        {
          formProps =>
            <Form id={formId} className='mt-16 mb-4 grid grid-cols-1 gap-y-4'>

              <FormInputRow name='name' placeholder='Name'
                value={formProps.values?.name} onChange={formProps.handleChange} />

              <RegionFormSelect formProps={formProps} name='country_id' placeholder='Country'
                options={regionsAsDropdownItems(countriesList)} isLoading={isFetchingCountriesList}
                onOptionValueChange={(updatedValue) => {
                  updateFormikField(formProps, 'city_id', null);
                  updateFormikField(formProps, 'province', '');
                  fetchRegions(`/cities?country_id=${updatedValue}`, setIsFetchingCitiesList, setCitiesList);
                }}
              />

              <FormInputRow name='street_address' placeholder='Street Address'
                value={formProps.values?.street_address} onChange={formProps.handleChange} />

              <RegionFormSelect formProps={formProps} name='city_id' placeholder='City/Town'
                options={regionsAsDropdownItems(citiesList)} isLoading={isFetchingCitiesList}
                onOptionValueChange={(updatedValue) => {
                  const city = getRegionById(citiesList, updatedValue);
                  updateFormikField(formProps, 'country_id', city.country.id);
                  updateFormikField(formProps, 'province', city.province);
                }}
              />

              <section className='grid grid-cols-2 gap-x-4'>
                <FormInputRow name='province' placeholder='Province'
                  isDisabled={true} value={formProps.values?.province} />

                <FormInputRow name='postal_code' placeholder='Postal Code'
                  value={formProps.values?.postal_code} onChange={formProps.handleChange} />
              </section>

              <FormPhoneInputRow name='phone_number' placeholder='Phone Number'
                country={getCountryCodeById(countriesList, formProps.values?.country_id)}
                value={formProps.values?.phone_number} onChange={number => {
                  formProps.values.phone_number = number;
                  formProps.setFieldValue('phone_number', number);
                }} />

              <FormCheckbox name='is_default' isChecked={formProps.values?.is_default} onChange={formProps.handleChange} />
              {children}
            </Form>
        }
      </Formik>
    </div >
  )
}

export default ShippingAddressForm;
