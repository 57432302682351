const optionText = (provided) => ({
  ...provided,
  fontSize: '1rem',
  fontWeight: 100,
})

const getOptionColor = (state) => { 
  if (state.isSelected)
    return '#DEA448';
  else if (state.isFocused)
    return state.isMulti ? '#DEA448' : '#DBD4C1';

  return "#FFFFFF";
}

const MyProductsSelectStyle = {
  control: (provided) => ({
    ...provided,
    padding: '3px 0px 3px 4px',
    borderWidth: '1px',
    borderRadius: '0.5rem',
    borderColor: '#F1EBE3',
    boxShadow: 'none',
    ':hover': {
      borderColor: '#DBD4C1' 
    },
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#383838",
    border: '1.5px',
  }),
  placeholder: (provided) => ({
    ...provided,
    width: '210px',
    color: '#383838',
    fontSize: '1rem',
    fontWeight: 300
  }),
  input: optionText,
  option: (provided, state) => ({
    ...provided,
    backgroundColor: getOptionColor(state),
    fontSize: '1rem',
    fontWeight: 100,
    color: state.isFocused && state.isMulti ? '#FFFFFF' : provided.color
  }),
  singleValue: optionText,
  multiValue: optionText,
}

export default MyProductsSelectStyle;
