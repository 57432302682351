import React from "react"
import Logo from "../../../../assets/images/logo_black.png"

const Header = () => {
  return (
    <header className='flex justify-between items-center bg-white px-2 lg:px-4'>
      <div className='flex items-center h-24 py-1 lg:py-2'>
        <a href='/'>
          <img className='h-16 ml-4' src={Logo} alt='Local Shops' />
        </a>
      </div>
    </header>
  )
};

export default Header;
