import React, { useEffect, useState } from 'react'
import axios from 'axios'
export const DefaultMarketContext = React.createContext({
  market: null,
})

const DefaultMarket = ({ children }) => {
  const [market, setMarket] = useState(null)

  useEffect(() => {
    axios
      .get('/markets/local-shops.json')
      .then((response) => setMarket(response.data))
      .catch((err) => console.err(err))
  }, [])

  return (
    <DefaultMarketContext.Provider value={{ market: market }}>
      {children}
    </DefaultMarketContext.Provider>
  )
}

export default DefaultMarket
