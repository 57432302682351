import React, { useState } from 'react'
import CategorySelect from "../../shared_components/categorySelect";
import axios from "axios";
import { toast } from "react-toastify";

const BulkCategoryEdit = ({ categories, integration, products }) => {
  let [selectedCategory, setSelectedCategory] = useState(null)

  const updateProducts = (selectedOption) => {
    const formData = new FormData();

    const ids = products.map(product => product.id)
    formData.append("ids", ids)
    formData.append("category_id", selectedOption.value)

    axios({
      method: 'PUT',
      url: `/merchants/integrations/${integration.id}/products/bulk_update`,
      data: formData,
    })
      .then(() => {
        toast.success(`Successfully changed ${products.length} products to ${selectedOption.label}`);
        setSelectedCategory(null)
        window.location.href = `/merchants/integrations/${integration.id}/products`
      })
      .catch((err) => console.log(err));
  };

  return <CategorySelect categories={categories} selectedCategory={selectedCategory} onChange={updateProducts} />
}

export default BulkCategoryEdit
