import axios from 'axios';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ShippingRatesInitialValues } from '../../../constants/form_initial_values/shippingRatesInitialValues';
import ShippingRatesValidation from '../../../constants/form_validations/shippingRatesValidation';
import { handleAPIError } from '../../../utilities/apiErrorHandling';
import ShippingsForm from './shippingsForm';

const Shippings = ({ authenticity_token, integration_id }) => {

  const [primaryIntegration, setPrimaryIntegration] = useState(null);
  const [shippingRates, setShippingRates] = useState(null);

  useEffect(() => {
    fetchIntegration();
  }, [])

  const fetchIntegration = () => {
    return axios.get('/merchants/integrations.json').then(response => {
      if (response.data.length) {
        const integration = response.data.find(shop => shop.id === integration_id);
        setPrimaryIntegration(integration);
        fetchShippingRates(integration);
      }
    })
  }

  const fetchShippingRates = (integration) => {
    if (integration) {
      axios.get(`/merchants/integrations/${integration.id}/shippings.json`)
        .then(response => {
          if (response.data)
            setShippingRates(response.data);
        })
    }
  }

  const updateShippingRates = (values) => {
    let method = axios.post, rateIdParam = '';
    const requestBody = { ...values, international_shippings_attributes: values.international_shippings };
    delete requestBody.international_shippings;

    if (shippingRates) {
      method = axios.put;
      rateIdParam = `/${shippingRates.id}`;
    }

    return method(`/merchants/integrations/${primaryIntegration.id}/shippings${rateIdParam}`,
      {
        authenticity_token,
        shipping: requestBody,
      },
      {
        headers: {
          Accept: 'application/json'
        }
      }
    )
  }

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    if (primaryIntegration) {
      setSubmitting(true);
      updateShippingRates(values).then(() => {
        toast.success('Shipping rates updated!');

        setShippingRates(values);
        resetForm();
      }).catch(error => {
        console.error(error);
        handleAPIError(error, window.location.href);
      }).finally(() => {
        setSubmitting(false);
      })
    }
  }

  return (
    <div className='mb-32 lg:pt-0 pt-8'>
      <h1 className="text-3xl font-base mb-8">Shipping Rates</h1>
      <Formik
        initialValues={shippingRates || ShippingRatesInitialValues}
        validationSchema={ShippingRatesValidation}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {() => <ShippingsForm integration={primaryIntegration} />}
      </Formik>
    </div>
  )
}

export default Shippings;
