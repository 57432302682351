import React from 'react'
import Widget from '../../../merchants/shared_components/widget'
import ReactTooltip from 'react-tooltip'
import Button from '../../../main/header/shared_components/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStore,
  faExternalLink,
  faCircle,
} from '@fortawesome/pro-solid-svg-icons'

 const MarketItem = ({market}) => {
   return (
     <Widget className='w-full h-full rounded-lg border p-2 border-gray-400 p-4 flex flex-col justify-between'>
       <div className='flex items-center'>
         <h4 className='font-semibold text-base py-1'>
           <FontAwesomeIcon icon={faStore} className='text-xl' /> {market.name}
         </h4>
         <a
           data-tip
           data-for='market_page'
           className='px-2'
           target='_blank'
           href={`/markets/${market.slug}`}
         >
           <FontAwesomeIcon icon={faExternalLink} title='Market Page' />
           <ReactTooltip id='market_page'>Go to Market Page</ReactTooltip>
         </a>
       </div>
       <div className='flex items-center'>
         <FontAwesomeIcon
           className={
             market.is_live
               ? 'text-xs text-green-700 absolute animate-ping'
               : 'text-xs absolute text-red-700'
           }
           icon={faCircle}
         />
         <FontAwesomeIcon
           className={
             market.is_live ? 'text-xs text-green-700' : 'text-xs text-red-700'
           }
           icon={faCircle}
         />
         <p className='px-2 py-1'>
           This market is {market.is_live ? 'live' : 'not live'}
         </p>
       </div>
       <div className='py-1'>
         <Button
           hover='gray-800'
           color='black'
           path={`/market_admins/markets/${market.id}/market_memberships`}
         >
           Go to Market Dashboard
         </Button>
       </div>
     </Widget>
   )
 }

export default MarketItem
