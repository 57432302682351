const optionText = provided => ({
  ...provided,
  fontSize: '0.95rem',
  fontWeight: 500
})

const ShippingAddressDropdownStyle = {
  control: (provided) => ({
    ...provided,
    padding: '8px 0px 8px 4px',
    borderWidth: '1px',
    borderRadius: '0.375rem',
    borderColor: 'rgba(241, 235, 227, var(--border-opacity))',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.85rem',
    fontWeight: 100
  }),
  input: optionText,
  option: optionText,
  singleValue: optionText
}

export default ShippingAddressDropdownStyle;
