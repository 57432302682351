import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const FooterLinkInternal = ({ title, path }) => {
  return (
    <div className='truncate text-white font-hairline mt-2 text-sm'>
      {path === '' ? (
        <a
          onClick={() => toast.success('Coming Soon!')}
          className='hover:text-mustard-400'>
          {title}
        </a>
      ) : (
        <Link to={`${path}`} className=' hover:text-mustard-400'>
          {title}
        </Link>
      )}
    </div>
  )
}

export default FooterLinkInternal;
