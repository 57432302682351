import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MarketCategoryPage from '../markets/marketCategoryPage'
import MarketSearchResultsPage from '../markets/marketSearchResultsPage'
import MarketShopBrowse from '../markets/marketShopBrowse'
import Market from '../markets/market'
import MarketProductsBrowse from '../markets/marketProductsBrowse'
import MarketLandingPage from '../markets/marketLandingPage'
import ShopPage from '../main/page_body/brands/shopPage'
import ProductPage from '../main/page_body/products/productPage'
import AllCategories from "../main/page_body/categories/allCategories";
import Favorites from '../main/page_body/shared_components/favorites'
import CheckoutShippingViewUI from "../users/checkout/shipping/checkoutShippingViewUI";
import CheckoutShippingAddFormUI from "../users/checkout/shipping/checkoutShippingAddFormUI";
import CheckoutShippingEditFormUI from "../users/checkout/shipping/checkoutShippingEditFormUI";
import CheckoutPaymentUI from "../users/checkout/payment/checkoutPaymentUI";

function Markets() {
  return (
    <Switch>
      <Route path='/markets/:market_slug/shops'>
        <MarketShopBrowse />
      </Route>
      <Route path='/markets/:market_slug/products'>
        <MarketProductsBrowse />
      </Route>
      <Route path='/markets/:market_slug/search'>
        <MarketSearchResultsPage />
      </Route>
      <Route path='/markets/:market_slug/categories'>
        <AllCategories />
      </Route>
      <Route path='/markets/:market_slug/:parent_slug/:category_slug'>
        <MarketCategoryPage />
      </Route>
      <Route path='/markets/:market_slug/:category_slug'>
        <MarketCategoryPage />
      </Route>
      <Route path='/markets/:market_slug'>
        <MarketLandingPage />
      </Route>
    </Switch>
  )
}

function CheckoutRoutes() {
  return (
    <Switch>
      <Route exact path='/checkout/addresses'>
        <CheckoutShippingViewUI />
      </Route>
      <Route exact path='/checkout/addresses/new'>
        <CheckoutShippingAddFormUI />
      </Route>
      <Route path='/checkout/addresses/:id/edit'>
        <CheckoutShippingEditFormUI />
      </Route>
      <Route exact path='/checkout/payments'>
        <CheckoutPaymentUI />
      </Route>
    </Switch>
  )
}

export default function MainMarkets({ current_merchant, current_user, form_authenticity_token }) {
  return (
    <Router>
      <Favorites current_user={current_user} authenticity_token={form_authenticity_token}>
        <Switch>
          <Route path='/products/:product_slug'>
            <Market current_merchant={current_merchant} current_user={current_user}>
              <ProductPage />
            </Market>
          </Route>
          <Route path='/shops/:integration_slug'>
            <Market current_merchant={current_merchant} current_user={current_user}>
              <ShopPage />
            </Market>
          </Route>
          <Route path='/markets/:market_slug'>
            <Market current_merchant={current_merchant} current_user={current_user}>
              <Markets current_merchant={current_merchant} />
            </Market>
          </Route>
          <Route path='/checkout'>
            <Market current_merchant={current_merchant} current_user={current_user}>
              <CheckoutRoutes />
            </Market>
          </Route>
        </Switch>
      </Favorites>
    </Router>
  )
}
