import React from 'react';

const TableHeader = ({ labelClassName}) => {
  return (
    <thead className='bg-beige-150'>
    <tr className='mb-2'>
      <th colSpan='2' className={labelClassName}>Date</th>
      <th className={labelClassName}>Customer</th>
      <th className={labelClassName}>Total</th>
      <th className={labelClassName}>Payment Status</th>
      <th className={labelClassName}>Fulfillment Status</th>
      <th className={labelClassName}># of Items</th>
      <th className={labelClassName}>Actions</th>
    </tr>
    </thead>
  )
}

export default TableHeader;
