import React from 'react'
import SynchronizeProducts from '../integrations_dashboard/integration_items/synchronizeProducts'
import Image from '../../../../assets/images/image-icon-productPage.png'

const OnboardingIntegrations = ({integration, title}) => {
  return (
    <div className='bg-white border border-gray-300 max-w-md flex flex-col mx-auto mt-24'>
      <div className='pt-4 px-24 flex flex-col items-center pb-12'>
        <p className='text-xs text-center '>No {title} to Display</p>
        <p className='text-xs text-center '>
          Let's get started by importing {title}
        </p>
        <div className='p-3'>
          <img src={Image} className='w-24' alt='No items found' />
        </div>
        <SynchronizeProducts integration={integration} />
      </div>
    </div>
  )
}
export default OnboardingIntegrations
