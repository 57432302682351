import React from 'react';
import NavigationBar from '../navigationBar';
import Addresses from './addresses';
import UserDetailForm from './userDetailForm';

const AccountSettings = () => {

  return (
    <div className='w-full mx-auto'>
      <NavigationBar activeKey={location.pathname} />
      <div className='lg:w-4/5 xl:w-2/3 w-full px-4 mx-auto md:my-12 my-6'>
        <h1 className='text-3xl font-medium text-black'>My Account</h1>
        <div className='grid grid-cols-1 xl:grid-cols-11 gap-x-4 gap-y-4 mt-8'>
          <div style={{ height: 'fit-content' }} className='col-span-1 xl:col-span-6 grid grid-cols-1 gap-y-4'>
            <UserDetailForm />
          </div>
          <Addresses className='col-span-1 xl:col-span-5' />
        </div>
      </div>
    </div>
  )
}

export default AccountSettings;
