import React from 'react';

const AccountDetails = ({ merchant }) => {

  const Row = ({ label, value }) => {
    return (
      <div className='flex flex-row items-center md:gap-10 gap-0'>
        <span className='font-light text-xs text-gray-600 '>{label}</span>
        <div className='text-sm font-light'>{value}</div>
      </div>
    )
  }

  return (
    <section className="border-2 rounded-md p-8 my-4 border-mustard-light">
      <h2 className='text-base font-semibold'>Account Details</h2>
      <div className='flex flex-col gap-5 py-5'>
        <Row label='First Name' value={merchant.first_name} />
        <Row label='Last Name' value={merchant.last_name} />
        <Row label='Email Address' value={merchant.email} />
      </div>
    </section>
  )
}

export default AccountDetails;
