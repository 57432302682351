import React from 'react';
import MyAccountButton from '../my_account/myAccountButton';
import LoginButton from './logInButton';

const SignInOptions = ({ current_merchant, current_user }) => {
  return !current_merchant && !current_user ?
    <LoginButton /> :
    <MyAccountButton current_merchant={current_merchant} current_user={current_user} />
}

export default SignInOptions;
