import React, { useState } from "react";

const Tooltip = () => {
  const [tooltip, setTooltip] = useState(false);

    const Icon = () => {
        return (
            <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M7.99968 14.6663C11.6816 14.6663 14.6663 11.6816 14.6663 7.99968C14.6663 4.31778 11.6816 1.33301 7.99968 1.33301C4.31778 1.33301 1.33301 4.31778 1.33301 7.99968C1.33301 11.6816 4.31778 14.6663 7.99968 14.6663Z"
                stroke="#383838"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 10.667H8.00667"
                stroke="#383838"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 5.33301V7.99967"
                stroke="#383838"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        );
    }

  return (
    <div
      onMouseEnter={() => tooltip || setTooltip(true)}
      onMouseLeave={() => setTooltip(false)}
    >
      <div
        className={`inline-block absolute ${
          tooltip || "invisible"
        } z-10 text-sm font-medium text-white bg-white rounded-lg shadow-lg`}
      >
      <Icon />
        <p className="py-2 px-3 text-sm text-gray-700 mb-4 font-light">
          Facebook Pixel tracks customer behavior on your online store. This
          data improves your marketing abilities, including retargeting ads.{" "}
          <a
            className="cursor-pointer underline"
            href="https://www.facebook.com/business/help/742478679120153?id=1205376682832142&helpref=search&sr=2&query=what%20is%20pixel"
            target="_blank"
          >
            Learn more about Facebook Pixel
          </a>
        </p>
      </div>
      <div>
        <Icon />
      </div>
    </div>
  );
};

export default Tooltip;
