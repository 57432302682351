import React from 'react';
import FooterColumn from './shared/footerColumn';
import FooterLinkExternal from './shared/footerLinkExternal';

const About = () => {
  return (
    <FooterColumn>
      <span className='text-white font-semibold pb-2 text-sm'>About</span>
      <FooterLinkExternal title='Contact Us' path='/contact-us' />
      <FooterLinkExternal
        title='Media Kit'
        path='https://drive.google.com/drive/u/1/folders/1cmmfLp6X_YP9sNn1mUM5Z-PQqwqnLw1R'
      />
    </FooterColumn>
  )
}

export default About;
