import React, { useContext } from 'react'
import Shops from './shops'
import ShopsGrid from './shopsGrid'
import { CitiesContext } from "../../../../contexts/citiesContext";
import SubHeader from "../shared_components/subHeader";

const ShopBrowse = () => {
  let { city } = useContext(CitiesContext)

  return city && <Shops>
    <SubHeader breadcrumbs='All Brands' link='/shops' />
    <div className='p-2 md:p-8 bg-white'>
      <div className='container mx-auto'>
        <ShopsGrid />
      </div>
    </div>
  </Shops>
}

export default ShopBrowse
