import React from 'react'
import FormMultiSelect from '../../../../shared/rails_form_fields/FormMultiSelect'

const OwnerTags = ({ tags, containerClassName }) => {
  const options = [
    { label: "Black Founder", value: "black" },
    { label: "Female Founder", value: "female" },
    { label: "Hispanic Founder", value: "hispanic" },
    { label: "Indigenous Founder", value: "indigenous" },
    { label: "LGBTQ2S+ Founder", value: "lgbtq2s+" },
    { label: "Minority Founder", value: "minority" },
    { label: "Veteran Founder", value: "veteran" },
    { label: "Disabled Founder", value: "disabled" },
    { label: "Asian Founder", value: "asian" },
    { label: "Arab Founder", value: "arab" },
  ]

  const getSelectedValuesFromTags = (tags) => {
    const tagValues = tags.map(tag => tag.name);
    return options.filter(option => tagValues.includes(option.value))
  }

  return (
    <FormMultiSelect name='integration[tag_names][]' containerClassName={containerClassName}
      placeholder='Owner Details' values={getSelectedValuesFromTags(tags)} options={options} />
  )
}

export default OwnerTags
