import React from 'react'
import FooterColumn from './shared/footerColumn'
import FooterLinkInternal from './shared/footerLinkInternal'

const Collective = () => {
  return (
    <FooterColumn>
      <span className='text-white font-semibold pb-2 text-sm'>Collective</span>
      <FooterLinkInternal title='Community Markets' path='/community-markets' />
      <FooterLinkInternal title='Retail Collective' path='/retail-collective' />
      <FooterLinkInternal title='Co-Warehousing' path='/co-warehousing' />
    </FooterColumn>
  )
}

export default Collective
