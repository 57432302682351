import React, { useContext, useEffect, useRef, useState } from 'react';
import SavedShippingAddressUI from './savedShippingAddressUI';
import IMGButton from '../../shared/IMGButton';

import IcPlus from '../../../../../../assets/images/ic-golden-plus.png';
import ShippingAddressForm from '../forms/shippingAddressForm';
import FormButton from '../../../../shared/form_fields/FormButton';
import { CheckoutShippingContext } from '../checkoutShippingContext';

const SavedShippingAddressesUI = ({ className, onEditAddress }) => {

  const {
    shippingAddresses,
    isAddingAddress,
    isDeletingAddress,
    isUpdatingAddress,
    hasAddresses,
    addAddress,
    chooseAddress,
    deleteAddress
  } = useContext(CheckoutShippingContext);

  const [isFirstScroll, setIsFirstScroll] = useState(true);
  const [isAddAddressSectionOpen, setIsAddAddressSectionOpen] = useState(false);

  const mainRef = useRef(null);
  const addressFormRef = useRef(null);

  const saveButtonColSpan = isAddingAddress ? 'col-span-2' : 'col-span-3';

  useEffect(() => {
    handleScrollUpdate();
  }, [isAddAddressSectionOpen])

  const handleScrollUpdate = () => {
    if (isFirstScroll) {
      setIsFirstScroll(false);
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      return;
    }

    const refToScrollTo = isAddAddressSectionOpen ? addressFormRef : mainRef;
    if (!!refToScrollTo && !!refToScrollTo.current)
      refToScrollTo.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleAddAddressSubmit = (values) => {
    addAddress(values).then(() => {
      setIsAddAddressSectionOpen(false);
    })
  }

  return (
    <div ref={mainRef}>
      <div className={`border border-beige-200 rounded-md p-10 ${className}`}>
        <h4 className='ml-0 mr-auto'>Shipping Address</h4>
        {
          hasAddresses() &&
          <div className='w-full mt-12 grid grid-cols-1 gap-y-4'>
            {
              shippingAddresses.map(address => {
                return (
                  <SavedShippingAddressUI
                    key={address.id}
                    onChoose={() => { chooseAddress(address) }}
                    onDelete={() => { deleteAddress(address.id) }}
                    onEdit={() => { onEditAddress(address.id) }}
                    disableInteraction={isDeletingAddress || isUpdatingAddress}
                    address={{
                      name: address.name,
                      country: address.city.country.name,
                      street_address: address.line1,
                      city: address.city.name,
                      province: address.city.province,
                      postal_code: address.postal_code,
                      phone_number: address.phone_number,
                      is_default: address.is_default
                    }}
                  />
                )
              })
            }
          </div>
        }
        <IMGButton onClick={() => { setIsAddAddressSectionOpen(!isAddAddressSectionOpen) }} src={IcPlus} label='Add Address'
          className='mt-4' imgClassName='w-3 h-auto mr-1' labelClassName='text-sm font-light text-yellow-600'
        />
      </div>
      {
        isAddAddressSectionOpen &&
        <ShippingAddressForm
          reference={addressFormRef}
          formTitle='Add Address'
          className='mt-6'
          onSubmit={handleAddAddressSubmit}
        >
          <section className='mr-0 ml-auto'>
            <section className='flex flex-row'>
              <button type='button' onClick={() => { setIsAddAddressSectionOpen(false) }}
                className='rounded-l-full rounded-r-full px-10 py-3 mr-2 relative bg-gray-200 text-sm'>Cancel</button>
              <FormButton type='submit' isLoading={isAddingAddress}
                loaderContainerClassName='w-5 h-0 col-span-1'
                className='rounded-l-full rounded-r-full px-10 py-3 
                relative bg-yellow-600 text-sm text-white grid grid-cols-3'>
                <span className={saveButtonColSpan}>Save</span>
              </FormButton>
            </section>
          </section>
        </ShippingAddressForm>
      }
    </div>
  )
}

export default SavedShippingAddressesUI;
