import React, { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel';
import RightArrow from "../../../../../assets/images/carousel-r-arrow"
import LeftArrow from "../../../../../assets/images/carousel-l-arrow"
import "react-multi-carousel/lib/styles.css";


const CustomCarousel = ({ children, breakpoints }) => {

  const CustomArrow = ({ onClick, right, left, arrow }) => (
    <button
      className="absolute bg-contain bg-no-repeat"
      onClick={onClick}
      style={{ backgroundImage: `url(${arrow})`, top: '35%', right: `${right}`, left: `${left}` }}
    >
      <div className='w-12 h-12' />
    </button>
  )

  return (
    <Carousel containerClass='w-full relative' responsive={breakpoints} renderButtonGroupOutside={true}
              customRightArrow={<CustomArrow arrow={LeftArrow} right='-1.4%' />}
              customLeftArrow={<CustomArrow arrow={RightArrow} left='-0.1%' />}
              itemClass="p-4"
    >
      {children}
    </Carousel>
  );
}

export default CustomCarousel
