const optionText = (provided) => ({
  ...provided,
  fontSize: '0.95rem',
  fontWeight: 300,
})

const getOptionColor = (state) => {
  if (state.isSelected)
    return '#DEA448';
  else if (state.isFocused)
    return state.isMulti ? '#DEA448' : '#DBD4C1';

  return "#FFFFFF";
}

const RailsFormSelectStyle = {
  control: (provided) => ({
    ...provided,
    padding: '8px 0px 8px 4px',
    borderWidth: '2px',
    borderRadius: '0.375rem',
    borderColor: '#DBD4C155',
    boxShadow: '0 0 1px #DBD4C1',
    ':hover': {
      borderColor: '#DBD4C1'
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '0.85rem',
    fontWeight: 100
  }),
  input: optionText,
  option: (provided, state) => ({
    ...provided,
    backgroundColor: getOptionColor(state),
    fontSize: '0.95rem',
    fontWeight: 300,
    color: state.isFocused && state.isMulti ? '#FFFFFF' : provided.color
  }),
  singleValue: optionText,
  multiValue: optionText
}

export default RailsFormSelectStyle;
