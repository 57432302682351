import React, { useContext } from 'react'
import { MarketContext } from "./market";
import ProductsGrid from "../main/page_body/products/productsGrid";
import Products from "../main/page_body/products/products";

const MarketProductsBrowse = () => {
  const SearchResults = () => {
    let { market } = useContext(MarketContext)

    return <>
      <h1 className='mb-2 md:mb-4'>
        {market && `Explore Products in ${market.name}`}
      </h1>
      <ProductsGrid />
    </>
  }

  return <Products>
    <div className='p-2 md:p-8 bg-light-grey'>
      <div className='container mx-auto'>
        <SearchResults />
      </div>
    </div>
  </Products>
}

export default MarketProductsBrowse
