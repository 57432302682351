import React from "react"

function YoutubeIconUnfilled() {
    return(
        <svg 
        className="h-5 w-6 text-white hover:fill-current hover:text-mustard-400"
        viewBox="0 0 24 17" 
        stroke="currentColor"
        fill="none" 
        strokeWidth="1.5"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M22.8313 3.41713C22.5748 2.4657 21.8193 
        1.71637 20.86 1.46208C19.1211 1 12.1486 1 12.1486 
        1C12.1486 1 5.1761 1 3.43723 1.46208C2.47791 1.71641 
        1.72236 2.4657 1.46592 3.41713C1 5.14167 1 8.73975 
        1 8.73975C1 8.73975 1 12.3378 1.46592 14.0624C1.72236 
        15.0138 2.47791 15.7319 3.43723 15.9862C5.1761 16.4483 
        12.1486 16.4483 12.1486 16.4483C12.1486 16.4483 19.1211 
        16.4483 20.86 15.9862C21.8193 15.7319 22.5748 15.0138 
        22.8313 14.0624C23.2972 12.3378 23.2972 8.73975 23.2972 
        8.73975C23.2972 8.73975 23.2972 5.14167 22.8313 3.41713ZM9.86818 
        12.0065V5.47296L15.6958 8.73983L9.86818 12.0065Z" 
        strokeLinejoin="round"/>
        </svg>
    );
}

export default YoutubeIconUnfilled;
