import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ImageCard from './imageCard';
import "react-multi-carousel/lib/styles.css";
import determineMarketContext from '../../../../utilities/determineMarketContext';
import carouselBreakpoints from '../../../../utilities/carouselBreakpoints';
import CustomCarousel from "../shared_components/customCarousel";
import { useParams } from 'react-router-dom'
import CommunityMarketsSkeleton from "../shared_components/skeletons/communityMarketsSkeleton";

const CommunityMarkets = () => {
  const { market } = useContext(determineMarketContext());
  const [markets, setMarkets] = useState([]);
  const [limitMarkets, setLimitMarkets] = useState(4);
  const { market_slug } = useParams()


  useEffect(() => {
    axios
      .get('/markets.json', { params: { is_live: true, market_slug: market_slug } })
      .then((response) => {
        setMarkets(response.data.markets)
      })
  }, [])

  const marketsView = () => {
    {
      limitMarkets === 4 ? setLimitMarkets(markets.length) : setLimitMarkets(4)
    }
  }

  const MobileCommunityMarkets = () => {
    return (
      <div className=''>
        <section id='markets' className='p-4'>
          <div className="flex flex-col">
            <div className="px-2 py-2 space-y-3">
              {markets.map((market, index) => (
                (index < limitMarkets) && (
                  <ImageCard
                    item={market}
                    image={market.market_icon}
                    path={`/markets/${market.slug}`}
                    key={market.id}
                  />
                )
              ))}
            </div>
            <div className="flex justify-tems-center col-span-2 py-10">
              <a
                className="border border-black opacity-50 w-full px-32 py-4 rounded-full break-normal items-center"
                onClick={(marketsView)}
              >
                {limitMarkets < 5 ? "Show More" : "Show Less"}
              </a>
            </div>
          </div>
        </section>
      </div>
    )
  }

  return (
    <div>
      <h2 className='text-3xl mt-8 mb-2 px-6'>Community Markets</h2>
      {markets.length > 0 ? (
        <div className='hidden md:block w-full'>
          <CustomCarousel
            breakpoints={carouselBreakpoints(4, 4, 3, 1)}
          >
            {markets.map((market) => (
              <div key={`markets_${market.id}`} className="px-3 ">
                <ImageCard item={market} image={market.market_icon}
                           path={market.slug === 'local-shops' ? '/' : `/markets/${market.slug}`} key={market.id} />
              </div>
            ))}
          </CustomCarousel>
        </div>
      ) : <CommunityMarketsSkeleton />}
      <div className='block md:hidden'>
        <MobileCommunityMarkets />
      </div>
    </div>
  )
}

export default CommunityMarkets
