import { useParams } from "react-router-dom"
import { MarketContext } from "../components/markets/market"
import { DefaultMarketContext } from "../contexts/defaultMarketContext"

export default function determineMarketContext() {
  let { market_slug } = useParams()
  const query = new URLSearchParams(location.search);
  market_slug = market_slug ? market_slug : query.get('market_slug')

  return market_slug ? MarketContext : DefaultMarketContext;
}
