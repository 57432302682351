import React from 'react';
import SubHeader from '../page_body/shared_components/subHeader';
import '../../../css/footer-page.css';
import Question from './shared/question';
import ScrollToTop from '../../../utilities/scrollToTop';

const ReturnsAndExchanges = () => {
  return (
    <ScrollToTop>
      <div className='footer-page'>
        <SubHeader breadcrumbs='Returns & Exchanges Policy' link='/returns-policy' />
        <div className="flex flex-col p-8 sm:px-32 lg:px-64 xl:px-80">
          <h1 className="font-bold text-3xl mx-auto my-4">Item Return or Exchange on Local Shops</h1>
          <p className='text-lg font-medium my-4'>
            For a return, exchange, or requesting a refund for your order, you must contact the brand
            your purchase from directly. Depending on the brands policies, they may be able to accommodate
            reasonable requests.
            <br /><br />
            If you need to return or exchange an item, the brand you purchased your order from is the best
            person to help you. Each brand on Local Shops manages their own orders, and makes decisions about
            cancellations, refunds, and returns.
          </p>
          <Question question='How do refunds and returns happen on Local Shops?'>
            Brands aren’t required to accept returns, exchanges, or provide a refund unless stated in their
            store policies. Go directly to the brands website and scroll to the bottom to see the shop's policies.
            If the brand does not have a policy, contact them directly for one. If a brand is willing to accept
            a return, be sure to document the details in an email before returning the item. Details should
            include:
            <ul className="footer-page-list px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 my-4">
              <li>Where to send the return</li>
              <li>When does the brand want to receive the return by</li>
              <li>Who will be responsible for the cost of return shipping</li>
              <li>What shipping method to use</li>
            </ul>
            What to do if a brand doesn’t refund an order, or if the brand doesn’t accept returns?
            If the brand doesn’t allow refunds or returns, you may be able to escalate communication to the Local Shops
            team. Please emails us all the details so we can decide on the best action. You can email us directly if:
            <ul className="footer-page-list px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 my-4">
              <li>You never receive your order</li>
              <li>Your order wasn’t as it was described on Local Shops</li>
            </ul>
          </Question>
          <Question question='What are store policies?'>
            Every brand on Local Shops is responsible for their own policies regarding refunds, exchanges, and
            returns.Policies vary from brand to brand.Before making a purchase, you should review the brand’s
            Returns & Exchanges Policy directly on their websites homepage.
          </Question>
          <Question question='What if a brand didn’t help me?'>
            Email the Local Shops team directly with the details of your order, item(s) in question and the
            communication you have attempted with the brand.
          </Question>
          <Question question='What if you checked out as a guest?'>
            Use your order confirmation details and contact the brand directly. You can find your guest order
            through your inbox.
            <ol className='footer-page-list px-8 py-2 mr-12 font-medium text-sm grid gap-y-4 my-4'>
              <li>
                Locate the email receipt you received when you placed your order. This email is sent from
                Local Shops.
              </li>
              <li>View your order and save it as a PDF on your desktop.</li>
              <li>Email your order detail and grievances directly to the brand you purchased from.</li>
            </ol>
          </Question>
          <Question question='I still need help!'>
            If you messaged the brand and did not receive the help you needed, contact
            <a href="mailto:hello@localshops.com" target="_blank" className="text-yellow-600"> hello@localshops.com </a>
            and we would be happy to assist you.
          </Question>
        </div>
      </div>
    </ScrollToTop>
  )
}

export default ReturnsAndExchanges;
