import React from 'react';

const TableHeader = ({ headerClassName, labelClassName }) => {
  return (
    <thead className={headerClassName}>
      <tr>
        <th className={labelClassName}>Product</th>
        <th className={labelClassName}>Order Number</th>
        <th className={`xl:table-cell hidden ${labelClassName}`}>Seller</th>
        <th className={labelClassName}>Date</th>
        <th className={`xl:table-cell hidden ${labelClassName}`}>Items</th>
        <th className={labelClassName}>Sub Total</th>
        <th className={labelClassName}>Shipping</th>
        <th className={labelClassName}>Taxes</th>
        <th className={labelClassName}>Total Price</th>
        <th className={labelClassName}>Details</th>
      </tr>
    </thead >
  )
}

export default TableHeader;
