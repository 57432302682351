import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import MapIcon from '../integration_items/map-icon';

export const ShopCity = ({ shop }) => {
  return shop.city ? <div className='flex-1 gap-1 inline-flex items-center'>
    <MapIcon className='h-5 w-5 mr-3' />
    <p> {shop.city.name}</p>
  </div> : <a href={`/merchants/integrations/${shop.id}/edit`}
              className='flex flex-row items-center uppercase hover:underline'>
    <FontAwesomeIcon icon={faExclamationTriangle} className='text-yellow-400 mr-1' />
    <div>City is missing</div>
  </a>
}
